.image-container[data-v-592f2e13] {
    display: flex;
    margin: 0px auto 0px auto;
    max-width: 100%;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.image-container[data-v-592f2e13]:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}