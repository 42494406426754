.list-top-item[data-v-e6298558] {
    list-style: none;
}
.list-item[data-v-e6298558] {
    list-style: none;
    width: auto;
    border-top: 1px solid rgb(205, 205, 205);
}
.side-bar-main[data-v-e6298558] {
    height: 100vh;
    width: 300px;
    max-width: 300px;
    position: fixed;
    background-color: white;
    overflow-y: scroll;
    z-index: 110;
}
.sidebar-back[data-v-e6298558] {
    color: var(--custom-primary-6);
    margin: 10px 0px 0px 10px;
    cursor: pointer;
}
.avatar-container[data-v-e6298558] {
    padding-top: 20%;
    padding-bottom: 10%;
}
.avatar-container > .inner[data-v-e6298558] {
    overflow: hidden;
}
.avatar-view[data-v-e6298558] {
    width: 60%;
    display: block;
    margin: auto;
    border-radius: 120px;
    transition: all 0.1s ease-in-out;
}
.avatar-view[data-v-e6298558]:hover {
    border: solid 1px var(--custom-primary-color);
}
.text-style[data-v-e6298558] {
    color: black;
    text-align: center;
}
.icon-style[data-v-e6298558] {
    margin-left: 10px;
    margin-right: 10px;
}
.user-title[data-v-e6298558] {
    display: block;
    font-size: large;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 5%;
}
.user-description[data-v-e6298558] {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;
    font-weight: lighter;
    margin-top: 2%;
}
.powered-title[data-v-e6298558] {
    display: flex;
    padding: 5%;
    justify-content: center;
}
@media screen and (min-width: 900px) {
.sidebar-back[data-v-e6298558] {
        display: none;
}
}