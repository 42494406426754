.checkout-btn[data-v-abe7250c] {
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
    padding: 1% 3% 1% 4%;
    box-shadow: 0 3px 15px rgb(0 0 0 / 0.1);
    border-radius: 25px;
    cursor: pointer;
    background-color: var(--custom-primary-color);

    -webkit-animation: idle-animation-data-v-abe7250c 1s infinite;

            animation: idle-animation-data-v-abe7250c 1s infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}
@-webkit-keyframes idle-animation-data-v-abe7250c {
0% {
        -webkit-transform: translate(-7px);
                transform: translate(-7px);
}
100% {
        -webkit-transform: translate(7px);
                transform: translate(7px);
        box-shadow: 0 3px 20px rgb(0 0 0 / 0.5);
}
}
@keyframes idle-animation-data-v-abe7250c {
0% {
        -webkit-transform: translate(-7px);
                transform: translate(-7px);
}
100% {
        -webkit-transform: translate(7px);
                transform: translate(7px);
        box-shadow: 0 3px 20px rgb(0 0 0 / 0.5);
}
}
.checkout-btn-title[data-v-abe7250c] {
    color: white;
    margin-left: 15px;
    font-size: 20px;
    font-weight: lighter;
}
.checkout-btn-icon[data-v-abe7250c] {
    color: white;
    font-size: 20px;
}
svg[data-v-abe7250c] {
    display: block;
}
.forward-icon[data-v-abe7250c] {
    margin-left: auto;
    color: white;
    font-size: 20px;
}
@media screen and (max-width: 700px) {
.checkout-btn[data-v-abe7250c] {
        width: 93%;
        padding: 1.5% 4% 1.5% 7%;
        border-radius: 10px;
}
}