.condiment-modal[data-v-68fb6c5a] {
    text-align: center;
}
.item-box[data-v-68fb6c5a] {
    display: block;
    margin: auto;
    height: 270px;
    width: 65%;
    padding-bottom: 5%;
}
.item-decoration[data-v-68fb6c5a] {
    color: black;
}
.item-price[data-v-68fb6c5a] {
    margin-left: auto;
}
.checkbox-item[data-v-68fb6c5a] {
    display: block;
}
.add-to-cart-btn[data-v-68fb6c5a] {
    display: flex;
    margin: auto;
    margin-top: 30px;
    width: 80%;
}
.btn-item[data-v-68fb6c5a] {
    flex: 1 1;
    text-align: center;
}
.btn-icon[data-v-68fb6c5a] {
    margin-left: 10px;
    margin-bottom: -2px;
}