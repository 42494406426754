.combo-carousel[data-v-58b59973] {
    overflow-x: auto;
    padding-top: 100px;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
}
.combo-container[data-v-58b59973] {
    display: flex;
    align-items: center;
    height: 200px;
    width: 400px;
    display: inline-block;
    margin: 15px 15px;

    -webkit-user-select: none;

            user-select: none;
    cursor: pointer;
}
.combo-content[data-v-58b59973] {
    background-color: white;

    display: flex;
    height: 100%;
    width: 100%;
    padding: 25px;
    margin-bottom: 0px;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    scroll-snap-align: center;
}
.combo-content:hover .menu-item-image[data-v-58b59973] {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}
.combo-menu-item[data-v-58b59973] {
    margin-top: -200px;

    width: 180px;
    height: 180px;
    border-radius: 100px;
    background-color: grey;
    overflow: hidden;
}
.combo-menu-item-content[data-v-58b59973] {
    text-align: center;

    height: 45%;
    width: 100%;
}
.item-title[data-v-58b59973] {
    text-overflow: ellipsis;
    overflow: hidden;

    font-size: 20px;
    font-weight: 1000;
}
.item-description[data-v-58b59973] {
    text-overflow: ellipsis;
    overflow: hidden;

    font-size: 13px;
}
.menu-item-image[data-v-58b59973] {
    height: 180px;
    width: 100%;
    object-fit: cover;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.algn-center[data-v-58b59973] {
    display: flex;
    align-items: center;
}
.jfy-center[data-v-58b59973] {
    display: flex;
    justify-content: center;
}
.jfy-end[data-v-58b59973] {
    display: flex;
    justify-content: flex-end;
}
@media screen and (max-width: 900px) {
.combo-carousel[data-v-58b59973]::-webkit-scrollbar {
        display: none;
}
}