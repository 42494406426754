.success-title[data-v-29a5e7f1] {
    color: green;
    text-align: center;
}
.row-content-center[data-v-29a5e7f1] {
 display: flex;
 justify-content: center;
}
.row-content-between[data-v-29a5e7f1] {
    display: flex;
    justify-content: space-between;
}