.loading-container[data-v-6bc104ff] {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 200;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
}
.loading-logo[data-v-6bc104ff] {
    border-radius: 500px;
    -webkit-animation: zooming-animation-data-v-6bc104ff 0.5s infinite alternate;
            animation: zooming-animation-data-v-6bc104ff 0.5s infinite alternate;
}
.loading-logo-default[data-v-6bc104ff] {
    align-items: center;
}
.primary-color[data-v-6bc104ff] {
    color: var(--custom-primary-color);
}
@-webkit-keyframes zooming-animation-data-v-6bc104ff {
from {
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
}
to {
        -webkit-transform: scale(0.2);
                transform: scale(0.2);
}
}
@keyframes zooming-animation-data-v-6bc104ff {
from {
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
}
to {
        -webkit-transform: scale(0.2);
                transform: scale(0.2);
}
}