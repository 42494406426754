.inner-layout[data-v-da6efe5c] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-top: 130px;
}
.main-container[data-v-da6efe5c] {
    width: 500px;
    height: 300px;
}
.login-title[data-v-da6efe5c] {
    display: flex;
    justify-content: space-around;

    width: 90%;
    margin: 0px auto 20px auto;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 5px solid var(--custom-primary-color);
}
.tab[data-v-da6efe5c] {
    cursor: pointer;
}
.input-holder[data-v-da6efe5c] {
    padding: 7px 9px 7px 9px;
}
.btn-holder[data-v-da6efe5c] {
    padding: 5px 10px 5px 10px;
}
.btn-confirm[data-v-da6efe5c] {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
}
h5[data-v-da6efe5c] {
    text-align: center;
}
.flex-top-bar[data-v-da6efe5c] {
    display: flex;
    justify-content: center;
    box-shadow: 0 5px 10px 5px rgb(0 0 0 / 5%);
    padding: 20px 0px 20px 0px;
    z-index: 5;
}
.flex-content[data-v-da6efe5c] {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flex-group[data-v-da6efe5c] {
    display: flex;
    align-items: center;
    line-height: 30px;
}
.flex-title[data-v-da6efe5c] {
    font-size: 30px;
    font-weight: 600;
}
.flex-subtitle[data-v-da6efe5c] {
    display: block;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
}
.flex-title-icon[data-v-da6efe5c] {
    font-size: 25px;
    margin-right: 15px;
    color: var(--custom-primary-color);
}
.store-logo[data-v-da6efe5c] {
    height: 135px;
    width: 150px;
    padding: 10px;
}
@media screen and (max-width: 500px) {
.main-container[data-v-da6efe5c] {
        width: 100%;
}
}