.combo-item-summary[data-v-2e56e9e3] {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(238, 238, 238);
}
.combo-item-summary > .content-topic[data-v-2e56e9e3] {
    display: flex;
    justify-content: space-between;
    padding: 10px 8px 10px 8px;
}
.combo-item-summary > .content-items[data-v-2e56e9e3] {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding: 5px 25px 0px 25px;
}
.combo-item-summary > .content-items > .menu-item[data-v-2e56e9e3] {
    display: flex;
    margin: 0px 10px 0px 10px;
    flex-direction: column;
    align-items: center;
}
.content-items > .menu-item > .menu-title[data-v-2e56e9e3] {
    width: 105px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.combo-item-summary > .content-items > .menu-item > .combo-image[data-v-2e56e9e3] {
    height: 70px;
    width: 75px;
}
.content-topic > .price[data-v-2e56e9e3] {
    font-size: 30px;
}
.combo-modal-footer[data-v-2e56e9e3] {
    display: flex;
    justify-content: space-between;
}
.combo-modal-footer > .item-requirement[data-v-2e56e9e3] {
    display: inline-block;
    text-align: start;
}
.combo-select-page[data-v-2e56e9e3] {
    height: 50%;
    overflow-y: scroll;
}
.extras-page[data-v-2e56e9e3] {
    margin-top: 20px;
    padding: 0px 10px 15px 10px;
    background-color: #ffcbcb;
    border-radius: 20px;
    border: 5px solid #fb8c8c;
    overflow: hidden;
}
.combo-select-page .tab-page[data-v-2e56e9e3] {
    overflow-y: scroll;
}
.combo-item-selection[data-v-2e56e9e3] {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}
.combo-image[data-v-2e56e9e3] {
    height: 62px;
    width: 65px;
    background-color: grey;
    border-radius: 100px;
    overflow: hidden;
}
.combo-image > .inner[data-v-2e56e9e3] {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.combo-item-content[data-v-2e56e9e3] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
}
.combo-layout-text[data-v-2e56e9e3] {
    text-align: start;
    width: 230px;
}
.title-text[data-v-2e56e9e3] {
    font-weight: bold;
    font-size: large;
}
.subtitle-text[data-v-2e56e9e3] {
    text-align: center;
    font-weight: lighter;
    font-size: small;
}
.subtitle2-text[data-v-2e56e9e3] {
    text-align: left;
    font-weight: lighter;
    font-size: small;
}
.required[data-v-2e56e9e3] {
    background-color: #ff4343;
    color: white;
    border-radius: 5px;
}
.combo-layout-btn[data-v-2e56e9e3] {
    display: flex;
    justify-content: space-between;
    width: 130px;
}
.combo-layout-btn > .btn-value[data-v-2e56e9e3] {
    margin: 0px 10px 0px 10px;
}
@media screen and (max-width: 525px) {
.combo-layout-text[data-v-2e56e9e3] {
        width: 180px;
}
.combo-item-content[data-v-2e56e9e3] {
        flex-direction: column;
        align-items: start;
}
}