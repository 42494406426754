.upper-bar[data-v-734b2e15] {
    width: 100%;
}
.upper-bar-item[data-v-734b2e15] {
    margin-left: 20px;
    font-size: larger;
    text-align: left;
    font-weight: bolder;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}
.search-bar[data-v-734b2e15] {
    width: 50%;
    margin-left: auto;
    display: block;
}
.top-bar-item[data-v-734b2e15] {
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgb(213, 213, 213);
    padding-top: 15px;
    padding-bottom: 5px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}
.top-bar-item:hover .bar-item-hover[data-v-734b2e15] {
    border: 4px solid var(--custom-primary-color);
}
.top-bar-item-content[data-v-734b2e15] {
    text-align: center;
    word-wrap: break-word;
}
.top-bar-table[data-v-734b2e15] {
    background-color: var(--custom-primary-color);
}
.top-bar-wrapper[data-v-734b2e15] {
    background-color: white;
    padding: 10px; 
    display: flex; 
    justify-content: center;
}
.bottom-indicator[data-v-734b2e15] {
    margin: auto auto 0 auto;
    width: 80%;
    border-bottom: 5px solid var(--custom-primary-color);
}
.primary-bar[data-v-734b2e15] {
    margin-left: 0px !important;
    margin-right: 0px !important;
    border: 1px solid rgb(213, 213, 213);
    overflow-x: scroll;
}
.hidebar .item-container[data-v-734b2e15] {
    height: 0px;
    width: 0px;
}
.item-container[data-v-734b2e15] {
    height: 80px;
    width: 85px;
    border-radius: 50px;
    margin: 0 auto 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-delay: 0.3s;
}
.item-img[data-v-734b2e15] {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.item-small[data-v-734b2e15] {
    height: 65px;
    width: 65px;
}
.small-font[data-v-734b2e15] {
    font-size: smaller;
}
@media screen and (min-width: 900px) {
.sidebar-btn[data-v-734b2e15] {
        display: none;
}
}
@media screen and (max-width: 900px) {
.primary-bar[data-v-734b2e15]::-webkit-scrollbar {
        display: none;
}
}
@media screen and (max-width: 600px) {
.search-bar[data-v-734b2e15] {
        width: 100%;
}
.upper-bar-item[data-v-734b2e15] {
        margin-left: 0px;
        text-align: center;
}
}