.recommend-container[data-v-2bd031fe] {
    margin: auto;
    width: 1200px;
    background-color: #ededed;
    padding: 10px 5px 10px 5px;
}
ul[data-v-2bd031fe] {
    padding: 0px;
    overflow-x: scroll;
    white-space: nowrap;
}
li[data-v-2bd031fe] {
    display: inline-block;
    margin: 0px 10px 0px 10px;
}
.recommend-text[data-v-2bd031fe] {
    text-transform: uppercase;
    padding-left: 20px;
}
.box-property[data-v-2bd031fe] {
    display: table;
    background-color: white;
    border-spacing: 10px;
    border-radius: 10px;
    box-shadow: 11px 11px 7px -10px rgb(0 0 0 / 15%) !important;
}
.item-box[data-v-2bd031fe] {
    display: block;
    object-fit: contain;
    max-height: 120px;
    width: 150px;
}
.box-group[data-v-2bd031fe] {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 35px;
}
.box-group-item[data-v-2bd031fe] {
    display: block;
}
.box-item-title[data-v-2bd031fe] {
    width: 150px;
}
.add-btn[data-v-2bd031fe] {
    display: flex;
    justify-content: right;
}
@media screen and (max-width: 1200px) {
.recommend-container[data-v-2bd031fe] {
        width: auto;
}
}