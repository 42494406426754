.row[data-v-45bcdf97] {
    display: flex;
}
.row-between[data-v-45bcdf97] {
    justify-content: space-between;
}
.row-center[data-v-45bcdf97] {
    justify-content: center;
}
.row-item[data-v-45bcdf97] {
    padding: 0px;
    margin: 15px auto 15px auto;
    display: inline-block;
    height: 150px;
    width: 550px;
    border-radius: 13px;
    box-shadow: 0 3px 15px rgb(0 0 0 / 0.1);
    overflow: hidden;

    cursor: pointer;
}
.item-decor[data-v-45bcdf97]:hover {
    border: 2px solid var(--custom-primary-color);
}
.item-content[data-v-45bcdf97] {
    display: flex;
    justify-content: center;
    background-color: white;
}
.side-content[data-v-45bcdf97] {
    width: 30%;
}
.side-image[data-v-45bcdf97] {
    display: flex;
    margin: auto;
    padding: 12px;
    height: 80%;
    width: 80%;
    border-radius: 100px;
}
.side-subtitle[data-v-45bcdf97] {
    text-align: center;
}
.main-content[data-v-45bcdf97] {
    width: 70%;
    padding: 12px 0px 12px 12px;
}
.main-sidetitle[data-v-45bcdf97] {
    display: block;
    font-weight: light;
    font-size: large;
    text-align: center;
}
.content-title[data-v-45bcdf97] {
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
    width: 100%;
    font-weight: light;
    font-size: large;
}
.content-item[data-v-45bcdf97] {
    display: inline-block;
    width: 70%;
}
.modal-content[data-v-45bcdf97] {
    padding: 10px;
}
.order-item-subtitle[data-v-45bcdf97] {
    width: 40%;
    word-wrap: break-word;
}
.order-item-midtitle[data-v-45bcdf97] {
    width: 20%;
    text-align: center;
}
@media screen and (max-width: 550px) {
.row-item[data-v-45bcdf97] {
        margin-left: 5px;
        margin-right: 5px;
}
.content-title[data-v-45bcdf97] {
        font-size: small;
}
.main-sidetitle[data-v-45bcdf97] {
        font-size: small;
}
@media (max-width: 480px) {
.side-image[data-v-45bcdf97] {
            height: 80%;
            width: 90%;
}
}
@media (max-width: 420px) {
.side-image[data-v-45bcdf97] {
            width: 100%;
}
}
@media (max-width: 360px) {
.side-image[data-v-45bcdf97] {
            height: 70%;
}
}
}