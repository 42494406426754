/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html {
    --custom-primary-color: #1890ff;
    --custom-primary-color-hover: #40a9ff;
    --custom-primary-color-active: #096dd9;
    --custom-primary-color-outline: rgba(24, 144, 255, 0.2);
    --custom-primary-1: #e6f7ff;
    --custom-primary-2: #bae7ff;
    --custom-primary-3: #91d5ff;
    --custom-primary-4: #69c0ff;
    --custom-primary-5: #40a9ff;
    --custom-primary-6: #1890ff;
    --custom-primary-7: #096dd9;
    --custom-primary-color-deprecated-pure: ;
    --custom-primary-color-deprecated-l-35: #cbe6ff;
    --custom-primary-color-deprecated-l-20: #7ec1ff;
    --custom-primary-color-deprecated-t-20: #46a6ff;
    --custom-primary-color-deprecated-t-50: #8cc8ff;
    --custom-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
    --custom-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
    --custom-primary-color-active-deprecated-d-02: #dcf4ff;
    --custom-success-color: #52c41a;
    --custom-success-color-hover: #73d13d;
    --custom-success-color-active: #389e0d;
    --custom-success-color-outline: rgba(82, 196, 26, 0.2);
    --custom-success-color-deprecated-bg: #f6ffed;
    --custom-success-color-deprecated-border: #b7eb8f;
    --custom-error-color: #ff4d4f;
    --custom-error-color-hover: #ff7875;
    --custom-error-color-active: #d9363e;
    --custom-error-color-outline: rgba(255, 77, 79, 0.2);
    --custom-error-color-deprecated-bg: #fff2f0;
    --custom-error-color-deprecated-border: #ffccc7;
    --custom-warning-color: #faad14;
    --custom-warning-color-hover: #ffc53d;
    --custom-warning-color-active: #d48806;
    --custom-warning-color-outline: rgba(250, 173, 20, 0.2);
    --custom-warning-color-deprecated-bg: #fffbe6;
    --custom-warning-color-deprecated-border: #ffe58f;
    --custom-info-color: #1890ff;
    --custom-info-color-deprecated-bg: #e6f7ff;
    --custom-info-color-deprecated-border: #91d5ff;
  }
  [class^=custom-]::-ms-clear,
  [class*= custom-]::-ms-clear,
  [class^=custom-] input::-ms-clear,
  [class*= custom-] input::-ms-clear,
  [class^=custom-] input::-ms-reveal,
  [class*= custom-] input::-ms-reveal {
    display: none;
  }
  /* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
  html,
  body {
    width: 100%;
    height: 100%;
  }
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
  }
  [tabindex='-1']:focus {
    outline: none !important;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }
  p {
    margin-top: 0;
    margin-bottom: 1em;
  }
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }
  address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }
  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    -webkit-appearance: none;
  }
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1em;
  }
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  dt {
    font-weight: 500;
  }
  dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1em;
  }
  dfn {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #1890ff;
    color: var(--custom-primary-color);
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
  }
  a:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
  }
  a:active,
  a:hover {
    text-decoration: none;
    outline: 0;
  }
  a:focus {
    text-decoration: none;
    outline: 0;
  }
  a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  pre,
  code,
  kbd,
  samp {
    font-size: 1em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
  }
  figure {
    margin: 0 0 1em;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  a,
  area,
  button,
  [role='button'],
  input:not([type='range']),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.45);
    text-align: left;
    caption-side: bottom;
  }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    -webkit-appearance: listbox;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  mark {
    padding: 0.2em;
    background-color: #feffe6;
  }
  ::selection {
    color: #fff;
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .clearfix::before {
    display: table;
    content: '';
  }
  .clearfix::after {
    display: table;
    clear: both;
    content: '';
  }
  .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon > * {
    line-height: 1;
  }
  .anticon svg {
    display: inline-block;
  }
  .anticon::before {
    display: none;
  }
  .anticon .anticon-icon {
    display: block;
  }
  .anticon > .anticon {
    line-height: 0;
    vertical-align: 0;
  }
  .anticon[tabindex] {
    cursor: pointer;
  }
  .anticon-spin,
  .anticon-spin::before {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .custom-fade-enter,
  .custom-fade-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-fade-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-fade-enter.custom-fade-enter-active,
  .custom-fade-appear.custom-fade-appear-active {
    -webkit-animation-name: antFadeIn;
            animation-name: antFadeIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-fade-leave.custom-fade-leave-active {
    -webkit-animation-name: antFadeOut;
            animation-name: antFadeOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-fade-enter,
  .custom-fade-appear {
    opacity: 0;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .custom-fade-leave {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  @-webkit-keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .custom-move-up-enter,
  .custom-move-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-up-enter.custom-move-up-enter-active,
  .custom-move-up-appear.custom-move-up-appear-active {
    -webkit-animation-name: antMoveUpIn;
            animation-name: antMoveUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-move-up-leave.custom-move-up-leave-active {
    -webkit-animation-name: antMoveUpOut;
            animation-name: antMoveUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-move-up-enter,
  .custom-move-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-move-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .custom-move-down-enter,
  .custom-move-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-down-enter.custom-move-down-enter-active,
  .custom-move-down-appear.custom-move-down-appear-active {
    -webkit-animation-name: antMoveDownIn;
            animation-name: antMoveDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-move-down-leave.custom-move-down-leave-active {
    -webkit-animation-name: antMoveDownOut;
            animation-name: antMoveDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-move-down-enter,
  .custom-move-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-move-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .custom-move-left-enter,
  .custom-move-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-left-enter.custom-move-left-enter-active,
  .custom-move-left-appear.custom-move-left-appear-active {
    -webkit-animation-name: antMoveLeftIn;
            animation-name: antMoveLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-move-left-leave.custom-move-left-leave-active {
    -webkit-animation-name: antMoveLeftOut;
            animation-name: antMoveLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-move-left-enter,
  .custom-move-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-move-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .custom-move-right-enter,
  .custom-move-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-move-right-enter.custom-move-right-enter-active,
  .custom-move-right-appear.custom-move-right-appear-active {
    -webkit-animation-name: antMoveRightIn;
            animation-name: antMoveRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-move-right-leave.custom-move-right-leave-active {
    -webkit-animation-name: antMoveRightOut;
            animation-name: antMoveRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-move-right-enter,
  .custom-move-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-move-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  @-webkit-keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  [custom-click-animating='true'],
  [custom-click-animating-without-extra-node='true'] {
    position: relative;
  }
  html {
    --antd-wave-shadow-color: var(--custom-primary-color);
    --scroll-bar: 0;
  }
  [custom-click-animating-without-extra-node='true']::after,
  .custom-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #1890ff;
    box-shadow: 0 0 0 0 var(--custom-primary-color);
    box-shadow: 0 0 0 0 #1890ff;
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    opacity: 0.2;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
            animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
  }
  @-webkit-keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #1890ff;
      box-shadow: 0 0 0 var(--custom-primary-color);
      box-shadow: 0 0 0 6px #1890ff;
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #1890ff;
      box-shadow: 0 0 0 var(--custom-primary-color);
      box-shadow: 0 0 0 6px #1890ff;
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @-webkit-keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  .custom-slide-up-enter,
  .custom-slide-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-up-enter.custom-slide-up-enter-active,
  .custom-slide-up-appear.custom-slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-slide-up-leave.custom-slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-slide-up-enter,
  .custom-slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .custom-slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .custom-slide-down-enter,
  .custom-slide-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-down-enter.custom-slide-down-enter-active,
  .custom-slide-down-appear.custom-slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-slide-down-leave.custom-slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-slide-down-enter,
  .custom-slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .custom-slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .custom-slide-left-enter,
  .custom-slide-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-left-enter.custom-slide-left-enter-active,
  .custom-slide-left-appear.custom-slide-left-appear-active {
    -webkit-animation-name: antSlideLeftIn;
            animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-slide-left-leave.custom-slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
            animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-slide-left-enter,
  .custom-slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .custom-slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .custom-slide-right-enter,
  .custom-slide-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-slide-right-enter.custom-slide-right-enter-active,
  .custom-slide-right-appear.custom-slide-right-appear-active {
    -webkit-animation-name: antSlideRightIn;
            animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-slide-right-leave.custom-slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
            animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-slide-right-enter,
  .custom-slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .custom-slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @-webkit-keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  .custom-zoom-enter,
  .custom-zoom-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-enter.custom-zoom-enter-active,
  .custom-zoom-appear.custom-zoom-appear-active {
    -webkit-animation-name: antZoomIn;
            animation-name: antZoomIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-zoom-leave.custom-zoom-leave-active {
    -webkit-animation-name: antZoomOut;
            animation-name: antZoomOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-zoom-enter,
  .custom-zoom-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-zoom-enter-prepare,
  .custom-zoom-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .custom-zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-zoom-big-enter,
  .custom-zoom-big-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-big-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-big-enter.custom-zoom-big-enter-active,
  .custom-zoom-big-appear.custom-zoom-big-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-zoom-big-leave.custom-zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-zoom-big-enter,
  .custom-zoom-big-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-zoom-big-enter-prepare,
  .custom-zoom-big-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .custom-zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-zoom-big-fast-enter,
  .custom-zoom-big-fast-appear {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-big-fast-enter.custom-zoom-big-fast-enter-active,
  .custom-zoom-big-fast-appear.custom-zoom-big-fast-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-zoom-big-fast-leave.custom-zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-zoom-big-fast-enter,
  .custom-zoom-big-fast-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-zoom-big-fast-enter-prepare,
  .custom-zoom-big-fast-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .custom-zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-zoom-up-enter,
  .custom-zoom-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-up-enter.custom-zoom-up-enter-active,
  .custom-zoom-up-appear.custom-zoom-up-appear-active {
    -webkit-animation-name: antZoomUpIn;
            animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-zoom-up-leave.custom-zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
            animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-zoom-up-enter,
  .custom-zoom-up-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-zoom-up-enter-prepare,
  .custom-zoom-up-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .custom-zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-zoom-down-enter,
  .custom-zoom-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-down-enter.custom-zoom-down-enter-active,
  .custom-zoom-down-appear.custom-zoom-down-appear-active {
    -webkit-animation-name: antZoomDownIn;
            animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-zoom-down-leave.custom-zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
            animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-zoom-down-enter,
  .custom-zoom-down-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-zoom-down-enter-prepare,
  .custom-zoom-down-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .custom-zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-zoom-left-enter,
  .custom-zoom-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-left-enter.custom-zoom-left-enter-active,
  .custom-zoom-left-appear.custom-zoom-left-appear-active {
    -webkit-animation-name: antZoomLeftIn;
            animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-zoom-left-leave.custom-zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
            animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-zoom-left-enter,
  .custom-zoom-left-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-zoom-left-enter-prepare,
  .custom-zoom-left-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .custom-zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-zoom-right-enter,
  .custom-zoom-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-zoom-right-enter.custom-zoom-right-enter-active,
  .custom-zoom-right-appear.custom-zoom-right-appear-active {
    -webkit-animation-name: antZoomRightIn;
            animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-zoom-right-leave.custom-zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
            animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .custom-zoom-right-enter,
  .custom-zoom-right-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .custom-zoom-right-enter-prepare,
  .custom-zoom-right-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .custom-zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  @-webkit-keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @-webkit-keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @-webkit-keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @-webkit-keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @-webkit-keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  @keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .ant-motion-collapse-legacy {
    overflow: hidden;
  }
  .ant-motion-collapse-legacy-active {
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .ant-motion-collapse {
    overflow: hidden;
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .custom-affix {
    position: fixed;
    z-index: 10;
  }
  .custom-alert {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    word-wrap: break-word;
    border-radius: 2px;
  }
  .custom-alert-content {
    flex: 1 1;
    min-width: 0;
  }
  .custom-alert-icon {
    margin-right: 8px;
  }
  .custom-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px;
  }
  .custom-alert-success {
    background-color: #f6ffed;
    background-color: var(--custom-success-color-deprecated-bg);
    border: 1px solid #b7eb8f;
    border: 1px solid var(--custom-success-color-deprecated-border);
  }
  .custom-alert-success .custom-alert-icon {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-alert-info {
    background-color: #e6f7ff;
    background-color: var(--custom-info-color-deprecated-bg);
    border: 1px solid #91d5ff;
    border: 1px solid var(--custom-info-color-deprecated-border);
  }
  .custom-alert-info .custom-alert-icon {
    color: #1890ff;
    color: var(--custom-info-color);
  }
  .custom-alert-warning {
    background-color: #fffbe6;
    background-color: var(--custom-warning-color-deprecated-bg);
    border: 1px solid #ffe58f;
    border: 1px solid var(--custom-warning-color-deprecated-border);
  }
  .custom-alert-warning .custom-alert-icon {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-alert-error {
    background-color: #fff2f0;
    background-color: var(--custom-error-color-deprecated-bg);
    border: 1px solid #ffccc7;
    border: 1px solid var(--custom-error-color-deprecated-border);
  }
  .custom-alert-error .custom-alert-icon {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-alert-error .custom-alert-description > pre {
    margin: 0;
    padding: 0;
  }
  .custom-alert-action {
    margin-left: 8px;
  }
  .custom-alert-close-icon {
    margin-left: 8px;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .custom-alert-close-icon .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
  }
  .custom-alert-close-icon .anticon-close:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .custom-alert-close-text {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
  }
  .custom-alert-close-text:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .custom-alert-with-description {
    align-items: flex-start;
    padding: 15px 15px 15px 24px;
  }
  .custom-alert-with-description.custom-alert-no-icon {
    padding: 15px 15px;
  }
  .custom-alert-with-description .custom-alert-icon {
    margin-right: 15px;
    font-size: 24px;
  }
  .custom-alert-with-description .custom-alert-message {
    display: block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
  .custom-alert-message {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-alert-with-description .custom-alert-description {
    display: block;
  }
  .custom-alert.custom-alert-motion-leave {
    overflow: hidden;
    opacity: 1;
    transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-alert.custom-alert-motion-leave-active {
    max-height: 0;
    margin-bottom: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
  .custom-alert-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
  .custom-alert.custom-alert-rtl {
    direction: rtl;
  }
  .custom-alert-rtl .custom-alert-icon {
    margin-right: auto;
    margin-left: 8px;
  }
  .custom-alert-rtl .custom-alert-action {
    margin-right: 8px;
    margin-left: auto;
  }
  .custom-alert-rtl .custom-alert-close-icon {
    margin-right: 8px;
    margin-left: auto;
  }
  .custom-alert-rtl.custom-alert-with-description {
    padding-right: 24px;
    padding-left: 15px;
  }
  .custom-alert-rtl.custom-alert-with-description .custom-alert-icon {
    margin-right: auto;
    margin-left: 15px;
  }
  .custom-anchor {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    padding-left: 2px;
  }
  .custom-anchor-wrapper {
    margin-left: -4px;
    padding-left: 4px;
    overflow: auto;
    background-color: transparent;
  }
  .custom-anchor-ink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .custom-anchor-ink::before {
    position: relative;
    display: block;
    width: 2px;
    height: 100%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.06);
    content: ' ';
  }
  .custom-anchor-ink-ball {
    position: absolute;
    left: 50%;
    display: none;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #1890ff;
    border: 2px solid var(--custom-primary-color);
    border-radius: 8px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    transition: top 0.3s ease-in-out;
  }
  .custom-anchor-ink-ball.visible {
    display: inline-block;
  }
  .custom-anchor-fixed .custom-anchor-ink .custom-anchor-ink-ball {
    display: none;
  }
  .custom-anchor-link {
    padding: 4px 0 4px 16px;
  }
  .custom-anchor-link-title {
    position: relative;
    display: block;
    margin-bottom: 3px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
  }
  .custom-anchor-link-title:only-child {
    margin-bottom: 0;
  }
  .custom-anchor-link-active > .custom-anchor-link-title {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-anchor-link .custom-anchor-link {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .custom-anchor-rtl {
    direction: rtl;
  }
  .custom-anchor-rtl.custom-anchor-wrapper {
    margin-right: -4px;
    margin-left: 0;
    padding-right: 4px;
    padding-left: 0;
  }
  .custom-anchor-rtl .custom-anchor-ink {
    right: 0;
    left: auto;
  }
  .custom-anchor-rtl .custom-anchor-ink-ball {
    right: 50%;
    left: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .custom-anchor-rtl .custom-anchor-link {
    padding: 4px 16px 4px 0;
  }
  .custom-select-auto-complete {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
  }
  .custom-select-auto-complete .custom-select-clear {
    right: 13px;
  }
  .custom-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
  }
  .custom-avatar-image {
    background: transparent;
  }
  .custom-avatar .custom-image-img {
    display: block;
  }
  .custom-avatar-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .custom-avatar.custom-avatar-icon {
    font-size: 18px;
  }
  .custom-avatar.custom-avatar-icon > .anticon {
    margin: 0;
  }
  .custom-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
  }
  .custom-avatar-lg-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .custom-avatar-lg.custom-avatar-icon {
    font-size: 24px;
  }
  .custom-avatar-lg.custom-avatar-icon > .anticon {
    margin: 0;
  }
  .custom-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
  }
  .custom-avatar-sm-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .custom-avatar-sm.custom-avatar-icon {
    font-size: 14px;
  }
  .custom-avatar-sm.custom-avatar-icon > .anticon {
    margin: 0;
  }
  .custom-avatar-square {
    border-radius: 2px;
  }
  .custom-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .custom-avatar-group {
    display: inline-flex;
  }
  .custom-avatar-group .custom-avatar {
    border: 1px solid #fff;
  }
  .custom-avatar-group .custom-avatar:not(:first-child) {
    margin-left: -8px;
  }
  .custom-avatar-group-popover .custom-avatar + .custom-avatar {
    margin-left: 3px;
  }
  .custom-avatar-group-rtl .custom-avatar:not(:first-child) {
    margin-right: -8px;
    margin-left: 0;
  }
  .custom-avatar-group-popover.custom-popover-rtl .custom-avatar + .custom-avatar {
    margin-right: 3px;
    margin-left: 0;
  }
  .custom-back-top {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: fixed;
    right: 100px;
    bottom: 50px;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .custom-back-top:empty {
    display: none;
  }
  .custom-back-top-rtl {
    right: auto;
    left: 100px;
    direction: rtl;
  }
  .custom-back-top-content {
    width: 40px;
    height: 40px;
    overflow: hidden;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 20px;
    transition: all 0.3s;
  }
  .custom-back-top-content:hover {
    background-color: rgba(0, 0, 0, 0.85);
    transition: all 0.3s;
  }
  .custom-back-top-icon {
    font-size: 24px;
    line-height: 40px;
  }
  @media screen and (max-width: 768px) {
    .custom-back-top {
      right: 60px;
    }
    .custom-back-top-rtl {
      right: auto;
      left: 60px;
    }
  }
  @media screen and (max-width: 480px) {
    .custom-back-top {
      right: 20px;
    }
    .custom-back-top-rtl {
      right: auto;
      left: 20px;
    }
  }
  .custom-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    line-height: 1;
  }
  .custom-badge-count {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
  }
  .custom-badge-count a,
  .custom-badge-count a:hover {
    color: #fff;
  }
  .custom-badge-count-sm {
    min-width: 14px;
    height: 14px;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    border-radius: 7px;
  }
  .custom-badge-multiple-words {
    padding: 0 8px;
  }
  .custom-badge-dot {
    z-index: auto;
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: #ff4d4f;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
  }
  .custom-badge-dot.custom-scroll-number {
    transition: background 1.5s;
  }
  .custom-badge-count,
  .custom-badge-dot,
  .custom-badge .custom-scroll-number-custom-component {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  .custom-badge-count.anticon-spin,
  .custom-badge-dot.anticon-spin,
  .custom-badge .custom-scroll-number-custom-component.anticon-spin {
    -webkit-animation: antBadgeLoadingCircle 1s infinite linear;
            animation: antBadgeLoadingCircle 1s infinite linear;
  }
  .custom-badge-status {
    line-height: inherit;
    vertical-align: baseline;
  }
  .custom-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%;
  }
  .custom-badge-status-success {
    background-color: #52c41a;
    background-color: var(--custom-success-color);
  }
  .custom-badge-status-processing {
    position: relative;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
  }
  .custom-badge-status-processing::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    border-radius: 50%;
    -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
            animation: antStatusProcessing 1.2s infinite ease-in-out;
    content: '';
  }
  .custom-badge-status-default {
    background-color: #d9d9d9;
  }
  .custom-badge-status-error {
    background-color: #ff4d4f;
    background-color: var(--custom-error-color);
  }
  .custom-badge-status-warning {
    background-color: #faad14;
    background-color: var(--custom-warning-color);
  }
  .custom-badge-status-pink {
    background: #eb2f96;
  }
  .custom-badge-status-magenta {
    background: #eb2f96;
  }
  .custom-badge-status-red {
    background: #f5222d;
  }
  .custom-badge-status-volcano {
    background: #fa541c;
  }
  .custom-badge-status-orange {
    background: #fa8c16;
  }
  .custom-badge-status-yellow {
    background: #fadb14;
  }
  .custom-badge-status-gold {
    background: #faad14;
  }
  .custom-badge-status-cyan {
    background: #13c2c2;
  }
  .custom-badge-status-lime {
    background: #a0d911;
  }
  .custom-badge-status-green {
    background: #52c41a;
  }
  .custom-badge-status-blue {
    background: #1890ff;
  }
  .custom-badge-status-geekblue {
    background: #2f54eb;
  }
  .custom-badge-status-purple {
    background: #722ed1;
  }
  .custom-badge-status-text {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
  .custom-badge-zoom-appear,
  .custom-badge-zoom-enter {
    -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
            animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .custom-badge-zoom-leave {
    -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
            animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .custom-badge-not-a-wrapper .custom-badge-zoom-appear,
  .custom-badge-not-a-wrapper .custom-badge-zoom-enter {
    -webkit-animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
            animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
  .custom-badge-not-a-wrapper .custom-badge-zoom-leave {
    -webkit-animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
            animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  }
  .custom-badge-not-a-wrapper:not(.custom-badge-status) {
    vertical-align: middle;
  }
  .custom-badge-not-a-wrapper .custom-scroll-number-custom-component,
  .custom-badge-not-a-wrapper .custom-badge-count {
    -webkit-transform: none;
            transform: none;
  }
  .custom-badge-not-a-wrapper .custom-scroll-number-custom-component,
  .custom-badge-not-a-wrapper .custom-scroll-number {
    position: relative;
    top: auto;
    display: block;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  @-webkit-keyframes antStatusProcessing {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
              transform: scale(2.4);
      opacity: 0;
    }
  }
  @keyframes antStatusProcessing {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
              transform: scale(2.4);
      opacity: 0;
    }
  }
  .custom-scroll-number {
    overflow: hidden;
    direction: ltr;
  }
  .custom-scroll-number-only {
    position: relative;
    display: inline-block;
    height: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    /* stylelint-enable property-no-vendor-prefix */
  }
  .custom-scroll-number-only > p.custom-scroll-number-only-unit {
    height: 20px;
    margin: 0;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    /* stylelint-enable property-no-vendor-prefix */
  }
  .custom-scroll-number-symbol {
    vertical-align: top;
  }
  @-webkit-keyframes antZoomBadgeIn {
    0% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
  }
  @keyframes antZoomBadgeIn {
    0% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
  }
  @-webkit-keyframes antZoomBadgeOut {
    0% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
    100% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
  }
  @keyframes antZoomBadgeOut {
    0% {
      -webkit-transform: scale(1) translate(50%, -50%);
              transform: scale(1) translate(50%, -50%);
    }
    100% {
      -webkit-transform: scale(0) translate(50%, -50%);
              transform: scale(0) translate(50%, -50%);
      opacity: 0;
    }
  }
  @-webkit-keyframes antNoWrapperZoomBadgeIn {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes antNoWrapperZoomBadgeIn {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes antNoWrapperZoomBadgeOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
  }
  @keyframes antNoWrapperZoomBadgeOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
  }
  @-webkit-keyframes antBadgeLoadingCircle {
    0% {
      -webkit-transform-origin: 50%;
              transform-origin: 50%;
    }
    100% {
      -webkit-transform: translate(50%, -50%) rotate(360deg);
              transform: translate(50%, -50%) rotate(360deg);
      -webkit-transform-origin: 50%;
              transform-origin: 50%;
    }
  }
  @keyframes antBadgeLoadingCircle {
    0% {
      -webkit-transform-origin: 50%;
              transform-origin: 50%;
    }
    100% {
      -webkit-transform: translate(50%, -50%) rotate(360deg);
              transform: translate(50%, -50%) rotate(360deg);
      -webkit-transform-origin: 50%;
              transform-origin: 50%;
    }
  }
  .custom-ribbon-wrapper {
    position: relative;
  }
  .custom-ribbon {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: 8px;
    height: 22px;
    padding: 0 8px;
    color: #fff;
    line-height: 22px;
    white-space: nowrap;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-radius: 2px;
  }
  .custom-ribbon-text {
    color: #fff;
  }
  .custom-ribbon-corner {
    position: absolute;
    top: 100%;
    width: 8px;
    height: 8px;
    color: currentcolor;
    border: 4px solid;
    -webkit-transform: scaleY(0.75);
            transform: scaleY(0.75);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  .custom-ribbon-corner::after {
    position: absolute;
    top: -4px;
    left: -4px;
    width: inherit;
    height: inherit;
    color: rgba(0, 0, 0, 0.25);
    border: inherit;
    content: '';
  }
  .custom-ribbon-color-pink {
    color: #eb2f96;
    background: #eb2f96;
  }
  .custom-ribbon-color-magenta {
    color: #eb2f96;
    background: #eb2f96;
  }
  .custom-ribbon-color-red {
    color: #f5222d;
    background: #f5222d;
  }
  .custom-ribbon-color-volcano {
    color: #fa541c;
    background: #fa541c;
  }
  .custom-ribbon-color-orange {
    color: #fa8c16;
    background: #fa8c16;
  }
  .custom-ribbon-color-yellow {
    color: #fadb14;
    background: #fadb14;
  }
  .custom-ribbon-color-gold {
    color: #faad14;
    background: #faad14;
  }
  .custom-ribbon-color-cyan {
    color: #13c2c2;
    background: #13c2c2;
  }
  .custom-ribbon-color-lime {
    color: #a0d911;
    background: #a0d911;
  }
  .custom-ribbon-color-green {
    color: #52c41a;
    background: #52c41a;
  }
  .custom-ribbon-color-blue {
    color: #1890ff;
    background: #1890ff;
  }
  .custom-ribbon-color-geekblue {
    color: #2f54eb;
    background: #2f54eb;
  }
  .custom-ribbon-color-purple {
    color: #722ed1;
    background: #722ed1;
  }
  .custom-ribbon.custom-ribbon-placement-end {
    right: -8px;
    border-bottom-right-radius: 0;
  }
  .custom-ribbon.custom-ribbon-placement-end .custom-ribbon-corner {
    right: 0;
    border-color: currentcolor transparent transparent currentcolor;
  }
  .custom-ribbon.custom-ribbon-placement-start {
    left: -8px;
    border-bottom-left-radius: 0;
  }
  .custom-ribbon.custom-ribbon-placement-start .custom-ribbon-corner {
    left: 0;
    border-color: currentcolor currentcolor transparent transparent;
  }
  .custom-badge-rtl {
    direction: rtl;
  }
  .custom-badge-rtl.custom-badge:not(.custom-badge-not-a-wrapper) .custom-badge-count,
  .custom-badge-rtl.custom-badge:not(.custom-badge-not-a-wrapper) .custom-badge-dot,
  .custom-badge-rtl.custom-badge:not(.custom-badge-not-a-wrapper) .custom-scroll-number-custom-component {
    right: auto;
    left: 0;
    direction: ltr;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  .custom-badge-rtl.custom-badge:not(.custom-badge-not-a-wrapper) .custom-scroll-number-custom-component {
    right: auto;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  .custom-badge-rtl .custom-badge-status-text {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-badge:not(.custom-badge-not-a-wrapper).custom-badge-rtl .custom-badge-zoom-appear,
  .custom-badge:not(.custom-badge-not-a-wrapper).custom-badge-rtl .custom-badge-zoom-enter {
    -webkit-animation-name: antZoomBadgeInRtl;
            animation-name: antZoomBadgeInRtl;
  }
  .custom-badge:not(.custom-badge-not-a-wrapper).custom-badge-rtl .custom-badge-zoom-leave {
    -webkit-animation-name: antZoomBadgeOutRtl;
            animation-name: antZoomBadgeOutRtl;
  }
  .custom-ribbon-rtl {
    direction: rtl;
  }
  .custom-ribbon-rtl.custom-ribbon-placement-end {
    right: auto;
    right: initial;
    left: -8px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
  .custom-ribbon-rtl.custom-ribbon-placement-end .custom-ribbon-corner {
    right: auto;
    right: initial;
    left: 0;
    border-color: currentcolor currentcolor transparent transparent;
  }
  .custom-ribbon-rtl.custom-ribbon-placement-end .custom-ribbon-corner::after {
    border-color: currentcolor currentcolor transparent transparent;
  }
  .custom-ribbon-rtl.custom-ribbon-placement-start {
    right: -8px;
    left: auto;
    left: initial;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
  }
  .custom-ribbon-rtl.custom-ribbon-placement-start .custom-ribbon-corner {
    right: 0;
    left: auto;
    left: initial;
    border-color: currentcolor transparent transparent currentcolor;
  }
  .custom-ribbon-rtl.custom-ribbon-placement-start .custom-ribbon-corner::after {
    border-color: currentcolor transparent transparent currentcolor;
  }
  @-webkit-keyframes antZoomBadgeInRtl {
    0% {
      -webkit-transform: scale(0) translate(-50%, -50%);
              transform: scale(0) translate(-50%, -50%);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
    }
  }
  @keyframes antZoomBadgeInRtl {
    0% {
      -webkit-transform: scale(0) translate(-50%, -50%);
              transform: scale(0) translate(-50%, -50%);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
    }
  }
  @-webkit-keyframes antZoomBadgeOutRtl {
    0% {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
    }
    100% {
      -webkit-transform: scale(0) translate(-50%, -50%);
              transform: scale(0) translate(-50%, -50%);
      opacity: 0;
    }
  }
  @keyframes antZoomBadgeOutRtl {
    0% {
      -webkit-transform: scale(1) translate(-50%, -50%);
              transform: scale(1) translate(-50%, -50%);
    }
    100% {
      -webkit-transform: scale(0) translate(-50%, -50%);
              transform: scale(0) translate(-50%, -50%);
      opacity: 0;
    }
  }
  .custom-breadcrumb {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .custom-breadcrumb .anticon {
    font-size: 14px;
  }
  .custom-breadcrumb ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .custom-breadcrumb a {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
  }
  .custom-breadcrumb a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-breadcrumb li:last-child {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-breadcrumb li:last-child a {
    color: rgba(0, 0, 0, 0.85);
  }
  li:last-child > .custom-breadcrumb-separator {
    display: none;
  }
  .custom-breadcrumb-separator {
    margin: 0 8px;
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-breadcrumb-link > .anticon + span,
  .custom-breadcrumb-link > .anticon + a {
    margin-left: 4px;
  }
  .custom-breadcrumb-overlay-link > .anticon {
    margin-left: 4px;
  }
  .custom-breadcrumb-rtl {
    direction: rtl;
  }
  .custom-breadcrumb-rtl::before {
    display: table;
    content: '';
  }
  .custom-breadcrumb-rtl::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-breadcrumb-rtl::before {
    display: table;
    content: '';
  }
  .custom-breadcrumb-rtl::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-breadcrumb-rtl > span {
    float: right;
  }
  .custom-breadcrumb-rtl .custom-breadcrumb-link > .anticon + span,
  .custom-breadcrumb-rtl .custom-breadcrumb-link > .anticon + a {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-breadcrumb-rtl .custom-breadcrumb-overlay-link > .anticon {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
            user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
  }
  .custom-btn > .anticon {
    line-height: 1;
  }
  .custom-btn,
  .custom-btn:active,
  .custom-btn:focus {
    outline: 0;
  }
  .custom-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .custom-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }
  .custom-btn[disabled] {
    cursor: not-allowed;
  }
  .custom-btn[disabled] > * {
    pointer-events: none;
  }
  .custom-btn-lg {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
  }
  .custom-btn-sm {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 2px;
  }
  .custom-btn > a:only-child {
    color: currentcolor;
  }
  .custom-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn:hover,
  .custom-btn:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: #fff;
  }
  .custom-btn:hover > a:only-child,
  .custom-btn:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn:hover > a:only-child::after,
  .custom-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: #fff;
  }
  .custom-btn:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn[disabled],
  .custom-btn[disabled]:hover,
  .custom-btn[disabled]:focus,
  .custom-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn[disabled] > a:only-child,
  .custom-btn[disabled]:hover > a:only-child,
  .custom-btn[disabled]:focus > a:only-child,
  .custom-btn[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn[disabled] > a:only-child::after,
  .custom-btn[disabled]:hover > a:only-child::after,
  .custom-btn[disabled]:focus > a:only-child::after,
  .custom-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn:hover,
  .custom-btn:focus,
  .custom-btn:active {
    text-decoration: none;
    background: #fff;
  }
  .custom-btn > span {
    display: inline-block;
  }
  .custom-btn-primary {
    color: #fff;
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
    background: #1890ff;
    background: var(--custom-primary-color);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .custom-btn-primary > a:only-child {
    color: currentcolor;
  }
  .custom-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-primary:hover,
  .custom-btn-primary:focus {
    color: #fff;
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: #40a9ff;
    background: var(--custom-primary-color-hover);
  }
  .custom-btn-primary:hover > a:only-child,
  .custom-btn-primary:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-primary:hover > a:only-child::after,
  .custom-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-primary:active {
    color: #fff;
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: #096dd9;
    background: var(--custom-primary-color-active);
  }
  .custom-btn-primary:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-primary[disabled],
  .custom-btn-primary[disabled]:hover,
  .custom-btn-primary[disabled]:focus,
  .custom-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-primary[disabled] > a:only-child,
  .custom-btn-primary[disabled]:hover > a:only-child,
  .custom-btn-primary[disabled]:focus > a:only-child,
  .custom-btn-primary[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-primary[disabled] > a:only-child::after,
  .custom-btn-primary[disabled]:hover > a:only-child::after,
  .custom-btn-primary[disabled]:focus > a:only-child::after,
  .custom-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-group .custom-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-right-color: var(--custom-primary-5);
    border-left-color: #40a9ff;
    border-left-color: var(--custom-primary-5);
  }
  .custom-btn-group .custom-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9;
  }
  .custom-btn-group .custom-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff;
    border-right-color: var(--custom-primary-5);
  }
  .custom-btn-group .custom-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9;
  }
  .custom-btn-group .custom-btn-primary:last-child:not(:first-child),
  .custom-btn-group .custom-btn-primary + .custom-btn-primary {
    border-left-color: #40a9ff;
    border-left-color: var(--custom-primary-5);
  }
  .custom-btn-group .custom-btn-primary:last-child:not(:first-child)[disabled],
  .custom-btn-group .custom-btn-primary + .custom-btn-primary[disabled] {
    border-left-color: #d9d9d9;
  }
  .custom-btn-ghost {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: transparent;
  }
  .custom-btn-ghost > a:only-child {
    color: currentcolor;
  }
  .custom-btn-ghost > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-ghost:hover,
  .custom-btn-ghost:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: transparent;
  }
  .custom-btn-ghost:hover > a:only-child,
  .custom-btn-ghost:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-ghost:hover > a:only-child::after,
  .custom-btn-ghost:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-ghost:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: transparent;
  }
  .custom-btn-ghost:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-ghost:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-ghost[disabled],
  .custom-btn-ghost[disabled]:hover,
  .custom-btn-ghost[disabled]:focus,
  .custom-btn-ghost[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-ghost[disabled] > a:only-child,
  .custom-btn-ghost[disabled]:hover > a:only-child,
  .custom-btn-ghost[disabled]:focus > a:only-child,
  .custom-btn-ghost[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-ghost[disabled] > a:only-child::after,
  .custom-btn-ghost[disabled]:hover > a:only-child::after,
  .custom-btn-ghost[disabled]:focus > a:only-child::after,
  .custom-btn-ghost[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dashed {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
    border-style: dashed;
  }
  .custom-btn-dashed > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dashed > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dashed:hover,
  .custom-btn-dashed:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: #fff;
  }
  .custom-btn-dashed:hover > a:only-child,
  .custom-btn-dashed:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dashed:hover > a:only-child::after,
  .custom-btn-dashed:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dashed:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: #fff;
  }
  .custom-btn-dashed:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dashed:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dashed[disabled],
  .custom-btn-dashed[disabled]:hover,
  .custom-btn-dashed[disabled]:focus,
  .custom-btn-dashed[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-dashed[disabled] > a:only-child,
  .custom-btn-dashed[disabled]:hover > a:only-child,
  .custom-btn-dashed[disabled]:focus > a:only-child,
  .custom-btn-dashed[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dashed[disabled] > a:only-child::after,
  .custom-btn-dashed[disabled]:hover > a:only-child::after,
  .custom-btn-dashed[disabled]:focus > a:only-child::after,
  .custom-btn-dashed[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-danger {
    color: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
    background: #ff4d4f;
    background: var(--custom-error-color);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .custom-btn-danger > a:only-child {
    color: currentcolor;
  }
  .custom-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-danger:hover,
  .custom-btn-danger:focus {
    color: #fff;
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    background: #ff7875;
    background: var(--custom-error-color-hover);
  }
  .custom-btn-danger:hover > a:only-child,
  .custom-btn-danger:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-danger:hover > a:only-child::after,
  .custom-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-danger:active {
    color: #fff;
    border-color: #d9363e;
    border-color: var(--custom-error-color-active);
    background: #d9363e;
    background: var(--custom-error-color-active);
  }
  .custom-btn-danger:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-danger[disabled],
  .custom-btn-danger[disabled]:hover,
  .custom-btn-danger[disabled]:focus,
  .custom-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-danger[disabled] > a:only-child,
  .custom-btn-danger[disabled]:hover > a:only-child,
  .custom-btn-danger[disabled]:focus > a:only-child,
  .custom-btn-danger[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-danger[disabled] > a:only-child::after,
  .custom-btn-danger[disabled]:hover > a:only-child::after,
  .custom-btn-danger[disabled]:focus > a:only-child::after,
  .custom-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-link {
    color: #1890ff;
    color: var(--custom-primary-color);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
  }
  .custom-btn-link > a:only-child {
    color: currentcolor;
  }
  .custom-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-link:hover,
  .custom-btn-link:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: transparent;
  }
  .custom-btn-link:hover > a:only-child,
  .custom-btn-link:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-link:hover > a:only-child::after,
  .custom-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-link:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: transparent;
  }
  .custom-btn-link:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-link[disabled],
  .custom-btn-link[disabled]:hover,
  .custom-btn-link[disabled]:focus,
  .custom-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-link[disabled] > a:only-child,
  .custom-btn-link[disabled]:hover > a:only-child,
  .custom-btn-link[disabled]:focus > a:only-child,
  .custom-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-link[disabled] > a:only-child::after,
  .custom-btn-link[disabled]:hover > a:only-child::after,
  .custom-btn-link[disabled]:focus > a:only-child::after,
  .custom-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-link:hover {
    background: transparent;
  }
  .custom-btn-link:hover,
  .custom-btn-link:focus,
  .custom-btn-link:active {
    border-color: transparent;
  }
  .custom-btn-link[disabled],
  .custom-btn-link[disabled]:hover,
  .custom-btn-link[disabled]:focus,
  .custom-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-link[disabled] > a:only-child,
  .custom-btn-link[disabled]:hover > a:only-child,
  .custom-btn-link[disabled]:focus > a:only-child,
  .custom-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-link[disabled] > a:only-child::after,
  .custom-btn-link[disabled]:hover > a:only-child::after,
  .custom-btn-link[disabled]:focus > a:only-child::after,
  .custom-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-text {
    color: rgba(0, 0, 0, 0.85);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
  }
  .custom-btn-text > a:only-child {
    color: currentcolor;
  }
  .custom-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-text:hover,
  .custom-btn-text:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: transparent;
  }
  .custom-btn-text:hover > a:only-child,
  .custom-btn-text:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-text:hover > a:only-child::after,
  .custom-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-text:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: transparent;
  }
  .custom-btn-text:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-text[disabled],
  .custom-btn-text[disabled]:hover,
  .custom-btn-text[disabled]:focus,
  .custom-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-text[disabled] > a:only-child,
  .custom-btn-text[disabled]:hover > a:only-child,
  .custom-btn-text[disabled]:focus > a:only-child,
  .custom-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-text[disabled] > a:only-child::after,
  .custom-btn-text[disabled]:hover > a:only-child::after,
  .custom-btn-text[disabled]:focus > a:only-child::after,
  .custom-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-text:hover,
  .custom-btn-text:focus {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }
  .custom-btn-text:active {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
  }
  .custom-btn-text[disabled],
  .custom-btn-text[disabled]:hover,
  .custom-btn-text[disabled]:focus,
  .custom-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-text[disabled] > a:only-child,
  .custom-btn-text[disabled]:hover > a:only-child,
  .custom-btn-text[disabled]:focus > a:only-child,
  .custom-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-text[disabled] > a:only-child::after,
  .custom-btn-text[disabled]:hover > a:only-child::after,
  .custom-btn-text[disabled]:focus > a:only-child::after,
  .custom-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
    background: #fff;
  }
  .custom-btn-dangerous > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous:hover,
  .custom-btn-dangerous:focus {
    color: #ff7875;
    color: var(--custom-error-color-hover);
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    background: #fff;
  }
  .custom-btn-dangerous:hover > a:only-child,
  .custom-btn-dangerous:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous:hover > a:only-child::after,
  .custom-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous:active {
    color: #d9363e;
    color: var(--custom-error-color-active);
    border-color: #d9363e;
    border-color: var(--custom-error-color-active);
    background: #fff;
  }
  .custom-btn-dangerous:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous[disabled],
  .custom-btn-dangerous[disabled]:hover,
  .custom-btn-dangerous[disabled]:focus,
  .custom-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-dangerous[disabled] > a:only-child,
  .custom-btn-dangerous[disabled]:hover > a:only-child,
  .custom-btn-dangerous[disabled]:focus > a:only-child,
  .custom-btn-dangerous[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous[disabled] > a:only-child::after,
  .custom-btn-dangerous[disabled]:hover > a:only-child::after,
  .custom-btn-dangerous[disabled]:focus > a:only-child::after,
  .custom-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-primary {
    color: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
    background: #ff4d4f;
    background: var(--custom-error-color);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .custom-btn-dangerous.custom-btn-primary > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-primary:hover,
  .custom-btn-dangerous.custom-btn-primary:focus {
    color: #fff;
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    background: #ff7875;
    background: var(--custom-error-color-hover);
  }
  .custom-btn-dangerous.custom-btn-primary:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-primary:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-primary:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-primary:active {
    color: #fff;
    border-color: #d9363e;
    border-color: var(--custom-error-color-active);
    background: #d9363e;
    background: var(--custom-error-color-active);
  }
  .custom-btn-dangerous.custom-btn-primary:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-primary[disabled],
  .custom-btn-dangerous.custom-btn-primary[disabled]:hover,
  .custom-btn-dangerous.custom-btn-primary[disabled]:focus,
  .custom-btn-dangerous.custom-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-dangerous.custom-btn-primary[disabled] > a:only-child,
  .custom-btn-dangerous.custom-btn-primary[disabled]:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-primary[disabled]:focus > a:only-child,
  .custom-btn-dangerous.custom-btn-primary[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-primary[disabled] > a:only-child::after,
  .custom-btn-dangerous.custom-btn-primary[disabled]:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-primary[disabled]:focus > a:only-child::after,
  .custom-btn-dangerous.custom-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-link {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
  }
  .custom-btn-dangerous.custom-btn-link > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-link:hover,
  .custom-btn-dangerous.custom-btn-link:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: transparent;
  }
  .custom-btn-dangerous.custom-btn-link:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-link:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-link:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-link:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: transparent;
  }
  .custom-btn-dangerous.custom-btn-link:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-link[disabled],
  .custom-btn-dangerous.custom-btn-link[disabled]:hover,
  .custom-btn-dangerous.custom-btn-link[disabled]:focus,
  .custom-btn-dangerous.custom-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-dangerous.custom-btn-link[disabled] > a:only-child,
  .custom-btn-dangerous.custom-btn-link[disabled]:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-link[disabled]:focus > a:only-child,
  .custom-btn-dangerous.custom-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-link[disabled] > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link[disabled]:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link[disabled]:focus > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-link:hover,
  .custom-btn-dangerous.custom-btn-link:focus {
    color: #ff7875;
    color: var(--custom-error-color-hover);
    border-color: transparent;
    background: transparent;
  }
  .custom-btn-dangerous.custom-btn-link:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-link:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-link:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-link:active {
    color: #d9363e;
    color: var(--custom-error-color-active);
    border-color: transparent;
    background: transparent;
  }
  .custom-btn-dangerous.custom-btn-link:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-link[disabled],
  .custom-btn-dangerous.custom-btn-link[disabled]:hover,
  .custom-btn-dangerous.custom-btn-link[disabled]:focus,
  .custom-btn-dangerous.custom-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-dangerous.custom-btn-link[disabled] > a:only-child,
  .custom-btn-dangerous.custom-btn-link[disabled]:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-link[disabled]:focus > a:only-child,
  .custom-btn-dangerous.custom-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-link[disabled] > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link[disabled]:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link[disabled]:focus > a:only-child::after,
  .custom-btn-dangerous.custom-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-text {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
  }
  .custom-btn-dangerous.custom-btn-text > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-text:hover,
  .custom-btn-dangerous.custom-btn-text:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: transparent;
  }
  .custom-btn-dangerous.custom-btn-text:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-text:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-text:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-text:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: transparent;
  }
  .custom-btn-dangerous.custom-btn-text:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-text[disabled],
  .custom-btn-dangerous.custom-btn-text[disabled]:hover,
  .custom-btn-dangerous.custom-btn-text[disabled]:focus,
  .custom-btn-dangerous.custom-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-dangerous.custom-btn-text[disabled] > a:only-child,
  .custom-btn-dangerous.custom-btn-text[disabled]:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-text[disabled]:focus > a:only-child,
  .custom-btn-dangerous.custom-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-text[disabled] > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text[disabled]:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text[disabled]:focus > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-text:hover,
  .custom-btn-dangerous.custom-btn-text:focus {
    color: #ff7875;
    color: var(--custom-error-color-hover);
    border-color: transparent;
    background: rgba(0, 0, 0, 0.018);
  }
  .custom-btn-dangerous.custom-btn-text:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-text:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-text:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-text:active {
    color: #d9363e;
    color: var(--custom-error-color-active);
    border-color: transparent;
    background: rgba(0, 0, 0, 0.028);
  }
  .custom-btn-dangerous.custom-btn-text:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-dangerous.custom-btn-text[disabled],
  .custom-btn-dangerous.custom-btn-text[disabled]:hover,
  .custom-btn-dangerous.custom-btn-text[disabled]:focus,
  .custom-btn-dangerous.custom-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-dangerous.custom-btn-text[disabled] > a:only-child,
  .custom-btn-dangerous.custom-btn-text[disabled]:hover > a:only-child,
  .custom-btn-dangerous.custom-btn-text[disabled]:focus > a:only-child,
  .custom-btn-dangerous.custom-btn-text[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-dangerous.custom-btn-text[disabled] > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text[disabled]:hover > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text[disabled]:focus > a:only-child::after,
  .custom-btn-dangerous.custom-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-icon-only {
    width: 32px;
    height: 32px;
    padding: 2.4px 0;
    font-size: 16px;
    border-radius: 2px;
    vertical-align: -3px;
  }
  .custom-btn-icon-only > * {
    font-size: 16px;
  }
  .custom-btn-icon-only.custom-btn-lg {
    width: 40px;
    height: 40px;
    padding: 4.9px 0;
    font-size: 18px;
    border-radius: 2px;
  }
  .custom-btn-icon-only.custom-btn-lg > * {
    font-size: 18px;
  }
  .custom-btn-icon-only.custom-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0px 0;
    font-size: 14px;
    border-radius: 2px;
  }
  .custom-btn-icon-only.custom-btn-sm > * {
    font-size: 14px;
  }
  .custom-btn-icon-only > .anticon {
    display: flex;
    justify-content: center;
  }
  .custom-btn-icon-only .anticon-loading {
    padding: 0 !important;
  }
  a.custom-btn-icon-only {
    vertical-align: -1px;
  }
  a.custom-btn-icon-only > .anticon {
    display: inline;
  }
  .custom-btn-round {
    height: 32px;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 32px;
  }
  .custom-btn-round.custom-btn-lg {
    height: 40px;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px;
  }
  .custom-btn-round.custom-btn-sm {
    height: 24px;
    padding: 0px 12px;
    font-size: 14px;
    border-radius: 24px;
  }
  .custom-btn-round.custom-btn-icon-only {
    width: auto;
  }
  .custom-btn-circle {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
  }
  .custom-btn-circle.custom-btn-lg {
    min-width: 40px;
    border-radius: 50%;
  }
  .custom-btn-circle.custom-btn-sm {
    min-width: 24px;
    border-radius: 50%;
  }
  .custom-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none;
  }
  .custom-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-btn .anticon.anticon-plus > svg,
  .custom-btn .anticon.anticon-minus > svg {
    shape-rendering: optimizespeed;
  }
  .custom-btn.custom-btn-loading {
    position: relative;
    cursor: default;
  }
  .custom-btn.custom-btn-loading::before {
    display: block;
  }
  .custom-btn > .custom-btn-loading-icon {
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-btn > .custom-btn-loading-icon .anticon {
    padding-right: 8px;
    -webkit-animation: none;
            animation: none;
  }
  .custom-btn > .custom-btn-loading-icon .anticon svg {
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .custom-btn-group {
    position: relative;
    display: inline-flex;
  }
  .custom-btn-group > .custom-btn,
  .custom-btn-group > span > .custom-btn {
    position: relative;
  }
  .custom-btn-group > .custom-btn:hover,
  .custom-btn-group > span > .custom-btn:hover,
  .custom-btn-group > .custom-btn:focus,
  .custom-btn-group > span > .custom-btn:focus,
  .custom-btn-group > .custom-btn:active,
  .custom-btn-group > span > .custom-btn:active {
    z-index: 2;
  }
  .custom-btn-group > .custom-btn[disabled],
  .custom-btn-group > span > .custom-btn[disabled] {
    z-index: 0;
  }
  .custom-btn-group .custom-btn-icon-only {
    font-size: 14px;
  }
  .custom-btn-group .custom-btn + .custom-btn,
  .custom-btn + .custom-btn-group,
  .custom-btn-group span + .custom-btn,
  .custom-btn-group .custom-btn + span,
  .custom-btn-group > span + span,
  .custom-btn-group + .custom-btn,
  .custom-btn-group + .custom-btn-group {
    margin-left: -1px;
  }
  .custom-btn-group .custom-btn-primary + .custom-btn:not(.custom-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }
  .custom-btn-group .custom-btn {
    border-radius: 0;
  }
  .custom-btn-group > .custom-btn:first-child,
  .custom-btn-group > span:first-child > .custom-btn {
    margin-left: 0;
  }
  .custom-btn-group > .custom-btn:only-child {
    border-radius: 2px;
  }
  .custom-btn-group > span:only-child > .custom-btn {
    border-radius: 2px;
  }
  .custom-btn-group > .custom-btn:first-child:not(:last-child),
  .custom-btn-group > span:first-child:not(:last-child) > .custom-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .custom-btn-group > .custom-btn:last-child:not(:first-child),
  .custom-btn-group > span:last-child:not(:first-child) > .custom-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .custom-btn-group-sm > .custom-btn:only-child {
    border-radius: 2px;
  }
  .custom-btn-group-sm > span:only-child > .custom-btn {
    border-radius: 2px;
  }
  .custom-btn-group-sm > .custom-btn:first-child:not(:last-child),
  .custom-btn-group-sm > span:first-child:not(:last-child) > .custom-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .custom-btn-group-sm > .custom-btn:last-child:not(:first-child),
  .custom-btn-group-sm > span:last-child:not(:first-child) > .custom-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .custom-btn-group > .custom-btn-group {
    float: left;
  }
  .custom-btn-group > .custom-btn-group:not(:first-child):not(:last-child) > .custom-btn {
    border-radius: 0;
  }
  .custom-btn-group > .custom-btn-group:first-child:not(:last-child) > .custom-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-btn-group > .custom-btn-group:last-child:not(:first-child) > .custom-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-btn-rtl.custom-btn-group .custom-btn + .custom-btn,
  .custom-btn-rtl.custom-btn + .custom-btn-group,
  .custom-btn-rtl.custom-btn-group span + .custom-btn,
  .custom-btn-rtl.custom-btn-group .custom-btn + span,
  .custom-btn-rtl.custom-btn-group > span + span,
  .custom-btn-rtl.custom-btn-group + .custom-btn,
  .custom-btn-rtl.custom-btn-group + .custom-btn-group,
  .custom-btn-group-rtl.custom-btn-group .custom-btn + .custom-btn,
  .custom-btn-group-rtl.custom-btn + .custom-btn-group,
  .custom-btn-group-rtl.custom-btn-group span + .custom-btn,
  .custom-btn-group-rtl.custom-btn-group .custom-btn + span,
  .custom-btn-group-rtl.custom-btn-group > span + span,
  .custom-btn-group-rtl.custom-btn-group + .custom-btn,
  .custom-btn-group-rtl.custom-btn-group + .custom-btn-group {
    margin-right: -1px;
    margin-left: auto;
  }
  .custom-btn-group.custom-btn-group-rtl {
    direction: rtl;
  }
  .custom-btn-group-rtl.custom-btn-group > .custom-btn:first-child:not(:last-child),
  .custom-btn-group-rtl.custom-btn-group > span:first-child:not(:last-child) > .custom-btn {
    border-radius: 0 2px 2px 0;
  }
  .custom-btn-group-rtl.custom-btn-group > .custom-btn:last-child:not(:first-child),
  .custom-btn-group-rtl.custom-btn-group > span:last-child:not(:first-child) > .custom-btn {
    border-radius: 2px 0 0 2px;
  }
  .custom-btn-group-rtl.custom-btn-group-sm > .custom-btn:first-child:not(:last-child),
  .custom-btn-group-rtl.custom-btn-group-sm > span:first-child:not(:last-child) > .custom-btn {
    border-radius: 0 2px 2px 0;
  }
  .custom-btn-group-rtl.custom-btn-group-sm > .custom-btn:last-child:not(:first-child),
  .custom-btn-group-rtl.custom-btn-group-sm > span:last-child:not(:first-child) > .custom-btn {
    border-radius: 2px 0 0 2px;
  }
  .custom-btn:focus > span,
  .custom-btn:active > span {
    position: relative;
  }
  .custom-btn > .anticon + span,
  .custom-btn > span + .anticon {
    margin-left: 8px;
  }
  .custom-btn.custom-btn-background-ghost {
    color: #fff;
    border-color: #fff;
  }
  .custom-btn.custom-btn-background-ghost,
  .custom-btn.custom-btn-background-ghost:hover,
  .custom-btn.custom-btn-background-ghost:active,
  .custom-btn.custom-btn-background-ghost:focus {
    background: transparent;
  }
  .custom-btn.custom-btn-background-ghost:hover,
  .custom-btn.custom-btn-background-ghost:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
  }
  .custom-btn.custom-btn-background-ghost:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
  }
  .custom-btn.custom-btn-background-ghost[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: #d9d9d9;
  }
  .custom-btn-background-ghost.custom-btn-primary {
    color: #1890ff;
    color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
    text-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-primary > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-primary:hover,
  .custom-btn-background-ghost.custom-btn-primary:focus {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-primary:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-primary:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-primary:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-primary:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
    border-color: #096dd9;
    border-color: var(--custom-primary-color-active);
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-primary:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-primary[disabled],
  .custom-btn-background-ghost.custom-btn-primary[disabled]:hover,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:focus,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-primary[disabled] > a:only-child,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:focus > a:only-child,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-primary[disabled] > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:focus > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-danger {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
    text-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-danger > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-danger:hover,
  .custom-btn-background-ghost.custom-btn-danger:focus {
    color: #ff7875;
    color: var(--custom-error-color-hover);
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-danger:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-danger:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-danger:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-danger:active {
    color: #d9363e;
    color: var(--custom-error-color-active);
    border-color: #d9363e;
    border-color: var(--custom-error-color-active);
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-danger:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-danger[disabled],
  .custom-btn-background-ghost.custom-btn-danger[disabled]:hover,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:focus,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-danger[disabled] > a:only-child,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:focus > a:only-child,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-danger[disabled] > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:focus > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
    text-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-dangerous > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous:hover,
  .custom-btn-background-ghost.custom-btn-dangerous:focus {
    color: #ff7875;
    color: var(--custom-error-color-hover);
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-dangerous:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous:active {
    color: #d9363e;
    color: var(--custom-error-color-active);
    border-color: #d9363e;
    border-color: var(--custom-error-color-active);
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-dangerous:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous[disabled],
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:hover,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:focus,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-dangerous[disabled] > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:focus > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous[disabled] > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:focus > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: transparent;
    text-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:hover,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:focus {
    color: #d9363e;
    color: var(--custom-error-color-active);
    border-color: transparent;
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:focus > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:active {
    color: #d9363e;
    color: var(--custom-error-color-active);
    border-color: transparent;
    background: transparent;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled],
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:hover,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:focus,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled] > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:hover > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:focus > a:only-child,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:active > a:only-child {
    color: currentcolor;
  }
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled] > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:hover > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:focus > a:only-child::after,
  .custom-btn-background-ghost.custom-btn-dangerous.custom-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .custom-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em;
  }
  .custom-btn-two-chinese-chars > *:not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
  }
  .custom-btn.custom-btn-block {
    width: 100%;
  }
  .custom-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  a.custom-btn {
    padding-top: 0.01px !important;
    line-height: 30px;
  }
  a.custom-btn-disabled {
    cursor: not-allowed;
  }
  a.custom-btn-disabled > * {
    pointer-events: none;
  }
  a.custom-btn-disabled,
  a.custom-btn-disabled:hover,
  a.custom-btn-disabled:focus,
  a.custom-btn-disabled:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  a.custom-btn-disabled > a:only-child,
  a.custom-btn-disabled:hover > a:only-child,
  a.custom-btn-disabled:focus > a:only-child,
  a.custom-btn-disabled:active > a:only-child {
    color: currentcolor;
  }
  a.custom-btn-disabled > a:only-child::after,
  a.custom-btn-disabled:hover > a:only-child::after,
  a.custom-btn-disabled:focus > a:only-child::after,
  a.custom-btn-disabled:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  a.custom-btn-lg {
    line-height: 38px;
  }
  a.custom-btn-sm {
    line-height: 22px;
  }
  .custom-btn-rtl {
    direction: rtl;
  }
  .custom-btn-group-rtl.custom-btn-group .custom-btn-primary:last-child:not(:first-child),
  .custom-btn-group-rtl.custom-btn-group .custom-btn-primary + .custom-btn-primary {
    border-right-color: #40a9ff;
    border-right-color: var(--custom-primary-5);
    border-left-color: #d9d9d9;
  }
  .custom-btn-group-rtl.custom-btn-group .custom-btn-primary:last-child:not(:first-child)[disabled],
  .custom-btn-group-rtl.custom-btn-group .custom-btn-primary + .custom-btn-primary[disabled] {
    border-right-color: #d9d9d9;
    border-left-color: #40a9ff;
    border-left-color: var(--custom-primary-5);
  }
  .custom-btn-rtl.custom-btn > .custom-btn-loading-icon .anticon {
    padding-right: 0;
    padding-left: 8px;
  }
  .custom-btn-rtl.custom-btn > .anticon + span,
  .custom-btn-rtl.custom-btn > span + .anticon {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-picker-calendar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    background: #fff;
  }
  .custom-picker-calendar-header {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
  }
  .custom-picker-calendar-header .custom-picker-calendar-year-select {
    min-width: 80px;
  }
  .custom-picker-calendar-header .custom-picker-calendar-month-select {
    min-width: 70px;
    margin-left: 8px;
  }
  .custom-picker-calendar-header .custom-picker-calendar-mode-switch {
    margin-left: 8px;
  }
  .custom-picker-calendar .custom-picker-panel {
    background: #fff;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0;
  }
  .custom-picker-calendar .custom-picker-panel .custom-picker-month-panel,
  .custom-picker-calendar .custom-picker-panel .custom-picker-date-panel {
    width: auto;
  }
  .custom-picker-calendar .custom-picker-panel .custom-picker-body {
    padding: 8px 0;
  }
  .custom-picker-calendar .custom-picker-panel .custom-picker-content {
    width: 100%;
  }
  .custom-picker-calendar-mini {
    border-radius: 2px;
  }
  .custom-picker-calendar-mini .custom-picker-calendar-header {
    padding-right: 8px;
    padding-left: 8px;
  }
  .custom-picker-calendar-mini .custom-picker-panel {
    border-radius: 0 0 2px 2px;
  }
  .custom-picker-calendar-mini .custom-picker-content {
    height: 256px;
  }
  .custom-picker-calendar-mini .custom-picker-content th {
    height: auto;
    padding: 0;
    line-height: 18px;
  }
  .custom-picker-calendar-mini .custom-picker-cell::before {
    pointer-events: none;
  }
  .custom-picker-calendar-full .custom-picker-panel {
    display: block;
    width: 100%;
    text-align: right;
    background: #fff;
    border: 0;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-body th,
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-body td {
    padding: 0;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-body th {
    height: auto;
    padding: 0 12px 5px 0;
    line-height: 18px;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell::before {
    display: none;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell:hover .custom-picker-calendar-date {
    background: #f5f5f5;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell .custom-picker-calendar-date-today::before {
    display: none;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected .custom-picker-calendar-date,
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected:hover .custom-picker-calendar-date,
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected .custom-picker-calendar-date-today,
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected:hover .custom-picker-calendar-date-today {
    background: #e6f7ff;
    background: var(--custom-primary-1);
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected .custom-picker-calendar-date .custom-picker-calendar-date-value,
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected:hover .custom-picker-calendar-date .custom-picker-calendar-date-value,
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected .custom-picker-calendar-date-today .custom-picker-calendar-date-value,
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-cell-selected:hover .custom-picker-calendar-date-today .custom-picker-calendar-date-value {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-calendar-date {
    display: block;
    width: auto;
    height: auto;
    margin: 0 4px;
    padding: 4px 8px 0;
    border: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.06);
    border-radius: 0;
    transition: background 0.3s;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-calendar-date-value {
    line-height: 24px;
    transition: color 0.3s;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-calendar-date-content {
    position: static;
    width: auto;
    height: 86px;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    text-align: left;
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-calendar-date-today {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-picker-calendar-full .custom-picker-panel .custom-picker-calendar-date-today .custom-picker-calendar-date-value {
    color: rgba(0, 0, 0, 0.85);
  }
  @media only screen and (max-width: 480px) {
    .custom-picker-calendar-header {
      display: block;
    }
    .custom-picker-calendar-header .custom-picker-calendar-year-select {
      width: 50%;
    }
    .custom-picker-calendar-header .custom-picker-calendar-month-select {
      width: calc(50% - 8px);
    }
    .custom-picker-calendar-header .custom-picker-calendar-mode-switch {
      width: 100%;
      margin-top: 8px;
      margin-left: 0;
    }
    .custom-picker-calendar-header .custom-picker-calendar-mode-switch > label {
      width: 50%;
      text-align: center;
    }
  }
  .custom-picker-calendar-rtl {
    direction: rtl;
  }
  .custom-picker-calendar-rtl .custom-picker-calendar-header .custom-picker-calendar-month-select {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-picker-calendar-rtl .custom-picker-calendar-header .custom-picker-calendar-mode-switch {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-picker-calendar-rtl.custom-picker-calendar-full .custom-picker-panel {
    text-align: left;
  }
  .custom-picker-calendar-rtl.custom-picker-calendar-full .custom-picker-panel .custom-picker-body th {
    padding: 0 0 5px 12px;
  }
  .custom-picker-calendar-rtl.custom-picker-calendar-full .custom-picker-panel .custom-picker-calendar-date-content {
    text-align: right;
  }
  .custom-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
  }
  .custom-card-rtl {
    direction: rtl;
  }
  .custom-card-hoverable {
    cursor: pointer;
    transition: box-shadow 0.3s, border-color 0.3s;
  }
  .custom-card-hoverable:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
  .custom-card-bordered {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px 2px 0 0;
  }
  .custom-card-head::before {
    display: table;
    content: '';
  }
  .custom-card-head::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-head::before {
    display: table;
    content: '';
  }
  .custom-card-head::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-head-wrapper {
    display: flex;
    align-items: center;
  }
  .custom-card-head-title {
    display: inline-block;
    flex: 1 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-card-head-title > .custom-typography,
  .custom-card-head-title > .custom-typography-edit-content {
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-card-head .custom-tabs-top {
    clear: both;
    margin-bottom: -17px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
  }
  .custom-card-head .custom-tabs-top-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-card-extra {
    margin-left: auto;
    padding: 16px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
  }
  .custom-card-rtl .custom-card-extra {
    margin-right: auto;
    margin-left: 0;
  }
  .custom-card-body {
    padding: 24px;
  }
  .custom-card-body::before {
    display: table;
    content: '';
  }
  .custom-card-body::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-body::before {
    display: table;
    content: '';
  }
  .custom-card-body::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-contain-grid .custom-card-body {
    display: flex;
    flex-wrap: wrap;
  }
  .custom-card-contain-grid:not(.custom-card-loading) .custom-card-body {
    margin: -1px 0 0 -1px;
    padding: 0;
  }
  .custom-card-grid {
    width: 33.33%;
    padding: 24px;
    border: 0;
    border-radius: 0;
    box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.06), 1px 1px 0 0 rgba(0, 0, 0, 0.06), 1px 0 0 0 rgba(0, 0, 0, 0.06) inset, 0 1px 0 0 rgba(0, 0, 0, 0.06) inset;
    transition: all 0.3s;
  }
  .custom-card-grid-hoverable:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
  .custom-card-contain-tabs > .custom-card-head .custom-card-head-title {
    min-height: 32px;
    padding-bottom: 0;
  }
  .custom-card-contain-tabs > .custom-card-head .custom-card-extra {
    padding-bottom: 0;
  }
  .custom-card-bordered .custom-card-cover {
    margin-top: -1px;
    margin-right: -1px;
    margin-left: -1px;
  }
  .custom-card-cover > * {
    display: block;
    width: 100%;
  }
  .custom-card-cover img {
    border-radius: 2px 2px 0 0;
  }
  .custom-card-actions {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-card-actions::before {
    display: table;
    content: '';
  }
  .custom-card-actions::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-actions::before {
    display: table;
    content: '';
  }
  .custom-card-actions::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-actions > li {
    margin: 12px 0;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }
  .custom-card-actions > li > span {
    position: relative;
    display: block;
    min-width: 32px;
    font-size: 14px;
    line-height: 1.5715;
    cursor: pointer;
  }
  .custom-card-actions > li > span:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
    transition: color 0.3s;
  }
  .custom-card-actions > li > span a:not(.custom-btn),
  .custom-card-actions > li > span > .anticon {
    display: inline-block;
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    transition: color 0.3s;
  }
  .custom-card-actions > li > span a:not(.custom-btn):hover,
  .custom-card-actions > li > span > .anticon:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-card-actions > li > span > .anticon {
    font-size: 16px;
    line-height: 22px;
  }
  .custom-card-actions > li:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-card-rtl .custom-card-actions > li:not(:last-child) {
    border-right: none;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-card-type-inner .custom-card-head {
    padding: 0 24px;
    background: #fafafa;
  }
  .custom-card-type-inner .custom-card-head-title {
    padding: 12px 0;
    font-size: 14px;
  }
  .custom-card-type-inner .custom-card-body {
    padding: 16px 24px;
  }
  .custom-card-type-inner .custom-card-extra {
    padding: 13.5px 0;
  }
  .custom-card-meta {
    display: flex;
    margin: -4px 0;
  }
  .custom-card-meta::before {
    display: table;
    content: '';
  }
  .custom-card-meta::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-meta::before {
    display: table;
    content: '';
  }
  .custom-card-meta::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-card-meta-avatar {
    padding-right: 16px;
  }
  .custom-card-rtl .custom-card-meta-avatar {
    padding-right: 0;
    padding-left: 16px;
  }
  .custom-card-meta-detail {
    overflow: hidden;
  }
  .custom-card-meta-detail > div:not(:last-child) {
    margin-bottom: 8px;
  }
  .custom-card-meta-title {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-card-loading {
    overflow: hidden;
  }
  .custom-card-loading .custom-card-body {
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-card-small > .custom-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: 14px;
  }
  .custom-card-small > .custom-card-head > .custom-card-head-wrapper > .custom-card-head-title {
    padding: 8px 0;
  }
  .custom-card-small > .custom-card-head > .custom-card-head-wrapper > .custom-card-extra {
    padding: 8px 0;
    font-size: 14px;
  }
  .custom-card-small > .custom-card-body {
    padding: 12px;
  }
  .custom-carousel {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
  }
  .custom-carousel .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  .custom-carousel .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .custom-carousel .slick-list:focus {
    outline: none;
  }
  .custom-carousel .slick-list.dragging {
    cursor: pointer;
  }
  .custom-carousel .slick-list .slick-slide {
    pointer-events: none;
  }
  .custom-carousel .slick-list .slick-slide input.custom-radio-input,
  .custom-carousel .slick-list .slick-slide input.custom-checkbox-input {
    visibility: hidden;
  }
  .custom-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto;
  }
  .custom-carousel .slick-list .slick-slide.slick-active input.custom-radio-input,
  .custom-carousel .slick-list .slick-slide.slick-active input.custom-checkbox-input {
    visibility: visible;
  }
  .custom-carousel .slick-list .slick-slide > div > div {
    vertical-align: bottom;
  }
  .custom-carousel .slick-slider .slick-track,
  .custom-carousel .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    touch-action: pan-y;
  }
  .custom-carousel .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }
  .custom-carousel .slick-track::before,
  .custom-carousel .slick-track::after {
    display: table;
    content: '';
  }
  .custom-carousel .slick-track::after {
    clear: both;
  }
  .slick-loading .custom-carousel .slick-track {
    visibility: hidden;
  }
  .custom-carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  .custom-carousel .slick-slide img {
    display: block;
  }
  .custom-carousel .slick-slide.slick-loading img {
    display: none;
  }
  .custom-carousel .slick-slide.dragging img {
    pointer-events: none;
  }
  .custom-carousel .slick-initialized .slick-slide {
    display: block;
  }
  .custom-carousel .slick-loading .slick-slide {
    visibility: hidden;
  }
  .custom-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
  }
  .custom-carousel .slick-arrow.slick-hidden {
    display: none;
  }
  .custom-carousel .slick-prev,
  .custom-carousel .slick-next {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }
  .custom-carousel .slick-prev:hover,
  .custom-carousel .slick-next:hover,
  .custom-carousel .slick-prev:focus,
  .custom-carousel .slick-next:focus {
    color: transparent;
    background: transparent;
    outline: none;
  }
  .custom-carousel .slick-prev:hover::before,
  .custom-carousel .slick-next:hover::before,
  .custom-carousel .slick-prev:focus::before,
  .custom-carousel .slick-next:focus::before {
    opacity: 1;
  }
  .custom-carousel .slick-prev.slick-disabled::before,
  .custom-carousel .slick-next.slick-disabled::before {
    opacity: 0.25;
  }
  .custom-carousel .slick-prev {
    left: -25px;
  }
  .custom-carousel .slick-prev::before {
    content: '←';
  }
  .custom-carousel .slick-next {
    right: -25px;
  }
  .custom-carousel .slick-next::before {
    content: '→';
  }
  .custom-carousel .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex !important;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;
    list-style: none;
  }
  .custom-carousel .slick-dots-bottom {
    bottom: 12px;
  }
  .custom-carousel .slick-dots-top {
    top: 12px;
    bottom: auto;
  }
  .custom-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 16px;
    height: 3px;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
  }
  .custom-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
  }
  .custom-carousel .slick-dots li button:hover,
  .custom-carousel .slick-dots li button:focus {
    opacity: 0.75;
  }
  .custom-carousel .slick-dots li.slick-active {
    width: 24px;
  }
  .custom-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1;
  }
  .custom-carousel .slick-dots li.slick-active:hover,
  .custom-carousel .slick-dots li.slick-active:focus {
    opacity: 1;
  }
  .custom-carousel-vertical .slick-dots {
    top: 50%;
    bottom: auto;
    flex-direction: column;
    width: 3px;
    height: auto;
    margin: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .custom-carousel-vertical .slick-dots-left {
    right: auto;
    left: 12px;
  }
  .custom-carousel-vertical .slick-dots-right {
    right: 12px;
    left: auto;
  }
  .custom-carousel-vertical .slick-dots li {
    width: 3px;
    height: 16px;
    margin: 4px 2px;
    vertical-align: baseline;
  }
  .custom-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px;
  }
  .custom-carousel-vertical .slick-dots li.slick-active {
    width: 3px;
    height: 24px;
  }
  .custom-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px;
  }
  .custom-carousel-rtl {
    direction: rtl;
  }
  .custom-carousel-rtl .custom-carousel .slick-track {
    right: 0;
    left: auto;
  }
  .custom-carousel-rtl .custom-carousel .slick-prev {
    right: -25px;
    left: auto;
  }
  .custom-carousel-rtl .custom-carousel .slick-prev::before {
    content: '→';
  }
  .custom-carousel-rtl .custom-carousel .slick-next {
    right: auto;
    left: -25px;
  }
  .custom-carousel-rtl .custom-carousel .slick-next::before {
    content: '←';
  }
  .custom-carousel-rtl.custom-carousel .slick-dots {
    flex-direction: row-reverse;
  }
  .custom-carousel-rtl.custom-carousel-vertical .slick-dots {
    flex-direction: column;
  }
  @-webkit-keyframes antCheckboxEffect {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1.6);
              transform: scale(1.6);
      opacity: 0;
    }
  }
  @keyframes antCheckboxEffect {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1.6);
              transform: scale(1.6);
      opacity: 0;
    }
  }
  .custom-cascader-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0.2em;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
  }
  .custom-cascader-checkbox-wrapper:hover .custom-cascader-checkbox-inner,
  .custom-cascader-checkbox:hover .custom-cascader-checkbox-inner,
  .custom-cascader-checkbox-input:focus + .custom-cascader-checkbox-inner {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-cascader-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
            animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    content: '';
  }
  .custom-cascader-checkbox:hover::after,
  .custom-cascader-checkbox-wrapper:hover .custom-cascader-checkbox::after {
    visibility: visible;
  }
  .custom-cascader-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .custom-cascader-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
            transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }
  .custom-cascader-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .custom-cascader-checkbox-checked .custom-cascader-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
  .custom-cascader-checkbox-checked .custom-cascader-checkbox-inner {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-cascader-checkbox-disabled {
    cursor: not-allowed;
  }
  .custom-cascader-checkbox-disabled.custom-cascader-checkbox-checked .custom-cascader-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-cascader-checkbox-disabled .custom-cascader-checkbox-input {
    cursor: not-allowed;
    pointer-events: none;
  }
  .custom-cascader-checkbox-disabled .custom-cascader-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
  }
  .custom-cascader-checkbox-disabled .custom-cascader-checkbox-inner::after {
    border-color: #f5f5f5;
    border-collapse: separate;
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-cascader-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-cascader-checkbox-disabled:hover::after,
  .custom-cascader-checkbox-wrapper:hover .custom-cascader-checkbox-disabled::after {
    visibility: hidden;
  }
  .custom-cascader-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-flex;
    align-items: baseline;
    line-height: inherit;
    cursor: pointer;
  }
  .custom-cascader-checkbox-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
  }
  .custom-cascader-checkbox-wrapper.custom-cascader-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .custom-cascader-checkbox-wrapper + .custom-cascader-checkbox-wrapper {
    margin-left: 8px;
  }
  .custom-cascader-checkbox-wrapper.custom-cascader-checkbox-wrapper-in-form-item input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
  .custom-cascader-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .custom-cascader-checkbox-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
  }
  .custom-cascader-checkbox-group-item {
    margin-right: 8px;
  }
  .custom-cascader-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .custom-cascader-checkbox-group-item + .custom-cascader-checkbox-group-item {
    margin-left: 0;
  }
  .custom-cascader-checkbox-indeterminate .custom-cascader-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .custom-cascader-checkbox-indeterminate .custom-cascader-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
  }
  .custom-cascader-checkbox-indeterminate.custom-cascader-checkbox-disabled .custom-cascader-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .custom-cascader-checkbox-rtl {
    direction: rtl;
  }
  .custom-cascader-checkbox-group-rtl .custom-cascader-checkbox-group-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-cascader-checkbox-group-rtl .custom-cascader-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .custom-cascader-checkbox-group-rtl .custom-cascader-checkbox-group-item + .custom-cascader-checkbox-group-item {
    margin-left: 8px;
  }
  .custom-cascader {
    width: 184px;
  }
  .custom-cascader-checkbox {
    top: 0;
    margin-right: 8px;
  }
  .custom-cascader-menus {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .custom-cascader-menus.custom-cascader-menu-empty .custom-cascader-menu {
    width: 100%;
    height: auto;
  }
  .custom-cascader-menu {
    flex-grow: 1;
    min-width: 111px;
    height: 180px;
    margin: 0;
    margin: -4px 0;
    padding: 4px 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .custom-cascader-menu-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-cascader-menu-item:hover {
    background: #f5f5f5;
  }
  .custom-cascader-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-cascader-menu-item-disabled:hover {
    background: transparent;
  }
  .custom-cascader-menu-empty .custom-cascader-menu-item {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
    pointer-events: none;
  }
  .custom-cascader-menu-item-active:not(.custom-cascader-menu-item-disabled),
  .custom-cascader-menu-item-active:not(.custom-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background-color: #e6f7ff;
    background-color: var(--custom-primary-1);
  }
  .custom-cascader-menu-item-content {
    flex: auto;
  }
  .custom-cascader-menu-item-expand .custom-cascader-menu-item-expand-icon,
  .custom-cascader-menu-item-loading-icon {
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
  }
  .custom-cascader-menu-item-disabled.custom-cascader-menu-item-expand .custom-cascader-menu-item-expand-icon,
  .custom-cascader-menu-item-disabled.custom-cascader-menu-item-loading-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-cascader-menu-item-keyword {
    color: #ff4d4f;
  }
  .custom-cascader-rtl .custom-cascader-menu-item-expand-icon,
  .custom-cascader-rtl .custom-cascader-menu-item-loading-icon {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-cascader-rtl .custom-cascader-checkbox {
    top: 0;
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0.2em;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
  }
  .custom-checkbox-wrapper:hover .custom-checkbox-inner,
  .custom-checkbox:hover .custom-checkbox-inner,
  .custom-checkbox-input:focus + .custom-checkbox-inner {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
            animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    content: '';
  }
  .custom-checkbox:hover::after,
  .custom-checkbox-wrapper:hover .custom-checkbox::after {
    visibility: visible;
  }
  .custom-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .custom-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
            transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }
  .custom-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .custom-checkbox-checked .custom-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
  .custom-checkbox-checked .custom-checkbox-inner {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-checkbox-disabled {
    cursor: not-allowed;
  }
  .custom-checkbox-disabled.custom-checkbox-checked .custom-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-checkbox-disabled .custom-checkbox-input {
    cursor: not-allowed;
    pointer-events: none;
  }
  .custom-checkbox-disabled .custom-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
  }
  .custom-checkbox-disabled .custom-checkbox-inner::after {
    border-color: #f5f5f5;
    border-collapse: separate;
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-checkbox-disabled:hover::after,
  .custom-checkbox-wrapper:hover .custom-checkbox-disabled::after {
    visibility: hidden;
  }
  .custom-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-flex;
    align-items: baseline;
    line-height: inherit;
    cursor: pointer;
  }
  .custom-checkbox-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
  }
  .custom-checkbox-wrapper.custom-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .custom-checkbox-wrapper + .custom-checkbox-wrapper {
    margin-left: 8px;
  }
  .custom-checkbox-wrapper.custom-checkbox-wrapper-in-form-item input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
  .custom-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .custom-checkbox-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
  }
  .custom-checkbox-group-item {
    margin-right: 8px;
  }
  .custom-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .custom-checkbox-group-item + .custom-checkbox-group-item {
    margin-left: 0;
  }
  .custom-checkbox-indeterminate .custom-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .custom-checkbox-indeterminate .custom-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
  }
  .custom-checkbox-indeterminate.custom-checkbox-disabled .custom-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .custom-checkbox-rtl {
    direction: rtl;
  }
  .custom-checkbox-group-rtl .custom-checkbox-group-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-checkbox-group-rtl .custom-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .custom-checkbox-group-rtl .custom-checkbox-group-item + .custom-checkbox-group-item {
    margin-left: 8px;
  }
  .custom-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    opacity: 0.85;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 2px;
  }
  .custom-collapse > .custom-collapse-item {
    border-bottom: 1px solid #d9d9d9;
  }
  .custom-collapse > .custom-collapse-item:last-child,
  .custom-collapse > .custom-collapse-item:last-child > .custom-collapse-header {
    border-radius: 0 0 2px 2px;
  }
  .custom-collapse > .custom-collapse-item > .custom-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
  }
  .custom-collapse > .custom-collapse-item > .custom-collapse-header .custom-collapse-arrow {
    display: inline-block;
    margin-right: 12px;
    font-size: 12px;
    vertical-align: -1px;
  }
  .custom-collapse > .custom-collapse-item > .custom-collapse-header .custom-collapse-arrow svg {
    transition: -webkit-transform 0.24s;
    transition: transform 0.24s;
    transition: transform 0.24s, -webkit-transform 0.24s;
  }
  .custom-collapse > .custom-collapse-item > .custom-collapse-header .custom-collapse-header-text {
    flex: auto;
  }
  .custom-collapse > .custom-collapse-item > .custom-collapse-header .custom-collapse-extra {
    margin-left: auto;
  }
  .custom-collapse > .custom-collapse-item > .custom-collapse-header:focus {
    outline: none;
  }
  .custom-collapse > .custom-collapse-item .custom-collapse-header-collapsible-only {
    cursor: default;
  }
  .custom-collapse > .custom-collapse-item .custom-collapse-header-collapsible-only .custom-collapse-header-text {
    flex: none;
    cursor: pointer;
  }
  .custom-collapse > .custom-collapse-item.custom-collapse-no-arrow > .custom-collapse-header {
    padding-left: 12px;
  }
  .custom-collapse-icon-position-end > .custom-collapse-item > .custom-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-right: 40px;
  }
  .custom-collapse-icon-position-end > .custom-collapse-item > .custom-collapse-header .custom-collapse-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    left: auto;
    margin: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .custom-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
  }
  .custom-collapse-content > .custom-collapse-content-box {
    padding: 16px;
  }
  .custom-collapse-content-hidden {
    display: none;
  }
  .custom-collapse-item:last-child > .custom-collapse-content {
    border-radius: 0 0 2px 2px;
  }
  .custom-collapse-borderless {
    background-color: #fafafa;
    border: 0;
  }
  .custom-collapse-borderless > .custom-collapse-item {
    border-bottom: 1px solid #d9d9d9;
  }
  .custom-collapse-borderless > .custom-collapse-item:last-child,
  .custom-collapse-borderless > .custom-collapse-item:last-child .custom-collapse-header {
    border-radius: 0;
  }
  .custom-collapse-borderless > .custom-collapse-item:last-child {
    border-bottom: 0;
  }
  .custom-collapse-borderless > .custom-collapse-item > .custom-collapse-content {
    background-color: transparent;
    border-top: 0;
  }
  .custom-collapse-borderless > .custom-collapse-item > .custom-collapse-content > .custom-collapse-content-box {
    padding-top: 4px;
  }
  .custom-collapse-ghost {
    background-color: transparent;
    border: 0;
  }
  .custom-collapse-ghost > .custom-collapse-item {
    border-bottom: 0;
  }
  .custom-collapse-ghost > .custom-collapse-item > .custom-collapse-content {
    background-color: transparent;
    border-top: 0;
  }
  .custom-collapse-ghost > .custom-collapse-item > .custom-collapse-content > .custom-collapse-content-box {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .custom-collapse .custom-collapse-item-disabled > .custom-collapse-header,
  .custom-collapse .custom-collapse-item-disabled > .custom-collapse-header > .arrow {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-collapse-rtl {
    direction: rtl;
  }
  .custom-collapse-rtl.custom-collapse.custom-collapse-icon-position-end > .custom-collapse-item > .custom-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
  }
  .custom-collapse-rtl.custom-collapse.custom-collapse-icon-position-end > .custom-collapse-item > .custom-collapse-header .custom-collapse-arrow {
    position: absolute;
    top: 50%;
    right: auto;
    left: 16px;
    margin: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .custom-collapse-rtl .custom-collapse > .custom-collapse-item > .custom-collapse-header {
    padding: 12px 16px;
    padding-right: 40px;
  }
  .custom-collapse-rtl.custom-collapse > .custom-collapse-item > .custom-collapse-header .custom-collapse-arrow {
    margin-right: 0;
    margin-left: 12px;
  }
  .custom-collapse-rtl.custom-collapse > .custom-collapse-item > .custom-collapse-header .custom-collapse-arrow svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .custom-collapse-rtl.custom-collapse > .custom-collapse-item > .custom-collapse-header .custom-collapse-extra {
    margin-right: auto;
    margin-left: 0;
  }
  .custom-collapse-rtl.custom-collapse > .custom-collapse-item.custom-collapse-no-arrow > .custom-collapse-header {
    padding-right: 12px;
    padding-left: 0;
  }
  .custom-comment {
    position: relative;
    background-color: inherit;
  }
  .custom-comment-inner {
    display: flex;
    padding: 16px 0;
  }
  .custom-comment-avatar {
    position: relative;
    flex-shrink: 0;
    margin-right: 12px;
    cursor: pointer;
  }
  .custom-comment-avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .custom-comment-content {
    position: relative;
    flex: 1 1 auto;
    min-width: 1px;
    font-size: 14px;
    word-wrap: break-word;
  }
  .custom-comment-content-author {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 4px;
    font-size: 14px;
  }
  .custom-comment-content-author > a,
  .custom-comment-content-author > span {
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
  }
  .custom-comment-content-author-name {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    transition: color 0.3s;
  }
  .custom-comment-content-author-name > * {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-comment-content-author-name > *:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-comment-content-author-time {
    color: #ccc;
    white-space: nowrap;
    cursor: auto;
  }
  .custom-comment-content-detail p {
    margin-bottom: inherit;
    white-space: pre-wrap;
  }
  .custom-comment-actions {
    margin-top: 12px;
    margin-bottom: inherit;
    padding-left: 0;
  }
  .custom-comment-actions > li {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-comment-actions > li > span {
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-comment-actions > li > span:hover {
    color: #595959;
  }
  .custom-comment-nested {
    margin-left: 44px;
  }
  .custom-comment-rtl {
    direction: rtl;
  }
  .custom-comment-rtl .custom-comment-avatar {
    margin-right: 0;
    margin-left: 12px;
  }
  .custom-comment-rtl .custom-comment-content-author > a,
  .custom-comment-rtl .custom-comment-content-author > span {
    padding-right: 0;
    padding-left: 8px;
  }
  .custom-comment-rtl .custom-comment-actions {
    padding-right: 0;
  }
  .custom-comment-rtl .custom-comment-actions > li > span {
    margin-right: 0;
    margin-left: 10px;
  }
  .custom-comment-rtl .custom-comment-nested {
    margin-right: 44px;
    margin-left: 0;
  }
  .custom-picker-status-error.custom-picker,
  .custom-picker-status-error.custom-picker:not([disabled]):hover {
    background-color: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-picker-status-error.custom-picker-focused,
  .custom-picker-status-error.custom-picker:focus {
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px var(--custom-error-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-picker-status-error.custom-picker .custom-picker-active-bar {
    background: #ff7875;
    background: var(--custom-error-color-hover);
  }
  .custom-picker-status-warning.custom-picker,
  .custom-picker-status-warning.custom-picker:not([disabled]):hover {
    background-color: #fff;
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-picker-status-warning.custom-picker-focused,
  .custom-picker-status-warning.custom-picker:focus {
    border-color: #ffc53d;
    border-color: var(--custom-warning-color-hover);
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow: 0 0 0 2px var(--custom-warning-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-picker-status-warning.custom-picker .custom-picker-active-bar {
    background: #ffc53d;
    background: var(--custom-warning-color-hover);
  }
  .custom-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: border 0.3s, box-shadow 0.3s;
  }
  .custom-picker:hover,
  .custom-picker-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-picker:hover,
  .custom-input-rtl .custom-picker-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-picker-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-picker-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-picker.custom-picker-disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-picker.custom-picker-disabled .custom-picker-suffix {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-picker.custom-picker-borderless {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .custom-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .custom-picker-input > input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    background: transparent;
    border: 0;
  }
  .custom-picker-input > input::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-picker-input > input::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-picker-input > input:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-picker-input > input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-picker-input > input:focus,
  .custom-picker-input > input-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-picker-input > input:focus,
  .custom-input-rtl .custom-picker-input > input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-picker-input > input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-picker-input > input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-picker-input > input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-picker-input > input-borderless,
  .custom-picker-input > input-borderless:hover,
  .custom-picker-input > input-borderless:focus,
  .custom-picker-input > input-borderless-focused,
  .custom-picker-input > input-borderless-disabled,
  .custom-picker-input > input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.custom-picker-input > input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .custom-picker-input > input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-picker-input > input-sm {
    padding: 0px 7px;
  }
  .custom-picker-input > input-rtl {
    direction: rtl;
  }
  .custom-picker-input > input:focus {
    box-shadow: none;
  }
  .custom-picker-input > input[disabled] {
    background: transparent;
  }
  .custom-picker-input:hover .custom-picker-clear {
    opacity: 1;
  }
  .custom-picker-input-placeholder > input {
    color: #bfbfbf;
  }
  .custom-picker-large {
    padding: 6.5px 11px 6.5px;
  }
  .custom-picker-large .custom-picker-input > input {
    font-size: 16px;
  }
  .custom-picker-small {
    padding: 0px 7px 0px;
  }
  .custom-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    pointer-events: none;
  }
  .custom-picker-suffix > * {
    vertical-align: top;
  }
  .custom-picker-suffix > *:not(:last-child) {
    margin-right: 8px;
  }
  .custom-picker-clear {
    position: absolute;
    top: 50%;
    right: 0;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    background: #fff;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s;
  }
  .custom-picker-clear > * {
    vertical-align: top;
  }
  .custom-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-picker-separator {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    vertical-align: top;
    cursor: default;
  }
  .custom-picker-focused .custom-picker-separator {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-picker-disabled .custom-picker-range-separator .custom-picker-separator {
    cursor: not-allowed;
  }
  .custom-picker-range {
    position: relative;
    display: inline-flex;
  }
  .custom-picker-range .custom-picker-clear {
    right: 11px;
  }
  .custom-picker-range:hover .custom-picker-clear {
    opacity: 1;
  }
  .custom-picker-range .custom-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: #1890ff;
    background: var(--custom-primary-color);
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }
  .custom-picker-range.custom-picker-focused .custom-picker-active-bar {
    opacity: 1;
  }
  .custom-picker-range-separator {
    align-items: center;
    padding: 0 8px;
    line-height: 1;
  }
  .custom-picker-range.custom-picker-small .custom-picker-clear {
    right: 7px;
  }
  .custom-picker-range.custom-picker-small .custom-picker-active-bar {
    margin-left: 7px;
  }
  .custom-picker-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
  }
  .custom-picker-dropdown-hidden {
    display: none;
  }
  .custom-picker-dropdown-placement-bottomLeft .custom-picker-range-arrow {
    top: 2.58561808px;
    display: block;
    -webkit-transform: rotate(-135deg) translateY(1px);
            transform: rotate(-135deg) translateY(1px);
  }
  .custom-picker-dropdown-placement-topLeft .custom-picker-range-arrow {
    bottom: 2.58561808px;
    display: block;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .custom-picker-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-picker-dropdown-placement-topLeft,
  .custom-picker-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-picker-dropdown-placement-topRight,
  .custom-picker-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-picker-dropdown-placement-topLeft,
  .custom-picker-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-picker-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .custom-picker-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-picker-dropdown-placement-bottomLeft,
  .custom-picker-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-picker-dropdown-placement-bottomRight,
  .custom-picker-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-picker-dropdown-placement-bottomLeft,
  .custom-picker-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-picker-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .custom-picker-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-picker-dropdown-placement-topLeft,
  .custom-picker-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-picker-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .custom-picker-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-picker-dropdown-placement-bottomLeft,
  .custom-picker-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-picker-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .custom-picker-dropdown-range {
    padding: 7.54247233px 0;
  }
  .custom-picker-dropdown-range-hidden {
    display: none;
  }
  .custom-picker-dropdown .custom-picker-panel > .custom-picker-time-panel {
    padding-top: 4px;
  }
  .custom-picker-ranges {
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
  }
  .custom-picker-ranges > li {
    display: inline-block;
  }
  .custom-picker-ranges .custom-picker-preset > .custom-tag-blue {
    color: #1890ff;
    color: var(--custom-primary-color);
    background: #e6f7ff;
    background: var(--custom-primary-1);
    border-color: #91d5ff;
    border-color: var(--custom-primary-3);
    cursor: pointer;
  }
  .custom-picker-ranges .custom-picker-ok {
    float: right;
    margin-left: 8px;
  }
  .custom-picker-range-wrapper {
    display: flex;
  }
  .custom-picker-range-arrow {
    position: absolute;
    z-index: 1;
    display: none;
    width: 11.3137085px;
    height: 11.3137085px;
    margin-left: 16.5px;
    box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease-out;
    border-radius: 0 0 2px;
    pointer-events: none;
  }
  .custom-picker-range-arrow::before {
    position: absolute;
    top: -11.3137085px;
    left: -11.3137085px;
    width: 33.9411255px;
    height: 33.9411255px;
    background: #fff;
    background-repeat: no-repeat;
    background-position: -10px -10px;
    content: '';
    -webkit-clip-path: inset(33% 33%);
            clip-path: inset(33% 33%);
    -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
            clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
  }
  .custom-picker-panel-container {
    overflow: hidden;
    vertical-align: top;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: margin 0.3s;
  }
  .custom-picker-panel-container .custom-picker-panels {
    display: inline-flex;
    flex-wrap: nowrap;
    direction: ltr;
  }
  .custom-picker-panel-container .custom-picker-panel {
    vertical-align: top;
    background: transparent;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .custom-picker-panel-container .custom-picker-panel .custom-picker-content,
  .custom-picker-panel-container .custom-picker-panel table {
    text-align: center;
  }
  .custom-picker-panel-container .custom-picker-panel-focused {
    border-color: rgba(0, 0, 0, 0.06);
  }
  .custom-picker-panel {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    outline: none;
  }
  .custom-picker-panel-focused {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-picker-decade-panel,
  .custom-picker-year-panel,
  .custom-picker-quarter-panel,
  .custom-picker-month-panel,
  .custom-picker-week-panel,
  .custom-picker-date-panel,
  .custom-picker-time-panel {
    display: flex;
    flex-direction: column;
    width: 280px;
  }
  .custom-picker-header {
    display: flex;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-picker-header > * {
    flex: none;
  }
  .custom-picker-header button {
    padding: 0;
    color: rgba(0, 0, 0, 0.25);
    line-height: 40px;
    background: transparent;
    border: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  .custom-picker-header > button {
    min-width: 1.6em;
    font-size: 14px;
  }
  .custom-picker-header > button:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-picker-header-view {
    flex: auto;
    font-weight: 500;
    line-height: 40px;
  }
  .custom-picker-header-view button {
    color: inherit;
    font-weight: inherit;
  }
  .custom-picker-header-view button:not(:first-child) {
    margin-left: 8px;
  }
  .custom-picker-header-view button:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-picker-prev-icon,
  .custom-picker-next-icon,
  .custom-picker-super-prev-icon,
  .custom-picker-super-next-icon {
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
  }
  .custom-picker-prev-icon::before,
  .custom-picker-next-icon::before,
  .custom-picker-super-prev-icon::before,
  .custom-picker-super-next-icon::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid currentcolor;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }
  .custom-picker-super-prev-icon::after,
  .custom-picker-super-next-icon::after {
    position: absolute;
    top: 4px;
    left: 4px;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid currentcolor;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }
  .custom-picker-prev-icon,
  .custom-picker-super-prev-icon {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .custom-picker-next-icon,
  .custom-picker-super-next-icon {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .custom-picker-content {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .custom-picker-content th,
  .custom-picker-content td {
    position: relative;
    min-width: 24px;
    font-weight: 400;
  }
  .custom-picker-content th {
    height: 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
  }
  .custom-picker-cell {
    padding: 3px 0;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  .custom-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-picker-cell::before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 1;
    height: 24px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: all 0.3s;
    content: '';
  }
  .custom-picker-cell .custom-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    transition: background 0.3s, border 0.3s;
  }
  .custom-picker-cell:hover:not(.custom-picker-cell-in-view) .custom-picker-cell-inner,
  .custom-picker-cell:hover:not(.custom-picker-cell-selected):not(.custom-picker-cell-range-start):not(.custom-picker-cell-range-end):not(.custom-picker-cell-range-hover-start):not(.custom-picker-cell-range-hover-end) .custom-picker-cell-inner {
    background: #f5f5f5;
  }
  .custom-picker-cell-in-view.custom-picker-cell-today .custom-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    border-radius: 2px;
    content: '';
  }
  .custom-picker-cell-in-view.custom-picker-cell-in-range {
    position: relative;
  }
  .custom-picker-cell-in-view.custom-picker-cell-in-range::before {
    background: #e6f7ff;
    background: var(--custom-primary-1);
  }
  .custom-picker-cell-in-view.custom-picker-cell-selected .custom-picker-cell-inner,
  .custom-picker-cell-in-view.custom-picker-cell-range-start .custom-picker-cell-inner,
  .custom-picker-cell-in-view.custom-picker-cell-range-end .custom-picker-cell-inner {
    color: #fff;
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-picker-cell-in-view.custom-picker-cell-range-start:not(.custom-picker-cell-range-start-single)::before,
  .custom-picker-cell-in-view.custom-picker-cell-range-end:not(.custom-picker-cell-range-end-single)::before {
    background: #e6f7ff;
    background: var(--custom-primary-1);
  }
  .custom-picker-cell-in-view.custom-picker-cell-range-start::before {
    left: 50%;
  }
  .custom-picker-cell-in-view.custom-picker-cell-range-end::before {
    right: 50%;
  }
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-start:not(.custom-picker-cell-in-range):not(.custom-picker-cell-range-start):not(.custom-picker-cell-range-end)::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-end:not(.custom-picker-cell-in-range):not(.custom-picker-cell-range-start):not(.custom-picker-cell-range-end)::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-start.custom-picker-cell-range-start-single::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-start.custom-picker-cell-range-start.custom-picker-cell-range-end.custom-picker-cell-range-end-near-hover::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-end.custom-picker-cell-range-start.custom-picker-cell-range-end.custom-picker-cell-range-start-near-hover::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-end.custom-picker-cell-range-end-single::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover:not(.custom-picker-cell-in-range)::after {
    position: absolute;
    top: 50%;
    z-index: 0;
    height: 24px;
    border-top: 1px dashed #7ec1ff;
    border-top: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-bottom: 1px dashed #7ec1ff;
    border-bottom: 1px dashed var(--custom-primary-color-deprecated-l-20);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: all 0.3s;
    content: '';
  }
  .custom-picker-cell-range-hover-start::after,
  .custom-picker-cell-range-hover-end::after,
  .custom-picker-cell-range-hover::after {
    right: 0;
    left: 2px;
  }
  .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover::before,
  .custom-picker-cell-in-view.custom-picker-cell-range-start.custom-picker-cell-range-hover::before,
  .custom-picker-cell-in-view.custom-picker-cell-range-end.custom-picker-cell-range-hover::before,
  .custom-picker-cell-in-view.custom-picker-cell-range-start:not(.custom-picker-cell-range-start-single).custom-picker-cell-range-hover-start::before,
  .custom-picker-cell-in-view.custom-picker-cell-range-end:not(.custom-picker-cell-range-end-single).custom-picker-cell-range-hover-end::before,
  .custom-picker-panel > :not(.custom-picker-date-panel) .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-start::before,
  .custom-picker-panel > :not(.custom-picker-date-panel) .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-end::before {
    background: #cbe6ff;
    background: var(--custom-primary-color-deprecated-l-35);
  }
  .custom-picker-cell-in-view.custom-picker-cell-range-start:not(.custom-picker-cell-range-start-single):not(.custom-picker-cell-range-end) .custom-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  }
  .custom-picker-cell-in-view.custom-picker-cell-range-end:not(.custom-picker-cell-range-end-single):not(.custom-picker-cell-range-start) .custom-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }
  .custom-picker-date-panel .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-start .custom-picker-cell-inner::after,
  .custom-picker-date-panel .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-end .custom-picker-cell-inner::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: #cbe6ff;
    background: var(--custom-primary-color-deprecated-l-35);
    transition: all 0.3s;
    content: '';
  }
  .custom-picker-date-panel .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-start .custom-picker-cell-inner::after {
    right: -6px;
    left: 0;
  }
  .custom-picker-date-panel .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-end .custom-picker-cell-inner::after {
    right: 0;
    left: -6px;
  }
  .custom-picker-cell-range-hover.custom-picker-cell-range-start::after {
    right: 50%;
  }
  .custom-picker-cell-range-hover.custom-picker-cell-range-end::after {
    left: 50%;
  }
  tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover:first-child::after,
  tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover-end:first-child::after,
  .custom-picker-cell-in-view.custom-picker-cell-start.custom-picker-cell-range-hover-edge-start.custom-picker-cell-range-hover-edge-start-near-range::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-edge-start:not(.custom-picker-cell-range-hover-edge-start-near-range)::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed #7ec1ff;
    border-left: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover:last-child::after,
  tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover-start:last-child::after,
  .custom-picker-cell-in-view.custom-picker-cell-end.custom-picker-cell-range-hover-edge-end.custom-picker-cell-range-hover-edge-end-near-range::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-edge-end:not(.custom-picker-cell-range-hover-edge-end-near-range)::after,
  .custom-picker-cell-in-view.custom-picker-cell-range-hover-end::after {
    right: 6px;
    border-right: 1px dashed #7ec1ff;
    border-right: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .custom-picker-cell-disabled {
    color: rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
  .custom-picker-cell-disabled .custom-picker-cell-inner {
    background: transparent;
  }
  .custom-picker-cell-disabled::before {
    background: rgba(0, 0, 0, 0.04);
  }
  .custom-picker-cell-disabled.custom-picker-cell-today .custom-picker-cell-inner::before {
    border-color: rgba(0, 0, 0, 0.25);
  }
  .custom-picker-decade-panel .custom-picker-content,
  .custom-picker-year-panel .custom-picker-content,
  .custom-picker-quarter-panel .custom-picker-content,
  .custom-picker-month-panel .custom-picker-content {
    height: 264px;
  }
  .custom-picker-decade-panel .custom-picker-cell-inner,
  .custom-picker-year-panel .custom-picker-cell-inner,
  .custom-picker-quarter-panel .custom-picker-cell-inner,
  .custom-picker-month-panel .custom-picker-cell-inner {
    padding: 0 8px;
  }
  .custom-picker-quarter-panel .custom-picker-content {
    height: 56px;
  }
  .custom-picker-footer {
    width: -webkit-min-content;
    width: min-content;
    min-width: 100%;
    line-height: 38px;
    text-align: center;
    border-bottom: 1px solid transparent;
  }
  .custom-picker-panel .custom-picker-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-picker-footer-extra {
    padding: 0 12px;
    line-height: 38px;
    text-align: left;
  }
  .custom-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-picker-now {
    text-align: left;
  }
  .custom-picker-today-btn {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-picker-today-btn:hover {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
  }
  .custom-picker-today-btn:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
  }
  .custom-picker-today-btn.custom-picker-today-btn-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-picker-decade-panel .custom-picker-cell-inner {
    padding: 0 4px;
  }
  .custom-picker-decade-panel .custom-picker-cell::before {
    display: none;
  }
  .custom-picker-year-panel .custom-picker-body,
  .custom-picker-quarter-panel .custom-picker-body,
  .custom-picker-month-panel .custom-picker-body {
    padding: 0 8px;
  }
  .custom-picker-year-panel .custom-picker-cell-inner,
  .custom-picker-quarter-panel .custom-picker-cell-inner,
  .custom-picker-month-panel .custom-picker-cell-inner {
    width: 60px;
  }
  .custom-picker-year-panel .custom-picker-cell-range-hover-start::after,
  .custom-picker-quarter-panel .custom-picker-cell-range-hover-start::after,
  .custom-picker-month-panel .custom-picker-cell-range-hover-start::after {
    left: 14px;
    border-left: 1px dashed #7ec1ff;
    border-left: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-radius: 2px 0 0 2px;
  }
  .custom-picker-panel-rtl .custom-picker-year-panel .custom-picker-cell-range-hover-start::after,
  .custom-picker-panel-rtl .custom-picker-quarter-panel .custom-picker-cell-range-hover-start::after,
  .custom-picker-panel-rtl .custom-picker-month-panel .custom-picker-cell-range-hover-start::after {
    right: 14px;
    border-right: 1px dashed #7ec1ff;
    border-right: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-radius: 0 2px 2px 0;
  }
  .custom-picker-year-panel .custom-picker-cell-range-hover-end::after,
  .custom-picker-quarter-panel .custom-picker-cell-range-hover-end::after,
  .custom-picker-month-panel .custom-picker-cell-range-hover-end::after {
    right: 14px;
    border-right: 1px dashed #7ec1ff;
    border-right: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-radius: 0 2px 2px 0;
  }
  .custom-picker-panel-rtl .custom-picker-year-panel .custom-picker-cell-range-hover-end::after,
  .custom-picker-panel-rtl .custom-picker-quarter-panel .custom-picker-cell-range-hover-end::after,
  .custom-picker-panel-rtl .custom-picker-month-panel .custom-picker-cell-range-hover-end::after {
    left: 14px;
    border-left: 1px dashed #7ec1ff;
    border-left: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-radius: 2px 0 0 2px;
  }
  .custom-picker-week-panel .custom-picker-body {
    padding: 8px 12px;
  }
  .custom-picker-week-panel .custom-picker-cell:hover .custom-picker-cell-inner,
  .custom-picker-week-panel .custom-picker-cell-selected .custom-picker-cell-inner,
  .custom-picker-week-panel .custom-picker-cell .custom-picker-cell-inner {
    background: transparent !important;
  }
  .custom-picker-week-panel-row td {
    transition: background 0.3s;
  }
  .custom-picker-week-panel-row:hover td {
    background: #f5f5f5;
  }
  .custom-picker-week-panel-row-selected td,
  .custom-picker-week-panel-row-selected:hover td {
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-picker-week-panel-row-selected td.custom-picker-cell-week,
  .custom-picker-week-panel-row-selected:hover td.custom-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
  }
  .custom-picker-week-panel-row-selected td.custom-picker-cell-today .custom-picker-cell-inner::before,
  .custom-picker-week-panel-row-selected:hover td.custom-picker-cell-today .custom-picker-cell-inner::before {
    border-color: #fff;
  }
  .custom-picker-week-panel-row-selected td .custom-picker-cell-inner,
  .custom-picker-week-panel-row-selected:hover td .custom-picker-cell-inner {
    color: #fff;
  }
  .custom-picker-date-panel .custom-picker-body {
    padding: 8px 12px;
  }
  .custom-picker-date-panel .custom-picker-content {
    width: 252px;
  }
  .custom-picker-date-panel .custom-picker-content th {
    width: 36px;
  }
  .custom-picker-datetime-panel {
    display: flex;
  }
  .custom-picker-datetime-panel .custom-picker-time-panel {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-picker-datetime-panel .custom-picker-date-panel,
  .custom-picker-datetime-panel .custom-picker-time-panel {
    transition: opacity 0.3s;
  }
  .custom-picker-datetime-panel-active .custom-picker-date-panel,
  .custom-picker-datetime-panel-active .custom-picker-time-panel {
    opacity: 0.3;
  }
  .custom-picker-datetime-panel-active .custom-picker-date-panel-active,
  .custom-picker-datetime-panel-active .custom-picker-time-panel-active {
    opacity: 1;
  }
  .custom-picker-time-panel {
    width: auto;
    min-width: auto;
  }
  .custom-picker-time-panel .custom-picker-content {
    display: flex;
    flex: auto;
    height: 224px;
  }
  .custom-picker-time-panel-column {
    flex: 1 0 auto;
    width: 56px;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    text-align: left;
    list-style: none;
    transition: background 0.3s;
  }
  .custom-picker-time-panel-column::after {
    display: block;
    height: 196px;
    content: '';
  }
  .custom-picker-datetime-panel .custom-picker-time-panel-column::after {
    height: 198px;
  }
  .custom-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-picker-time-panel-column-active {
    background: rgba(230, 247, 255, 0.3);
    background: var(--custom-primary-color-active-deprecated-f-30);
  }
  .custom-picker-time-panel-column:hover {
    overflow-y: auto;
  }
  .custom-picker-time-panel-column > li {
    margin: 0;
    padding: 0;
  }
  .custom-picker-time-panel-column > li.custom-picker-time-panel-cell .custom-picker-time-panel-cell-inner {
    display: block;
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0 0 0 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    border-radius: 0;
    cursor: pointer;
    transition: background 0.3s;
  }
  .custom-picker-time-panel-column > li.custom-picker-time-panel-cell .custom-picker-time-panel-cell-inner:hover {
    background: #f5f5f5;
  }
  .custom-picker-time-panel-column > li.custom-picker-time-panel-cell-selected .custom-picker-time-panel-cell-inner {
    background: #e6f7ff;
    background: var(--custom-primary-1);
  }
  .custom-picker-time-panel-column > li.custom-picker-time-panel-cell-disabled .custom-picker-time-panel-cell-inner {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    cursor: not-allowed;
  }
  /* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
  _:-ms-fullscreen .custom-picker-range-wrapper .custom-picker-month-panel .custom-picker-cell,
  :root .custom-picker-range-wrapper .custom-picker-month-panel .custom-picker-cell,
  _:-ms-fullscreen .custom-picker-range-wrapper .custom-picker-year-panel .custom-picker-cell,
  :root .custom-picker-range-wrapper .custom-picker-year-panel .custom-picker-cell {
    padding: 21px 0;
  }
  .custom-picker-rtl {
    direction: rtl;
  }
  .custom-picker-rtl .custom-picker-suffix {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-picker-rtl .custom-picker-clear {
    right: auto;
    left: 0;
  }
  .custom-picker-rtl .custom-picker-separator {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .custom-picker-panel-rtl .custom-picker-header-view button:not(:first-child) {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-picker-rtl.custom-picker-range .custom-picker-clear {
    right: auto;
    left: 11px;
  }
  .custom-picker-rtl.custom-picker-range .custom-picker-active-bar {
    margin-right: 11px;
    margin-left: 0;
  }
  .custom-picker-rtl.custom-picker-range.custom-picker-small .custom-picker-active-bar {
    margin-right: 7px;
  }
  .custom-picker-dropdown-rtl .custom-picker-ranges {
    text-align: right;
  }
  .custom-picker-dropdown-rtl .custom-picker-ranges .custom-picker-ok {
    float: left;
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-picker-panel-rtl {
    direction: rtl;
  }
  .custom-picker-panel-rtl .custom-picker-prev-icon,
  .custom-picker-panel-rtl .custom-picker-super-prev-icon {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .custom-picker-panel-rtl .custom-picker-next-icon,
  .custom-picker-panel-rtl .custom-picker-super-next-icon {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .custom-picker-cell .custom-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    transition: background 0.3s, border 0.3s;
  }
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-start::before {
    right: 50%;
    left: 0;
  }
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-end::before {
    right: 0;
    left: 50%;
  }
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-start.custom-picker-cell-range-end::before {
    right: 50%;
    left: 50%;
  }
  .custom-picker-panel-rtl .custom-picker-date-panel .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-start .custom-picker-cell-inner::after {
    right: 0;
    left: -6px;
  }
  .custom-picker-panel-rtl .custom-picker-date-panel .custom-picker-cell-in-view.custom-picker-cell-in-range.custom-picker-cell-range-hover-end .custom-picker-cell-inner::after {
    right: -6px;
    left: 0;
  }
  .custom-picker-panel-rtl .custom-picker-cell-range-hover.custom-picker-cell-range-start::after {
    right: 0;
    left: 50%;
  }
  .custom-picker-panel-rtl .custom-picker-cell-range-hover.custom-picker-cell-range-end::after {
    right: 50%;
    left: 0;
  }
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-start:not(.custom-picker-cell-range-start-single):not(.custom-picker-cell-range-end) .custom-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-end:not(.custom-picker-cell-range-end-single):not(.custom-picker-cell-range-start) .custom-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  }
  .custom-picker-panel-rtl tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover:not(.custom-picker-cell-selected):first-child::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-start.custom-picker-cell-range-hover-edge-start.custom-picker-cell-range-hover-edge-start-near-range::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-hover-edge-start:not(.custom-picker-cell-range-hover-edge-start-near-range)::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-hover-start::after {
    right: 6px;
    left: 0;
    border-right: 1px dashed #7ec1ff;
    border-right: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-left: none;
    border-radius: 0 2px 2px 0;
  }
  .custom-picker-panel-rtl tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover:not(.custom-picker-cell-selected):last-child::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-end.custom-picker-cell-range-hover-edge-end.custom-picker-cell-range-hover-edge-end-near-range::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-hover-edge-end:not(.custom-picker-cell-range-hover-edge-end-near-range)::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-range-hover-end::after {
    right: 0;
    left: 6px;
    border-right: none;
    border-left: 1px dashed #7ec1ff;
    border-left: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-radius: 2px 0 0 2px;
  }
  .custom-picker-panel-rtl tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover-start:last-child::after,
  .custom-picker-panel-rtl tr > .custom-picker-cell-in-view.custom-picker-cell-range-hover-end:first-child::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-start.custom-picker-cell-range-hover-edge-start:not(.custom-picker-cell-range-hover)::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-start.custom-picker-cell-range-hover-end.custom-picker-cell-range-hover-edge-start:not(.custom-picker-cell-range-hover)::after,
  .custom-picker-panel-rtl .custom-picker-cell-in-view.custom-picker-cell-end.custom-picker-cell-range-hover-start.custom-picker-cell-range-hover-edge-end:not(.custom-picker-cell-range-hover)::after,
  .custom-picker-panel-rtl tr > .custom-picker-cell-in-view.custom-picker-cell-start.custom-picker-cell-range-hover.custom-picker-cell-range-hover-edge-start:last-child::after,
  .custom-picker-panel-rtl tr > .custom-picker-cell-in-view.custom-picker-cell-end.custom-picker-cell-range-hover.custom-picker-cell-range-hover-edge-end:first-child::after {
    right: 6px;
    left: 6px;
    border-right: 1px dashed #7ec1ff;
    border-right: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-left: 1px dashed #7ec1ff;
    border-left: 1px dashed var(--custom-primary-color-deprecated-l-20);
    border-radius: 2px;
  }
  .custom-picker-dropdown-rtl .custom-picker-footer-extra {
    direction: rtl;
    text-align: right;
  }
  .custom-picker-panel-rtl .custom-picker-time-panel {
    direction: ltr;
  }
  .custom-descriptions-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .custom-descriptions-title {
    flex: auto;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-descriptions-extra {
    margin-left: auto;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
  .custom-descriptions-view {
    width: 100%;
    border-radius: 2px;
  }
  .custom-descriptions-view table {
    width: 100%;
    table-layout: fixed;
  }
  .custom-descriptions-row > th,
  .custom-descriptions-row > td {
    padding-bottom: 16px;
  }
  .custom-descriptions-row:last-child {
    border-bottom: none;
  }
  .custom-descriptions-item-label {
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5715;
    text-align: start;
  }
  .custom-descriptions-item-label::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
  .custom-descriptions-item-label.custom-descriptions-item-no-colon::after {
    content: ' ';
  }
  .custom-descriptions-item-no-label::after {
    margin: 0;
    content: '';
  }
  .custom-descriptions-item-content {
    display: table-cell;
    flex: 1 1;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  .custom-descriptions-item {
    padding-bottom: 0;
    vertical-align: top;
  }
  .custom-descriptions-item-container {
    display: flex;
  }
  .custom-descriptions-item-container .custom-descriptions-item-label,
  .custom-descriptions-item-container .custom-descriptions-item-content {
    display: inline-flex;
    align-items: baseline;
  }
  .custom-descriptions-middle .custom-descriptions-row > th,
  .custom-descriptions-middle .custom-descriptions-row > td {
    padding-bottom: 12px;
  }
  .custom-descriptions-small .custom-descriptions-row > th,
  .custom-descriptions-small .custom-descriptions-row > td {
    padding-bottom: 8px;
  }
  .custom-descriptions-bordered .custom-descriptions-view {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-descriptions-bordered .custom-descriptions-view > table {
    table-layout: auto;
    border-collapse: collapse;
  }
  .custom-descriptions-bordered .custom-descriptions-item-label,
  .custom-descriptions-bordered .custom-descriptions-item-content {
    padding: 16px 24px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-descriptions-bordered .custom-descriptions-item-label:last-child,
  .custom-descriptions-bordered .custom-descriptions-item-content:last-child {
    border-right: none;
  }
  .custom-descriptions-bordered .custom-descriptions-item-label {
    background-color: #fafafa;
  }
  .custom-descriptions-bordered .custom-descriptions-item-label::after {
    display: none;
  }
  .custom-descriptions-bordered .custom-descriptions-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-descriptions-bordered .custom-descriptions-row:last-child {
    border-bottom: none;
  }
  .custom-descriptions-bordered.custom-descriptions-middle .custom-descriptions-item-label,
  .custom-descriptions-bordered.custom-descriptions-middle .custom-descriptions-item-content {
    padding: 12px 24px;
  }
  .custom-descriptions-bordered.custom-descriptions-small .custom-descriptions-item-label,
  .custom-descriptions-bordered.custom-descriptions-small .custom-descriptions-item-content {
    padding: 8px 16px;
  }
  .custom-descriptions-rtl {
    direction: rtl;
  }
  .custom-descriptions-rtl .custom-descriptions-item-label::after {
    margin: 0 2px 0 8px;
  }
  .custom-descriptions-rtl.custom-descriptions-bordered .custom-descriptions-item-label,
  .custom-descriptions-rtl.custom-descriptions-bordered .custom-descriptions-item-content {
    border-right: none;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-descriptions-rtl.custom-descriptions-bordered .custom-descriptions-item-label:last-child,
  .custom-descriptions-rtl.custom-descriptions-bordered .custom-descriptions-item-content:last-child {
    border-left: none;
  }
  .custom-divider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
    border-top: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 24px 0;
  }
  .custom-divider-horizontal.custom-divider-with-text {
    display: flex;
    margin: 16px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
    border-top-color: rgba(0, 0, 0, 0.06);
  }
  .custom-divider-horizontal.custom-divider-with-text::before,
  .custom-divider-horizontal.custom-divider-with-text::after {
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid transparent;
    border-top-color: inherit;
    border-bottom: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    content: '';
  }
  .custom-divider-horizontal.custom-divider-with-text-left::before {
    top: 50%;
    width: 5%;
  }
  .custom-divider-horizontal.custom-divider-with-text-left::after {
    top: 50%;
    width: 95%;
  }
  .custom-divider-horizontal.custom-divider-with-text-right::before {
    top: 50%;
    width: 95%;
  }
  .custom-divider-horizontal.custom-divider-with-text-right::after {
    top: 50%;
    width: 5%;
  }
  .custom-divider-inner-text {
    display: inline-block;
    padding: 0 1em;
  }
  .custom-divider-dashed {
    background: none;
    border-color: rgba(0, 0, 0, 0.06);
    border-style: dashed;
    border-width: 1px 0 0;
  }
  .custom-divider-horizontal.custom-divider-with-text.custom-divider-dashed::before,
  .custom-divider-horizontal.custom-divider-with-text.custom-divider-dashed::after {
    border-style: dashed none none;
  }
  .custom-divider-vertical.custom-divider-dashed {
    border-width: 0 0 0 1px;
  }
  .custom-divider-plain.custom-divider-with-text {
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
  }
  .custom-divider-horizontal.custom-divider-with-text-left.custom-divider-no-default-orientation-margin-left::before {
    width: 0;
  }
  .custom-divider-horizontal.custom-divider-with-text-left.custom-divider-no-default-orientation-margin-left::after {
    width: 100%;
  }
  .custom-divider-horizontal.custom-divider-with-text-left.custom-divider-no-default-orientation-margin-left .custom-divider-inner-text {
    padding-left: 0;
  }
  .custom-divider-horizontal.custom-divider-with-text-right.custom-divider-no-default-orientation-margin-right::before {
    width: 100%;
  }
  .custom-divider-horizontal.custom-divider-with-text-right.custom-divider-no-default-orientation-margin-right::after {
    width: 0;
  }
  .custom-divider-horizontal.custom-divider-with-text-right.custom-divider-no-default-orientation-margin-right .custom-divider-inner-text {
    padding-right: 0;
  }
  .custom-divider-rtl {
    direction: rtl;
  }
  .custom-divider-rtl.custom-divider-horizontal.custom-divider-with-text-left::before {
    width: 95%;
  }
  .custom-divider-rtl.custom-divider-horizontal.custom-divider-with-text-left::after {
    width: 5%;
  }
  .custom-divider-rtl.custom-divider-horizontal.custom-divider-with-text-right::before {
    width: 5%;
  }
  .custom-divider-rtl.custom-divider-horizontal.custom-divider-with-text-right::after {
    width: 95%;
  }
  .custom-drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    pointer-events: none;
  }
  .custom-drawer-inline {
    position: absolute;
  }
  .custom-drawer-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.45);
    pointer-events: auto;
  }
  .custom-drawer-content-wrapper {
    position: absolute;
    z-index: 1000;
    transition: all 0.3s;
  }
  .custom-drawer-content-wrapper-hidden {
    display: none;
  }
  .custom-drawer-left > .custom-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
  }
  .custom-drawer-right > .custom-drawer-content-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  }
  .custom-drawer-top > .custom-drawer-content-wrapper {
    top: 0;
    right: 0;
    left: 0;
    box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
  }
  .custom-drawer-bottom > .custom-drawer-content-wrapper {
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
  }
  .custom-drawer-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff;
    pointer-events: auto;
  }
  .custom-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .custom-drawer-header {
    display: flex;
    flex: 0 1;
    align-items: center;
    padding: 16px 24px;
    font-size: 16px;
    line-height: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-drawer-header-title {
    display: flex;
    flex: 1 1;
    align-items: center;
    min-width: 0;
    min-height: 0;
  }
  .custom-drawer-extra {
    flex: 0 1;
  }
  .custom-drawer-close {
    display: inline-block;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
  }
  .custom-drawer-close:focus,
  .custom-drawer-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
  .custom-drawer-title {
    flex: 1 1;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .custom-drawer-body {
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    padding: 24px;
    overflow: auto;
  }
  .custom-drawer-footer {
    flex-shrink: 0;
    padding: 10px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .panel-motion-enter-start,
  .panel-motion-appear-start,
  .panel-motion-leave-start {
    transition: none;
  }
  .panel-motion-enter-active,
  .panel-motion-appear-active,
  .panel-motion-leave-active {
    transition: all 0.3s;
  }
  .custom-drawer-mask-motion-enter-active,
  .custom-drawer-mask-motion-appear-active,
  .custom-drawer-mask-motion-leave-active {
    transition: all 0.3s;
  }
  .custom-drawer-mask-motion-enter,
  .custom-drawer-mask-motion-appear {
    opacity: 0;
  }
  .custom-drawer-mask-motion-enter-active,
  .custom-drawer-mask-motion-appear-active {
    opacity: 1;
  }
  .custom-drawer-mask-motion-leave {
    opacity: 1;
  }
  .custom-drawer-mask-motion-leave-active {
    opacity: 0;
  }
  .custom-drawer-panel-motion-left-enter-start,
  .custom-drawer-panel-motion-left-appear-start,
  .custom-drawer-panel-motion-left-leave-start {
    transition: none;
  }
  .custom-drawer-panel-motion-left-enter-active,
  .custom-drawer-panel-motion-left-appear-active,
  .custom-drawer-panel-motion-left-leave-active {
    transition: all 0.3s;
  }
  .custom-drawer-panel-motion-left-enter,
  .custom-drawer-panel-motion-left-appear {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .custom-drawer-panel-motion-left-enter-active,
  .custom-drawer-panel-motion-left-appear-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .custom-drawer-panel-motion-left-leave {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .custom-drawer-panel-motion-left-leave-active {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .custom-drawer-panel-motion-right-enter-start,
  .custom-drawer-panel-motion-right-appear-start,
  .custom-drawer-panel-motion-right-leave-start {
    transition: none;
  }
  .custom-drawer-panel-motion-right-enter-active,
  .custom-drawer-panel-motion-right-appear-active,
  .custom-drawer-panel-motion-right-leave-active {
    transition: all 0.3s;
  }
  .custom-drawer-panel-motion-right-enter,
  .custom-drawer-panel-motion-right-appear {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .custom-drawer-panel-motion-right-enter-active,
  .custom-drawer-panel-motion-right-appear-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .custom-drawer-panel-motion-right-leave {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .custom-drawer-panel-motion-right-leave-active {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .custom-drawer-panel-motion-top-enter-start,
  .custom-drawer-panel-motion-top-appear-start,
  .custom-drawer-panel-motion-top-leave-start {
    transition: none;
  }
  .custom-drawer-panel-motion-top-enter-active,
  .custom-drawer-panel-motion-top-appear-active,
  .custom-drawer-panel-motion-top-leave-active {
    transition: all 0.3s;
  }
  .custom-drawer-panel-motion-top-enter,
  .custom-drawer-panel-motion-top-appear {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  .custom-drawer-panel-motion-top-enter-active,
  .custom-drawer-panel-motion-top-appear-active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .custom-drawer-panel-motion-top-leave {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .custom-drawer-panel-motion-top-leave-active {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  .custom-drawer-panel-motion-bottom-enter-start,
  .custom-drawer-panel-motion-bottom-appear-start,
  .custom-drawer-panel-motion-bottom-leave-start {
    transition: none;
  }
  .custom-drawer-panel-motion-bottom-enter-active,
  .custom-drawer-panel-motion-bottom-appear-active,
  .custom-drawer-panel-motion-bottom-leave-active {
    transition: all 0.3s;
  }
  .custom-drawer-panel-motion-bottom-enter,
  .custom-drawer-panel-motion-bottom-appear {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .custom-drawer-panel-motion-bottom-enter-active,
  .custom-drawer-panel-motion-bottom-appear-active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .custom-drawer-panel-motion-bottom-leave {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .custom-drawer-panel-motion-bottom-leave-active {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .custom-drawer-rtl {
    direction: rtl;
  }
  .custom-drawer-rtl .custom-drawer-close {
    margin-right: 0;
    margin-left: 12px;
  }
  .custom-dropdown-menu-item.custom-dropdown-menu-item-danger {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-dropdown-menu-item.custom-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
    background-color: var(--custom-error-color);
  }
  .custom-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .custom-dropdown::before {
    position: absolute;
    top: -4px;
    right: 0;
    bottom: -4px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: ' ';
  }
  .custom-dropdown-wrap {
    position: relative;
  }
  .custom-dropdown-wrap .custom-btn > .anticon-down {
    font-size: 10px;
  }
  .custom-dropdown-wrap .anticon-down::before {
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .custom-dropdown-wrap-open .anticon-down::before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .custom-dropdown-hidden,
  .custom-dropdown-menu-hidden,
  .custom-dropdown-menu-submenu-hidden {
    display: none;
  }
  .custom-dropdown-show-arrow.custom-dropdown-placement-topLeft,
  .custom-dropdown-show-arrow.custom-dropdown-placement-top,
  .custom-dropdown-show-arrow.custom-dropdown-placement-topRight {
    padding-bottom: 15.3137085px;
  }
  .custom-dropdown-show-arrow.custom-dropdown-placement-bottomLeft,
  .custom-dropdown-show-arrow.custom-dropdown-placement-bottom,
  .custom-dropdown-show-arrow.custom-dropdown-placement-bottomRight {
    padding-top: 15.3137085px;
  }
  .custom-dropdown-arrow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 11.3137085px;
    height: 11.3137085px;
    border-radius: 0 0 2px;
    pointer-events: none;
  }
  .custom-dropdown-arrow::before {
    position: absolute;
    top: -11.3137085px;
    left: -11.3137085px;
    width: 33.9411255px;
    height: 33.9411255px;
    background: #fff;
    background-repeat: no-repeat;
    background-position: -10px -10px;
    content: '';
    -webkit-clip-path: inset(33% 33%);
            clip-path: inset(33% 33%);
    -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
            clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
  }
  .custom-dropdown-placement-top > .custom-dropdown-arrow,
  .custom-dropdown-placement-topLeft > .custom-dropdown-arrow,
  .custom-dropdown-placement-topRight > .custom-dropdown-arrow {
    bottom: 10px;
    box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .custom-dropdown-placement-top > .custom-dropdown-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
  }
  .custom-dropdown-placement-topLeft > .custom-dropdown-arrow {
    left: 16px;
  }
  .custom-dropdown-placement-topRight > .custom-dropdown-arrow {
    right: 16px;
  }
  .custom-dropdown-placement-bottom > .custom-dropdown-arrow,
  .custom-dropdown-placement-bottomLeft > .custom-dropdown-arrow,
  .custom-dropdown-placement-bottomRight > .custom-dropdown-arrow {
    top: 9.41421356px;
    box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
    -webkit-transform: rotate(-135deg) translateY(-0.5px);
            transform: rotate(-135deg) translateY(-0.5px);
  }
  .custom-dropdown-placement-bottom > .custom-dropdown-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
            transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
  }
  .custom-dropdown-placement-bottomLeft > .custom-dropdown-arrow {
    left: 16px;
  }
  .custom-dropdown-placement-bottomRight > .custom-dropdown-arrow {
    right: 16px;
  }
  .custom-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .custom-dropdown-menu-item-group-title {
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.45);
    transition: all 0.3s;
  }
  .custom-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: transparent;
    box-shadow: none;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .custom-dropdown-menu-submenu-popup ul,
  .custom-dropdown-menu-submenu-popup li {
    list-style: none;
  }
  .custom-dropdown-menu-submenu-popup ul {
    margin-right: 0.3em;
    margin-left: 0.3em;
  }
  .custom-dropdown-menu-item {
    position: relative;
    display: flex;
    align-items: center;
  }
  .custom-dropdown-menu-item-icon {
    min-width: 12px;
    margin-right: 8px;
    font-size: 12px;
  }
  .custom-dropdown-menu-title-content {
    flex: auto;
  }
  .custom-dropdown-menu-title-content > a {
    color: inherit;
    transition: all 0.3s;
  }
  .custom-dropdown-menu-title-content > a:hover {
    color: inherit;
  }
  .custom-dropdown-menu-title-content > a::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
  .custom-dropdown-menu-item,
  .custom-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-dropdown-menu-item-selected,
  .custom-dropdown-menu-submenu-title-selected {
    color: #1890ff;
    color: var(--custom-primary-color);
    background-color: #e6f7ff;
    background-color: var(--custom-primary-1);
  }
  .custom-dropdown-menu-item:hover,
  .custom-dropdown-menu-submenu-title:hover,
  .custom-dropdown-menu-item.custom-dropdown-menu-item-active,
  .custom-dropdown-menu-item.custom-dropdown-menu-submenu-title-active,
  .custom-dropdown-menu-submenu-title.custom-dropdown-menu-item-active,
  .custom-dropdown-menu-submenu-title.custom-dropdown-menu-submenu-title-active {
    background-color: #f5f5f5;
  }
  .custom-dropdown-menu-item-disabled,
  .custom-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-dropdown-menu-item-disabled:hover,
  .custom-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .custom-dropdown-menu-item-disabled a,
  .custom-dropdown-menu-submenu-title-disabled a {
    pointer-events: none;
  }
  .custom-dropdown-menu-item-divider,
  .custom-dropdown-menu-submenu-title-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-dropdown-menu-item .custom-dropdown-menu-submenu-expand-icon,
  .custom-dropdown-menu-submenu-title .custom-dropdown-menu-submenu-expand-icon {
    position: absolute;
    right: 8px;
  }
  .custom-dropdown-menu-item .custom-dropdown-menu-submenu-expand-icon .custom-dropdown-menu-submenu-arrow-icon,
  .custom-dropdown-menu-submenu-title .custom-dropdown-menu-submenu-expand-icon .custom-dropdown-menu-submenu-arrow-icon {
    margin-right: 0 !important;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    font-style: normal;
  }
  .custom-dropdown-menu-item-group-list {
    margin: 0 8px;
    padding: 0;
    list-style: none;
  }
  .custom-dropdown-menu-submenu-title {
    padding-right: 24px;
  }
  .custom-dropdown-menu-submenu-vertical {
    position: relative;
  }
  .custom-dropdown-menu-submenu-vertical > .custom-dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 100%;
    margin-left: 4px;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .custom-dropdown-menu-submenu.custom-dropdown-menu-submenu-disabled .custom-dropdown-menu-submenu-title,
  .custom-dropdown-menu-submenu.custom-dropdown-menu-submenu-disabled .custom-dropdown-menu-submenu-title .custom-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .custom-dropdown-menu-submenu-selected .custom-dropdown-menu-submenu-title {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-dropdown.custom-slide-down-enter.custom-slide-down-enter-active.custom-dropdown-placement-bottomLeft,
  .custom-dropdown.custom-slide-down-appear.custom-slide-down-appear-active.custom-dropdown-placement-bottomLeft,
  .custom-dropdown.custom-slide-down-enter.custom-slide-down-enter-active.custom-dropdown-placement-bottom,
  .custom-dropdown.custom-slide-down-appear.custom-slide-down-appear-active.custom-dropdown-placement-bottom,
  .custom-dropdown.custom-slide-down-enter.custom-slide-down-enter-active.custom-dropdown-placement-bottomRight,
  .custom-dropdown.custom-slide-down-appear.custom-slide-down-appear-active.custom-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .custom-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-dropdown-placement-topLeft,
  .custom-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-dropdown-placement-topLeft,
  .custom-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-dropdown-placement-top,
  .custom-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-dropdown-placement-top,
  .custom-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-dropdown-placement-topRight,
  .custom-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .custom-dropdown.custom-slide-down-leave.custom-slide-down-leave-active.custom-dropdown-placement-bottomLeft,
  .custom-dropdown.custom-slide-down-leave.custom-slide-down-leave-active.custom-dropdown-placement-bottom,
  .custom-dropdown.custom-slide-down-leave.custom-slide-down-leave-active.custom-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .custom-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-dropdown-placement-topLeft,
  .custom-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-dropdown-placement-top,
  .custom-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .custom-dropdown-trigger > .anticon.anticon-down,
  .custom-dropdown-link > .anticon.anticon-down,
  .custom-dropdown-button > .anticon.anticon-down {
    font-size: 10px;
    vertical-align: baseline;
  }
  .custom-dropdown-button {
    white-space: nowrap;
  }
  .custom-dropdown-button.custom-btn-group > .custom-btn-loading,
  .custom-dropdown-button.custom-btn-group > .custom-btn-loading + .custom-btn {
    cursor: default;
    pointer-events: none;
  }
  .custom-dropdown-button.custom-btn-group > .custom-btn-loading + .custom-btn::before {
    display: block;
  }
  .custom-dropdown-button.custom-btn-group > .custom-btn:last-child:not(:first-child):not(.custom-btn-icon-only) {
    padding-right: 8px;
    padding-left: 8px;
  }
  .custom-dropdown-menu-dark,
  .custom-dropdown-menu-dark .custom-dropdown-menu {
    background: #001529;
  }
  .custom-dropdown-menu-dark .custom-dropdown-menu-item,
  .custom-dropdown-menu-dark .custom-dropdown-menu-submenu-title,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item > a,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item > .anticon + span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .custom-dropdown-menu-dark .custom-dropdown-menu-item .custom-dropdown-menu-submenu-arrow::after,
  .custom-dropdown-menu-dark .custom-dropdown-menu-submenu-title .custom-dropdown-menu-submenu-arrow::after,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item > a .custom-dropdown-menu-submenu-arrow::after,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item > .anticon + span > a .custom-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
  }
  .custom-dropdown-menu-dark .custom-dropdown-menu-item:hover,
  .custom-dropdown-menu-dark .custom-dropdown-menu-submenu-title:hover,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item > a:hover,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item > .anticon + span > a:hover {
    color: #fff;
    background: transparent;
  }
  .custom-dropdown-menu-dark .custom-dropdown-menu-item-selected,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item-selected:hover,
  .custom-dropdown-menu-dark .custom-dropdown-menu-item-selected > a {
    color: #fff;
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-dropdown-rtl {
    direction: rtl;
  }
  .custom-dropdown-rtl.custom-dropdown::before {
    right: -7px;
    left: 0;
  }
  .custom-dropdown-menu.custom-dropdown-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .custom-dropdown-rtl .custom-dropdown-menu-item-group-title,
  .custom-dropdown-menu-submenu-rtl .custom-dropdown-menu-item-group-title {
    direction: rtl;
    text-align: right;
  }
  .custom-dropdown-menu-submenu-popup.custom-dropdown-menu-submenu-rtl {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-popup ul,
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-popup li {
    text-align: right;
  }
  .custom-dropdown-rtl .custom-dropdown-menu-item,
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-title {
    text-align: right;
  }
  .custom-dropdown-rtl .custom-dropdown-menu-item > .anticon:first-child,
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-title > .anticon:first-child,
  .custom-dropdown-rtl .custom-dropdown-menu-item > span > .anticon:first-child,
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-title > span > .anticon:first-child {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-dropdown-rtl .custom-dropdown-menu-item .custom-dropdown-menu-submenu-expand-icon,
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-title .custom-dropdown-menu-submenu-expand-icon {
    right: auto;
    left: 8px;
  }
  .custom-dropdown-rtl .custom-dropdown-menu-item .custom-dropdown-menu-submenu-expand-icon .custom-dropdown-menu-submenu-arrow-icon,
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-title .custom-dropdown-menu-submenu-expand-icon .custom-dropdown-menu-submenu-arrow-icon {
    margin-left: 0 !important;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-title {
    padding-right: 12px;
    padding-left: 24px;
  }
  .custom-dropdown-rtl .custom-dropdown-menu-submenu-vertical > .custom-dropdown-menu {
    right: 100%;
    left: 0;
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-empty {
    margin: 0 8px;
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
  }
  .custom-empty-image {
    height: 100px;
    margin-bottom: 8px;
  }
  .custom-empty-image img {
    height: 100%;
  }
  .custom-empty-image svg {
    height: 100%;
    margin: auto;
  }
  .custom-empty-footer {
    margin-top: 16px;
  }
  .custom-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-empty-normal .custom-empty-image {
    height: 40px;
  }
  .custom-empty-small {
    margin: 8px 0;
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-empty-small .custom-empty-image {
    height: 35px;
  }
  .custom-empty-img-default-ellipse {
    fill: #f5f5f5;
    fill-opacity: 0.8;
  }
  .custom-empty-img-default-path-1 {
    fill: #aeb8c2;
  }
  .custom-empty-img-default-path-2 {
    fill: url('#linearGradient-1');
  }
  .custom-empty-img-default-path-3 {
    fill: #f5f5f7;
  }
  .custom-empty-img-default-path-4 {
    fill: #dce0e6;
  }
  .custom-empty-img-default-path-5 {
    fill: #dce0e6;
  }
  .custom-empty-img-default-g {
    fill: #fff;
  }
  .custom-empty-img-simple-ellipse {
    fill: #f5f5f5;
  }
  .custom-empty-img-simple-g {
    stroke: #d9d9d9;
  }
  .custom-empty-img-simple-path {
    fill: #fafafa;
  }
  .custom-empty-rtl {
    direction: rtl;
  }
  .custom-form-item .custom-input-number + .custom-form-text {
    margin-left: 8px;
  }
  .custom-form-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .custom-form-inline .custom-form-item {
    flex: none;
    flex-wrap: nowrap;
    margin-right: 16px;
    margin-bottom: 0;
  }
  .custom-form-inline .custom-form-item-with-help {
    margin-bottom: 24px;
  }
  .custom-form-inline .custom-form-item > .custom-form-item-label,
  .custom-form-inline .custom-form-item > .custom-form-item-control {
    display: inline-block;
    vertical-align: top;
  }
  .custom-form-inline .custom-form-item > .custom-form-item-label {
    flex: none;
  }
  .custom-form-inline .custom-form-item .custom-form-text {
    display: inline-block;
  }
  .custom-form-inline .custom-form-item .custom-form-item-has-feedback {
    display: inline-block;
  }
  .custom-form-horizontal .custom-form-item-label {
    flex-grow: 0;
  }
  .custom-form-horizontal .custom-form-item-control {
    flex: 1 1;
    min-width: 0;
  }
  .custom-form-horizontal .custom-form-item-label[class$='-24'] + .custom-form-item-control,
  .custom-form-horizontal .custom-form-item-label[class*='-24 '] + .custom-form-item-control {
    min-width: 0;
    min-width: initial;
  }
  .custom-form-vertical .custom-form-item-row {
    flex-direction: column;
  }
  .custom-form-vertical .custom-form-item-label > label {
    height: auto;
  }
  .custom-form-vertical .custom-form-item .custom-form-item-control {
    width: 100%;
  }
  .custom-form-vertical .custom-form-item-label,
  .custom-col-24.custom-form-item-label,
  .custom-col-xl-24.custom-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .custom-form-vertical .custom-form-item-label > label,
  .custom-col-24.custom-form-item-label > label,
  .custom-col-xl-24.custom-form-item-label > label {
    margin: 0;
  }
  .custom-form-vertical .custom-form-item-label > label::after,
  .custom-col-24.custom-form-item-label > label::after,
  .custom-col-xl-24.custom-form-item-label > label::after {
    display: none;
  }
  .custom-form-rtl.custom-form-vertical .custom-form-item-label,
  .custom-form-rtl.custom-col-24.custom-form-item-label,
  .custom-form-rtl.custom-col-xl-24.custom-form-item-label {
    text-align: right;
  }
  @media (max-width: 575px) {
    .custom-form-item .custom-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      white-space: initial;
      text-align: left;
    }
    .custom-form-item .custom-form-item-label > label {
      margin: 0;
    }
    .custom-form-item .custom-form-item-label > label::after {
      display: none;
    }
    .custom-form-rtl.custom-form-item .custom-form-item-label {
      text-align: right;
    }
    .custom-form .custom-form-item {
      flex-wrap: wrap;
    }
    .custom-form .custom-form-item .custom-form-item-label,
    .custom-form .custom-form-item .custom-form-item-control {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .custom-col-xs-24.custom-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      white-space: initial;
      text-align: left;
    }
    .custom-col-xs-24.custom-form-item-label > label {
      margin: 0;
    }
    .custom-col-xs-24.custom-form-item-label > label::after {
      display: none;
    }
    .custom-form-rtl.custom-col-xs-24.custom-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 767px) {
    .custom-col-sm-24.custom-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      white-space: initial;
      text-align: left;
    }
    .custom-col-sm-24.custom-form-item-label > label {
      margin: 0;
    }
    .custom-col-sm-24.custom-form-item-label > label::after {
      display: none;
    }
    .custom-form-rtl.custom-col-sm-24.custom-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 991px) {
    .custom-col-md-24.custom-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      white-space: initial;
      text-align: left;
    }
    .custom-col-md-24.custom-form-item-label > label {
      margin: 0;
    }
    .custom-col-md-24.custom-form-item-label > label::after {
      display: none;
    }
    .custom-form-rtl.custom-col-md-24.custom-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1199px) {
    .custom-col-lg-24.custom-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      white-space: initial;
      text-align: left;
    }
    .custom-col-lg-24.custom-form-item-label > label {
      margin: 0;
    }
    .custom-col-lg-24.custom-form-item-label > label::after {
      display: none;
    }
    .custom-form-rtl.custom-col-lg-24.custom-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1599px) {
    .custom-col-xl-24.custom-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: normal;
      white-space: initial;
      text-align: left;
    }
    .custom-col-xl-24.custom-form-item-label > label {
      margin: 0;
    }
    .custom-col-xl-24.custom-form-item-label > label::after {
      display: none;
    }
    .custom-form-rtl.custom-col-xl-24.custom-form-item-label {
      text-align: right;
    }
  }
  .custom-form-item {
    /* Some non-status related component style is in `components.less` */
    /* To support leave along ErrorList. We add additional className to handle explain style */
  }
  .custom-form-item-explain-error {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-form-item-explain-warning {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-form-item-has-feedback .custom-switch {
    margin: 2px 0 4px;
  }
  .custom-form-item-has-warning .custom-form-item-split {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-form-item-has-error .custom-form-item-split {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
  }
  .custom-form legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .custom-form label {
    font-size: 14px;
  }
  .custom-form input[type='search'] {
    box-sizing: border-box;
  }
  .custom-form input[type='radio'],
  .custom-form input[type='checkbox'] {
    line-height: normal;
  }
  .custom-form input[type='file'] {
    display: block;
  }
  .custom-form input[type='range'] {
    display: block;
    width: 100%;
  }
  .custom-form select[multiple],
  .custom-form select[size] {
    height: auto;
  }
  .custom-form input[type='file']:focus,
  .custom-form input[type='radio']:focus,
  .custom-form input[type='checkbox']:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .custom-form output {
    display: block;
    padding-top: 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }
  .custom-form .custom-form-text {
    display: inline-block;
    padding-right: 8px;
  }
  .custom-form-small .custom-form-item-label > label {
    height: 24px;
  }
  .custom-form-small .custom-form-item-control-input {
    min-height: 24px;
  }
  .custom-form-large .custom-form-item-label > label {
    height: 40px;
  }
  .custom-form-large .custom-form-item-control-input {
    min-height: 40px;
  }
  .custom-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    margin-bottom: 24px;
    vertical-align: top;
  }
  .custom-form-item-with-help {
    transition: none;
  }
  .custom-form-item-hidden,
  .custom-form-item-hidden.custom-row {
    display: none;
  }
  .custom-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }
  .custom-form-item-label-left {
    text-align: left;
  }
  .custom-form-item-label-wrap {
    overflow: visible;
    overflow: initial;
    line-height: 1.3215em;
    white-space: inherit;
  }
  .custom-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
  .custom-form-item-label > label > .anticon {
    font-size: 14px;
    vertical-align: top;
  }
  .custom-form-item-label > label.custom-form-item-required:not(.custom-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .custom-form-hide-required-mark .custom-form-item-label > label.custom-form-item-required:not(.custom-form-item-required-mark-optional)::before {
    display: none;
  }
  .custom-form-item-label > label .custom-form-item-optional {
    display: inline-block;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-form-hide-required-mark .custom-form-item-label > label .custom-form-item-optional {
    display: none;
  }
  .custom-form-item-label > label .custom-form-item-tooltip {
    color: rgba(0, 0, 0, 0.45);
    cursor: help;
    -webkit-writing-mode: horizontal-tb;
            writing-mode: horizontal-tb;
    -webkit-margin-start: 4px;
            margin-inline-start: 4px;
  }
  .custom-form-item-label > label::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
  .custom-form-item-label > label.custom-form-item-no-colon::after {
    content: ' ';
  }
  .custom-form-item-control {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .custom-form-item-control:first-child:not([class^='custom-col-']):not([class*=' custom-col-']) {
    width: 100%;
  }
  .custom-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
  }
  .custom-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
  }
  .custom-form-item-explain,
  .custom-form-item-extra {
    clear: both;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .custom-form-item-explain-connected {
    width: 100%;
  }
  .custom-form-item-extra {
    min-height: 24px;
  }
  .custom-form-item-with-help .custom-form-item-explain {
    height: auto;
    opacity: 1;
  }
  .custom-form-item-feedback-icon {
    font-size: 14px;
    text-align: center;
    visibility: visible;
    -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
            animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    pointer-events: none;
  }
  .custom-form-item-feedback-icon-success {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-form-item-feedback-icon-error {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-form-item-feedback-icon-warning {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-form-item-feedback-icon-validating {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-show-help {
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-show-help-appear,
  .custom-show-help-enter {
    opacity: 0;
  }
  .custom-show-help-appear-active,
  .custom-show-help-enter-active {
    opacity: 1;
  }
  .custom-show-help-leave {
    opacity: 1;
  }
  .custom-show-help-leave-active {
    opacity: 0;
  }
  .custom-show-help-item {
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .custom-show-help-item-appear,
  .custom-show-help-item-enter {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  .custom-show-help-item-appear-active,
  .custom-show-help-item-enter-active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  .custom-show-help-item-leave {
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .custom-show-help-item-leave-active {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  @-webkit-keyframes diffZoomIn1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes diffZoomIn1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes diffZoomIn2 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes diffZoomIn2 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes diffZoomIn3 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes diffZoomIn3 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  .custom-form-rtl {
    direction: rtl;
  }
  .custom-form-rtl .custom-form-item-label {
    text-align: left;
  }
  .custom-form-rtl .custom-form-item-label > label.custom-form-item-required::before {
    margin-right: 0;
    margin-left: 4px;
  }
  .custom-form-rtl .custom-form-item-label > label::after {
    margin: 0 2px 0 8px;
  }
  .custom-form-rtl .custom-form-item-label > label .custom-form-item-optional {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-col-rtl .custom-form-item-control:first-child {
    width: 100%;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-input {
    padding-right: 11px;
    padding-left: 24px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-input-affix-wrapper .custom-input-suffix {
    padding-right: 11px;
    padding-left: 18px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-input-affix-wrapper .custom-input {
    padding: 0;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-input-number-affix-wrapper .custom-input-number {
    padding: 0;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-input-search:not(.custom-input-search-enter-button) .custom-input-suffix {
    right: auto;
    left: 28px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-input-number {
    padding-left: 18px;
  }
  .custom-form-rtl .custom-form-item-has-feedback > .custom-select .custom-select-arrow,
  .custom-form-rtl .custom-form-item-has-feedback > .custom-select .custom-select-clear,
  .custom-form-rtl .custom-form-item-has-feedback :not(.custom-input-group-addon) > .custom-select .custom-select-arrow,
  .custom-form-rtl .custom-form-item-has-feedback :not(.custom-input-group-addon) > .custom-select .custom-select-clear,
  .custom-form-rtl .custom-form-item-has-feedback :not(.custom-input-number-group-addon) > .custom-select .custom-select-arrow,
  .custom-form-rtl .custom-form-item-has-feedback :not(.custom-input-number-group-addon) > .custom-select .custom-select-clear {
    right: auto;
    left: 32px;
  }
  .custom-form-rtl .custom-form-item-has-feedback > .custom-select .custom-select-selection-selected-value,
  .custom-form-rtl .custom-form-item-has-feedback :not(.custom-input-group-addon) > .custom-select .custom-select-selection-selected-value,
  .custom-form-rtl .custom-form-item-has-feedback :not(.custom-input-number-group-addon) > .custom-select .custom-select-selection-selected-value {
    padding-right: 0;
    padding-left: 42px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-cascader-picker-arrow {
    margin-right: 0;
    margin-left: 19px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-cascader-picker-clear {
    right: auto;
    left: 32px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-picker {
    padding-right: 11px;
    padding-left: 29.2px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-picker-large {
    padding-right: 11px;
    padding-left: 29.2px;
  }
  .custom-form-rtl .custom-form-item-has-feedback .custom-picker-small {
    padding-right: 7px;
    padding-left: 25.2px;
  }
  .custom-form-rtl .custom-form-item-has-feedback.custom-form-item-has-success .custom-form-item-children-icon,
  .custom-form-rtl .custom-form-item-has-feedback.custom-form-item-has-warning .custom-form-item-children-icon,
  .custom-form-rtl .custom-form-item-has-feedback.custom-form-item-has-error .custom-form-item-children-icon,
  .custom-form-rtl .custom-form-item-has-feedback.custom-form-item-is-validating .custom-form-item-children-icon {
    right: auto;
    left: 0;
  }
  .custom-form-rtl.custom-form-inline .custom-form-item {
    margin-right: 0;
    margin-left: 16px;
  }
  .custom-row {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
  }
  .custom-row::before,
  .custom-row::after {
    display: flex;
  }
  .custom-row-no-wrap {
    flex-wrap: nowrap;
  }
  .custom-row-start {
    justify-content: flex-start;
  }
  .custom-row-center {
    justify-content: center;
  }
  .custom-row-end {
    justify-content: flex-end;
  }
  .custom-row-space-between {
    justify-content: space-between;
  }
  .custom-row-space-around {
    justify-content: space-around;
  }
  .custom-row-space-evenly {
    justify-content: space-evenly;
  }
  .custom-row-top {
    align-items: flex-start;
  }
  .custom-row-middle {
    align-items: center;
  }
  .custom-row-bottom {
    align-items: flex-end;
  }
  .custom-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }
  .custom-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-push-24 {
    left: 100%;
  }
  .custom-col-pull-24 {
    right: 100%;
  }
  .custom-col-offset-24 {
    margin-left: 100%;
  }
  .custom-col-order-24 {
    order: 24;
  }
  .custom-col-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .custom-col-push-23 {
    left: 95.83333333%;
  }
  .custom-col-pull-23 {
    right: 95.83333333%;
  }
  .custom-col-offset-23 {
    margin-left: 95.83333333%;
  }
  .custom-col-order-23 {
    order: 23;
  }
  .custom-col-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .custom-col-push-22 {
    left: 91.66666667%;
  }
  .custom-col-pull-22 {
    right: 91.66666667%;
  }
  .custom-col-offset-22 {
    margin-left: 91.66666667%;
  }
  .custom-col-order-22 {
    order: 22;
  }
  .custom-col-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .custom-col-push-21 {
    left: 87.5%;
  }
  .custom-col-pull-21 {
    right: 87.5%;
  }
  .custom-col-offset-21 {
    margin-left: 87.5%;
  }
  .custom-col-order-21 {
    order: 21;
  }
  .custom-col-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .custom-col-push-20 {
    left: 83.33333333%;
  }
  .custom-col-pull-20 {
    right: 83.33333333%;
  }
  .custom-col-offset-20 {
    margin-left: 83.33333333%;
  }
  .custom-col-order-20 {
    order: 20;
  }
  .custom-col-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .custom-col-push-19 {
    left: 79.16666667%;
  }
  .custom-col-pull-19 {
    right: 79.16666667%;
  }
  .custom-col-offset-19 {
    margin-left: 79.16666667%;
  }
  .custom-col-order-19 {
    order: 19;
  }
  .custom-col-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .custom-col-push-18 {
    left: 75%;
  }
  .custom-col-pull-18 {
    right: 75%;
  }
  .custom-col-offset-18 {
    margin-left: 75%;
  }
  .custom-col-order-18 {
    order: 18;
  }
  .custom-col-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .custom-col-push-17 {
    left: 70.83333333%;
  }
  .custom-col-pull-17 {
    right: 70.83333333%;
  }
  .custom-col-offset-17 {
    margin-left: 70.83333333%;
  }
  .custom-col-order-17 {
    order: 17;
  }
  .custom-col-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .custom-col-push-16 {
    left: 66.66666667%;
  }
  .custom-col-pull-16 {
    right: 66.66666667%;
  }
  .custom-col-offset-16 {
    margin-left: 66.66666667%;
  }
  .custom-col-order-16 {
    order: 16;
  }
  .custom-col-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .custom-col-push-15 {
    left: 62.5%;
  }
  .custom-col-pull-15 {
    right: 62.5%;
  }
  .custom-col-offset-15 {
    margin-left: 62.5%;
  }
  .custom-col-order-15 {
    order: 15;
  }
  .custom-col-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .custom-col-push-14 {
    left: 58.33333333%;
  }
  .custom-col-pull-14 {
    right: 58.33333333%;
  }
  .custom-col-offset-14 {
    margin-left: 58.33333333%;
  }
  .custom-col-order-14 {
    order: 14;
  }
  .custom-col-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .custom-col-push-13 {
    left: 54.16666667%;
  }
  .custom-col-pull-13 {
    right: 54.16666667%;
  }
  .custom-col-offset-13 {
    margin-left: 54.16666667%;
  }
  .custom-col-order-13 {
    order: 13;
  }
  .custom-col-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-push-12 {
    left: 50%;
  }
  .custom-col-pull-12 {
    right: 50%;
  }
  .custom-col-offset-12 {
    margin-left: 50%;
  }
  .custom-col-order-12 {
    order: 12;
  }
  .custom-col-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .custom-col-push-11 {
    left: 45.83333333%;
  }
  .custom-col-pull-11 {
    right: 45.83333333%;
  }
  .custom-col-offset-11 {
    margin-left: 45.83333333%;
  }
  .custom-col-order-11 {
    order: 11;
  }
  .custom-col-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .custom-col-push-10 {
    left: 41.66666667%;
  }
  .custom-col-pull-10 {
    right: 41.66666667%;
  }
  .custom-col-offset-10 {
    margin-left: 41.66666667%;
  }
  .custom-col-order-10 {
    order: 10;
  }
  .custom-col-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .custom-col-push-9 {
    left: 37.5%;
  }
  .custom-col-pull-9 {
    right: 37.5%;
  }
  .custom-col-offset-9 {
    margin-left: 37.5%;
  }
  .custom-col-order-9 {
    order: 9;
  }
  .custom-col-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .custom-col-push-8 {
    left: 33.33333333%;
  }
  .custom-col-pull-8 {
    right: 33.33333333%;
  }
  .custom-col-offset-8 {
    margin-left: 33.33333333%;
  }
  .custom-col-order-8 {
    order: 8;
  }
  .custom-col-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .custom-col-push-7 {
    left: 29.16666667%;
  }
  .custom-col-pull-7 {
    right: 29.16666667%;
  }
  .custom-col-offset-7 {
    margin-left: 29.16666667%;
  }
  .custom-col-order-7 {
    order: 7;
  }
  .custom-col-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .custom-col-push-6 {
    left: 25%;
  }
  .custom-col-pull-6 {
    right: 25%;
  }
  .custom-col-offset-6 {
    margin-left: 25%;
  }
  .custom-col-order-6 {
    order: 6;
  }
  .custom-col-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .custom-col-push-5 {
    left: 20.83333333%;
  }
  .custom-col-pull-5 {
    right: 20.83333333%;
  }
  .custom-col-offset-5 {
    margin-left: 20.83333333%;
  }
  .custom-col-order-5 {
    order: 5;
  }
  .custom-col-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .custom-col-push-4 {
    left: 16.66666667%;
  }
  .custom-col-pull-4 {
    right: 16.66666667%;
  }
  .custom-col-offset-4 {
    margin-left: 16.66666667%;
  }
  .custom-col-order-4 {
    order: 4;
  }
  .custom-col-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .custom-col-push-3 {
    left: 12.5%;
  }
  .custom-col-pull-3 {
    right: 12.5%;
  }
  .custom-col-offset-3 {
    margin-left: 12.5%;
  }
  .custom-col-order-3 {
    order: 3;
  }
  .custom-col-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .custom-col-push-2 {
    left: 8.33333333%;
  }
  .custom-col-pull-2 {
    right: 8.33333333%;
  }
  .custom-col-offset-2 {
    margin-left: 8.33333333%;
  }
  .custom-col-order-2 {
    order: 2;
  }
  .custom-col-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .custom-col-push-1 {
    left: 4.16666667%;
  }
  .custom-col-pull-1 {
    right: 4.16666667%;
  }
  .custom-col-offset-1 {
    margin-left: 4.16666667%;
  }
  .custom-col-order-1 {
    order: 1;
  }
  .custom-col-0 {
    display: none;
  }
  .custom-col-push-0 {
    left: auto;
  }
  .custom-col-pull-0 {
    right: auto;
  }
  .custom-col-push-0 {
    left: auto;
  }
  .custom-col-pull-0 {
    right: auto;
  }
  .custom-col-offset-0 {
    margin-left: 0;
  }
  .custom-col-order-0 {
    order: 0;
  }
  .custom-col-push-0.custom-col-rtl {
    right: auto;
  }
  .custom-col-pull-0.custom-col-rtl {
    left: auto;
  }
  .custom-col-push-0.custom-col-rtl {
    right: auto;
  }
  .custom-col-pull-0.custom-col-rtl {
    left: auto;
  }
  .custom-col-offset-0.custom-col-rtl {
    margin-right: 0;
  }
  .custom-col-push-1.custom-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .custom-col-pull-1.custom-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .custom-col-offset-1.custom-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .custom-col-push-2.custom-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .custom-col-pull-2.custom-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .custom-col-offset-2.custom-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .custom-col-push-3.custom-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .custom-col-pull-3.custom-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .custom-col-offset-3.custom-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .custom-col-push-4.custom-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .custom-col-pull-4.custom-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .custom-col-offset-4.custom-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .custom-col-push-5.custom-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .custom-col-pull-5.custom-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .custom-col-offset-5.custom-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .custom-col-push-6.custom-col-rtl {
    right: 25%;
    left: auto;
  }
  .custom-col-pull-6.custom-col-rtl {
    right: auto;
    left: 25%;
  }
  .custom-col-offset-6.custom-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .custom-col-push-7.custom-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .custom-col-pull-7.custom-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .custom-col-offset-7.custom-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .custom-col-push-8.custom-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .custom-col-pull-8.custom-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .custom-col-offset-8.custom-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .custom-col-push-9.custom-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .custom-col-pull-9.custom-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .custom-col-offset-9.custom-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .custom-col-push-10.custom-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .custom-col-pull-10.custom-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .custom-col-offset-10.custom-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .custom-col-push-11.custom-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .custom-col-pull-11.custom-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .custom-col-offset-11.custom-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .custom-col-push-12.custom-col-rtl {
    right: 50%;
    left: auto;
  }
  .custom-col-pull-12.custom-col-rtl {
    right: auto;
    left: 50%;
  }
  .custom-col-offset-12.custom-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .custom-col-push-13.custom-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .custom-col-pull-13.custom-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .custom-col-offset-13.custom-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .custom-col-push-14.custom-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .custom-col-pull-14.custom-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .custom-col-offset-14.custom-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .custom-col-push-15.custom-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .custom-col-pull-15.custom-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .custom-col-offset-15.custom-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .custom-col-push-16.custom-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .custom-col-pull-16.custom-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .custom-col-offset-16.custom-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .custom-col-push-17.custom-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .custom-col-pull-17.custom-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .custom-col-offset-17.custom-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .custom-col-push-18.custom-col-rtl {
    right: 75%;
    left: auto;
  }
  .custom-col-pull-18.custom-col-rtl {
    right: auto;
    left: 75%;
  }
  .custom-col-offset-18.custom-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .custom-col-push-19.custom-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .custom-col-pull-19.custom-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .custom-col-offset-19.custom-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .custom-col-push-20.custom-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .custom-col-pull-20.custom-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .custom-col-offset-20.custom-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .custom-col-push-21.custom-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .custom-col-pull-21.custom-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .custom-col-offset-21.custom-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .custom-col-push-22.custom-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .custom-col-pull-22.custom-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .custom-col-offset-22.custom-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .custom-col-push-23.custom-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .custom-col-pull-23.custom-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .custom-col-offset-23.custom-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .custom-col-push-24.custom-col-rtl {
    right: 100%;
    left: auto;
  }
  .custom-col-pull-24.custom-col-rtl {
    right: auto;
    left: 100%;
  }
  .custom-col-offset-24.custom-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .custom-col-xs-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-xs-push-24 {
    left: 100%;
  }
  .custom-col-xs-pull-24 {
    right: 100%;
  }
  .custom-col-xs-offset-24 {
    margin-left: 100%;
  }
  .custom-col-xs-order-24 {
    order: 24;
  }
  .custom-col-xs-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .custom-col-xs-push-23 {
    left: 95.83333333%;
  }
  .custom-col-xs-pull-23 {
    right: 95.83333333%;
  }
  .custom-col-xs-offset-23 {
    margin-left: 95.83333333%;
  }
  .custom-col-xs-order-23 {
    order: 23;
  }
  .custom-col-xs-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .custom-col-xs-push-22 {
    left: 91.66666667%;
  }
  .custom-col-xs-pull-22 {
    right: 91.66666667%;
  }
  .custom-col-xs-offset-22 {
    margin-left: 91.66666667%;
  }
  .custom-col-xs-order-22 {
    order: 22;
  }
  .custom-col-xs-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .custom-col-xs-push-21 {
    left: 87.5%;
  }
  .custom-col-xs-pull-21 {
    right: 87.5%;
  }
  .custom-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .custom-col-xs-order-21 {
    order: 21;
  }
  .custom-col-xs-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .custom-col-xs-push-20 {
    left: 83.33333333%;
  }
  .custom-col-xs-pull-20 {
    right: 83.33333333%;
  }
  .custom-col-xs-offset-20 {
    margin-left: 83.33333333%;
  }
  .custom-col-xs-order-20 {
    order: 20;
  }
  .custom-col-xs-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .custom-col-xs-push-19 {
    left: 79.16666667%;
  }
  .custom-col-xs-pull-19 {
    right: 79.16666667%;
  }
  .custom-col-xs-offset-19 {
    margin-left: 79.16666667%;
  }
  .custom-col-xs-order-19 {
    order: 19;
  }
  .custom-col-xs-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .custom-col-xs-push-18 {
    left: 75%;
  }
  .custom-col-xs-pull-18 {
    right: 75%;
  }
  .custom-col-xs-offset-18 {
    margin-left: 75%;
  }
  .custom-col-xs-order-18 {
    order: 18;
  }
  .custom-col-xs-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .custom-col-xs-push-17 {
    left: 70.83333333%;
  }
  .custom-col-xs-pull-17 {
    right: 70.83333333%;
  }
  .custom-col-xs-offset-17 {
    margin-left: 70.83333333%;
  }
  .custom-col-xs-order-17 {
    order: 17;
  }
  .custom-col-xs-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .custom-col-xs-push-16 {
    left: 66.66666667%;
  }
  .custom-col-xs-pull-16 {
    right: 66.66666667%;
  }
  .custom-col-xs-offset-16 {
    margin-left: 66.66666667%;
  }
  .custom-col-xs-order-16 {
    order: 16;
  }
  .custom-col-xs-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .custom-col-xs-push-15 {
    left: 62.5%;
  }
  .custom-col-xs-pull-15 {
    right: 62.5%;
  }
  .custom-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .custom-col-xs-order-15 {
    order: 15;
  }
  .custom-col-xs-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .custom-col-xs-push-14 {
    left: 58.33333333%;
  }
  .custom-col-xs-pull-14 {
    right: 58.33333333%;
  }
  .custom-col-xs-offset-14 {
    margin-left: 58.33333333%;
  }
  .custom-col-xs-order-14 {
    order: 14;
  }
  .custom-col-xs-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .custom-col-xs-push-13 {
    left: 54.16666667%;
  }
  .custom-col-xs-pull-13 {
    right: 54.16666667%;
  }
  .custom-col-xs-offset-13 {
    margin-left: 54.16666667%;
  }
  .custom-col-xs-order-13 {
    order: 13;
  }
  .custom-col-xs-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-xs-push-12 {
    left: 50%;
  }
  .custom-col-xs-pull-12 {
    right: 50%;
  }
  .custom-col-xs-offset-12 {
    margin-left: 50%;
  }
  .custom-col-xs-order-12 {
    order: 12;
  }
  .custom-col-xs-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .custom-col-xs-push-11 {
    left: 45.83333333%;
  }
  .custom-col-xs-pull-11 {
    right: 45.83333333%;
  }
  .custom-col-xs-offset-11 {
    margin-left: 45.83333333%;
  }
  .custom-col-xs-order-11 {
    order: 11;
  }
  .custom-col-xs-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .custom-col-xs-push-10 {
    left: 41.66666667%;
  }
  .custom-col-xs-pull-10 {
    right: 41.66666667%;
  }
  .custom-col-xs-offset-10 {
    margin-left: 41.66666667%;
  }
  .custom-col-xs-order-10 {
    order: 10;
  }
  .custom-col-xs-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .custom-col-xs-push-9 {
    left: 37.5%;
  }
  .custom-col-xs-pull-9 {
    right: 37.5%;
  }
  .custom-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .custom-col-xs-order-9 {
    order: 9;
  }
  .custom-col-xs-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .custom-col-xs-push-8 {
    left: 33.33333333%;
  }
  .custom-col-xs-pull-8 {
    right: 33.33333333%;
  }
  .custom-col-xs-offset-8 {
    margin-left: 33.33333333%;
  }
  .custom-col-xs-order-8 {
    order: 8;
  }
  .custom-col-xs-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .custom-col-xs-push-7 {
    left: 29.16666667%;
  }
  .custom-col-xs-pull-7 {
    right: 29.16666667%;
  }
  .custom-col-xs-offset-7 {
    margin-left: 29.16666667%;
  }
  .custom-col-xs-order-7 {
    order: 7;
  }
  .custom-col-xs-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .custom-col-xs-push-6 {
    left: 25%;
  }
  .custom-col-xs-pull-6 {
    right: 25%;
  }
  .custom-col-xs-offset-6 {
    margin-left: 25%;
  }
  .custom-col-xs-order-6 {
    order: 6;
  }
  .custom-col-xs-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .custom-col-xs-push-5 {
    left: 20.83333333%;
  }
  .custom-col-xs-pull-5 {
    right: 20.83333333%;
  }
  .custom-col-xs-offset-5 {
    margin-left: 20.83333333%;
  }
  .custom-col-xs-order-5 {
    order: 5;
  }
  .custom-col-xs-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .custom-col-xs-push-4 {
    left: 16.66666667%;
  }
  .custom-col-xs-pull-4 {
    right: 16.66666667%;
  }
  .custom-col-xs-offset-4 {
    margin-left: 16.66666667%;
  }
  .custom-col-xs-order-4 {
    order: 4;
  }
  .custom-col-xs-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .custom-col-xs-push-3 {
    left: 12.5%;
  }
  .custom-col-xs-pull-3 {
    right: 12.5%;
  }
  .custom-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .custom-col-xs-order-3 {
    order: 3;
  }
  .custom-col-xs-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .custom-col-xs-push-2 {
    left: 8.33333333%;
  }
  .custom-col-xs-pull-2 {
    right: 8.33333333%;
  }
  .custom-col-xs-offset-2 {
    margin-left: 8.33333333%;
  }
  .custom-col-xs-order-2 {
    order: 2;
  }
  .custom-col-xs-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .custom-col-xs-push-1 {
    left: 4.16666667%;
  }
  .custom-col-xs-pull-1 {
    right: 4.16666667%;
  }
  .custom-col-xs-offset-1 {
    margin-left: 4.16666667%;
  }
  .custom-col-xs-order-1 {
    order: 1;
  }
  .custom-col-xs-0 {
    display: none;
  }
  .custom-col-push-0 {
    left: auto;
  }
  .custom-col-pull-0 {
    right: auto;
  }
  .custom-col-xs-push-0 {
    left: auto;
  }
  .custom-col-xs-pull-0 {
    right: auto;
  }
  .custom-col-xs-offset-0 {
    margin-left: 0;
  }
  .custom-col-xs-order-0 {
    order: 0;
  }
  .custom-col-push-0.custom-col-rtl {
    right: auto;
  }
  .custom-col-pull-0.custom-col-rtl {
    left: auto;
  }
  .custom-col-xs-push-0.custom-col-rtl {
    right: auto;
  }
  .custom-col-xs-pull-0.custom-col-rtl {
    left: auto;
  }
  .custom-col-xs-offset-0.custom-col-rtl {
    margin-right: 0;
  }
  .custom-col-xs-push-1.custom-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .custom-col-xs-pull-1.custom-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .custom-col-xs-offset-1.custom-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-2.custom-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .custom-col-xs-pull-2.custom-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .custom-col-xs-offset-2.custom-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-3.custom-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .custom-col-xs-pull-3.custom-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .custom-col-xs-offset-3.custom-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .custom-col-xs-push-4.custom-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .custom-col-xs-pull-4.custom-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .custom-col-xs-offset-4.custom-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-5.custom-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .custom-col-xs-pull-5.custom-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .custom-col-xs-offset-5.custom-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-6.custom-col-rtl {
    right: 25%;
    left: auto;
  }
  .custom-col-xs-pull-6.custom-col-rtl {
    right: auto;
    left: 25%;
  }
  .custom-col-xs-offset-6.custom-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .custom-col-xs-push-7.custom-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .custom-col-xs-pull-7.custom-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .custom-col-xs-offset-7.custom-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-8.custom-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .custom-col-xs-pull-8.custom-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .custom-col-xs-offset-8.custom-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-9.custom-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .custom-col-xs-pull-9.custom-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .custom-col-xs-offset-9.custom-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .custom-col-xs-push-10.custom-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .custom-col-xs-pull-10.custom-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .custom-col-xs-offset-10.custom-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-11.custom-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .custom-col-xs-pull-11.custom-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .custom-col-xs-offset-11.custom-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-12.custom-col-rtl {
    right: 50%;
    left: auto;
  }
  .custom-col-xs-pull-12.custom-col-rtl {
    right: auto;
    left: 50%;
  }
  .custom-col-xs-offset-12.custom-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .custom-col-xs-push-13.custom-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .custom-col-xs-pull-13.custom-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .custom-col-xs-offset-13.custom-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-14.custom-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .custom-col-xs-pull-14.custom-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .custom-col-xs-offset-14.custom-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-15.custom-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .custom-col-xs-pull-15.custom-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .custom-col-xs-offset-15.custom-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .custom-col-xs-push-16.custom-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .custom-col-xs-pull-16.custom-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .custom-col-xs-offset-16.custom-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-17.custom-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .custom-col-xs-pull-17.custom-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .custom-col-xs-offset-17.custom-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-18.custom-col-rtl {
    right: 75%;
    left: auto;
  }
  .custom-col-xs-pull-18.custom-col-rtl {
    right: auto;
    left: 75%;
  }
  .custom-col-xs-offset-18.custom-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .custom-col-xs-push-19.custom-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .custom-col-xs-pull-19.custom-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .custom-col-xs-offset-19.custom-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-20.custom-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .custom-col-xs-pull-20.custom-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .custom-col-xs-offset-20.custom-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-21.custom-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .custom-col-xs-pull-21.custom-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .custom-col-xs-offset-21.custom-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .custom-col-xs-push-22.custom-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .custom-col-xs-pull-22.custom-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .custom-col-xs-offset-22.custom-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .custom-col-xs-push-23.custom-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .custom-col-xs-pull-23.custom-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .custom-col-xs-offset-23.custom-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .custom-col-xs-push-24.custom-col-rtl {
    right: 100%;
    left: auto;
  }
  .custom-col-xs-pull-24.custom-col-rtl {
    right: auto;
    left: 100%;
  }
  .custom-col-xs-offset-24.custom-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  @media (min-width: 576px) {
    .custom-col-sm-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .custom-col-sm-push-24 {
      left: 100%;
    }
    .custom-col-sm-pull-24 {
      right: 100%;
    }
    .custom-col-sm-offset-24 {
      margin-left: 100%;
    }
    .custom-col-sm-order-24 {
      order: 24;
    }
    .custom-col-sm-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .custom-col-sm-push-23 {
      left: 95.83333333%;
    }
    .custom-col-sm-pull-23 {
      right: 95.83333333%;
    }
    .custom-col-sm-offset-23 {
      margin-left: 95.83333333%;
    }
    .custom-col-sm-order-23 {
      order: 23;
    }
    .custom-col-sm-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .custom-col-sm-push-22 {
      left: 91.66666667%;
    }
    .custom-col-sm-pull-22 {
      right: 91.66666667%;
    }
    .custom-col-sm-offset-22 {
      margin-left: 91.66666667%;
    }
    .custom-col-sm-order-22 {
      order: 22;
    }
    .custom-col-sm-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .custom-col-sm-push-21 {
      left: 87.5%;
    }
    .custom-col-sm-pull-21 {
      right: 87.5%;
    }
    .custom-col-sm-offset-21 {
      margin-left: 87.5%;
    }
    .custom-col-sm-order-21 {
      order: 21;
    }
    .custom-col-sm-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .custom-col-sm-push-20 {
      left: 83.33333333%;
    }
    .custom-col-sm-pull-20 {
      right: 83.33333333%;
    }
    .custom-col-sm-offset-20 {
      margin-left: 83.33333333%;
    }
    .custom-col-sm-order-20 {
      order: 20;
    }
    .custom-col-sm-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .custom-col-sm-push-19 {
      left: 79.16666667%;
    }
    .custom-col-sm-pull-19 {
      right: 79.16666667%;
    }
    .custom-col-sm-offset-19 {
      margin-left: 79.16666667%;
    }
    .custom-col-sm-order-19 {
      order: 19;
    }
    .custom-col-sm-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .custom-col-sm-push-18 {
      left: 75%;
    }
    .custom-col-sm-pull-18 {
      right: 75%;
    }
    .custom-col-sm-offset-18 {
      margin-left: 75%;
    }
    .custom-col-sm-order-18 {
      order: 18;
    }
    .custom-col-sm-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .custom-col-sm-push-17 {
      left: 70.83333333%;
    }
    .custom-col-sm-pull-17 {
      right: 70.83333333%;
    }
    .custom-col-sm-offset-17 {
      margin-left: 70.83333333%;
    }
    .custom-col-sm-order-17 {
      order: 17;
    }
    .custom-col-sm-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .custom-col-sm-push-16 {
      left: 66.66666667%;
    }
    .custom-col-sm-pull-16 {
      right: 66.66666667%;
    }
    .custom-col-sm-offset-16 {
      margin-left: 66.66666667%;
    }
    .custom-col-sm-order-16 {
      order: 16;
    }
    .custom-col-sm-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .custom-col-sm-push-15 {
      left: 62.5%;
    }
    .custom-col-sm-pull-15 {
      right: 62.5%;
    }
    .custom-col-sm-offset-15 {
      margin-left: 62.5%;
    }
    .custom-col-sm-order-15 {
      order: 15;
    }
    .custom-col-sm-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .custom-col-sm-push-14 {
      left: 58.33333333%;
    }
    .custom-col-sm-pull-14 {
      right: 58.33333333%;
    }
    .custom-col-sm-offset-14 {
      margin-left: 58.33333333%;
    }
    .custom-col-sm-order-14 {
      order: 14;
    }
    .custom-col-sm-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .custom-col-sm-push-13 {
      left: 54.16666667%;
    }
    .custom-col-sm-pull-13 {
      right: 54.16666667%;
    }
    .custom-col-sm-offset-13 {
      margin-left: 54.16666667%;
    }
    .custom-col-sm-order-13 {
      order: 13;
    }
    .custom-col-sm-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .custom-col-sm-push-12 {
      left: 50%;
    }
    .custom-col-sm-pull-12 {
      right: 50%;
    }
    .custom-col-sm-offset-12 {
      margin-left: 50%;
    }
    .custom-col-sm-order-12 {
      order: 12;
    }
    .custom-col-sm-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .custom-col-sm-push-11 {
      left: 45.83333333%;
    }
    .custom-col-sm-pull-11 {
      right: 45.83333333%;
    }
    .custom-col-sm-offset-11 {
      margin-left: 45.83333333%;
    }
    .custom-col-sm-order-11 {
      order: 11;
    }
    .custom-col-sm-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .custom-col-sm-push-10 {
      left: 41.66666667%;
    }
    .custom-col-sm-pull-10 {
      right: 41.66666667%;
    }
    .custom-col-sm-offset-10 {
      margin-left: 41.66666667%;
    }
    .custom-col-sm-order-10 {
      order: 10;
    }
    .custom-col-sm-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .custom-col-sm-push-9 {
      left: 37.5%;
    }
    .custom-col-sm-pull-9 {
      right: 37.5%;
    }
    .custom-col-sm-offset-9 {
      margin-left: 37.5%;
    }
    .custom-col-sm-order-9 {
      order: 9;
    }
    .custom-col-sm-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .custom-col-sm-push-8 {
      left: 33.33333333%;
    }
    .custom-col-sm-pull-8 {
      right: 33.33333333%;
    }
    .custom-col-sm-offset-8 {
      margin-left: 33.33333333%;
    }
    .custom-col-sm-order-8 {
      order: 8;
    }
    .custom-col-sm-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .custom-col-sm-push-7 {
      left: 29.16666667%;
    }
    .custom-col-sm-pull-7 {
      right: 29.16666667%;
    }
    .custom-col-sm-offset-7 {
      margin-left: 29.16666667%;
    }
    .custom-col-sm-order-7 {
      order: 7;
    }
    .custom-col-sm-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .custom-col-sm-push-6 {
      left: 25%;
    }
    .custom-col-sm-pull-6 {
      right: 25%;
    }
    .custom-col-sm-offset-6 {
      margin-left: 25%;
    }
    .custom-col-sm-order-6 {
      order: 6;
    }
    .custom-col-sm-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .custom-col-sm-push-5 {
      left: 20.83333333%;
    }
    .custom-col-sm-pull-5 {
      right: 20.83333333%;
    }
    .custom-col-sm-offset-5 {
      margin-left: 20.83333333%;
    }
    .custom-col-sm-order-5 {
      order: 5;
    }
    .custom-col-sm-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .custom-col-sm-push-4 {
      left: 16.66666667%;
    }
    .custom-col-sm-pull-4 {
      right: 16.66666667%;
    }
    .custom-col-sm-offset-4 {
      margin-left: 16.66666667%;
    }
    .custom-col-sm-order-4 {
      order: 4;
    }
    .custom-col-sm-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .custom-col-sm-push-3 {
      left: 12.5%;
    }
    .custom-col-sm-pull-3 {
      right: 12.5%;
    }
    .custom-col-sm-offset-3 {
      margin-left: 12.5%;
    }
    .custom-col-sm-order-3 {
      order: 3;
    }
    .custom-col-sm-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .custom-col-sm-push-2 {
      left: 8.33333333%;
    }
    .custom-col-sm-pull-2 {
      right: 8.33333333%;
    }
    .custom-col-sm-offset-2 {
      margin-left: 8.33333333%;
    }
    .custom-col-sm-order-2 {
      order: 2;
    }
    .custom-col-sm-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .custom-col-sm-push-1 {
      left: 4.16666667%;
    }
    .custom-col-sm-pull-1 {
      right: 4.16666667%;
    }
    .custom-col-sm-offset-1 {
      margin-left: 4.16666667%;
    }
    .custom-col-sm-order-1 {
      order: 1;
    }
    .custom-col-sm-0 {
      display: none;
    }
    .custom-col-push-0 {
      left: auto;
    }
    .custom-col-pull-0 {
      right: auto;
    }
    .custom-col-sm-push-0 {
      left: auto;
    }
    .custom-col-sm-pull-0 {
      right: auto;
    }
    .custom-col-sm-offset-0 {
      margin-left: 0;
    }
    .custom-col-sm-order-0 {
      order: 0;
    }
    .custom-col-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-sm-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-sm-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-sm-offset-0.custom-col-rtl {
      margin-right: 0;
    }
    .custom-col-sm-push-1.custom-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .custom-col-sm-pull-1.custom-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .custom-col-sm-offset-1.custom-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-2.custom-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .custom-col-sm-pull-2.custom-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .custom-col-sm-offset-2.custom-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-3.custom-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .custom-col-sm-pull-3.custom-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .custom-col-sm-offset-3.custom-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .custom-col-sm-push-4.custom-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .custom-col-sm-pull-4.custom-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .custom-col-sm-offset-4.custom-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-5.custom-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .custom-col-sm-pull-5.custom-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .custom-col-sm-offset-5.custom-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-6.custom-col-rtl {
      right: 25%;
      left: auto;
    }
    .custom-col-sm-pull-6.custom-col-rtl {
      right: auto;
      left: 25%;
    }
    .custom-col-sm-offset-6.custom-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .custom-col-sm-push-7.custom-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .custom-col-sm-pull-7.custom-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .custom-col-sm-offset-7.custom-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-8.custom-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .custom-col-sm-pull-8.custom-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .custom-col-sm-offset-8.custom-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-9.custom-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .custom-col-sm-pull-9.custom-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .custom-col-sm-offset-9.custom-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .custom-col-sm-push-10.custom-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .custom-col-sm-pull-10.custom-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .custom-col-sm-offset-10.custom-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-11.custom-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .custom-col-sm-pull-11.custom-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .custom-col-sm-offset-11.custom-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-12.custom-col-rtl {
      right: 50%;
      left: auto;
    }
    .custom-col-sm-pull-12.custom-col-rtl {
      right: auto;
      left: 50%;
    }
    .custom-col-sm-offset-12.custom-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .custom-col-sm-push-13.custom-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .custom-col-sm-pull-13.custom-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .custom-col-sm-offset-13.custom-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-14.custom-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .custom-col-sm-pull-14.custom-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .custom-col-sm-offset-14.custom-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-15.custom-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .custom-col-sm-pull-15.custom-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .custom-col-sm-offset-15.custom-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .custom-col-sm-push-16.custom-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .custom-col-sm-pull-16.custom-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .custom-col-sm-offset-16.custom-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-17.custom-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .custom-col-sm-pull-17.custom-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .custom-col-sm-offset-17.custom-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-18.custom-col-rtl {
      right: 75%;
      left: auto;
    }
    .custom-col-sm-pull-18.custom-col-rtl {
      right: auto;
      left: 75%;
    }
    .custom-col-sm-offset-18.custom-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .custom-col-sm-push-19.custom-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .custom-col-sm-pull-19.custom-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .custom-col-sm-offset-19.custom-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-20.custom-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .custom-col-sm-pull-20.custom-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .custom-col-sm-offset-20.custom-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-21.custom-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .custom-col-sm-pull-21.custom-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .custom-col-sm-offset-21.custom-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .custom-col-sm-push-22.custom-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .custom-col-sm-pull-22.custom-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .custom-col-sm-offset-22.custom-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .custom-col-sm-push-23.custom-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .custom-col-sm-pull-23.custom-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .custom-col-sm-offset-23.custom-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .custom-col-sm-push-24.custom-col-rtl {
      right: 100%;
      left: auto;
    }
    .custom-col-sm-pull-24.custom-col-rtl {
      right: auto;
      left: 100%;
    }
    .custom-col-sm-offset-24.custom-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 768px) {
    .custom-col-md-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .custom-col-md-push-24 {
      left: 100%;
    }
    .custom-col-md-pull-24 {
      right: 100%;
    }
    .custom-col-md-offset-24 {
      margin-left: 100%;
    }
    .custom-col-md-order-24 {
      order: 24;
    }
    .custom-col-md-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .custom-col-md-push-23 {
      left: 95.83333333%;
    }
    .custom-col-md-pull-23 {
      right: 95.83333333%;
    }
    .custom-col-md-offset-23 {
      margin-left: 95.83333333%;
    }
    .custom-col-md-order-23 {
      order: 23;
    }
    .custom-col-md-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .custom-col-md-push-22 {
      left: 91.66666667%;
    }
    .custom-col-md-pull-22 {
      right: 91.66666667%;
    }
    .custom-col-md-offset-22 {
      margin-left: 91.66666667%;
    }
    .custom-col-md-order-22 {
      order: 22;
    }
    .custom-col-md-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .custom-col-md-push-21 {
      left: 87.5%;
    }
    .custom-col-md-pull-21 {
      right: 87.5%;
    }
    .custom-col-md-offset-21 {
      margin-left: 87.5%;
    }
    .custom-col-md-order-21 {
      order: 21;
    }
    .custom-col-md-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .custom-col-md-push-20 {
      left: 83.33333333%;
    }
    .custom-col-md-pull-20 {
      right: 83.33333333%;
    }
    .custom-col-md-offset-20 {
      margin-left: 83.33333333%;
    }
    .custom-col-md-order-20 {
      order: 20;
    }
    .custom-col-md-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .custom-col-md-push-19 {
      left: 79.16666667%;
    }
    .custom-col-md-pull-19 {
      right: 79.16666667%;
    }
    .custom-col-md-offset-19 {
      margin-left: 79.16666667%;
    }
    .custom-col-md-order-19 {
      order: 19;
    }
    .custom-col-md-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .custom-col-md-push-18 {
      left: 75%;
    }
    .custom-col-md-pull-18 {
      right: 75%;
    }
    .custom-col-md-offset-18 {
      margin-left: 75%;
    }
    .custom-col-md-order-18 {
      order: 18;
    }
    .custom-col-md-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .custom-col-md-push-17 {
      left: 70.83333333%;
    }
    .custom-col-md-pull-17 {
      right: 70.83333333%;
    }
    .custom-col-md-offset-17 {
      margin-left: 70.83333333%;
    }
    .custom-col-md-order-17 {
      order: 17;
    }
    .custom-col-md-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .custom-col-md-push-16 {
      left: 66.66666667%;
    }
    .custom-col-md-pull-16 {
      right: 66.66666667%;
    }
    .custom-col-md-offset-16 {
      margin-left: 66.66666667%;
    }
    .custom-col-md-order-16 {
      order: 16;
    }
    .custom-col-md-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .custom-col-md-push-15 {
      left: 62.5%;
    }
    .custom-col-md-pull-15 {
      right: 62.5%;
    }
    .custom-col-md-offset-15 {
      margin-left: 62.5%;
    }
    .custom-col-md-order-15 {
      order: 15;
    }
    .custom-col-md-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .custom-col-md-push-14 {
      left: 58.33333333%;
    }
    .custom-col-md-pull-14 {
      right: 58.33333333%;
    }
    .custom-col-md-offset-14 {
      margin-left: 58.33333333%;
    }
    .custom-col-md-order-14 {
      order: 14;
    }
    .custom-col-md-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .custom-col-md-push-13 {
      left: 54.16666667%;
    }
    .custom-col-md-pull-13 {
      right: 54.16666667%;
    }
    .custom-col-md-offset-13 {
      margin-left: 54.16666667%;
    }
    .custom-col-md-order-13 {
      order: 13;
    }
    .custom-col-md-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .custom-col-md-push-12 {
      left: 50%;
    }
    .custom-col-md-pull-12 {
      right: 50%;
    }
    .custom-col-md-offset-12 {
      margin-left: 50%;
    }
    .custom-col-md-order-12 {
      order: 12;
    }
    .custom-col-md-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .custom-col-md-push-11 {
      left: 45.83333333%;
    }
    .custom-col-md-pull-11 {
      right: 45.83333333%;
    }
    .custom-col-md-offset-11 {
      margin-left: 45.83333333%;
    }
    .custom-col-md-order-11 {
      order: 11;
    }
    .custom-col-md-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .custom-col-md-push-10 {
      left: 41.66666667%;
    }
    .custom-col-md-pull-10 {
      right: 41.66666667%;
    }
    .custom-col-md-offset-10 {
      margin-left: 41.66666667%;
    }
    .custom-col-md-order-10 {
      order: 10;
    }
    .custom-col-md-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .custom-col-md-push-9 {
      left: 37.5%;
    }
    .custom-col-md-pull-9 {
      right: 37.5%;
    }
    .custom-col-md-offset-9 {
      margin-left: 37.5%;
    }
    .custom-col-md-order-9 {
      order: 9;
    }
    .custom-col-md-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .custom-col-md-push-8 {
      left: 33.33333333%;
    }
    .custom-col-md-pull-8 {
      right: 33.33333333%;
    }
    .custom-col-md-offset-8 {
      margin-left: 33.33333333%;
    }
    .custom-col-md-order-8 {
      order: 8;
    }
    .custom-col-md-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .custom-col-md-push-7 {
      left: 29.16666667%;
    }
    .custom-col-md-pull-7 {
      right: 29.16666667%;
    }
    .custom-col-md-offset-7 {
      margin-left: 29.16666667%;
    }
    .custom-col-md-order-7 {
      order: 7;
    }
    .custom-col-md-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .custom-col-md-push-6 {
      left: 25%;
    }
    .custom-col-md-pull-6 {
      right: 25%;
    }
    .custom-col-md-offset-6 {
      margin-left: 25%;
    }
    .custom-col-md-order-6 {
      order: 6;
    }
    .custom-col-md-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .custom-col-md-push-5 {
      left: 20.83333333%;
    }
    .custom-col-md-pull-5 {
      right: 20.83333333%;
    }
    .custom-col-md-offset-5 {
      margin-left: 20.83333333%;
    }
    .custom-col-md-order-5 {
      order: 5;
    }
    .custom-col-md-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .custom-col-md-push-4 {
      left: 16.66666667%;
    }
    .custom-col-md-pull-4 {
      right: 16.66666667%;
    }
    .custom-col-md-offset-4 {
      margin-left: 16.66666667%;
    }
    .custom-col-md-order-4 {
      order: 4;
    }
    .custom-col-md-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .custom-col-md-push-3 {
      left: 12.5%;
    }
    .custom-col-md-pull-3 {
      right: 12.5%;
    }
    .custom-col-md-offset-3 {
      margin-left: 12.5%;
    }
    .custom-col-md-order-3 {
      order: 3;
    }
    .custom-col-md-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .custom-col-md-push-2 {
      left: 8.33333333%;
    }
    .custom-col-md-pull-2 {
      right: 8.33333333%;
    }
    .custom-col-md-offset-2 {
      margin-left: 8.33333333%;
    }
    .custom-col-md-order-2 {
      order: 2;
    }
    .custom-col-md-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .custom-col-md-push-1 {
      left: 4.16666667%;
    }
    .custom-col-md-pull-1 {
      right: 4.16666667%;
    }
    .custom-col-md-offset-1 {
      margin-left: 4.16666667%;
    }
    .custom-col-md-order-1 {
      order: 1;
    }
    .custom-col-md-0 {
      display: none;
    }
    .custom-col-push-0 {
      left: auto;
    }
    .custom-col-pull-0 {
      right: auto;
    }
    .custom-col-md-push-0 {
      left: auto;
    }
    .custom-col-md-pull-0 {
      right: auto;
    }
    .custom-col-md-offset-0 {
      margin-left: 0;
    }
    .custom-col-md-order-0 {
      order: 0;
    }
    .custom-col-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-md-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-md-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-md-offset-0.custom-col-rtl {
      margin-right: 0;
    }
    .custom-col-md-push-1.custom-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .custom-col-md-pull-1.custom-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .custom-col-md-offset-1.custom-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .custom-col-md-push-2.custom-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .custom-col-md-pull-2.custom-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .custom-col-md-offset-2.custom-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .custom-col-md-push-3.custom-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .custom-col-md-pull-3.custom-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .custom-col-md-offset-3.custom-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .custom-col-md-push-4.custom-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .custom-col-md-pull-4.custom-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .custom-col-md-offset-4.custom-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .custom-col-md-push-5.custom-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .custom-col-md-pull-5.custom-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .custom-col-md-offset-5.custom-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .custom-col-md-push-6.custom-col-rtl {
      right: 25%;
      left: auto;
    }
    .custom-col-md-pull-6.custom-col-rtl {
      right: auto;
      left: 25%;
    }
    .custom-col-md-offset-6.custom-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .custom-col-md-push-7.custom-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .custom-col-md-pull-7.custom-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .custom-col-md-offset-7.custom-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .custom-col-md-push-8.custom-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .custom-col-md-pull-8.custom-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .custom-col-md-offset-8.custom-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .custom-col-md-push-9.custom-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .custom-col-md-pull-9.custom-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .custom-col-md-offset-9.custom-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .custom-col-md-push-10.custom-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .custom-col-md-pull-10.custom-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .custom-col-md-offset-10.custom-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .custom-col-md-push-11.custom-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .custom-col-md-pull-11.custom-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .custom-col-md-offset-11.custom-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .custom-col-md-push-12.custom-col-rtl {
      right: 50%;
      left: auto;
    }
    .custom-col-md-pull-12.custom-col-rtl {
      right: auto;
      left: 50%;
    }
    .custom-col-md-offset-12.custom-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .custom-col-md-push-13.custom-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .custom-col-md-pull-13.custom-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .custom-col-md-offset-13.custom-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .custom-col-md-push-14.custom-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .custom-col-md-pull-14.custom-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .custom-col-md-offset-14.custom-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .custom-col-md-push-15.custom-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .custom-col-md-pull-15.custom-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .custom-col-md-offset-15.custom-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .custom-col-md-push-16.custom-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .custom-col-md-pull-16.custom-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .custom-col-md-offset-16.custom-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .custom-col-md-push-17.custom-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .custom-col-md-pull-17.custom-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .custom-col-md-offset-17.custom-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .custom-col-md-push-18.custom-col-rtl {
      right: 75%;
      left: auto;
    }
    .custom-col-md-pull-18.custom-col-rtl {
      right: auto;
      left: 75%;
    }
    .custom-col-md-offset-18.custom-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .custom-col-md-push-19.custom-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .custom-col-md-pull-19.custom-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .custom-col-md-offset-19.custom-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .custom-col-md-push-20.custom-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .custom-col-md-pull-20.custom-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .custom-col-md-offset-20.custom-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .custom-col-md-push-21.custom-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .custom-col-md-pull-21.custom-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .custom-col-md-offset-21.custom-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .custom-col-md-push-22.custom-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .custom-col-md-pull-22.custom-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .custom-col-md-offset-22.custom-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .custom-col-md-push-23.custom-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .custom-col-md-pull-23.custom-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .custom-col-md-offset-23.custom-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .custom-col-md-push-24.custom-col-rtl {
      right: 100%;
      left: auto;
    }
    .custom-col-md-pull-24.custom-col-rtl {
      right: auto;
      left: 100%;
    }
    .custom-col-md-offset-24.custom-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .custom-col-lg-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .custom-col-lg-push-24 {
      left: 100%;
    }
    .custom-col-lg-pull-24 {
      right: 100%;
    }
    .custom-col-lg-offset-24 {
      margin-left: 100%;
    }
    .custom-col-lg-order-24 {
      order: 24;
    }
    .custom-col-lg-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .custom-col-lg-push-23 {
      left: 95.83333333%;
    }
    .custom-col-lg-pull-23 {
      right: 95.83333333%;
    }
    .custom-col-lg-offset-23 {
      margin-left: 95.83333333%;
    }
    .custom-col-lg-order-23 {
      order: 23;
    }
    .custom-col-lg-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .custom-col-lg-push-22 {
      left: 91.66666667%;
    }
    .custom-col-lg-pull-22 {
      right: 91.66666667%;
    }
    .custom-col-lg-offset-22 {
      margin-left: 91.66666667%;
    }
    .custom-col-lg-order-22 {
      order: 22;
    }
    .custom-col-lg-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .custom-col-lg-push-21 {
      left: 87.5%;
    }
    .custom-col-lg-pull-21 {
      right: 87.5%;
    }
    .custom-col-lg-offset-21 {
      margin-left: 87.5%;
    }
    .custom-col-lg-order-21 {
      order: 21;
    }
    .custom-col-lg-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .custom-col-lg-push-20 {
      left: 83.33333333%;
    }
    .custom-col-lg-pull-20 {
      right: 83.33333333%;
    }
    .custom-col-lg-offset-20 {
      margin-left: 83.33333333%;
    }
    .custom-col-lg-order-20 {
      order: 20;
    }
    .custom-col-lg-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .custom-col-lg-push-19 {
      left: 79.16666667%;
    }
    .custom-col-lg-pull-19 {
      right: 79.16666667%;
    }
    .custom-col-lg-offset-19 {
      margin-left: 79.16666667%;
    }
    .custom-col-lg-order-19 {
      order: 19;
    }
    .custom-col-lg-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .custom-col-lg-push-18 {
      left: 75%;
    }
    .custom-col-lg-pull-18 {
      right: 75%;
    }
    .custom-col-lg-offset-18 {
      margin-left: 75%;
    }
    .custom-col-lg-order-18 {
      order: 18;
    }
    .custom-col-lg-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .custom-col-lg-push-17 {
      left: 70.83333333%;
    }
    .custom-col-lg-pull-17 {
      right: 70.83333333%;
    }
    .custom-col-lg-offset-17 {
      margin-left: 70.83333333%;
    }
    .custom-col-lg-order-17 {
      order: 17;
    }
    .custom-col-lg-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .custom-col-lg-push-16 {
      left: 66.66666667%;
    }
    .custom-col-lg-pull-16 {
      right: 66.66666667%;
    }
    .custom-col-lg-offset-16 {
      margin-left: 66.66666667%;
    }
    .custom-col-lg-order-16 {
      order: 16;
    }
    .custom-col-lg-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .custom-col-lg-push-15 {
      left: 62.5%;
    }
    .custom-col-lg-pull-15 {
      right: 62.5%;
    }
    .custom-col-lg-offset-15 {
      margin-left: 62.5%;
    }
    .custom-col-lg-order-15 {
      order: 15;
    }
    .custom-col-lg-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .custom-col-lg-push-14 {
      left: 58.33333333%;
    }
    .custom-col-lg-pull-14 {
      right: 58.33333333%;
    }
    .custom-col-lg-offset-14 {
      margin-left: 58.33333333%;
    }
    .custom-col-lg-order-14 {
      order: 14;
    }
    .custom-col-lg-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .custom-col-lg-push-13 {
      left: 54.16666667%;
    }
    .custom-col-lg-pull-13 {
      right: 54.16666667%;
    }
    .custom-col-lg-offset-13 {
      margin-left: 54.16666667%;
    }
    .custom-col-lg-order-13 {
      order: 13;
    }
    .custom-col-lg-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .custom-col-lg-push-12 {
      left: 50%;
    }
    .custom-col-lg-pull-12 {
      right: 50%;
    }
    .custom-col-lg-offset-12 {
      margin-left: 50%;
    }
    .custom-col-lg-order-12 {
      order: 12;
    }
    .custom-col-lg-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .custom-col-lg-push-11 {
      left: 45.83333333%;
    }
    .custom-col-lg-pull-11 {
      right: 45.83333333%;
    }
    .custom-col-lg-offset-11 {
      margin-left: 45.83333333%;
    }
    .custom-col-lg-order-11 {
      order: 11;
    }
    .custom-col-lg-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .custom-col-lg-push-10 {
      left: 41.66666667%;
    }
    .custom-col-lg-pull-10 {
      right: 41.66666667%;
    }
    .custom-col-lg-offset-10 {
      margin-left: 41.66666667%;
    }
    .custom-col-lg-order-10 {
      order: 10;
    }
    .custom-col-lg-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .custom-col-lg-push-9 {
      left: 37.5%;
    }
    .custom-col-lg-pull-9 {
      right: 37.5%;
    }
    .custom-col-lg-offset-9 {
      margin-left: 37.5%;
    }
    .custom-col-lg-order-9 {
      order: 9;
    }
    .custom-col-lg-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .custom-col-lg-push-8 {
      left: 33.33333333%;
    }
    .custom-col-lg-pull-8 {
      right: 33.33333333%;
    }
    .custom-col-lg-offset-8 {
      margin-left: 33.33333333%;
    }
    .custom-col-lg-order-8 {
      order: 8;
    }
    .custom-col-lg-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .custom-col-lg-push-7 {
      left: 29.16666667%;
    }
    .custom-col-lg-pull-7 {
      right: 29.16666667%;
    }
    .custom-col-lg-offset-7 {
      margin-left: 29.16666667%;
    }
    .custom-col-lg-order-7 {
      order: 7;
    }
    .custom-col-lg-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .custom-col-lg-push-6 {
      left: 25%;
    }
    .custom-col-lg-pull-6 {
      right: 25%;
    }
    .custom-col-lg-offset-6 {
      margin-left: 25%;
    }
    .custom-col-lg-order-6 {
      order: 6;
    }
    .custom-col-lg-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .custom-col-lg-push-5 {
      left: 20.83333333%;
    }
    .custom-col-lg-pull-5 {
      right: 20.83333333%;
    }
    .custom-col-lg-offset-5 {
      margin-left: 20.83333333%;
    }
    .custom-col-lg-order-5 {
      order: 5;
    }
    .custom-col-lg-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .custom-col-lg-push-4 {
      left: 16.66666667%;
    }
    .custom-col-lg-pull-4 {
      right: 16.66666667%;
    }
    .custom-col-lg-offset-4 {
      margin-left: 16.66666667%;
    }
    .custom-col-lg-order-4 {
      order: 4;
    }
    .custom-col-lg-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .custom-col-lg-push-3 {
      left: 12.5%;
    }
    .custom-col-lg-pull-3 {
      right: 12.5%;
    }
    .custom-col-lg-offset-3 {
      margin-left: 12.5%;
    }
    .custom-col-lg-order-3 {
      order: 3;
    }
    .custom-col-lg-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .custom-col-lg-push-2 {
      left: 8.33333333%;
    }
    .custom-col-lg-pull-2 {
      right: 8.33333333%;
    }
    .custom-col-lg-offset-2 {
      margin-left: 8.33333333%;
    }
    .custom-col-lg-order-2 {
      order: 2;
    }
    .custom-col-lg-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .custom-col-lg-push-1 {
      left: 4.16666667%;
    }
    .custom-col-lg-pull-1 {
      right: 4.16666667%;
    }
    .custom-col-lg-offset-1 {
      margin-left: 4.16666667%;
    }
    .custom-col-lg-order-1 {
      order: 1;
    }
    .custom-col-lg-0 {
      display: none;
    }
    .custom-col-push-0 {
      left: auto;
    }
    .custom-col-pull-0 {
      right: auto;
    }
    .custom-col-lg-push-0 {
      left: auto;
    }
    .custom-col-lg-pull-0 {
      right: auto;
    }
    .custom-col-lg-offset-0 {
      margin-left: 0;
    }
    .custom-col-lg-order-0 {
      order: 0;
    }
    .custom-col-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-lg-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-lg-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-lg-offset-0.custom-col-rtl {
      margin-right: 0;
    }
    .custom-col-lg-push-1.custom-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .custom-col-lg-pull-1.custom-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .custom-col-lg-offset-1.custom-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-2.custom-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .custom-col-lg-pull-2.custom-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .custom-col-lg-offset-2.custom-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-3.custom-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .custom-col-lg-pull-3.custom-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .custom-col-lg-offset-3.custom-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .custom-col-lg-push-4.custom-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .custom-col-lg-pull-4.custom-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .custom-col-lg-offset-4.custom-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-5.custom-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .custom-col-lg-pull-5.custom-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .custom-col-lg-offset-5.custom-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-6.custom-col-rtl {
      right: 25%;
      left: auto;
    }
    .custom-col-lg-pull-6.custom-col-rtl {
      right: auto;
      left: 25%;
    }
    .custom-col-lg-offset-6.custom-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .custom-col-lg-push-7.custom-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .custom-col-lg-pull-7.custom-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .custom-col-lg-offset-7.custom-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-8.custom-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .custom-col-lg-pull-8.custom-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .custom-col-lg-offset-8.custom-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-9.custom-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .custom-col-lg-pull-9.custom-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .custom-col-lg-offset-9.custom-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .custom-col-lg-push-10.custom-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .custom-col-lg-pull-10.custom-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .custom-col-lg-offset-10.custom-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-11.custom-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .custom-col-lg-pull-11.custom-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .custom-col-lg-offset-11.custom-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-12.custom-col-rtl {
      right: 50%;
      left: auto;
    }
    .custom-col-lg-pull-12.custom-col-rtl {
      right: auto;
      left: 50%;
    }
    .custom-col-lg-offset-12.custom-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .custom-col-lg-push-13.custom-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .custom-col-lg-pull-13.custom-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .custom-col-lg-offset-13.custom-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-14.custom-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .custom-col-lg-pull-14.custom-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .custom-col-lg-offset-14.custom-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-15.custom-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .custom-col-lg-pull-15.custom-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .custom-col-lg-offset-15.custom-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .custom-col-lg-push-16.custom-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .custom-col-lg-pull-16.custom-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .custom-col-lg-offset-16.custom-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-17.custom-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .custom-col-lg-pull-17.custom-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .custom-col-lg-offset-17.custom-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-18.custom-col-rtl {
      right: 75%;
      left: auto;
    }
    .custom-col-lg-pull-18.custom-col-rtl {
      right: auto;
      left: 75%;
    }
    .custom-col-lg-offset-18.custom-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .custom-col-lg-push-19.custom-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .custom-col-lg-pull-19.custom-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .custom-col-lg-offset-19.custom-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-20.custom-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .custom-col-lg-pull-20.custom-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .custom-col-lg-offset-20.custom-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-21.custom-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .custom-col-lg-pull-21.custom-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .custom-col-lg-offset-21.custom-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .custom-col-lg-push-22.custom-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .custom-col-lg-pull-22.custom-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .custom-col-lg-offset-22.custom-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .custom-col-lg-push-23.custom-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .custom-col-lg-pull-23.custom-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .custom-col-lg-offset-23.custom-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .custom-col-lg-push-24.custom-col-rtl {
      right: 100%;
      left: auto;
    }
    .custom-col-lg-pull-24.custom-col-rtl {
      right: auto;
      left: 100%;
    }
    .custom-col-lg-offset-24.custom-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .custom-col-xl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .custom-col-xl-push-24 {
      left: 100%;
    }
    .custom-col-xl-pull-24 {
      right: 100%;
    }
    .custom-col-xl-offset-24 {
      margin-left: 100%;
    }
    .custom-col-xl-order-24 {
      order: 24;
    }
    .custom-col-xl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .custom-col-xl-push-23 {
      left: 95.83333333%;
    }
    .custom-col-xl-pull-23 {
      right: 95.83333333%;
    }
    .custom-col-xl-offset-23 {
      margin-left: 95.83333333%;
    }
    .custom-col-xl-order-23 {
      order: 23;
    }
    .custom-col-xl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .custom-col-xl-push-22 {
      left: 91.66666667%;
    }
    .custom-col-xl-pull-22 {
      right: 91.66666667%;
    }
    .custom-col-xl-offset-22 {
      margin-left: 91.66666667%;
    }
    .custom-col-xl-order-22 {
      order: 22;
    }
    .custom-col-xl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .custom-col-xl-push-21 {
      left: 87.5%;
    }
    .custom-col-xl-pull-21 {
      right: 87.5%;
    }
    .custom-col-xl-offset-21 {
      margin-left: 87.5%;
    }
    .custom-col-xl-order-21 {
      order: 21;
    }
    .custom-col-xl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .custom-col-xl-push-20 {
      left: 83.33333333%;
    }
    .custom-col-xl-pull-20 {
      right: 83.33333333%;
    }
    .custom-col-xl-offset-20 {
      margin-left: 83.33333333%;
    }
    .custom-col-xl-order-20 {
      order: 20;
    }
    .custom-col-xl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .custom-col-xl-push-19 {
      left: 79.16666667%;
    }
    .custom-col-xl-pull-19 {
      right: 79.16666667%;
    }
    .custom-col-xl-offset-19 {
      margin-left: 79.16666667%;
    }
    .custom-col-xl-order-19 {
      order: 19;
    }
    .custom-col-xl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .custom-col-xl-push-18 {
      left: 75%;
    }
    .custom-col-xl-pull-18 {
      right: 75%;
    }
    .custom-col-xl-offset-18 {
      margin-left: 75%;
    }
    .custom-col-xl-order-18 {
      order: 18;
    }
    .custom-col-xl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .custom-col-xl-push-17 {
      left: 70.83333333%;
    }
    .custom-col-xl-pull-17 {
      right: 70.83333333%;
    }
    .custom-col-xl-offset-17 {
      margin-left: 70.83333333%;
    }
    .custom-col-xl-order-17 {
      order: 17;
    }
    .custom-col-xl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .custom-col-xl-push-16 {
      left: 66.66666667%;
    }
    .custom-col-xl-pull-16 {
      right: 66.66666667%;
    }
    .custom-col-xl-offset-16 {
      margin-left: 66.66666667%;
    }
    .custom-col-xl-order-16 {
      order: 16;
    }
    .custom-col-xl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .custom-col-xl-push-15 {
      left: 62.5%;
    }
    .custom-col-xl-pull-15 {
      right: 62.5%;
    }
    .custom-col-xl-offset-15 {
      margin-left: 62.5%;
    }
    .custom-col-xl-order-15 {
      order: 15;
    }
    .custom-col-xl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .custom-col-xl-push-14 {
      left: 58.33333333%;
    }
    .custom-col-xl-pull-14 {
      right: 58.33333333%;
    }
    .custom-col-xl-offset-14 {
      margin-left: 58.33333333%;
    }
    .custom-col-xl-order-14 {
      order: 14;
    }
    .custom-col-xl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .custom-col-xl-push-13 {
      left: 54.16666667%;
    }
    .custom-col-xl-pull-13 {
      right: 54.16666667%;
    }
    .custom-col-xl-offset-13 {
      margin-left: 54.16666667%;
    }
    .custom-col-xl-order-13 {
      order: 13;
    }
    .custom-col-xl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .custom-col-xl-push-12 {
      left: 50%;
    }
    .custom-col-xl-pull-12 {
      right: 50%;
    }
    .custom-col-xl-offset-12 {
      margin-left: 50%;
    }
    .custom-col-xl-order-12 {
      order: 12;
    }
    .custom-col-xl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .custom-col-xl-push-11 {
      left: 45.83333333%;
    }
    .custom-col-xl-pull-11 {
      right: 45.83333333%;
    }
    .custom-col-xl-offset-11 {
      margin-left: 45.83333333%;
    }
    .custom-col-xl-order-11 {
      order: 11;
    }
    .custom-col-xl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .custom-col-xl-push-10 {
      left: 41.66666667%;
    }
    .custom-col-xl-pull-10 {
      right: 41.66666667%;
    }
    .custom-col-xl-offset-10 {
      margin-left: 41.66666667%;
    }
    .custom-col-xl-order-10 {
      order: 10;
    }
    .custom-col-xl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .custom-col-xl-push-9 {
      left: 37.5%;
    }
    .custom-col-xl-pull-9 {
      right: 37.5%;
    }
    .custom-col-xl-offset-9 {
      margin-left: 37.5%;
    }
    .custom-col-xl-order-9 {
      order: 9;
    }
    .custom-col-xl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .custom-col-xl-push-8 {
      left: 33.33333333%;
    }
    .custom-col-xl-pull-8 {
      right: 33.33333333%;
    }
    .custom-col-xl-offset-8 {
      margin-left: 33.33333333%;
    }
    .custom-col-xl-order-8 {
      order: 8;
    }
    .custom-col-xl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .custom-col-xl-push-7 {
      left: 29.16666667%;
    }
    .custom-col-xl-pull-7 {
      right: 29.16666667%;
    }
    .custom-col-xl-offset-7 {
      margin-left: 29.16666667%;
    }
    .custom-col-xl-order-7 {
      order: 7;
    }
    .custom-col-xl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .custom-col-xl-push-6 {
      left: 25%;
    }
    .custom-col-xl-pull-6 {
      right: 25%;
    }
    .custom-col-xl-offset-6 {
      margin-left: 25%;
    }
    .custom-col-xl-order-6 {
      order: 6;
    }
    .custom-col-xl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .custom-col-xl-push-5 {
      left: 20.83333333%;
    }
    .custom-col-xl-pull-5 {
      right: 20.83333333%;
    }
    .custom-col-xl-offset-5 {
      margin-left: 20.83333333%;
    }
    .custom-col-xl-order-5 {
      order: 5;
    }
    .custom-col-xl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .custom-col-xl-push-4 {
      left: 16.66666667%;
    }
    .custom-col-xl-pull-4 {
      right: 16.66666667%;
    }
    .custom-col-xl-offset-4 {
      margin-left: 16.66666667%;
    }
    .custom-col-xl-order-4 {
      order: 4;
    }
    .custom-col-xl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .custom-col-xl-push-3 {
      left: 12.5%;
    }
    .custom-col-xl-pull-3 {
      right: 12.5%;
    }
    .custom-col-xl-offset-3 {
      margin-left: 12.5%;
    }
    .custom-col-xl-order-3 {
      order: 3;
    }
    .custom-col-xl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .custom-col-xl-push-2 {
      left: 8.33333333%;
    }
    .custom-col-xl-pull-2 {
      right: 8.33333333%;
    }
    .custom-col-xl-offset-2 {
      margin-left: 8.33333333%;
    }
    .custom-col-xl-order-2 {
      order: 2;
    }
    .custom-col-xl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .custom-col-xl-push-1 {
      left: 4.16666667%;
    }
    .custom-col-xl-pull-1 {
      right: 4.16666667%;
    }
    .custom-col-xl-offset-1 {
      margin-left: 4.16666667%;
    }
    .custom-col-xl-order-1 {
      order: 1;
    }
    .custom-col-xl-0 {
      display: none;
    }
    .custom-col-push-0 {
      left: auto;
    }
    .custom-col-pull-0 {
      right: auto;
    }
    .custom-col-xl-push-0 {
      left: auto;
    }
    .custom-col-xl-pull-0 {
      right: auto;
    }
    .custom-col-xl-offset-0 {
      margin-left: 0;
    }
    .custom-col-xl-order-0 {
      order: 0;
    }
    .custom-col-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-xl-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-xl-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-xl-offset-0.custom-col-rtl {
      margin-right: 0;
    }
    .custom-col-xl-push-1.custom-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .custom-col-xl-pull-1.custom-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .custom-col-xl-offset-1.custom-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-2.custom-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .custom-col-xl-pull-2.custom-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .custom-col-xl-offset-2.custom-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-3.custom-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .custom-col-xl-pull-3.custom-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .custom-col-xl-offset-3.custom-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .custom-col-xl-push-4.custom-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .custom-col-xl-pull-4.custom-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .custom-col-xl-offset-4.custom-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-5.custom-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .custom-col-xl-pull-5.custom-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .custom-col-xl-offset-5.custom-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-6.custom-col-rtl {
      right: 25%;
      left: auto;
    }
    .custom-col-xl-pull-6.custom-col-rtl {
      right: auto;
      left: 25%;
    }
    .custom-col-xl-offset-6.custom-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .custom-col-xl-push-7.custom-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .custom-col-xl-pull-7.custom-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .custom-col-xl-offset-7.custom-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-8.custom-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .custom-col-xl-pull-8.custom-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .custom-col-xl-offset-8.custom-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-9.custom-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .custom-col-xl-pull-9.custom-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .custom-col-xl-offset-9.custom-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .custom-col-xl-push-10.custom-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .custom-col-xl-pull-10.custom-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .custom-col-xl-offset-10.custom-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-11.custom-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .custom-col-xl-pull-11.custom-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .custom-col-xl-offset-11.custom-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-12.custom-col-rtl {
      right: 50%;
      left: auto;
    }
    .custom-col-xl-pull-12.custom-col-rtl {
      right: auto;
      left: 50%;
    }
    .custom-col-xl-offset-12.custom-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .custom-col-xl-push-13.custom-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .custom-col-xl-pull-13.custom-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .custom-col-xl-offset-13.custom-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-14.custom-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .custom-col-xl-pull-14.custom-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .custom-col-xl-offset-14.custom-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-15.custom-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .custom-col-xl-pull-15.custom-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .custom-col-xl-offset-15.custom-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .custom-col-xl-push-16.custom-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .custom-col-xl-pull-16.custom-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .custom-col-xl-offset-16.custom-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-17.custom-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .custom-col-xl-pull-17.custom-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .custom-col-xl-offset-17.custom-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-18.custom-col-rtl {
      right: 75%;
      left: auto;
    }
    .custom-col-xl-pull-18.custom-col-rtl {
      right: auto;
      left: 75%;
    }
    .custom-col-xl-offset-18.custom-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .custom-col-xl-push-19.custom-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .custom-col-xl-pull-19.custom-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .custom-col-xl-offset-19.custom-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-20.custom-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .custom-col-xl-pull-20.custom-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .custom-col-xl-offset-20.custom-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-21.custom-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .custom-col-xl-pull-21.custom-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .custom-col-xl-offset-21.custom-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .custom-col-xl-push-22.custom-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .custom-col-xl-pull-22.custom-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .custom-col-xl-offset-22.custom-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .custom-col-xl-push-23.custom-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .custom-col-xl-pull-23.custom-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .custom-col-xl-offset-23.custom-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .custom-col-xl-push-24.custom-col-rtl {
      right: 100%;
      left: auto;
    }
    .custom-col-xl-pull-24.custom-col-rtl {
      right: auto;
      left: 100%;
    }
    .custom-col-xl-offset-24.custom-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 1600px) {
    .custom-col-xxl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .custom-col-xxl-push-24 {
      left: 100%;
    }
    .custom-col-xxl-pull-24 {
      right: 100%;
    }
    .custom-col-xxl-offset-24 {
      margin-left: 100%;
    }
    .custom-col-xxl-order-24 {
      order: 24;
    }
    .custom-col-xxl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .custom-col-xxl-push-23 {
      left: 95.83333333%;
    }
    .custom-col-xxl-pull-23 {
      right: 95.83333333%;
    }
    .custom-col-xxl-offset-23 {
      margin-left: 95.83333333%;
    }
    .custom-col-xxl-order-23 {
      order: 23;
    }
    .custom-col-xxl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .custom-col-xxl-push-22 {
      left: 91.66666667%;
    }
    .custom-col-xxl-pull-22 {
      right: 91.66666667%;
    }
    .custom-col-xxl-offset-22 {
      margin-left: 91.66666667%;
    }
    .custom-col-xxl-order-22 {
      order: 22;
    }
    .custom-col-xxl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .custom-col-xxl-push-21 {
      left: 87.5%;
    }
    .custom-col-xxl-pull-21 {
      right: 87.5%;
    }
    .custom-col-xxl-offset-21 {
      margin-left: 87.5%;
    }
    .custom-col-xxl-order-21 {
      order: 21;
    }
    .custom-col-xxl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .custom-col-xxl-push-20 {
      left: 83.33333333%;
    }
    .custom-col-xxl-pull-20 {
      right: 83.33333333%;
    }
    .custom-col-xxl-offset-20 {
      margin-left: 83.33333333%;
    }
    .custom-col-xxl-order-20 {
      order: 20;
    }
    .custom-col-xxl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .custom-col-xxl-push-19 {
      left: 79.16666667%;
    }
    .custom-col-xxl-pull-19 {
      right: 79.16666667%;
    }
    .custom-col-xxl-offset-19 {
      margin-left: 79.16666667%;
    }
    .custom-col-xxl-order-19 {
      order: 19;
    }
    .custom-col-xxl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .custom-col-xxl-push-18 {
      left: 75%;
    }
    .custom-col-xxl-pull-18 {
      right: 75%;
    }
    .custom-col-xxl-offset-18 {
      margin-left: 75%;
    }
    .custom-col-xxl-order-18 {
      order: 18;
    }
    .custom-col-xxl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .custom-col-xxl-push-17 {
      left: 70.83333333%;
    }
    .custom-col-xxl-pull-17 {
      right: 70.83333333%;
    }
    .custom-col-xxl-offset-17 {
      margin-left: 70.83333333%;
    }
    .custom-col-xxl-order-17 {
      order: 17;
    }
    .custom-col-xxl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .custom-col-xxl-push-16 {
      left: 66.66666667%;
    }
    .custom-col-xxl-pull-16 {
      right: 66.66666667%;
    }
    .custom-col-xxl-offset-16 {
      margin-left: 66.66666667%;
    }
    .custom-col-xxl-order-16 {
      order: 16;
    }
    .custom-col-xxl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .custom-col-xxl-push-15 {
      left: 62.5%;
    }
    .custom-col-xxl-pull-15 {
      right: 62.5%;
    }
    .custom-col-xxl-offset-15 {
      margin-left: 62.5%;
    }
    .custom-col-xxl-order-15 {
      order: 15;
    }
    .custom-col-xxl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .custom-col-xxl-push-14 {
      left: 58.33333333%;
    }
    .custom-col-xxl-pull-14 {
      right: 58.33333333%;
    }
    .custom-col-xxl-offset-14 {
      margin-left: 58.33333333%;
    }
    .custom-col-xxl-order-14 {
      order: 14;
    }
    .custom-col-xxl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .custom-col-xxl-push-13 {
      left: 54.16666667%;
    }
    .custom-col-xxl-pull-13 {
      right: 54.16666667%;
    }
    .custom-col-xxl-offset-13 {
      margin-left: 54.16666667%;
    }
    .custom-col-xxl-order-13 {
      order: 13;
    }
    .custom-col-xxl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .custom-col-xxl-push-12 {
      left: 50%;
    }
    .custom-col-xxl-pull-12 {
      right: 50%;
    }
    .custom-col-xxl-offset-12 {
      margin-left: 50%;
    }
    .custom-col-xxl-order-12 {
      order: 12;
    }
    .custom-col-xxl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .custom-col-xxl-push-11 {
      left: 45.83333333%;
    }
    .custom-col-xxl-pull-11 {
      right: 45.83333333%;
    }
    .custom-col-xxl-offset-11 {
      margin-left: 45.83333333%;
    }
    .custom-col-xxl-order-11 {
      order: 11;
    }
    .custom-col-xxl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .custom-col-xxl-push-10 {
      left: 41.66666667%;
    }
    .custom-col-xxl-pull-10 {
      right: 41.66666667%;
    }
    .custom-col-xxl-offset-10 {
      margin-left: 41.66666667%;
    }
    .custom-col-xxl-order-10 {
      order: 10;
    }
    .custom-col-xxl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .custom-col-xxl-push-9 {
      left: 37.5%;
    }
    .custom-col-xxl-pull-9 {
      right: 37.5%;
    }
    .custom-col-xxl-offset-9 {
      margin-left: 37.5%;
    }
    .custom-col-xxl-order-9 {
      order: 9;
    }
    .custom-col-xxl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .custom-col-xxl-push-8 {
      left: 33.33333333%;
    }
    .custom-col-xxl-pull-8 {
      right: 33.33333333%;
    }
    .custom-col-xxl-offset-8 {
      margin-left: 33.33333333%;
    }
    .custom-col-xxl-order-8 {
      order: 8;
    }
    .custom-col-xxl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .custom-col-xxl-push-7 {
      left: 29.16666667%;
    }
    .custom-col-xxl-pull-7 {
      right: 29.16666667%;
    }
    .custom-col-xxl-offset-7 {
      margin-left: 29.16666667%;
    }
    .custom-col-xxl-order-7 {
      order: 7;
    }
    .custom-col-xxl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .custom-col-xxl-push-6 {
      left: 25%;
    }
    .custom-col-xxl-pull-6 {
      right: 25%;
    }
    .custom-col-xxl-offset-6 {
      margin-left: 25%;
    }
    .custom-col-xxl-order-6 {
      order: 6;
    }
    .custom-col-xxl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .custom-col-xxl-push-5 {
      left: 20.83333333%;
    }
    .custom-col-xxl-pull-5 {
      right: 20.83333333%;
    }
    .custom-col-xxl-offset-5 {
      margin-left: 20.83333333%;
    }
    .custom-col-xxl-order-5 {
      order: 5;
    }
    .custom-col-xxl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .custom-col-xxl-push-4 {
      left: 16.66666667%;
    }
    .custom-col-xxl-pull-4 {
      right: 16.66666667%;
    }
    .custom-col-xxl-offset-4 {
      margin-left: 16.66666667%;
    }
    .custom-col-xxl-order-4 {
      order: 4;
    }
    .custom-col-xxl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .custom-col-xxl-push-3 {
      left: 12.5%;
    }
    .custom-col-xxl-pull-3 {
      right: 12.5%;
    }
    .custom-col-xxl-offset-3 {
      margin-left: 12.5%;
    }
    .custom-col-xxl-order-3 {
      order: 3;
    }
    .custom-col-xxl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .custom-col-xxl-push-2 {
      left: 8.33333333%;
    }
    .custom-col-xxl-pull-2 {
      right: 8.33333333%;
    }
    .custom-col-xxl-offset-2 {
      margin-left: 8.33333333%;
    }
    .custom-col-xxl-order-2 {
      order: 2;
    }
    .custom-col-xxl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .custom-col-xxl-push-1 {
      left: 4.16666667%;
    }
    .custom-col-xxl-pull-1 {
      right: 4.16666667%;
    }
    .custom-col-xxl-offset-1 {
      margin-left: 4.16666667%;
    }
    .custom-col-xxl-order-1 {
      order: 1;
    }
    .custom-col-xxl-0 {
      display: none;
    }
    .custom-col-push-0 {
      left: auto;
    }
    .custom-col-pull-0 {
      right: auto;
    }
    .custom-col-xxl-push-0 {
      left: auto;
    }
    .custom-col-xxl-pull-0 {
      right: auto;
    }
    .custom-col-xxl-offset-0 {
      margin-left: 0;
    }
    .custom-col-xxl-order-0 {
      order: 0;
    }
    .custom-col-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-xxl-push-0.custom-col-rtl {
      right: auto;
    }
    .custom-col-xxl-pull-0.custom-col-rtl {
      left: auto;
    }
    .custom-col-xxl-offset-0.custom-col-rtl {
      margin-right: 0;
    }
    .custom-col-xxl-push-1.custom-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .custom-col-xxl-pull-1.custom-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .custom-col-xxl-offset-1.custom-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-2.custom-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .custom-col-xxl-pull-2.custom-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .custom-col-xxl-offset-2.custom-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-3.custom-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .custom-col-xxl-pull-3.custom-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .custom-col-xxl-offset-3.custom-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .custom-col-xxl-push-4.custom-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .custom-col-xxl-pull-4.custom-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .custom-col-xxl-offset-4.custom-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-5.custom-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .custom-col-xxl-pull-5.custom-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .custom-col-xxl-offset-5.custom-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-6.custom-col-rtl {
      right: 25%;
      left: auto;
    }
    .custom-col-xxl-pull-6.custom-col-rtl {
      right: auto;
      left: 25%;
    }
    .custom-col-xxl-offset-6.custom-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .custom-col-xxl-push-7.custom-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .custom-col-xxl-pull-7.custom-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .custom-col-xxl-offset-7.custom-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-8.custom-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .custom-col-xxl-pull-8.custom-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .custom-col-xxl-offset-8.custom-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-9.custom-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .custom-col-xxl-pull-9.custom-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .custom-col-xxl-offset-9.custom-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .custom-col-xxl-push-10.custom-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .custom-col-xxl-pull-10.custom-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .custom-col-xxl-offset-10.custom-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-11.custom-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .custom-col-xxl-pull-11.custom-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .custom-col-xxl-offset-11.custom-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-12.custom-col-rtl {
      right: 50%;
      left: auto;
    }
    .custom-col-xxl-pull-12.custom-col-rtl {
      right: auto;
      left: 50%;
    }
    .custom-col-xxl-offset-12.custom-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .custom-col-xxl-push-13.custom-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .custom-col-xxl-pull-13.custom-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .custom-col-xxl-offset-13.custom-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-14.custom-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .custom-col-xxl-pull-14.custom-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .custom-col-xxl-offset-14.custom-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-15.custom-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .custom-col-xxl-pull-15.custom-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .custom-col-xxl-offset-15.custom-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .custom-col-xxl-push-16.custom-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .custom-col-xxl-pull-16.custom-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .custom-col-xxl-offset-16.custom-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-17.custom-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .custom-col-xxl-pull-17.custom-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .custom-col-xxl-offset-17.custom-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-18.custom-col-rtl {
      right: 75%;
      left: auto;
    }
    .custom-col-xxl-pull-18.custom-col-rtl {
      right: auto;
      left: 75%;
    }
    .custom-col-xxl-offset-18.custom-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .custom-col-xxl-push-19.custom-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .custom-col-xxl-pull-19.custom-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .custom-col-xxl-offset-19.custom-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-20.custom-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .custom-col-xxl-pull-20.custom-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .custom-col-xxl-offset-20.custom-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-21.custom-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .custom-col-xxl-pull-21.custom-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .custom-col-xxl-offset-21.custom-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .custom-col-xxl-push-22.custom-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .custom-col-xxl-pull-22.custom-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .custom-col-xxl-offset-22.custom-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .custom-col-xxl-push-23.custom-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .custom-col-xxl-pull-23.custom-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .custom-col-xxl-offset-23.custom-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .custom-col-xxl-push-24.custom-col-rtl {
      right: 100%;
      left: auto;
    }
    .custom-col-xxl-pull-24.custom-col-rtl {
      right: auto;
      left: 100%;
    }
    .custom-col-xxl-offset-24.custom-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  .custom-row-rtl {
    direction: rtl;
  }
  .custom-image {
    position: relative;
    display: inline-block;
  }
  .custom-image-img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  .custom-image-img-placeholder {
    background-color: #f5f5f5;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30%;
  }
  .custom-image-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .custom-image-mask-info {
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-image-mask-info .anticon {
    -webkit-margin-end: 4px;
            margin-inline-end: 4px;
  }
  .custom-image-mask:hover {
    opacity: 1;
  }
  .custom-image-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .custom-image-preview {
    pointer-events: none;
    height: 100%;
    text-align: center;
  }
  .custom-image-preview.custom-zoom-enter,
  .custom-image-preview.custom-zoom-appear {
    -webkit-transform: none;
            transform: none;
    opacity: 0;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-image-preview-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .custom-image-preview-mask-hidden {
    display: none;
  }
  .custom-image-preview-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
  }
  .custom-image-preview-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  .custom-image-preview-img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    cursor: grab;
    transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: auto;
  }
  .custom-image-preview-img-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  .custom-image-preview-img-wrapper::before {
    display: inline-block;
    width: 1px;
    height: 50%;
    margin-right: -1px;
    content: '';
  }
  .custom-image-preview-moving .custom-image-preview-img {
    cursor: grabbing;
  }
  .custom-image-preview-moving .custom-image-preview-img-wrapper {
    transition-duration: 0s;
  }
  .custom-image-preview-wrap {
    z-index: 1080;
  }
  .custom-image-preview-operations {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.85);
    list-style: none;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: auto;
  }
  .custom-image-preview-operations-operation {
    margin-left: 12px;
    padding: 12px;
    cursor: pointer;
  }
  .custom-image-preview-operations-operation-disabled {
    color: rgba(255, 255, 255, 0.25);
    pointer-events: none;
  }
  .custom-image-preview-operations-operation:last-of-type {
    margin-left: 0;
  }
  .custom-image-preview-operations-progress {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .custom-image-preview-operations-icon {
    font-size: 18px;
  }
  .custom-image-preview-switch-left,
  .custom-image-preview-switch-right {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-top: -22px;
    color: rgba(255, 255, 255, 0.85);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
  }
  .custom-image-preview-switch-left-disabled,
  .custom-image-preview-switch-right-disabled {
    color: rgba(255, 255, 255, 0.25);
    cursor: not-allowed;
  }
  .custom-image-preview-switch-left-disabled > .anticon,
  .custom-image-preview-switch-right-disabled > .anticon {
    cursor: not-allowed;
  }
  .custom-image-preview-switch-left > .anticon,
  .custom-image-preview-switch-right > .anticon {
    font-size: 18px;
  }
  .custom-image-preview-switch-left {
    left: 10px;
  }
  .custom-image-preview-switch-right {
    right: 10px;
  }
  .custom-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    display: inline-flex;
  }
  .custom-input-affix-wrapper::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-affix-wrapper::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-input-affix-wrapper:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-input-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-affix-wrapper:focus,
  .custom-input-affix-wrapper-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-input-affix-wrapper:focus,
  .custom-input-rtl .custom-input-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-affix-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-affix-wrapper-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-affix-wrapper[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-affix-wrapper[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-affix-wrapper-borderless,
  .custom-input-affix-wrapper-borderless:hover,
  .custom-input-affix-wrapper-borderless:focus,
  .custom-input-affix-wrapper-borderless-focused,
  .custom-input-affix-wrapper-borderless-disabled,
  .custom-input-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.custom-input-affix-wrapper {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .custom-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-input-affix-wrapper-sm {
    padding: 0px 7px;
  }
  .custom-input-affix-wrapper-rtl {
    direction: rtl;
  }
  .custom-input-affix-wrapper:not(.custom-input-affix-wrapper-disabled):hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
    z-index: 1;
  }
  .custom-input-rtl .custom-input-affix-wrapper:not(.custom-input-affix-wrapper-disabled):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-search-with-button .custom-input-affix-wrapper:not(.custom-input-affix-wrapper-disabled):hover {
    z-index: 0;
  }
  .custom-input-affix-wrapper-focused,
  .custom-input-affix-wrapper:focus {
    z-index: 1;
  }
  .custom-input-affix-wrapper-disabled .custom-input[disabled] {
    background: transparent;
  }
  .custom-input-affix-wrapper > input.custom-input {
    padding: 0;
    border: none;
    outline: none;
  }
  .custom-input-affix-wrapper > input.custom-input:focus {
    box-shadow: none !important;
  }
  .custom-input-affix-wrapper::before {
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .custom-input-prefix,
  .custom-input-suffix {
    display: flex;
    flex: none;
    align-items: center;
  }
  .custom-input-prefix > *:not(:last-child),
  .custom-input-suffix > *:not(:last-child) {
    margin-right: 8px;
  }
  .custom-input-show-count-suffix {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-input-show-count-has-suffix {
    margin-right: 2px;
  }
  .custom-input-prefix {
    margin-right: 4px;
  }
  .custom-input-suffix {
    margin-left: 4px;
  }
  .anticon.custom-input-clear-icon,
  .custom-input-clear-icon {
    margin: 0;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    vertical-align: -1px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .anticon.custom-input-clear-icon:hover,
  .custom-input-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .anticon.custom-input-clear-icon:active,
  .custom-input-clear-icon:active {
    color: rgba(0, 0, 0, 0.85);
  }
  .anticon.custom-input-clear-icon-hidden,
  .custom-input-clear-icon-hidden {
    visibility: hidden;
  }
  .anticon.custom-input-clear-icon-has-suffix,
  .custom-input-clear-icon-has-suffix {
    margin: 0 4px;
  }
  .custom-input-affix-wrapper-textarea-with-clear-btn {
    padding: 0 !important;
    border: 0 !important;
  }
  .custom-input-affix-wrapper-textarea-with-clear-btn .custom-input-clear-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
  }
  .custom-input-status-error:not(.custom-input-disabled):not(.custom-input-borderless).custom-input,
  .custom-input-status-error:not(.custom-input-disabled):not(.custom-input-borderless).custom-input:hover {
    background: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-input-status-error:not(.custom-input-disabled):not(.custom-input-borderless).custom-input:focus,
  .custom-input-status-error:not(.custom-input-disabled):not(.custom-input-borderless).custom-input-focused {
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px var(--custom-error-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-status-error .custom-input-prefix {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-input-status-warning:not(.custom-input-disabled):not(.custom-input-borderless).custom-input,
  .custom-input-status-warning:not(.custom-input-disabled):not(.custom-input-borderless).custom-input:hover {
    background: #fff;
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-input-status-warning:not(.custom-input-disabled):not(.custom-input-borderless).custom-input:focus,
  .custom-input-status-warning:not(.custom-input-disabled):not(.custom-input-borderless).custom-input-focused {
    border-color: #ffc53d;
    border-color: var(--custom-warning-color-hover);
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow: 0 0 0 2px var(--custom-warning-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-status-warning .custom-input-prefix {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-input-affix-wrapper-status-error:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper,
  .custom-input-affix-wrapper-status-error:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper:hover {
    background: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-input-affix-wrapper-status-error:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper:focus,
  .custom-input-affix-wrapper-status-error:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper-focused {
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px var(--custom-error-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-affix-wrapper-status-error .custom-input-prefix {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-input-affix-wrapper-status-warning:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper,
  .custom-input-affix-wrapper-status-warning:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper:hover {
    background: #fff;
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-input-affix-wrapper-status-warning:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper:focus,
  .custom-input-affix-wrapper-status-warning:not(.custom-input-affix-wrapper-disabled):not(.custom-input-affix-wrapper-borderless).custom-input-affix-wrapper-focused {
    border-color: #ffc53d;
    border-color: var(--custom-warning-color-hover);
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow: 0 0 0 2px var(--custom-warning-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-affix-wrapper-status-warning .custom-input-prefix {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-input-textarea-status-error.custom-input-textarea-has-feedback .custom-input,
  .custom-input-textarea-status-warning.custom-input-textarea-has-feedback .custom-input,
  .custom-input-textarea-status-success.custom-input-textarea-has-feedback .custom-input,
  .custom-input-textarea-status-validating.custom-input-textarea-has-feedback .custom-input {
    padding-right: 24px;
  }
  .custom-input-group-wrapper-status-error .custom-input-group-addon {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-input-group-wrapper-status-warning .custom-input-group-addon {
    color: #faad14;
    color: var(--custom-warning-color);
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }
  .custom-input::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-input:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input:focus,
  .custom-input-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-input:focus,
  .custom-input-rtl .custom-input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-borderless,
  .custom-input-borderless:hover,
  .custom-input-borderless:focus,
  .custom-input-borderless-focused,
  .custom-input-borderless-disabled,
  .custom-input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.custom-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .custom-input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-input-sm {
    padding: 0px 7px;
  }
  .custom-input-rtl {
    direction: rtl;
  }
  .custom-input-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .custom-input-group[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .custom-input-group > [class*='col-'] {
    padding-right: 8px;
  }
  .custom-input-group > [class*='col-']:last-child {
    padding-right: 0;
  }
  .custom-input-group-addon,
  .custom-input-group-wrap,
  .custom-input-group > .custom-input {
    display: table-cell;
  }
  .custom-input-group-addon:not(:first-child):not(:last-child),
  .custom-input-group-wrap:not(:first-child):not(:last-child),
  .custom-input-group > .custom-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .custom-input-group-addon,
  .custom-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .custom-input-group-wrap > * {
    display: block !important;
  }
  .custom-input-group .custom-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  .custom-input-group .custom-input:focus {
    z-index: 1;
    border-right-width: 1px;
  }
  .custom-input-group .custom-input:hover {
    z-index: 1;
    border-right-width: 1px;
  }
  .custom-input-search-with-button .custom-input-group .custom-input:hover {
    z-index: 0;
  }
  .custom-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }
  .custom-input-group-addon .custom-select {
    margin: -5px -11px;
  }
  .custom-input-group-addon .custom-select.custom-select-single:not(.custom-select-customize-input) .custom-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
    width: 150px;
  }
  .custom-input-group-addon .custom-select-open .custom-select-selector,
  .custom-input-group-addon .custom-select-focused .custom-select-selector {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-input-group-addon .custom-cascader-picker {
    margin: -9px -12px;
    background-color: transparent;
  }
  .custom-input-group-addon .custom-cascader-picker .custom-cascader-input {
    text-align: left;
    border: 0;
    box-shadow: none;
  }
  .custom-input-group > .custom-input:first-child,
  .custom-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-group > .custom-input:first-child .custom-select .custom-select-selector,
  .custom-input-group-addon:first-child .custom-select .custom-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-group > .custom-input-affix-wrapper:not(:first-child) .custom-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-group > .custom-input-affix-wrapper:not(:last-child) .custom-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-group-addon:first-child {
    border-right: 0;
  }
  .custom-input-group-addon:last-child {
    border-left: 0;
  }
  .custom-input-group > .custom-input:last-child,
  .custom-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-group > .custom-input:last-child .custom-select .custom-select-selector,
  .custom-input-group-addon:last-child .custom-select .custom-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-group-lg .custom-input,
  .custom-input-group-lg > .custom-input-group-addon {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-input-group-sm .custom-input,
  .custom-input-group-sm > .custom-input-group-addon {
    padding: 0px 7px;
  }
  .custom-input-group-lg .custom-select-single .custom-select-selector {
    height: 40px;
  }
  .custom-input-group-sm .custom-select-single .custom-select-selector {
    height: 24px;
  }
  .custom-input-group .custom-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-search .custom-input-group .custom-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .custom-input-group .custom-input-affix-wrapper:not(:first-child),
  .custom-input-search .custom-input-group .custom-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-group.custom-input-group-compact {
    display: block;
  }
  .custom-input-group.custom-input-group-compact::before {
    display: table;
    content: '';
  }
  .custom-input-group.custom-input-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-input-group.custom-input-group-compact::before {
    display: table;
    content: '';
  }
  .custom-input-group.custom-input-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-input-group.custom-input-group-compact-addon:not(:first-child):not(:last-child),
  .custom-input-group.custom-input-group-compact-wrap:not(:first-child):not(:last-child),
  .custom-input-group.custom-input-group-compact > .custom-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .custom-input-group.custom-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .custom-input-group.custom-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .custom-input-group.custom-input-group-compact > .custom-input:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .custom-input-group.custom-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .custom-input-group.custom-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .custom-input-group.custom-input-group-compact > .custom-input:not(:first-child):not(:last-child):focus {
    z-index: 1;
  }
  .custom-input-group.custom-input-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;
  }
  .custom-input-group.custom-input-group-compact > .custom-input-affix-wrapper {
    display: inline-flex;
  }
  .custom-input-group.custom-input-group-compact > .custom-picker-range {
    display: inline-flex;
  }
  .custom-input-group.custom-input-group-compact > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }
  .custom-input-group.custom-input-group-compact .custom-input {
    float: none;
  }
  .custom-input-group.custom-input-group-compact > .custom-select > .custom-select-selector,
  .custom-input-group.custom-input-group-compact > .custom-select-auto-complete .custom-input,
  .custom-input-group.custom-input-group-compact > .custom-cascader-picker .custom-input,
  .custom-input-group.custom-input-group-compact > .custom-input-group-wrapper .custom-input {
    border-right-width: 1px;
    border-radius: 0;
  }
  .custom-input-group.custom-input-group-compact > .custom-select > .custom-select-selector:hover,
  .custom-input-group.custom-input-group-compact > .custom-select-auto-complete .custom-input:hover,
  .custom-input-group.custom-input-group-compact > .custom-cascader-picker .custom-input:hover,
  .custom-input-group.custom-input-group-compact > .custom-input-group-wrapper .custom-input:hover {
    z-index: 1;
  }
  .custom-input-group.custom-input-group-compact > .custom-select > .custom-select-selector:focus,
  .custom-input-group.custom-input-group-compact > .custom-select-auto-complete .custom-input:focus,
  .custom-input-group.custom-input-group-compact > .custom-cascader-picker .custom-input:focus,
  .custom-input-group.custom-input-group-compact > .custom-input-group-wrapper .custom-input:focus {
    z-index: 1;
  }
  .custom-input-group.custom-input-group-compact > .custom-select-focused {
    z-index: 1;
  }
  .custom-input-group.custom-input-group-compact > .custom-select > .custom-select-arrow {
    z-index: 1;
  }
  .custom-input-group.custom-input-group-compact > *:first-child,
  .custom-input-group.custom-input-group-compact > .custom-select:first-child > .custom-select-selector,
  .custom-input-group.custom-input-group-compact > .custom-select-auto-complete:first-child .custom-input,
  .custom-input-group.custom-input-group-compact > .custom-cascader-picker:first-child .custom-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .custom-input-group.custom-input-group-compact > *:last-child,
  .custom-input-group.custom-input-group-compact > .custom-select:last-child > .custom-select-selector,
  .custom-input-group.custom-input-group-compact > .custom-cascader-picker:last-child .custom-input,
  .custom-input-group.custom-input-group-compact > .custom-cascader-picker-focused:last-child .custom-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .custom-input-group.custom-input-group-compact > .custom-select-auto-complete .custom-input {
    vertical-align: top;
  }
  .custom-input-group.custom-input-group-compact .custom-input-group-wrapper + .custom-input-group-wrapper {
    margin-left: -1px;
  }
  .custom-input-group.custom-input-group-compact .custom-input-group-wrapper + .custom-input-group-wrapper .custom-input-affix-wrapper {
    border-radius: 0;
  }
  .custom-input-group.custom-input-group-compact .custom-input-group-wrapper:not(:last-child).custom-input-search > .custom-input-group > .custom-input-group-addon > .custom-input-search-button {
    border-radius: 0;
  }
  .custom-input-group.custom-input-group-compact .custom-input-group-wrapper:not(:last-child).custom-input-search > .custom-input-group > .custom-input {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-group > .custom-input-rtl:first-child,
  .custom-input-group-rtl .custom-input-group-addon:first-child {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-group-rtl .custom-input-group-addon:first-child {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .custom-input-group-rtl .custom-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
  }
  .custom-input-group-rtl.custom-input-group > .custom-input:last-child,
  .custom-input-group-rtl.custom-input-group-addon:last-child {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-group-rtl.custom-input-group .custom-input-affix-wrapper:not(:first-child) {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-group-rtl.custom-input-group .custom-input-affix-wrapper:not(:last-child) {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > *:not(:last-child) {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px;
  }
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > *:first-child,
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > .custom-select:first-child > .custom-select-selector,
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > .custom-select-auto-complete:first-child .custom-input,
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > .custom-cascader-picker:first-child .custom-input {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > *:last-child,
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > .custom-select:last-child > .custom-select-selector,
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > .custom-select-auto-complete:last-child .custom-input,
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > .custom-cascader-picker:last-child .custom-input,
  .custom-input-group-rtl.custom-input-group.custom-input-group-compact > .custom-cascader-picker-focused:last-child .custom-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
  }
  .custom-input-group.custom-input-group-compact .custom-input-group-wrapper-rtl + .custom-input-group-wrapper-rtl {
    margin-right: -1px;
    margin-left: 0;
  }
  .custom-input-group.custom-input-group-compact .custom-input-group-wrapper-rtl:not(:last-child).custom-input-search > .custom-input-group > .custom-input {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-group > .custom-input-rtl:first-child {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-group > .custom-input-rtl:last-child {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-group-rtl .custom-input-group-addon:first-child {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
    border-radius: 0 2px 2px 0;
  }
  .custom-input-group-rtl .custom-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
  }
  .custom-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top;
  }
  .custom-input-password-icon.anticon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-input-password-icon.anticon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-input[type='color'] {
    height: 32px;
  }
  .custom-input[type='color'].custom-input-lg {
    height: 40px;
  }
  .custom-input[type='color'].custom-input-sm {
    height: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .custom-input-textarea-show-count > .custom-input {
    height: 100%;
  }
  .custom-input-textarea-show-count::after {
    float: right;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none;
  }
  .custom-input-textarea-show-count.custom-input-textarea-in-form-item::after {
    margin-bottom: -22px;
  }
  .custom-input-textarea-suffix {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: auto;
  }
  .custom-input-search .custom-input:hover,
  .custom-input-search .custom-input:focus {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
  }
  .custom-input-search .custom-input:hover + .custom-input-group-addon .custom-input-search-button:not(.custom-btn-primary),
  .custom-input-search .custom-input:focus + .custom-input-group-addon .custom-input-search-button:not(.custom-btn-primary) {
    border-left-color: #40a9ff;
    border-left-color: var(--custom-primary-5);
  }
  .custom-input-search .custom-input-affix-wrapper {
    border-radius: 0;
  }
  .custom-input-search .custom-input-lg {
    line-height: 1.5713;
  }
  .custom-input-search > .custom-input-group > .custom-input-group-addon:last-child {
    left: -1px;
    padding: 0;
    border: 0;
  }
  .custom-input-search > .custom-input-group > .custom-input-group-addon:last-child .custom-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 2px 2px 0;
  }
  .custom-input-search > .custom-input-group > .custom-input-group-addon:last-child .custom-input-search-button:not(.custom-btn-primary) {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-input-search > .custom-input-group > .custom-input-group-addon:last-child .custom-input-search-button:not(.custom-btn-primary).custom-btn-loading::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .custom-input-search-button {
    height: 32px;
  }
  .custom-input-search-button:hover,
  .custom-input-search-button:focus {
    z-index: 1;
  }
  .custom-input-search-large .custom-input-search-button {
    height: 40px;
  }
  .custom-input-search-small .custom-input-search-button {
    height: 24px;
  }
  .custom-input-group-wrapper-rtl {
    direction: rtl;
  }
  .custom-input-group-rtl {
    direction: rtl;
  }
  .custom-input-affix-wrapper.custom-input-affix-wrapper-rtl > input.custom-input {
    border: none;
    outline: none;
  }
  .custom-input-affix-wrapper-rtl .custom-input-prefix {
    margin: 0 0 0 4px;
  }
  .custom-input-affix-wrapper-rtl .custom-input-suffix {
    margin: 0 4px 0 0;
  }
  .custom-input-textarea-rtl {
    direction: rtl;
  }
  .custom-input-textarea-rtl.custom-input-textarea-show-count::after {
    text-align: left;
  }
  .custom-input-affix-wrapper-rtl .custom-input-clear-icon-has-suffix {
    margin-right: 0;
    margin-left: 4px;
  }
  .custom-input-affix-wrapper-rtl .custom-input-clear-icon {
    right: auto;
    left: 8px;
  }
  .custom-input-search-rtl {
    direction: rtl;
  }
  .custom-input-search-rtl .custom-input:hover + .custom-input-group-addon .custom-input-search-button:not(.custom-btn-primary),
  .custom-input-search-rtl .custom-input:focus + .custom-input-group-addon .custom-input-search-button:not(.custom-btn-primary) {
    border-right-color: #40a9ff;
    border-right-color: var(--custom-primary-5);
    border-left-color: #d9d9d9;
  }
  .custom-input-search-rtl > .custom-input-group > .custom-input-affix-wrapper:hover,
  .custom-input-search-rtl > .custom-input-group > .custom-input-affix-wrapper-focused {
    border-right-color: #40a9ff;
    border-right-color: var(--custom-primary-5);
  }
  .custom-input-search-rtl > .custom-input-group > .custom-input-group-addon {
    right: -1px;
    left: auto;
  }
  .custom-input-search-rtl > .custom-input-group > .custom-input-group-addon .custom-input-search-button {
    border-radius: 2px 0 0 2px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .custom-input {
      height: 32px;
    }
    .custom-input-lg {
      height: 40px;
    }
    .custom-input-sm {
      height: 24px;
    }
    .custom-input-affix-wrapper > input.custom-input {
      height: auto;
    }
  }
  .custom-input-number-affix-wrapper {
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    position: relative;
    display: inline-flex;
    width: 90px;
    padding: 0;
    -webkit-padding-start: 11px;
            padding-inline-start: 11px;
  }
  .custom-input-number-affix-wrapper::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-number-affix-wrapper::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-number-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-input-number-affix-wrapper:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-input-number-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-number-affix-wrapper:focus,
  .custom-input-number-affix-wrapper-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-input-number-affix-wrapper:focus,
  .custom-input-rtl .custom-input-number-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-number-affix-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-number-affix-wrapper-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-number-affix-wrapper[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-number-affix-wrapper[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-number-affix-wrapper-borderless,
  .custom-input-number-affix-wrapper-borderless:hover,
  .custom-input-number-affix-wrapper-borderless:focus,
  .custom-input-number-affix-wrapper-borderless-focused,
  .custom-input-number-affix-wrapper-borderless-disabled,
  .custom-input-number-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.custom-input-number-affix-wrapper {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .custom-input-number-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-input-number-affix-wrapper-sm {
    padding: 0px 7px;
  }
  .custom-input-number-affix-wrapper-rtl {
    direction: rtl;
  }
  .custom-input-number-affix-wrapper:not(.custom-input-number-affix-wrapper-disabled):hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
    z-index: 1;
  }
  .custom-input-rtl .custom-input-number-affix-wrapper:not(.custom-input-number-affix-wrapper-disabled):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-number-affix-wrapper-focused,
  .custom-input-number-affix-wrapper:focus {
    z-index: 1;
  }
  .custom-input-number-affix-wrapper-disabled .custom-input-number[disabled] {
    background: transparent;
  }
  .custom-input-number-affix-wrapper > div.custom-input-number {
    width: 100%;
    border: none;
    outline: none;
  }
  .custom-input-number-affix-wrapper > div.custom-input-number.custom-input-number-focused {
    box-shadow: none !important;
  }
  .custom-input-number-affix-wrapper input.custom-input-number-input {
    padding: 0;
  }
  .custom-input-number-affix-wrapper::before {
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .custom-input-number-affix-wrapper .custom-input-number-handler-wrap {
    z-index: 2;
  }
  .custom-input-number-prefix,
  .custom-input-number-suffix {
    display: flex;
    flex: none;
    align-items: center;
    pointer-events: none;
  }
  .custom-input-number-prefix {
    -webkit-margin-end: 4px;
            margin-inline-end: 4px;
  }
  .custom-input-number-suffix {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    margin-right: 11px;
    margin-left: 4px;
  }
  .custom-input-number-group-wrapper .custom-input-number-affix-wrapper {
    width: 100%;
  }
  .custom-input-number-status-error:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number,
  .custom-input-number-status-error:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number:hover {
    background: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-input-number-status-error:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number:focus,
  .custom-input-number-status-error:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number-focused {
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px var(--custom-error-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-number-status-error .custom-input-number-prefix {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-input-number-status-warning:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number,
  .custom-input-number-status-warning:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number:hover {
    background: #fff;
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-input-number-status-warning:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number:focus,
  .custom-input-number-status-warning:not(.custom-input-number-disabled):not(.custom-input-number-borderless).custom-input-number-focused {
    border-color: #ffc53d;
    border-color: var(--custom-warning-color-hover);
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow: 0 0 0 2px var(--custom-warning-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-number-status-warning .custom-input-number-prefix {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-input-number-affix-wrapper-status-error:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper,
  .custom-input-number-affix-wrapper-status-error:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper:hover {
    background: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-input-number-affix-wrapper-status-error:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper:focus,
  .custom-input-number-affix-wrapper-status-error:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper-focused {
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px var(--custom-error-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-number-affix-wrapper-status-error .custom-input-number-prefix {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-input-number-affix-wrapper-status-warning:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper,
  .custom-input-number-affix-wrapper-status-warning:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper:hover {
    background: #fff;
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-input-number-affix-wrapper-status-warning:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper:focus,
  .custom-input-number-affix-wrapper-status-warning:not(.custom-input-number-affix-wrapper-disabled):not(.custom-input-number-affix-wrapper-borderless).custom-input-number-affix-wrapper-focused {
    border-color: #ffc53d;
    border-color: var(--custom-warning-color-hover);
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow: 0 0 0 2px var(--custom-warning-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-number-affix-wrapper-status-warning .custom-input-number-prefix {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-input-number-group-wrapper-status-error .custom-input-number-group-addon {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-input-number-group-wrapper-status-warning .custom-input-number-group-addon {
    color: #faad14;
    color: var(--custom-warning-color);
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-input-number {
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    transition: all 0.3s;
    display: inline-block;
    width: 90px;
    margin: 0;
    padding: 0;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .custom-input-number::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-number::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-number:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-input-number:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-input-number:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-number:focus,
  .custom-input-number-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-input-number:focus,
  .custom-input-rtl .custom-input-number-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-number-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-number[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-number[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-number-borderless,
  .custom-input-number-borderless:hover,
  .custom-input-number-borderless:focus,
  .custom-input-number-borderless-focused,
  .custom-input-number-borderless-disabled,
  .custom-input-number-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.custom-input-number {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .custom-input-number-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-input-number-sm {
    padding: 0px 7px;
  }
  .custom-input-number-rtl {
    direction: rtl;
  }
  .custom-input-number-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .custom-input-number-group[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .custom-input-number-group > [class*='col-'] {
    padding-right: 8px;
  }
  .custom-input-number-group > [class*='col-']:last-child {
    padding-right: 0;
  }
  .custom-input-number-group-addon,
  .custom-input-number-group-wrap,
  .custom-input-number-group > .custom-input-number {
    display: table-cell;
  }
  .custom-input-number-group-addon:not(:first-child):not(:last-child),
  .custom-input-number-group-wrap:not(:first-child):not(:last-child),
  .custom-input-number-group > .custom-input-number:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .custom-input-number-group-addon,
  .custom-input-number-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .custom-input-number-group-wrap > * {
    display: block !important;
  }
  .custom-input-number-group .custom-input-number {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  .custom-input-number-group .custom-input-number:focus {
    z-index: 1;
    border-right-width: 1px;
  }
  .custom-input-number-group .custom-input-number:hover {
    z-index: 1;
    border-right-width: 1px;
  }
  .custom-input-search-with-button .custom-input-number-group .custom-input-number:hover {
    z-index: 0;
  }
  .custom-input-number-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }
  .custom-input-number-group-addon .custom-select {
    margin: -5px -11px;
  }
  .custom-input-number-group-addon .custom-select.custom-select-single:not(.custom-select-customize-input) .custom-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
  }
  .custom-input-number-group-addon .custom-select-open .custom-select-selector,
  .custom-input-number-group-addon .custom-select-focused .custom-select-selector {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-input-number-group-addon .custom-cascader-picker {
    margin: -9px -12px;
    background-color: transparent;
  }
  .custom-input-number-group-addon .custom-cascader-picker .custom-cascader-input {
    text-align: left;
    border: 0;
    box-shadow: none;
  }
  .custom-input-number-group > .custom-input-number:first-child,
  .custom-input-number-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-number-group > .custom-input-number:first-child .custom-select .custom-select-selector,
  .custom-input-number-group-addon:first-child .custom-select .custom-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-number-group > .custom-input-number-affix-wrapper:not(:first-child) .custom-input-number {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-number-group > .custom-input-number-affix-wrapper:not(:last-child) .custom-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-number-group-addon:first-child {
    border-right: 0;
  }
  .custom-input-number-group-addon:last-child {
    border-left: 0;
  }
  .custom-input-number-group > .custom-input-number:last-child,
  .custom-input-number-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-number-group > .custom-input-number:last-child .custom-select .custom-select-selector,
  .custom-input-number-group-addon:last-child .custom-select .custom-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-number-group-lg .custom-input-number,
  .custom-input-number-group-lg > .custom-input-number-group-addon {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-input-number-group-sm .custom-input-number,
  .custom-input-number-group-sm > .custom-input-number-group-addon {
    padding: 0px 7px;
  }
  .custom-input-number-group-lg .custom-select-single .custom-select-selector {
    height: 40px;
  }
  .custom-input-number-group-sm .custom-select-single .custom-select-selector {
    height: 24px;
  }
  .custom-input-number-group .custom-input-number-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-input-search .custom-input-number-group .custom-input-number-affix-wrapper:not(:last-child) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .custom-input-number-group .custom-input-number-affix-wrapper:not(:first-child),
  .custom-input-search .custom-input-number-group .custom-input-number-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-input-number-group.custom-input-number-group-compact {
    display: block;
  }
  .custom-input-number-group.custom-input-number-group-compact::before {
    display: table;
    content: '';
  }
  .custom-input-number-group.custom-input-number-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-input-number-group.custom-input-number-group-compact::before {
    display: table;
    content: '';
  }
  .custom-input-number-group.custom-input-number-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-input-number-group.custom-input-number-group-compact-addon:not(:first-child):not(:last-child),
  .custom-input-number-group.custom-input-number-group-compact-wrap:not(:first-child):not(:last-child),
  .custom-input-number-group.custom-input-number-group-compact > .custom-input-number:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .custom-input-number-group.custom-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
  .custom-input-number-group.custom-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .custom-input-number-group.custom-input-number-group-compact > .custom-input-number:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .custom-input-number-group.custom-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
  .custom-input-number-group.custom-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .custom-input-number-group.custom-input-number-group-compact > .custom-input-number:not(:first-child):not(:last-child):focus {
    z-index: 1;
  }
  .custom-input-number-group.custom-input-number-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-input-number-affix-wrapper {
    display: inline-flex;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-picker-range {
    display: inline-flex;
  }
  .custom-input-number-group.custom-input-number-group-compact > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }
  .custom-input-number-group.custom-input-number-group-compact .custom-input-number {
    float: none;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-select > .custom-select-selector,
  .custom-input-number-group.custom-input-number-group-compact > .custom-select-auto-complete .custom-input,
  .custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker .custom-input,
  .custom-input-number-group.custom-input-number-group-compact > .custom-input-group-wrapper .custom-input {
    border-right-width: 1px;
    border-radius: 0;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-select > .custom-select-selector:hover,
  .custom-input-number-group.custom-input-number-group-compact > .custom-select-auto-complete .custom-input:hover,
  .custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker .custom-input:hover,
  .custom-input-number-group.custom-input-number-group-compact > .custom-input-group-wrapper .custom-input:hover {
    z-index: 1;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-select > .custom-select-selector:focus,
  .custom-input-number-group.custom-input-number-group-compact > .custom-select-auto-complete .custom-input:focus,
  .custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker .custom-input:focus,
  .custom-input-number-group.custom-input-number-group-compact > .custom-input-group-wrapper .custom-input:focus {
    z-index: 1;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-select-focused {
    z-index: 1;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-select > .custom-select-arrow {
    z-index: 1;
  }
  .custom-input-number-group.custom-input-number-group-compact > *:first-child,
  .custom-input-number-group.custom-input-number-group-compact > .custom-select:first-child > .custom-select-selector,
  .custom-input-number-group.custom-input-number-group-compact > .custom-select-auto-complete:first-child .custom-input,
  .custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker:first-child .custom-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .custom-input-number-group.custom-input-number-group-compact > *:last-child,
  .custom-input-number-group.custom-input-number-group-compact > .custom-select:last-child > .custom-select-selector,
  .custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker:last-child .custom-input,
  .custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker-focused:last-child .custom-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .custom-input-number-group.custom-input-number-group-compact > .custom-select-auto-complete .custom-input {
    vertical-align: top;
  }
  .custom-input-number-group.custom-input-number-group-compact .custom-input-group-wrapper + .custom-input-group-wrapper {
    margin-left: -1px;
  }
  .custom-input-number-group.custom-input-number-group-compact .custom-input-group-wrapper + .custom-input-group-wrapper .custom-input-affix-wrapper {
    border-radius: 0;
  }
  .custom-input-number-group.custom-input-number-group-compact .custom-input-group-wrapper:not(:last-child).custom-input-search > .custom-input-group > .custom-input-group-addon > .custom-input-search-button {
    border-radius: 0;
  }
  .custom-input-number-group.custom-input-number-group-compact .custom-input-group-wrapper:not(:last-child).custom-input-search > .custom-input-group > .custom-input {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-number-group > .custom-input-number-rtl:first-child,
  .custom-input-number-group-rtl .custom-input-number-group-addon:first-child {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-number-group-rtl .custom-input-number-group-addon:first-child {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .custom-input-number-group-rtl .custom-input-number-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
  }
  .custom-input-number-group-rtl.custom-input-number-group > .custom-input-number:last-child,
  .custom-input-number-group-rtl.custom-input-number-group-addon:last-child {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-number-group-rtl.custom-input-number-group .custom-input-number-affix-wrapper:not(:first-child) {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-number-group-rtl.custom-input-number-group .custom-input-number-affix-wrapper:not(:last-child) {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > *:not(:last-child) {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px;
  }
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > *:first-child,
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > .custom-select:first-child > .custom-select-selector,
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > .custom-select-auto-complete:first-child .custom-input,
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker:first-child .custom-input {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > *:last-child,
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > .custom-select:last-child > .custom-select-selector,
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > .custom-select-auto-complete:last-child .custom-input,
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker:last-child .custom-input,
  .custom-input-number-group-rtl.custom-input-number-group.custom-input-number-group-compact > .custom-cascader-picker-focused:last-child .custom-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
  }
  .custom-input-number-group.custom-input-number-group-compact .custom-input-group-wrapper-rtl + .custom-input-group-wrapper-rtl {
    margin-right: -1px;
    margin-left: 0;
  }
  .custom-input-number-group.custom-input-number-group-compact .custom-input-group-wrapper-rtl:not(:last-child).custom-input-search > .custom-input-group > .custom-input {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-number-group > .custom-input-number-rtl:first-child {
    border-radius: 0 2px 2px 0;
  }
  .custom-input-number-group > .custom-input-number-rtl:last-child {
    border-radius: 2px 0 0 2px;
  }
  .custom-input-number-group-rtl .custom-input-number-group-addon:first-child {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
    border-radius: 0 2px 2px 0;
  }
  .custom-input-number-group-rtl .custom-input-number-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
  }
  .custom-input-number-group-wrapper {
    display: inline-block;
    text-align: start;
    vertical-align: top;
  }
  .custom-input-number-handler {
    position: relative;
    display: block;
    width: 100%;
    height: 50%;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    line-height: 0;
    text-align: center;
    border-left: 1px solid #d9d9d9;
    transition: all 0.1s linear;
  }
  .custom-input-number-handler:active {
    background: #f4f4f4;
  }
  .custom-input-number-handler:hover .custom-input-number-handler-up-inner,
  .custom-input-number-handler:hover .custom-input-number-handler-down-inner {
    color: #40a9ff;
    color: var(--custom-primary-5);
  }
  .custom-input-number-handler-up-inner,
  .custom-input-number-handler-down-inner {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    width: 12px;
    height: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 12px;
    transition: all 0.1s linear;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-number-handler-up-inner > *,
  .custom-input-number-handler-down-inner > * {
    line-height: 1;
  }
  .custom-input-number-handler-up-inner svg,
  .custom-input-number-handler-down-inner svg {
    display: inline-block;
  }
  .custom-input-number-handler-up-inner::before,
  .custom-input-number-handler-down-inner::before {
    display: none;
  }
  .custom-input-number-handler-up-inner .custom-input-number-handler-up-inner-icon,
  .custom-input-number-handler-up-inner .custom-input-number-handler-down-inner-icon,
  .custom-input-number-handler-down-inner .custom-input-number-handler-up-inner-icon,
  .custom-input-number-handler-down-inner .custom-input-number-handler-down-inner-icon {
    display: block;
  }
  .custom-input-number:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-number:hover + .custom-form-item-children-icon {
    opacity: 0;
    transition: opacity 0.24s linear 0.24s;
  }
  .custom-input-number-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-input-number-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-input-number-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-input-number-disabled .custom-input-number-input {
    cursor: not-allowed;
  }
  .custom-input-number-disabled .custom-input-number-handler-wrap {
    display: none;
  }
  .custom-input-number-readonly .custom-input-number-handler-wrap {
    display: none;
  }
  .custom-input-number-input {
    width: 100%;
    height: 30px;
    padding: 0 11px;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 2px;
    outline: 0;
    transition: all 0.3s linear;
    -webkit-appearance: textfield !important;
            appearance: textfield !important;
  }
  .custom-input-number-input::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-number-input::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-input-number-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-input-number-input[type='number']::-webkit-inner-spin-button,
  .custom-input-number-input[type='number']::-webkit-outer-spin-button {
    margin: 0;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    appearance: none;
  }
  .custom-input-number-lg {
    padding: 0;
    font-size: 16px;
  }
  .custom-input-number-lg input {
    height: 38px;
  }
  .custom-input-number-sm {
    padding: 0;
  }
  .custom-input-number-sm input {
    height: 22px;
    padding: 0 7px;
  }
  .custom-input-number-handler-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 100%;
    background: #fff;
    border-radius: 0 2px 2px 0;
    opacity: 0;
    transition: opacity 0.24s linear 0.1s;
  }
  .custom-input-number-handler-wrap .custom-input-number-handler .custom-input-number-handler-up-inner,
  .custom-input-number-handler-wrap .custom-input-number-handler .custom-input-number-handler-down-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    margin-right: 0;
    font-size: 7px;
  }
  .custom-input-number-borderless .custom-input-number-handler-wrap {
    border-left-width: 0;
  }
  .custom-input-number-handler-wrap:hover .custom-input-number-handler {
    height: 40%;
  }
  .custom-input-number:hover .custom-input-number-handler-wrap,
  .custom-input-number-focused .custom-input-number-handler-wrap {
    opacity: 1;
  }
  .custom-input-number-handler-up {
    border-top-right-radius: 2px;
    cursor: pointer;
  }
  .custom-input-number-handler-up-inner {
    top: 50%;
    margin-top: -5px;
    text-align: center;
  }
  .custom-input-number-handler-up:hover {
    height: 60% !important;
  }
  .custom-input-number-handler-down {
    top: 0;
    border-top: 1px solid #d9d9d9;
    border-bottom-right-radius: 2px;
    cursor: pointer;
  }
  .custom-input-number-handler-down-inner {
    top: 50%;
    text-align: center;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .custom-input-number-handler-down:hover {
    height: 60% !important;
  }
  .custom-input-number-borderless .custom-input-number-handler-down {
    border-top-width: 0;
  }
  .custom-input-number:hover:not(.custom-input-number-borderless) .custom-input-number-handler-down,
  .custom-input-number-focused:not(.custom-input-number-borderless) .custom-input-number-handler-down {
    border-top: 1px solid #d9d9d9;
  }
  .custom-input-number-handler-up-disabled,
  .custom-input-number-handler-down-disabled {
    cursor: not-allowed;
  }
  .custom-input-number-handler-up-disabled:hover .custom-input-number-handler-up-inner,
  .custom-input-number-handler-down-disabled:hover .custom-input-number-handler-down-inner {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-input-number-borderless {
    box-shadow: none;
  }
  .custom-input-number-out-of-range input {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-input-number-rtl {
    direction: rtl;
  }
  .custom-input-number-rtl .custom-input-number-handler {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .custom-input-number-rtl .custom-input-number-handler-wrap {
    right: auto;
    left: 0;
  }
  .custom-input-number-rtl.custom-input-number-borderless .custom-input-number-handler-wrap {
    border-right-width: 0;
  }
  .custom-input-number-rtl .custom-input-number-handler-up {
    border-top-right-radius: 0;
  }
  .custom-input-number-rtl .custom-input-number-handler-down {
    border-bottom-right-radius: 0;
  }
  .custom-input-number-rtl .custom-input-number-input {
    direction: ltr;
    text-align: right;
  }
  .custom-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    /* fix firefox can't set height smaller than content on flex item */
    min-height: 0;
    background: #f0f2f5;
  }
  .custom-layout,
  .custom-layout * {
    box-sizing: border-box;
  }
  .custom-layout.custom-layout-has-sider {
    flex-direction: row;
  }
  .custom-layout.custom-layout-has-sider > .custom-layout,
  .custom-layout.custom-layout-has-sider > .custom-layout-content {
    width: 0;
  }
  .custom-layout-header,
  .custom-layout-footer {
    flex: 0 0 auto;
  }
  .custom-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #001529;
  }
  .custom-layout-footer {
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background: #f0f2f5;
  }
  .custom-layout-content {
    flex: auto;
    /* fix firefox can't set height smaller than content on flex item */
    min-height: 0;
  }
  .custom-layout-sider {
    position: relative;
    /* fix firefox can't set width smaller than content on flex item */
    min-width: 0;
    background: #001529;
    transition: all 0.2s;
  }
  .custom-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
  }
  .custom-layout-sider-children .custom-menu.custom-menu-inline-collapsed {
    width: auto;
  }
  .custom-layout-sider-has-trigger {
    padding-bottom: 48px;
  }
  .custom-layout-sider-right {
    order: 1;
  }
  .custom-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #002140;
    cursor: pointer;
    transition: all 0.2s;
  }
  .custom-layout-sider-zero-width > * {
    overflow: hidden;
  }
  .custom-layout-sider-zero-width-trigger {
    position: absolute;
    top: 64px;
    right: -36px;
    z-index: 1;
    width: 36px;
    height: 42px;
    color: #fff;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    background: #001529;
    border-radius: 0 2px 2px 0;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .custom-layout-sider-zero-width-trigger::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    transition: all 0.3s;
    content: '';
  }
  .custom-layout-sider-zero-width-trigger:hover::after {
    background: rgba(255, 255, 255, 0.1);
  }
  .custom-layout-sider-zero-width-trigger-right {
    left: -36px;
    border-radius: 2px 0 0 2px;
  }
  .custom-layout-sider-light {
    background: #fff;
  }
  .custom-layout-sider-light .custom-layout-sider-trigger {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
  }
  .custom-layout-sider-light .custom-layout-sider-zero-width-trigger {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
  }
  .custom-layout-rtl {
    direction: rtl;
  }
  .custom-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
  }
  .custom-list * {
    outline: none;
  }
  .custom-list-pagination {
    margin-top: 24px;
    text-align: right;
  }
  .custom-list-pagination .custom-pagination-options {
    text-align: left;
  }
  .custom-list-more {
    margin-top: 12px;
    text-align: center;
  }
  .custom-list-more button {
    padding-right: 32px;
    padding-left: 32px;
  }
  .custom-list-spin {
    min-height: 40px;
    text-align: center;
  }
  .custom-list-empty-text {
    padding: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    text-align: center;
  }
  .custom-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .custom-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-list-item-meta {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    max-width: 100%;
  }
  .custom-list-item-meta-avatar {
    margin-right: 16px;
  }
  .custom-list-item-meta-content {
    flex: 1 0;
    width: 0;
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-list-item-meta-title {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }
  .custom-list-item-meta-title > a {
    color: rgba(0, 0, 0, 0.85);
    transition: all 0.3s;
  }
  .custom-list-item-meta-title > a:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-list-item-meta-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
  }
  .custom-list-item-action {
    flex: 0 0 auto;
    margin-left: 48px;
    padding: 0;
    font-size: 0;
    list-style: none;
  }
  .custom-list-item-action > li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
  }
  .custom-list-item-action > li:first-child {
    padding-left: 0;
  }
  .custom-list-item-action-split {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 14px;
    margin-top: -7px;
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-list-header {
    background: transparent;
  }
  .custom-list-footer {
    background: transparent;
  }
  .custom-list-header,
  .custom-list-footer {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .custom-list-empty {
    padding: 16px 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    text-align: center;
  }
  .custom-list-split .custom-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-list-split .custom-list-item:last-child {
    border-bottom: none;
  }
  .custom-list-split .custom-list-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-list-split.custom-list-empty .custom-list-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-list-loading .custom-list-spin-nested-loading {
    min-height: 32px;
  }
  .custom-list-split.custom-list-something-after-last-item .custom-spin-container > .custom-list-items > .custom-list-item:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-list-lg .custom-list-item {
    padding: 16px 24px;
  }
  .custom-list-sm .custom-list-item {
    padding: 8px 16px;
  }
  .custom-list-vertical .custom-list-item {
    align-items: initial;
  }
  .custom-list-vertical .custom-list-item-main {
    display: block;
    flex: 1 1;
  }
  .custom-list-vertical .custom-list-item-extra {
    margin-left: 40px;
  }
  .custom-list-vertical .custom-list-item-meta {
    margin-bottom: 16px;
  }
  .custom-list-vertical .custom-list-item-meta-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
  }
  .custom-list-vertical .custom-list-item-action {
    margin-top: 16px;
    margin-left: auto;
  }
  .custom-list-vertical .custom-list-item-action > li {
    padding: 0 16px;
  }
  .custom-list-vertical .custom-list-item-action > li:first-child {
    padding-left: 0;
  }
  .custom-list-grid .custom-col > .custom-list-item {
    display: block;
    max-width: 100%;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .custom-list-item-no-flex {
    display: block;
  }
  .custom-list:not(.custom-list-vertical) .custom-list-item-no-flex .custom-list-item-action {
    float: right;
  }
  .custom-list-bordered {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .custom-list-bordered .custom-list-header {
    padding-right: 24px;
    padding-left: 24px;
  }
  .custom-list-bordered .custom-list-footer {
    padding-right: 24px;
    padding-left: 24px;
  }
  .custom-list-bordered .custom-list-item {
    padding-right: 24px;
    padding-left: 24px;
  }
  .custom-list-bordered .custom-list-pagination {
    margin: 16px 24px;
  }
  .custom-list-bordered.custom-list-sm .custom-list-item {
    padding: 8px 16px;
  }
  .custom-list-bordered.custom-list-sm .custom-list-header,
  .custom-list-bordered.custom-list-sm .custom-list-footer {
    padding: 8px 16px;
  }
  .custom-list-bordered.custom-list-lg .custom-list-item {
    padding: 16px 24px;
  }
  .custom-list-bordered.custom-list-lg .custom-list-header,
  .custom-list-bordered.custom-list-lg .custom-list-footer {
    padding: 16px 24px;
  }
  @media screen and (max-width: 768px) {
    .custom-list-item-action {
      margin-left: 24px;
    }
    .custom-list-vertical .custom-list-item-extra {
      margin-left: 24px;
    }
  }
  @media screen and (max-width: 576px) {
    .custom-list-item {
      flex-wrap: wrap;
    }
    .custom-list-item-action {
      margin-left: 12px;
    }
    .custom-list-vertical .custom-list-item {
      flex-wrap: wrap-reverse;
    }
    .custom-list-vertical .custom-list-item-main {
      min-width: 220px;
    }
    .custom-list-vertical .custom-list-item-extra {
      margin: auto auto 16px;
    }
  }
  .custom-list-rtl {
    direction: rtl;
    text-align: right;
  }
  .custom-list-rtl .ReactVirtualized__List .custom-list-item {
    direction: rtl;
  }
  .custom-list-rtl .custom-list-pagination {
    text-align: left;
  }
  .custom-list-rtl .custom-list-item-meta-avatar {
    margin-right: 0;
    margin-left: 16px;
  }
  .custom-list-rtl .custom-list-item-action {
    margin-right: 48px;
    margin-left: 0;
  }
  .custom-list.custom-list-rtl .custom-list-item-action > li:first-child {
    padding-right: 0;
    padding-left: 16px;
  }
  .custom-list-rtl .custom-list-item-action-split {
    right: auto;
    left: 0;
  }
  .custom-list-rtl.custom-list-vertical .custom-list-item-extra {
    margin-right: 40px;
    margin-left: 0;
  }
  .custom-list-rtl.custom-list-vertical .custom-list-item-action {
    margin-right: auto;
  }
  .custom-list-rtl .custom-list-vertical .custom-list-item-action > li:first-child {
    padding-right: 0;
    padding-left: 16px;
  }
  .custom-list-rtl .custom-list:not(.custom-list-vertical) .custom-list-item-no-flex .custom-list-item-action {
    float: left;
  }
  @media screen and (max-width: 768px) {
    .custom-list-rtl .custom-list-item-action {
      margin-right: 24px;
      margin-left: 0;
    }
    .custom-list-rtl .custom-list-vertical .custom-list-item-extra {
      margin-right: 24px;
      margin-left: 0;
    }
  }
  @media screen and (max-width: 576px) {
    .custom-list-rtl .custom-list-item-action {
      margin-right: 22px;
      margin-left: 0;
    }
    .custom-list-rtl.custom-list-vertical .custom-list-item-extra {
      margin: auto auto 16px;
    }
  }
  .custom-mentions-status-error:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions,
  .custom-mentions-status-error:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions:hover {
    background: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-mentions-status-error:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions:focus,
  .custom-mentions-status-error:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions-focused {
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px var(--custom-error-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-mentions-status-error .custom-input-prefix {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-mentions-status-warning:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions,
  .custom-mentions-status-warning:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions:hover {
    background: #fff;
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-mentions-status-warning:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions:focus,
  .custom-mentions-status-warning:not(.custom-mentions-disabled):not(.custom-mentions-borderless).custom-mentions-focused {
    border-color: #ffc53d;
    border-color: var(--custom-warning-color-hover);
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow: 0 0 0 2px var(--custom-warning-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-mentions-status-warning .custom-input-prefix {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-mentions {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    position: relative;
    display: inline-block;
    height: auto;
    padding: 0;
    overflow: hidden;
    line-height: 1.5715;
    white-space: pre-wrap;
    vertical-align: bottom;
  }
  .custom-mentions::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-mentions::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-mentions:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-mentions:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-mentions:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-mentions:focus,
  .custom-mentions-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-mentions:focus,
  .custom-input-rtl .custom-mentions-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-mentions-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-mentions-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-mentions[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-mentions[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-mentions-borderless,
  .custom-mentions-borderless:hover,
  .custom-mentions-borderless:focus,
  .custom-mentions-borderless-focused,
  .custom-mentions-borderless-disabled,
  .custom-mentions-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.custom-mentions {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .custom-mentions-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-mentions-sm {
    padding: 0px 7px;
  }
  .custom-mentions-rtl {
    direction: rtl;
  }
  .custom-mentions-disabled > textarea {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-mentions-disabled > textarea:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-mentions-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-mentions-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-mentions > textarea,
  .custom-mentions-measure {
    min-height: 30px;
    margin: 0;
    padding: 4px 11px;
    overflow: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    -webkit-font-feature-settings: inherit;
            font-feature-settings: inherit;
    font-variant: inherit;
    font-size-adjust: inherit;
    font-stretch: inherit;
    line-height: inherit;
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    direction: inherit;
    letter-spacing: inherit;
    white-space: inherit;
    text-align: inherit;
    vertical-align: top;
    word-wrap: break-word;
    word-break: inherit;
    tab-size: inherit;
  }
  .custom-mentions > textarea {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
  }
  .custom-mentions > textarea::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-mentions > textarea::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-mentions > textarea:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-mentions-measure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    color: transparent;
    pointer-events: none;
  }
  .custom-mentions-measure > span {
    display: inline-block;
    min-height: 1em;
  }
  .custom-mentions-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum", ;
            font-feature-settings: 'tnum', "tnum", ;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .custom-mentions-dropdown-hidden {
    display: none;
  }
  .custom-mentions-dropdown-menu {
    max-height: 250px;
    margin-bottom: 0;
    padding-left: 0;
    overflow: auto;
    list-style: none;
    outline: none;
  }
  .custom-mentions-dropdown-menu-item {
    position: relative;
    display: block;
    min-width: 100px;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .custom-mentions-dropdown-menu-item:hover {
    background-color: #f5f5f5;
  }
  .custom-mentions-dropdown-menu-item:first-child {
    border-radius: 2px 2px 0 0;
  }
  .custom-mentions-dropdown-menu-item:last-child {
    border-radius: 0 0 2px 2px;
  }
  .custom-mentions-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-mentions-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .custom-mentions-dropdown-menu-item-selected {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #fafafa;
  }
  .custom-mentions-dropdown-menu-item-active {
    background-color: #f5f5f5;
  }
  .custom-mentions-suffix {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: auto;
  }
  .custom-mentions-rtl {
    direction: rtl;
  }
  .custom-menu-item-danger.custom-menu-item {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-menu-item-danger.custom-menu-item:hover,
  .custom-menu-item-danger.custom-menu-item-active {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-menu-item-danger.custom-menu-item:active {
    background: #fff1f0;
  }
  .custom-menu-item-danger.custom-menu-item-selected {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-menu-item-danger.custom-menu-item-selected > a,
  .custom-menu-item-danger.custom-menu-item-selected > a:hover {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-menu:not(.custom-menu-horizontal) .custom-menu-item-danger.custom-menu-item-selected {
    background-color: #fff1f0;
  }
  .custom-menu-inline .custom-menu-item-danger.custom-menu-item::after {
    border-right-color: #ff4d4f;
    border-right-color: var(--custom-error-color);
  }
  .custom-menu-dark .custom-menu-item-danger.custom-menu-item,
  .custom-menu-dark .custom-menu-item-danger.custom-menu-item:hover,
  .custom-menu-dark .custom-menu-item-danger.custom-menu-item > a {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-menu-dark.custom-menu-dark:not(.custom-menu-horizontal) .custom-menu-item-danger.custom-menu-item-selected {
    color: #fff;
    background-color: #ff4d4f;
    background-color: var(--custom-error-color);
  }
  .custom-menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    margin-bottom: 0;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: #fff;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  .custom-menu::before {
    display: table;
    content: '';
  }
  .custom-menu::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-menu::before {
    display: table;
    content: '';
  }
  .custom-menu::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-menu.custom-menu-root:focus-visible {
    box-shadow: 0 0 0 2px #bae7ff;
    box-shadow: 0 0 0 2px var(--custom-primary-2);
  }
  .custom-menu ul,
  .custom-menu ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .custom-menu-overflow {
    display: flex;
  }
  .custom-menu-overflow-item {
    flex: none;
  }
  .custom-menu-hidden,
  .custom-menu-submenu-hidden {
    display: none;
  }
  .custom-menu-item-group-title {
    height: 1.5715;
    padding: 8px 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: all 0.3s;
  }
  .custom-menu-horizontal .custom-menu-submenu {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-menu-submenu,
  .custom-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-menu-submenu-selected {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-item:active,
  .custom-menu-submenu-title:active {
    background: #e6f7ff;
    background: var(--custom-primary-1);
  }
  .custom-menu-submenu .custom-menu-sub {
    cursor: auto;
    cursor: initial;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-menu-title-content {
    transition: color 0.3s;
  }
  .custom-menu-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-menu-item a:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-item a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: '';
  }
  .custom-menu-item > .custom-badge a {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-menu-item > .custom-badge a:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-item-divider {
    overflow: hidden;
    line-height: 0;
    border-color: rgba(0, 0, 0, 0.06);
    border-style: solid;
    border-width: 1px 0 0;
  }
  .custom-menu-item-divider-dashed {
    border-style: dashed;
  }
  .custom-menu-horizontal .custom-menu-item,
  .custom-menu-horizontal .custom-menu-submenu {
    margin-top: -1px;
  }
  .custom-menu-horizontal > .custom-menu-item:hover,
  .custom-menu-horizontal > .custom-menu-item-active,
  .custom-menu-horizontal > .custom-menu-submenu .custom-menu-submenu-title:hover {
    background-color: transparent;
  }
  .custom-menu-item-selected {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-item-selected a,
  .custom-menu-item-selected a:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu:not(.custom-menu-horizontal) .custom-menu-item-selected {
    background-color: #e6f7ff;
    background-color: var(--custom-primary-1);
  }
  .custom-menu-inline,
  .custom-menu-vertical,
  .custom-menu-vertical-left {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-menu-vertical-right {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-menu-vertical.custom-menu-sub,
  .custom-menu-vertical-left.custom-menu-sub,
  .custom-menu-vertical-right.custom-menu-sub {
    min-width: 160px;
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow: hidden;
    border-right: 0;
  }
  .custom-menu-vertical.custom-menu-sub:not([class*='-active']),
  .custom-menu-vertical-left.custom-menu-sub:not([class*='-active']),
  .custom-menu-vertical-right.custom-menu-sub:not([class*='-active']) {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .custom-menu-vertical.custom-menu-sub .custom-menu-item,
  .custom-menu-vertical-left.custom-menu-sub .custom-menu-item,
  .custom-menu-vertical-right.custom-menu-sub .custom-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .custom-menu-vertical.custom-menu-sub .custom-menu-item::after,
  .custom-menu-vertical-left.custom-menu-sub .custom-menu-item::after,
  .custom-menu-vertical-right.custom-menu-sub .custom-menu-item::after {
    border-right: 0;
  }
  .custom-menu-vertical.custom-menu-sub > .custom-menu-item,
  .custom-menu-vertical-left.custom-menu-sub > .custom-menu-item,
  .custom-menu-vertical-right.custom-menu-sub > .custom-menu-item,
  .custom-menu-vertical.custom-menu-sub > .custom-menu-submenu,
  .custom-menu-vertical-left.custom-menu-sub > .custom-menu-submenu,
  .custom-menu-vertical-right.custom-menu-sub > .custom-menu-submenu {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .custom-menu-horizontal.custom-menu-sub {
    min-width: 114px;
  }
  .custom-menu-horizontal .custom-menu-item,
  .custom-menu-horizontal .custom-menu-submenu-title {
    transition: border-color 0.3s, background 0.3s;
  }
  .custom-menu-item,
  .custom-menu-submenu-title {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-menu-item .custom-menu-item-icon,
  .custom-menu-submenu-title .custom-menu-item-icon,
  .custom-menu-item .anticon,
  .custom-menu-submenu-title .anticon {
    min-width: 14px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  }
  .custom-menu-item .custom-menu-item-icon + span,
  .custom-menu-submenu-title .custom-menu-item-icon + span,
  .custom-menu-item .anticon + span,
  .custom-menu-submenu-title .anticon + span {
    margin-left: 10px;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
  }
  .custom-menu-item .custom-menu-item-icon.svg,
  .custom-menu-submenu-title .custom-menu-item-icon.svg {
    vertical-align: -0.125em;
  }
  .custom-menu-item.custom-menu-item-only-child > .anticon,
  .custom-menu-submenu-title.custom-menu-item-only-child > .anticon,
  .custom-menu-item.custom-menu-item-only-child > .custom-menu-item-icon,
  .custom-menu-submenu-title.custom-menu-item-only-child > .custom-menu-item-icon {
    margin-right: 0;
  }
  .custom-menu-item:focus-visible,
  .custom-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px #bae7ff;
    box-shadow: 0 0 0 2px var(--custom-primary-2);
  }
  .custom-menu > .custom-menu-item-divider {
    margin: 1px 0;
    padding: 0;
  }
  .custom-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: transparent;
    border-radius: 2px;
    box-shadow: none;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .custom-menu-submenu-popup::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.0001;
    content: ' ';
  }
  .custom-menu-submenu-placement-rightTop::before {
    top: 0;
    left: -7px;
  }
  .custom-menu-submenu > .custom-menu {
    background-color: #fff;
    border-radius: 2px;
  }
  .custom-menu-submenu > .custom-menu-submenu-title::after {
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-menu-submenu-popup > .custom-menu {
    background-color: #fff;
  }
  .custom-menu-submenu-expand-icon,
  .custom-menu-submenu-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    color: rgba(0, 0, 0, 0.85);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-menu-submenu-arrow::before,
  .custom-menu-submenu-arrow::after {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-color: currentcolor;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }
  .custom-menu-submenu-arrow::before {
    -webkit-transform: rotate(45deg) translateY(-2.5px);
            transform: rotate(45deg) translateY(-2.5px);
  }
  .custom-menu-submenu-arrow::after {
    -webkit-transform: rotate(-45deg) translateY(2.5px);
            transform: rotate(-45deg) translateY(2.5px);
  }
  .custom-menu-submenu:hover > .custom-menu-submenu-title > .custom-menu-submenu-expand-icon,
  .custom-menu-submenu:hover > .custom-menu-submenu-title > .custom-menu-submenu-arrow {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-inline-collapsed .custom-menu-submenu-arrow::before,
  .custom-menu-submenu-inline .custom-menu-submenu-arrow::before {
    -webkit-transform: rotate(-45deg) translateX(2.5px);
            transform: rotate(-45deg) translateX(2.5px);
  }
  .custom-menu-inline-collapsed .custom-menu-submenu-arrow::after,
  .custom-menu-submenu-inline .custom-menu-submenu-arrow::after {
    -webkit-transform: rotate(45deg) translateX(-2.5px);
            transform: rotate(45deg) translateX(-2.5px);
  }
  .custom-menu-submenu-horizontal .custom-menu-submenu-arrow {
    display: none;
  }
  .custom-menu-submenu-open.custom-menu-submenu-inline > .custom-menu-submenu-title > .custom-menu-submenu-arrow {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  .custom-menu-submenu-open.custom-menu-submenu-inline > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after {
    -webkit-transform: rotate(-45deg) translateX(-2.5px);
            transform: rotate(-45deg) translateX(-2.5px);
  }
  .custom-menu-submenu-open.custom-menu-submenu-inline > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before {
    -webkit-transform: rotate(45deg) translateX(2.5px);
            transform: rotate(45deg) translateX(2.5px);
  }
  .custom-menu-vertical .custom-menu-submenu-selected,
  .custom-menu-vertical-left .custom-menu-submenu-selected,
  .custom-menu-vertical-right .custom-menu-submenu-selected {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: none;
  }
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu {
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0 20px;
  }
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item:hover,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu:hover,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item-active,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu-active,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item-open,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu-open,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item-selected,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu-selected {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item:hover::after,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu:hover::after,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item-active::after,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu-active::after,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item-open::after,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu-open::after,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-item-selected::after,
  .custom-menu-horizontal:not(.custom-menu-dark) > .custom-menu-submenu-selected::after {
    border-bottom: 2px solid #1890ff;
    border-bottom: 2px solid var(--custom-primary-color);
  }
  .custom-menu-horizontal > .custom-menu-item,
  .custom-menu-horizontal > .custom-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom;
  }
  .custom-menu-horizontal > .custom-menu-item::after,
  .custom-menu-horizontal > .custom-menu-submenu::after {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }
  .custom-menu-horizontal > .custom-menu-submenu > .custom-menu-submenu-title {
    padding: 0;
  }
  .custom-menu-horizontal > .custom-menu-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-menu-horizontal > .custom-menu-item a:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-horizontal > .custom-menu-item a::before {
    bottom: -2px;
  }
  .custom-menu-horizontal > .custom-menu-item-selected a {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu-horizontal::after {
    display: block;
    clear: both;
    height: 0;
    content: '\20';
  }
  .custom-menu-vertical .custom-menu-item,
  .custom-menu-vertical-left .custom-menu-item,
  .custom-menu-vertical-right .custom-menu-item,
  .custom-menu-inline .custom-menu-item {
    position: relative;
  }
  .custom-menu-vertical .custom-menu-item::after,
  .custom-menu-vertical-left .custom-menu-item::after,
  .custom-menu-vertical-right .custom-menu-item::after,
  .custom-menu-inline .custom-menu-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
    border-right: 3px solid var(--custom-primary-color);
    -webkit-transform: scaleY(0.0001);
            transform: scaleY(0.0001);
    opacity: 0;
    transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
  }
  .custom-menu-vertical .custom-menu-item,
  .custom-menu-vertical-left .custom-menu-item,
  .custom-menu-vertical-right .custom-menu-item,
  .custom-menu-inline .custom-menu-item,
  .custom-menu-vertical .custom-menu-submenu-title,
  .custom-menu-vertical-left .custom-menu-submenu-title,
  .custom-menu-vertical-right .custom-menu-submenu-title,
  .custom-menu-inline .custom-menu-submenu-title {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;
  }
  .custom-menu-vertical .custom-menu-submenu,
  .custom-menu-vertical-left .custom-menu-submenu,
  .custom-menu-vertical-right .custom-menu-submenu,
  .custom-menu-inline .custom-menu-submenu {
    padding-bottom: 0.02px;
  }
  .custom-menu-vertical .custom-menu-item:not(:last-child),
  .custom-menu-vertical-left .custom-menu-item:not(:last-child),
  .custom-menu-vertical-right .custom-menu-item:not(:last-child),
  .custom-menu-inline .custom-menu-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .custom-menu-vertical > .custom-menu-item,
  .custom-menu-vertical-left > .custom-menu-item,
  .custom-menu-vertical-right > .custom-menu-item,
  .custom-menu-inline > .custom-menu-item,
  .custom-menu-vertical > .custom-menu-submenu > .custom-menu-submenu-title,
  .custom-menu-vertical-left > .custom-menu-submenu > .custom-menu-submenu-title,
  .custom-menu-vertical-right > .custom-menu-submenu > .custom-menu-submenu-title,
  .custom-menu-inline > .custom-menu-submenu > .custom-menu-submenu-title {
    height: 40px;
    line-height: 40px;
  }
  .custom-menu-vertical .custom-menu-item-group-list .custom-menu-submenu-title,
  .custom-menu-vertical .custom-menu-submenu-title {
    padding-right: 34px;
  }
  .custom-menu-inline {
    width: 100%;
  }
  .custom-menu-inline .custom-menu-selected::after,
  .custom-menu-inline .custom-menu-item-selected::after {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
    transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-menu-inline .custom-menu-item,
  .custom-menu-inline .custom-menu-submenu-title {
    width: calc(100% + 1px);
  }
  .custom-menu-inline .custom-menu-item-group-list .custom-menu-submenu-title,
  .custom-menu-inline .custom-menu-submenu-title {
    padding-right: 34px;
  }
  .custom-menu-inline.custom-menu-root .custom-menu-item,
  .custom-menu-inline.custom-menu-root .custom-menu-submenu-title {
    display: flex;
    align-items: center;
    transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .custom-menu-inline.custom-menu-root .custom-menu-item > .custom-menu-title-content,
  .custom-menu-inline.custom-menu-root .custom-menu-submenu-title > .custom-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-menu-inline.custom-menu-root .custom-menu-item > *,
  .custom-menu-inline.custom-menu-root .custom-menu-submenu-title > * {
    flex: none;
  }
  .custom-menu.custom-menu-inline-collapsed {
    width: 80px;
  }
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-item,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-submenu > .custom-menu-submenu-title,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-submenu > .custom-menu-submenu-title {
    left: 0;
    padding: 0 calc(50% - 16px / 2);
    text-overflow: clip;
  }
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item .custom-menu-submenu-arrow,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-item .custom-menu-submenu-arrow,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-submenu > .custom-menu-submenu-title .custom-menu-submenu-arrow,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-submenu > .custom-menu-submenu-title .custom-menu-submenu-arrow {
    opacity: 0;
  }
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item .custom-menu-item-icon,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-item .custom-menu-item-icon,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-submenu > .custom-menu-submenu-title .custom-menu-item-icon,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-submenu > .custom-menu-submenu-title .custom-menu-item-icon,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item .anticon,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-item .anticon,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-submenu > .custom-menu-submenu-title .anticon,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-submenu > .custom-menu-submenu-title .anticon {
    margin: 0;
    font-size: 16px;
    line-height: 40px;
  }
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item .custom-menu-item-icon + span,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-item .custom-menu-item-icon + span,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-submenu > .custom-menu-submenu-title .custom-menu-item-icon + span,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-submenu > .custom-menu-submenu-title .custom-menu-item-icon + span,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item .anticon + span,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-item .anticon + span,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-item-group > .custom-menu-item-group-list > .custom-menu-submenu > .custom-menu-submenu-title .anticon + span,
  .custom-menu.custom-menu-inline-collapsed > .custom-menu-submenu > .custom-menu-submenu-title .anticon + span {
    display: inline-block;
    opacity: 0;
  }
  .custom-menu.custom-menu-inline-collapsed .custom-menu-item-icon,
  .custom-menu.custom-menu-inline-collapsed .anticon {
    display: inline-block;
  }
  .custom-menu.custom-menu-inline-collapsed-tooltip {
    pointer-events: none;
  }
  .custom-menu.custom-menu-inline-collapsed-tooltip .custom-menu-item-icon,
  .custom-menu.custom-menu-inline-collapsed-tooltip .anticon {
    display: none;
  }
  .custom-menu.custom-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85);
  }
  .custom-menu.custom-menu-inline-collapsed .custom-menu-item-group-title {
    padding-right: 4px;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  .custom-menu-item-group-list .custom-menu-item,
  .custom-menu-item-group-list .custom-menu-submenu-title {
    padding: 0 16px 0 28px;
  }
  .custom-menu-root.custom-menu-vertical,
  .custom-menu-root.custom-menu-vertical-left,
  .custom-menu-root.custom-menu-vertical-right,
  .custom-menu-root.custom-menu-inline {
    box-shadow: none;
  }
  .custom-menu-root.custom-menu-inline-collapsed .custom-menu-item > .custom-menu-inline-collapsed-noicon,
  .custom-menu-root.custom-menu-inline-collapsed .custom-menu-submenu .custom-menu-submenu-title > .custom-menu-inline-collapsed-noicon {
    font-size: 16px;
    text-align: center;
  }
  .custom-menu-sub.custom-menu-inline {
    padding: 0;
    background: #fafafa;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .custom-menu-sub.custom-menu-inline > .custom-menu-item,
  .custom-menu-sub.custom-menu-inline > .custom-menu-submenu > .custom-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
  }
  .custom-menu-sub.custom-menu-inline .custom-menu-item-group-title {
    padding-left: 32px;
  }
  .custom-menu-item-disabled,
  .custom-menu-submenu-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background: none;
    cursor: not-allowed;
  }
  .custom-menu-item-disabled::after,
  .custom-menu-submenu-disabled::after {
    border-color: transparent !important;
  }
  .custom-menu-item-disabled a,
  .custom-menu-submenu-disabled a {
    color: rgba(0, 0, 0, 0.25) !important;
    pointer-events: none;
  }
  .custom-menu-item-disabled > .custom-menu-submenu-title,
  .custom-menu-submenu-disabled > .custom-menu-submenu-title {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
  }
  .custom-menu-item-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-submenu-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-item-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-submenu-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after {
    background: rgba(0, 0, 0, 0.25) !important;
  }
  .custom-layout-header .custom-menu {
    line-height: inherit;
  }
  .custom-menu-inline-collapsed-tooltip a,
  .custom-menu-inline-collapsed-tooltip a:hover {
    color: #fff;
  }
  .custom-menu-light .custom-menu-item:hover,
  .custom-menu-light .custom-menu-item-active,
  .custom-menu-light .custom-menu:not(.custom-menu-inline) .custom-menu-submenu-open,
  .custom-menu-light .custom-menu-submenu-active,
  .custom-menu-light .custom-menu-submenu-title:hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-menu.custom-menu-root:focus-visible {
    box-shadow: 0 0 0 2px #096dd9;
    box-shadow: 0 0 0 2px var(--custom-primary-7);
  }
  .custom-menu-dark .custom-menu-item:focus-visible,
  .custom-menu-dark .custom-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px #096dd9;
    box-shadow: 0 0 0 2px var(--custom-primary-7);
  }
  .custom-menu.custom-menu-dark,
  .custom-menu-dark .custom-menu-sub,
  .custom-menu.custom-menu-dark .custom-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #001529;
  }
  .custom-menu.custom-menu-dark .custom-menu-submenu-title .custom-menu-submenu-arrow,
  .custom-menu-dark .custom-menu-sub .custom-menu-submenu-title .custom-menu-submenu-arrow,
  .custom-menu.custom-menu-dark .custom-menu-sub .custom-menu-submenu-title .custom-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s;
  }
  .custom-menu.custom-menu-dark .custom-menu-submenu-title .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-sub .custom-menu-submenu-title .custom-menu-submenu-arrow::after,
  .custom-menu.custom-menu-dark .custom-menu-sub .custom-menu-submenu-title .custom-menu-submenu-arrow::after,
  .custom-menu.custom-menu-dark .custom-menu-submenu-title .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-sub .custom-menu-submenu-title .custom-menu-submenu-arrow::before,
  .custom-menu.custom-menu-dark .custom-menu-sub .custom-menu-submenu-title .custom-menu-submenu-arrow::before {
    background: #fff;
  }
  .custom-menu-dark.custom-menu-submenu-popup {
    background: transparent;
  }
  .custom-menu-dark .custom-menu-inline.custom-menu-sub {
    background: #000c17;
  }
  .custom-menu-dark.custom-menu-horizontal {
    border-bottom: 0;
  }
  .custom-menu-dark.custom-menu-horizontal > .custom-menu-item,
  .custom-menu-dark.custom-menu-horizontal > .custom-menu-submenu {
    top: 0;
    margin-top: 0;
    padding: 0 20px;
    border-color: #001529;
    border-bottom: 0;
  }
  .custom-menu-dark.custom-menu-horizontal > .custom-menu-item:hover {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
  }
  .custom-menu-dark.custom-menu-horizontal > .custom-menu-item > a::before {
    bottom: 0;
  }
  .custom-menu-dark .custom-menu-item,
  .custom-menu-dark .custom-menu-item-group-title,
  .custom-menu-dark .custom-menu-item > a,
  .custom-menu-dark .custom-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .custom-menu-dark.custom-menu-inline,
  .custom-menu-dark.custom-menu-vertical,
  .custom-menu-dark.custom-menu-vertical-left,
  .custom-menu-dark.custom-menu-vertical-right {
    border-right: 0;
  }
  .custom-menu-dark.custom-menu-inline .custom-menu-item,
  .custom-menu-dark.custom-menu-vertical .custom-menu-item,
  .custom-menu-dark.custom-menu-vertical-left .custom-menu-item,
  .custom-menu-dark.custom-menu-vertical-right .custom-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .custom-menu-dark.custom-menu-inline .custom-menu-item::after,
  .custom-menu-dark.custom-menu-vertical .custom-menu-item::after,
  .custom-menu-dark.custom-menu-vertical-left .custom-menu-item::after,
  .custom-menu-dark.custom-menu-vertical-right .custom-menu-item::after {
    border-right: 0;
  }
  .custom-menu-dark.custom-menu-inline .custom-menu-item,
  .custom-menu-dark.custom-menu-inline .custom-menu-submenu-title {
    width: 100%;
  }
  .custom-menu-dark .custom-menu-item:hover,
  .custom-menu-dark .custom-menu-item-active,
  .custom-menu-dark .custom-menu-submenu-active,
  .custom-menu-dark .custom-menu-submenu-open,
  .custom-menu-dark .custom-menu-submenu-selected,
  .custom-menu-dark .custom-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent;
  }
  .custom-menu-dark .custom-menu-item:hover > a,
  .custom-menu-dark .custom-menu-item-active > a,
  .custom-menu-dark .custom-menu-submenu-active > a,
  .custom-menu-dark .custom-menu-submenu-open > a,
  .custom-menu-dark .custom-menu-submenu-selected > a,
  .custom-menu-dark .custom-menu-submenu-title:hover > a,
  .custom-menu-dark .custom-menu-item:hover > span > a,
  .custom-menu-dark .custom-menu-item-active > span > a,
  .custom-menu-dark .custom-menu-submenu-active > span > a,
  .custom-menu-dark .custom-menu-submenu-open > span > a,
  .custom-menu-dark .custom-menu-submenu-selected > span > a,
  .custom-menu-dark .custom-menu-submenu-title:hover > span > a {
    color: #fff;
  }
  .custom-menu-dark .custom-menu-item:hover > .custom-menu-submenu-title > .custom-menu-submenu-arrow,
  .custom-menu-dark .custom-menu-item-active > .custom-menu-submenu-title > .custom-menu-submenu-arrow,
  .custom-menu-dark .custom-menu-submenu-active > .custom-menu-submenu-title > .custom-menu-submenu-arrow,
  .custom-menu-dark .custom-menu-submenu-open > .custom-menu-submenu-title > .custom-menu-submenu-arrow,
  .custom-menu-dark .custom-menu-submenu-selected > .custom-menu-submenu-title > .custom-menu-submenu-arrow,
  .custom-menu-dark .custom-menu-submenu-title:hover > .custom-menu-submenu-title > .custom-menu-submenu-arrow {
    opacity: 1;
  }
  .custom-menu-dark .custom-menu-item:hover > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-item-active > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-submenu-active > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-submenu-open > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-submenu-selected > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-submenu-title:hover > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-item:hover > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-item-active > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-submenu-active > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-submenu-open > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-submenu-selected > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-submenu-title:hover > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before {
    background: #fff;
  }
  .custom-menu-dark .custom-menu-item:hover {
    background-color: transparent;
  }
  .custom-menu-dark.custom-menu-dark:not(.custom-menu-horizontal) .custom-menu-item-selected {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
  }
  .custom-menu-dark .custom-menu-item-selected {
    color: #fff;
    border-right: 0;
  }
  .custom-menu-dark .custom-menu-item-selected::after {
    border-right: 0;
  }
  .custom-menu-dark .custom-menu-item-selected > a,
  .custom-menu-dark .custom-menu-item-selected > span > a,
  .custom-menu-dark .custom-menu-item-selected > a:hover,
  .custom-menu-dark .custom-menu-item-selected > span > a:hover {
    color: #fff;
  }
  .custom-menu-dark .custom-menu-item-selected .custom-menu-item-icon,
  .custom-menu-dark .custom-menu-item-selected .anticon {
    color: #fff;
  }
  .custom-menu-dark .custom-menu-item-selected .custom-menu-item-icon + span,
  .custom-menu-dark .custom-menu-item-selected .anticon + span {
    color: #fff;
  }
  .custom-menu.custom-menu-dark .custom-menu-item-selected,
  .custom-menu-submenu-popup.custom-menu-dark .custom-menu-item-selected {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
  }
  .custom-menu-dark .custom-menu-item-disabled,
  .custom-menu-dark .custom-menu-submenu-disabled,
  .custom-menu-dark .custom-menu-item-disabled > a,
  .custom-menu-dark .custom-menu-submenu-disabled > a,
  .custom-menu-dark .custom-menu-item-disabled > span > a,
  .custom-menu-dark .custom-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 0.8;
  }
  .custom-menu-dark .custom-menu-item-disabled > .custom-menu-submenu-title,
  .custom-menu-dark .custom-menu-submenu-disabled > .custom-menu-submenu-title {
    color: rgba(255, 255, 255, 0.35) !important;
  }
  .custom-menu-dark .custom-menu-item-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-submenu-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::before,
  .custom-menu-dark .custom-menu-item-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after,
  .custom-menu-dark .custom-menu-submenu-disabled > .custom-menu-submenu-title > .custom-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.35) !important;
  }
  .custom-menu.custom-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .custom-menu-rtl .custom-menu-item-group-title {
    text-align: right;
  }
  .custom-menu-rtl.custom-menu-inline,
  .custom-menu-rtl.custom-menu-vertical {
    border-right: none;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-menu-rtl.custom-menu-dark.custom-menu-inline,
  .custom-menu-rtl.custom-menu-dark.custom-menu-vertical {
    border-left: none;
  }
  .custom-menu-rtl.custom-menu-vertical.custom-menu-sub > .custom-menu-item,
  .custom-menu-rtl.custom-menu-vertical-left.custom-menu-sub > .custom-menu-item,
  .custom-menu-rtl.custom-menu-vertical-right.custom-menu-sub > .custom-menu-item,
  .custom-menu-rtl.custom-menu-vertical.custom-menu-sub > .custom-menu-submenu,
  .custom-menu-rtl.custom-menu-vertical-left.custom-menu-sub > .custom-menu-submenu,
  .custom-menu-rtl.custom-menu-vertical-right.custom-menu-sub > .custom-menu-submenu {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  .custom-menu-rtl .custom-menu-item .custom-menu-item-icon,
  .custom-menu-rtl .custom-menu-submenu-title .custom-menu-item-icon,
  .custom-menu-rtl .custom-menu-item .anticon,
  .custom-menu-rtl .custom-menu-submenu-title .anticon {
    margin-right: auto;
    margin-left: 10px;
  }
  .custom-menu-rtl .custom-menu-item.custom-menu-item-only-child > .custom-menu-item-icon,
  .custom-menu-rtl .custom-menu-submenu-title.custom-menu-item-only-child > .custom-menu-item-icon,
  .custom-menu-rtl .custom-menu-item.custom-menu-item-only-child > .anticon,
  .custom-menu-rtl .custom-menu-submenu-title.custom-menu-item-only-child > .anticon {
    margin-left: 0;
  }
  .custom-menu-submenu-rtl.custom-menu-submenu-popup {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  .custom-menu-rtl .custom-menu-submenu-vertical > .custom-menu-submenu-title .custom-menu-submenu-arrow,
  .custom-menu-rtl .custom-menu-submenu-vertical-left > .custom-menu-submenu-title .custom-menu-submenu-arrow,
  .custom-menu-rtl .custom-menu-submenu-vertical-right > .custom-menu-submenu-title .custom-menu-submenu-arrow,
  .custom-menu-rtl .custom-menu-submenu-inline > .custom-menu-submenu-title .custom-menu-submenu-arrow {
    right: auto;
    left: 16px;
  }
  .custom-menu-rtl .custom-menu-submenu-vertical > .custom-menu-submenu-title .custom-menu-submenu-arrow::before,
  .custom-menu-rtl .custom-menu-submenu-vertical-left > .custom-menu-submenu-title .custom-menu-submenu-arrow::before,
  .custom-menu-rtl .custom-menu-submenu-vertical-right > .custom-menu-submenu-title .custom-menu-submenu-arrow::before {
    -webkit-transform: rotate(-45deg) translateY(-2px);
            transform: rotate(-45deg) translateY(-2px);
  }
  .custom-menu-rtl .custom-menu-submenu-vertical > .custom-menu-submenu-title .custom-menu-submenu-arrow::after,
  .custom-menu-rtl .custom-menu-submenu-vertical-left > .custom-menu-submenu-title .custom-menu-submenu-arrow::after,
  .custom-menu-rtl .custom-menu-submenu-vertical-right > .custom-menu-submenu-title .custom-menu-submenu-arrow::after {
    -webkit-transform: rotate(45deg) translateY(2px);
            transform: rotate(45deg) translateY(2px);
  }
  .custom-menu-rtl.custom-menu-vertical .custom-menu-item::after,
  .custom-menu-rtl.custom-menu-vertical-left .custom-menu-item::after,
  .custom-menu-rtl.custom-menu-vertical-right .custom-menu-item::after,
  .custom-menu-rtl.custom-menu-inline .custom-menu-item::after {
    right: auto;
    left: 0;
  }
  .custom-menu-rtl.custom-menu-vertical .custom-menu-item,
  .custom-menu-rtl.custom-menu-vertical-left .custom-menu-item,
  .custom-menu-rtl.custom-menu-vertical-right .custom-menu-item,
  .custom-menu-rtl.custom-menu-inline .custom-menu-item,
  .custom-menu-rtl.custom-menu-vertical .custom-menu-submenu-title,
  .custom-menu-rtl.custom-menu-vertical-left .custom-menu-submenu-title,
  .custom-menu-rtl.custom-menu-vertical-right .custom-menu-submenu-title,
  .custom-menu-rtl.custom-menu-inline .custom-menu-submenu-title {
    text-align: right;
  }
  .custom-menu-rtl.custom-menu-inline .custom-menu-submenu-title {
    padding-right: 0;
    padding-left: 34px;
  }
  .custom-menu-rtl.custom-menu-vertical .custom-menu-submenu-title {
    padding-right: 16px;
    padding-left: 34px;
  }
  .custom-menu-rtl.custom-menu-inline-collapsed.custom-menu-vertical .custom-menu-submenu-title {
    padding: 0 calc(50% - 16px / 2);
  }
  .custom-menu-rtl .custom-menu-item-group-list .custom-menu-item,
  .custom-menu-rtl .custom-menu-item-group-list .custom-menu-submenu-title {
    padding: 0 28px 0 16px;
  }
  .custom-menu-sub.custom-menu-inline {
    border: 0;
  }
  .custom-menu-rtl.custom-menu-sub.custom-menu-inline .custom-menu-item-group-title {
    padding-right: 32px;
    padding-left: 0;
  }
  .custom-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 1010;
    width: 100%;
    pointer-events: none;
  }
  .custom-message-notice {
    padding: 8px;
    text-align: center;
  }
  .custom-message-notice-content {
    display: inline-block;
    padding: 10px 16px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all;
  }
  .custom-message-success .anticon {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-message-error .anticon {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-message-warning .anticon {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-message-info .anticon,
  .custom-message-loading .anticon {
    color: #1890ff;
    color: var(--custom-info-color);
  }
  .custom-message .anticon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px;
  }
  .custom-message-notice.custom-move-up-leave.custom-move-up-leave-active {
    -webkit-animation-name: MessageMoveOut;
            animation-name: MessageMoveOut;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
  }
  @-webkit-keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  .custom-message-rtl {
    direction: rtl;
  }
  .custom-message-rtl span {
    direction: rtl;
  }
  .custom-message-rtl .anticon {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    pointer-events: none;
    position: relative;
    top: 100px;
    width: auto;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding-bottom: 24px;
  }
  .custom-modal.custom-zoom-enter,
  .custom-modal.custom-zoom-appear {
    -webkit-transform: none;
            transform: none;
    opacity: 0;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .custom-modal-mask-hidden {
    display: none;
  }
  .custom-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
  }
  .custom-modal-wrap {
    z-index: 1000;
  }
  .custom-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
  }
  .custom-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
  }
  .custom-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  .custom-modal-close-x {
    display: block;
    width: 54px;
    height: 54px;
    font-size: 16px;
    font-style: normal;
    line-height: 54px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .custom-modal-close:focus,
  .custom-modal-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
  .custom-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px 2px 0 0;
  }
  .custom-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .custom-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0 0 2px 2px;
  }
  .custom-modal-footer .custom-btn + .custom-btn:not(.custom-dropdown-trigger) {
    margin-bottom: 0;
    margin-left: 8px;
  }
  .custom-modal-open {
    overflow: hidden;
  }
  .custom-modal-centered {
    text-align: center;
  }
  .custom-modal-centered::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }
  .custom-modal-centered .custom-modal {
    top: 0;
    display: inline-block;
    padding-bottom: 0;
    text-align: left;
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    .custom-modal {
      max-width: calc(100vw - 16px);
      margin: 8px auto;
    }
    .custom-modal-centered .custom-modal {
      flex: 1 1;
    }
  }
  .custom-modal-confirm .custom-modal-header {
    display: none;
  }
  .custom-modal-confirm .custom-modal-body {
    padding: 32px 32px 24px;
  }
  .custom-modal-confirm-body-wrapper::before {
    display: table;
    content: '';
  }
  .custom-modal-confirm-body-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-modal-confirm-body-wrapper::before {
    display: table;
    content: '';
  }
  .custom-modal-confirm-body-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-modal-confirm-body .custom-modal-confirm-title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
  .custom-modal-confirm-body .custom-modal-confirm-content {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
  .custom-modal-confirm-body > .anticon {
    float: left;
    margin-right: 16px;
    font-size: 22px;
  }
  .custom-modal-confirm-body > .anticon + .custom-modal-confirm-title + .custom-modal-confirm-content {
    margin-left: 38px;
  }
  .custom-modal-confirm .custom-modal-confirm-btns {
    margin-top: 24px;
    text-align: right;
  }
  .custom-modal-confirm .custom-modal-confirm-btns .custom-btn + .custom-btn {
    margin-bottom: 0;
    margin-left: 8px;
  }
  .custom-modal-confirm-error .custom-modal-confirm-body > .anticon {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-modal-confirm-warning .custom-modal-confirm-body > .anticon,
  .custom-modal-confirm-confirm .custom-modal-confirm-body > .anticon {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-modal-confirm-info .custom-modal-confirm-body > .anticon {
    color: #1890ff;
    color: var(--custom-info-color);
  }
  .custom-modal-confirm-success .custom-modal-confirm-body > .anticon {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-modal-wrap-rtl {
    direction: rtl;
  }
  .custom-modal-wrap-rtl .custom-modal-close {
    right: auto;
    right: initial;
    left: 0;
  }
  .custom-modal-wrap-rtl .custom-modal-footer {
    text-align: left;
  }
  .custom-modal-wrap-rtl .custom-modal-footer .custom-btn + .custom-btn {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-modal-wrap-rtl .custom-modal-confirm-body {
    direction: rtl;
  }
  .custom-modal-wrap-rtl .custom-modal-confirm-body > .anticon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .custom-modal-wrap-rtl .custom-modal-confirm-body > .anticon + .custom-modal-confirm-title + .custom-modal-confirm-content {
    margin-right: 38px;
    margin-left: 0;
  }
  .custom-modal-wrap-rtl .custom-modal-confirm-btns {
    text-align: left;
  }
  .custom-modal-wrap-rtl .custom-modal-confirm-btns .custom-btn + .custom-btn {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-modal-wrap-rtl.custom-modal-centered .custom-modal {
    text-align: right;
  }
  .custom-notification {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: fixed;
    z-index: 1010;
    margin-right: 24px;
  }
  .custom-notification-close-icon {
    font-size: 14px;
    cursor: pointer;
  }
  .custom-notification-hook-holder {
    position: relative;
  }
  .custom-notification-notice {
    position: relative;
    width: 384px;
    max-width: calc(100vw - 24px * 2);
    margin-bottom: 16px;
    margin-left: auto;
    padding: 16px 24px;
    overflow: hidden;
    line-height: 1.5715;
    word-wrap: break-word;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .custom-notification-top .custom-notification-notice,
  .custom-notification-bottom .custom-notification-notice {
    margin-right: auto;
    margin-left: auto;
  }
  .custom-notification-topLeft .custom-notification-notice,
  .custom-notification-bottomLeft .custom-notification-notice {
    margin-right: auto;
    margin-left: 0;
  }
  .custom-notification-notice-message {
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
  }
  .custom-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(384px - 24px * 2 - 24px - 48px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none;
  }
  .custom-notification-notice-message-single-line-auto-margin::before {
    display: block;
    content: '';
  }
  .custom-notification-notice-description {
    font-size: 14px;
  }
  .custom-notification-notice-closable .custom-notification-notice-message {
    padding-right: 24px;
  }
  .custom-notification-notice-with-icon .custom-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px;
  }
  .custom-notification-notice-with-icon .custom-notification-notice-description {
    margin-left: 48px;
    font-size: 14px;
  }
  .custom-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px;
  }
  .anticon.custom-notification-notice-icon-success {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .anticon.custom-notification-notice-icon-info {
    color: #1890ff;
    color: var(--custom-info-color);
  }
  .anticon.custom-notification-notice-icon-warning {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .anticon.custom-notification-notice-icon-error {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-notification-notice-close {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
  }
  .custom-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.67);
  }
  .custom-notification-notice-btn {
    float: right;
    margin-top: 16px;
  }
  .custom-notification .notification-fade-effect {
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .custom-notification-fade-enter,
  .custom-notification-fade-appear {
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    opacity: 0;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-notification-fade-leave {
    -webkit-animation-duration: 0.24s;
            animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .custom-notification-fade-enter.custom-notification-fade-enter-active,
  .custom-notification-fade-appear.custom-notification-fade-appear-active {
    -webkit-animation-name: NotificationFadeIn;
            animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .custom-notification-fade-leave.custom-notification-fade-leave-active {
    -webkit-animation-name: NotificationFadeOut;
            animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  @-webkit-keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  .custom-notification-rtl {
    direction: rtl;
  }
  .custom-notification-rtl .custom-notification-notice-closable .custom-notification-notice-message {
    padding-right: 0;
    padding-left: 24px;
  }
  .custom-notification-rtl .custom-notification-notice-with-icon .custom-notification-notice-message {
    margin-right: 48px;
    margin-left: 0;
  }
  .custom-notification-rtl .custom-notification-notice-with-icon .custom-notification-notice-description {
    margin-right: 48px;
    margin-left: 0;
  }
  .custom-notification-rtl .custom-notification-notice-icon {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-notification-rtl .custom-notification-notice-close {
    right: auto;
    left: 22px;
  }
  .custom-notification-rtl .custom-notification-notice-btn {
    float: left;
  }
  .custom-notification-top,
  .custom-notification-bottom {
    margin-right: 0;
    margin-left: 0;
  }
  .custom-notification-top .custom-notification-fade-enter.custom-notification-fade-enter-active,
  .custom-notification-top .custom-notification-fade-appear.custom-notification-fade-appear-active {
    -webkit-animation-name: NotificationTopFadeIn;
            animation-name: NotificationTopFadeIn;
  }
  .custom-notification-bottom .custom-notification-fade-enter.custom-notification-fade-enter-active,
  .custom-notification-bottom .custom-notification-fade-appear.custom-notification-fade-appear-active {
    -webkit-animation-name: NotificationBottomFadeIn;
            animation-name: NotificationBottomFadeIn;
  }
  .custom-notification-topLeft,
  .custom-notification-bottomLeft {
    margin-right: 0;
    margin-left: 24px;
  }
  .custom-notification-topLeft .custom-notification-fade-enter.custom-notification-fade-enter-active,
  .custom-notification-bottomLeft .custom-notification-fade-enter.custom-notification-fade-enter-active,
  .custom-notification-topLeft .custom-notification-fade-appear.custom-notification-fade-appear-active,
  .custom-notification-bottomLeft .custom-notification-fade-appear.custom-notification-fade-appear-active {
    -webkit-animation-name: NotificationLeftFadeIn;
            animation-name: NotificationLeftFadeIn;
  }
  @-webkit-keyframes NotificationTopFadeIn {
    0% {
      margin-top: -100%;
      opacity: 0;
    }
    100% {
      margin-top: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationTopFadeIn {
    0% {
      margin-top: -100%;
      opacity: 0;
    }
    100% {
      margin-top: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationBottomFadeIn {
    0% {
      margin-bottom: -100%;
      opacity: 0;
    }
    100% {
      margin-bottom: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationBottomFadeIn {
    0% {
      margin-bottom: -100%;
      opacity: 0;
    }
    100% {
      margin-bottom: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  .custom-page-header {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    padding: 16px 24px;
    background-color: #fff;
  }
  .custom-page-header-ghost {
    background-color: inherit;
  }
  .custom-page-header.has-breadcrumb {
    padding-top: 12px;
  }
  .custom-page-header.has-footer {
    padding-bottom: 0;
  }
  .custom-page-header-back {
    margin-right: 16px;
    font-size: 16px;
    line-height: 1;
  }
  .custom-page-header-back-button {
    color: #1890ff;
    color: var(--custom-primary-color);
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    color: #000;
  }
  .custom-page-header-back-button:focus-visible,
  .custom-page-header-back-button:hover {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
  }
  .custom-page-header-back-button:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
  }
  .custom-page-header .custom-divider-vertical {
    height: 14px;
    margin: 0 12px;
    vertical-align: middle;
  }
  .custom-breadcrumb + .custom-page-header-heading {
    margin-top: 8px;
  }
  .custom-page-header-heading {
    display: flex;
    justify-content: space-between;
  }
  .custom-page-header-heading-left {
    display: flex;
    align-items: center;
    margin: 4px 0;
    overflow: hidden;
  }
  .custom-page-header-heading-title {
    margin-right: 12px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-page-header-heading .custom-avatar {
    margin-right: 12px;
  }
  .custom-page-header-heading-sub-title {
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-page-header-heading-extra {
    margin: 4px 0;
    white-space: nowrap;
  }
  .custom-page-header-heading-extra > * {
    white-space: inherit;
  }
  .custom-page-header-content {
    padding-top: 12px;
  }
  .custom-page-header-footer {
    margin-top: 16px;
  }
  .custom-page-header-footer .custom-tabs > .custom-tabs-nav {
    margin: 0;
  }
  .custom-page-header-footer .custom-tabs > .custom-tabs-nav::before {
    border: none;
  }
  .custom-page-header-footer .custom-tabs .custom-tabs-tab {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
  }
  .custom-page-header-compact .custom-page-header-heading {
    flex-wrap: wrap;
  }
  .custom-page-header-rtl {
    direction: rtl;
  }
  .custom-page-header-rtl .custom-page-header-back {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .custom-page-header-rtl .custom-page-header-heading-title {
    margin-right: 0;
    margin-left: 12px;
  }
  .custom-page-header-rtl .custom-page-header-heading .custom-avatar {
    margin-right: 0;
    margin-left: 12px;
  }
  .custom-page-header-rtl .custom-page-header-heading-sub-title {
    float: right;
    margin-right: 0;
    margin-left: 12px;
  }
  .custom-page-header-rtl .custom-page-header-heading-tags {
    float: right;
  }
  .custom-page-header-rtl .custom-page-header-heading-extra {
    float: left;
  }
  .custom-page-header-rtl .custom-page-header-heading-extra > * {
    margin-right: 12px;
    margin-left: 0;
  }
  .custom-page-header-rtl .custom-page-header-heading-extra > *:first-child {
    margin-right: 0;
  }
  .custom-page-header-rtl .custom-page-header-footer .custom-tabs-bar .custom-tabs-nav {
    float: right;
  }
  .custom-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
  }
  .custom-pagination ul,
  .custom-pagination ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .custom-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  }
  .custom-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
  }
  .custom-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.85);
    transition: none;
  }
  .custom-pagination-item a:hover {
    text-decoration: none;
  }
  .custom-pagination-item:hover {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
    transition: all 0.3s;
  }
  .custom-pagination-item:hover a {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-pagination-item:focus-visible {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
    transition: all 0.3s;
  }
  .custom-pagination-item:focus-visible a {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-pagination-item-active a {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-pagination-item-active:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
  }
  .custom-pagination-item-active:focus-visible {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
  }
  .custom-pagination-item-active:hover a {
    color: #40a9ff;
    color: var(--custom-primary-5);
  }
  .custom-pagination-item-active:focus-visible a {
    color: #40a9ff;
    color: var(--custom-primary-5);
  }
  .custom-pagination-jump-prev,
  .custom-pagination-jump-next {
    outline: 0;
  }
  .custom-pagination-jump-prev .custom-pagination-item-container,
  .custom-pagination-jump-next .custom-pagination-item-container {
    position: relative;
  }
  .custom-pagination-jump-prev .custom-pagination-item-container .custom-pagination-item-link-icon,
  .custom-pagination-jump-next .custom-pagination-item-container .custom-pagination-item-link-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
    font-size: 12px;
    letter-spacing: -1px;
    opacity: 0;
    transition: all 0.2s;
  }
  .custom-pagination-jump-prev .custom-pagination-item-container .custom-pagination-item-link-icon-svg,
  .custom-pagination-jump-next .custom-pagination-item-container .custom-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .custom-pagination-jump-prev .custom-pagination-item-container .custom-pagination-item-ellipsis,
  .custom-pagination-jump-next .custom-pagination-item-container .custom-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    color: rgba(0, 0, 0, 0.25);
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-align: center;
    text-indent: 0.13em;
    opacity: 1;
    transition: all 0.2s;
  }
  .custom-pagination-jump-prev:hover .custom-pagination-item-link-icon,
  .custom-pagination-jump-next:hover .custom-pagination-item-link-icon {
    opacity: 1;
  }
  .custom-pagination-jump-prev:hover .custom-pagination-item-ellipsis,
  .custom-pagination-jump-next:hover .custom-pagination-item-ellipsis {
    opacity: 0;
  }
  .custom-pagination-jump-prev:focus-visible .custom-pagination-item-link-icon,
  .custom-pagination-jump-next:focus-visible .custom-pagination-item-link-icon {
    opacity: 1;
  }
  .custom-pagination-jump-prev:focus-visible .custom-pagination-item-ellipsis,
  .custom-pagination-jump-next:focus-visible .custom-pagination-item-ellipsis {
    opacity: 0;
  }
  .custom-pagination-prev,
  .custom-pagination-jump-prev,
  .custom-pagination-jump-next {
    margin-right: 8px;
  }
  .custom-pagination-prev,
  .custom-pagination-next,
  .custom-pagination-jump-prev,
  .custom-pagination-jump-next {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-pagination-prev,
  .custom-pagination-next {
    font-family: Arial, Helvetica, sans-serif;
    outline: 0;
  }
  .custom-pagination-prev button,
  .custom-pagination-next button {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-pagination-prev:hover button,
  .custom-pagination-next:hover button {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
  }
  .custom-pagination-prev .custom-pagination-item-link,
  .custom-pagination-next .custom-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
  }
  .custom-pagination-prev:focus-visible .custom-pagination-item-link,
  .custom-pagination-next:focus-visible .custom-pagination-item-link {
    color: #1890ff;
    color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-pagination-prev:hover .custom-pagination-item-link,
  .custom-pagination-next:hover .custom-pagination-item-link {
    color: #1890ff;
    color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-pagination-disabled,
  .custom-pagination-disabled:hover {
    cursor: not-allowed;
  }
  .custom-pagination-disabled .custom-pagination-item-link,
  .custom-pagination-disabled:hover .custom-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-pagination-disabled:focus-visible {
    cursor: not-allowed;
  }
  .custom-pagination-disabled:focus-visible .custom-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-pagination-slash {
    margin: 0 10px 0 5px;
  }
  .custom-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
  }
  @media all and (-ms-high-contrast: none) {
    .custom-pagination-options *::-ms-backdrop,
    .custom-pagination-options {
      vertical-align: top;
    }
  }
  .custom-pagination-options-size-changer.custom-select {
    display: inline-block;
    width: auto;
  }
  .custom-pagination-options-quick-jumper {
    display: inline-block;
    height: 32px;
    margin-left: 8px;
    line-height: 32px;
    vertical-align: top;
  }
  .custom-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    width: 50px;
    height: 32px;
    margin: 0 8px;
  }
  .custom-pagination-options-quick-jumper input::-webkit-input-placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-pagination-options-quick-jumper input::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .custom-pagination-options-quick-jumper input:hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-pagination-options-quick-jumper input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-pagination-options-quick-jumper input:focus,
  .custom-pagination-options-quick-jumper input-focused {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-pagination-options-quick-jumper input:focus,
  .custom-input-rtl .custom-pagination-options-quick-jumper input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-pagination-options-quick-jumper input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-pagination-options-quick-jumper input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-pagination-options-quick-jumper input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  .custom-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .custom-pagination-options-quick-jumper input-borderless,
  .custom-pagination-options-quick-jumper input-borderless:hover,
  .custom-pagination-options-quick-jumper input-borderless:focus,
  .custom-pagination-options-quick-jumper input-borderless-focused,
  .custom-pagination-options-quick-jumper input-borderless-disabled,
  .custom-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.custom-pagination-options-quick-jumper input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .custom-pagination-options-quick-jumper input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .custom-pagination-options-quick-jumper input-sm {
    padding: 0px 7px;
  }
  .custom-pagination-options-quick-jumper input-rtl {
    direction: rtl;
  }
  .custom-pagination-simple .custom-pagination-prev,
  .custom-pagination-simple .custom-pagination-next {
    height: 24px;
    line-height: 24px;
    vertical-align: top;
  }
  .custom-pagination-simple .custom-pagination-prev .custom-pagination-item-link,
  .custom-pagination-simple .custom-pagination-next .custom-pagination-item-link {
    height: 24px;
    background-color: transparent;
    border: 0;
  }
  .custom-pagination-simple .custom-pagination-prev .custom-pagination-item-link::after,
  .custom-pagination-simple .custom-pagination-next .custom-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .custom-pagination-simple .custom-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
  .custom-pagination-simple .custom-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: border-color 0.3s;
  }
  .custom-pagination-simple .custom-pagination-simple-pager input:hover {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-pagination-simple .custom-pagination-simple-pager input:focus {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
  }
  .custom-pagination-simple .custom-pagination-simple-pager input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-total-text,
  .custom-pagination.custom-pagination-mini .custom-pagination-simple-pager {
    height: 24px;
    line-height: 24px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-item:not(.custom-pagination-item-active) {
    background: transparent;
    border-color: transparent;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-prev,
  .custom-pagination.custom-pagination-mini .custom-pagination-next {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-prev .custom-pagination-item-link,
  .custom-pagination.custom-pagination-mini .custom-pagination-next .custom-pagination-item-link {
    background: transparent;
    border-color: transparent;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-prev .custom-pagination-item-link::after,
  .custom-pagination.custom-pagination-mini .custom-pagination-next .custom-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-jump-prev,
  .custom-pagination.custom-pagination-mini .custom-pagination-jump-next {
    height: 24px;
    margin-right: 0;
    line-height: 24px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-options {
    margin-left: 2px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-options-size-changer {
    top: 0px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px;
  }
  .custom-pagination.custom-pagination-mini .custom-pagination-options-quick-jumper input {
    padding: 0px 7px;
    width: 44px;
    height: 24px;
  }
  .custom-pagination.custom-pagination-disabled {
    cursor: not-allowed;
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-item {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-item a {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border: none;
    cursor: not-allowed;
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-item-active {
    background: #e6e6e6;
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-item-active a {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-pagination-simple.custom-pagination.custom-pagination-disabled .custom-pagination-item-link {
    background: transparent;
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-item-link-icon {
    opacity: 0;
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-item-ellipsis {
    opacity: 1;
  }
  .custom-pagination.custom-pagination-disabled .custom-pagination-simple-pager {
    color: rgba(0, 0, 0, 0.25);
  }
  @media only screen and (max-width: 992px) {
    .custom-pagination-item-after-jump-prev,
    .custom-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .custom-pagination-options {
      display: none;
    }
  }
  .custom-pagination-rtl .custom-pagination-total-text {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-pagination-rtl .custom-pagination-item,
  .custom-pagination-rtl .custom-pagination-prev,
  .custom-pagination-rtl .custom-pagination-jump-prev,
  .custom-pagination-rtl .custom-pagination-jump-next {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-pagination-rtl .custom-pagination-slash {
    margin: 0 5px 0 10px;
  }
  .custom-pagination-rtl .custom-pagination-options {
    margin-right: 16px;
    margin-left: 0;
  }
  .custom-pagination-rtl .custom-pagination-options .custom-pagination-options-size-changer.custom-select {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-pagination-rtl .custom-pagination-options .custom-pagination-options-quick-jumper {
    margin-left: 0;
  }
  .custom-pagination-rtl.custom-pagination-simple .custom-pagination-simple-pager {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-pagination-rtl.custom-pagination-simple .custom-pagination-simple-pager input {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-pagination-rtl.custom-pagination.mini .custom-pagination-options {
    margin-right: 2px;
    margin-left: 0;
  }
  .custom-popconfirm {
    z-index: 1060;
  }
  .custom-popover {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
            user-select: text;
  }
  .custom-popover-content {
    position: relative;
  }
  .custom-popover::after {
    position: absolute;
    background: rgba(255, 255, 255, 0.01);
    content: '';
  }
  .custom-popover-hidden {
    display: none;
  }
  .custom-popover-placement-top,
  .custom-popover-placement-topLeft,
  .custom-popover-placement-topRight {
    padding-bottom: 15.3137085px;
  }
  .custom-popover-placement-right,
  .custom-popover-placement-rightTop,
  .custom-popover-placement-rightBottom {
    padding-left: 15.3137085px;
  }
  .custom-popover-placement-bottom,
  .custom-popover-placement-bottomLeft,
  .custom-popover-placement-bottomRight {
    padding-top: 15.3137085px;
  }
  .custom-popover-placement-left,
  .custom-popover-placement-leftTop,
  .custom-popover-placement-leftBottom {
    padding-right: 15.3137085px;
  }
  .custom-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .custom-popover {
      /* IE10+ */
    }
    .custom-popover-inner {
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
  }
  .custom-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-popover-message {
    position: relative;
    padding: 4px 0 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
  .custom-popover-message > .anticon {
    position: absolute;
    top: 8.0005px;
    color: #faad14;
    color: var(--custom-warning-color);
    font-size: 14px;
  }
  .custom-popover-message-title {
    padding-left: 22px;
  }
  .custom-popover-buttons {
    margin-bottom: 4px;
    text-align: right;
  }
  .custom-popover-buttons button:not(:first-child) {
    margin-left: 8px;
  }
  .custom-popover-arrow {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
  }
  .custom-popover-arrow-content {
    --antd-arrow-background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 11.3137085px;
    height: 11.3137085px;
    margin: auto;
    content: '';
    pointer-events: auto;
    border-radius: 0 0 2px;
    pointer-events: none;
  }
  .custom-popover-arrow-content::before {
    position: absolute;
    top: -11.3137085px;
    left: -11.3137085px;
    width: 33.9411255px;
    height: 33.9411255px;
    background: var(--antd-arrow-background-color);
    background-repeat: no-repeat;
    background-position: -10px -10px;
    content: '';
    -webkit-clip-path: inset(33% 33%);
            clip-path: inset(33% 33%);
    -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
            clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
  }
  .custom-popover-placement-top .custom-popover-arrow,
  .custom-popover-placement-topLeft .custom-popover-arrow,
  .custom-popover-placement-topRight .custom-popover-arrow {
    bottom: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .custom-popover-placement-top .custom-popover-arrow-content,
  .custom-popover-placement-topLeft .custom-popover-arrow-content,
  .custom-popover-placement-topRight .custom-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(-11px) rotate(45deg);
            transform: translateY(-11px) rotate(45deg);
  }
  .custom-popover-placement-top .custom-popover-arrow {
    left: 50%;
    -webkit-transform: translateY(100%) translateX(-50%);
            transform: translateY(100%) translateX(-50%);
  }
  .custom-popover-placement-topLeft .custom-popover-arrow {
    left: 16px;
  }
  .custom-popover-placement-topRight .custom-popover-arrow {
    right: 16px;
  }
  .custom-popover-placement-right .custom-popover-arrow,
  .custom-popover-placement-rightTop .custom-popover-arrow,
  .custom-popover-placement-rightBottom .custom-popover-arrow {
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .custom-popover-placement-right .custom-popover-arrow-content,
  .custom-popover-placement-rightTop .custom-popover-arrow-content,
  .custom-popover-placement-rightBottom .custom-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(11px) rotate(135deg);
            transform: translateX(11px) rotate(135deg);
  }
  .custom-popover-placement-right .custom-popover-arrow {
    top: 50%;
    -webkit-transform: translateX(-100%) translateY(-50%);
            transform: translateX(-100%) translateY(-50%);
  }
  .custom-popover-placement-rightTop .custom-popover-arrow {
    top: 12px;
  }
  .custom-popover-placement-rightBottom .custom-popover-arrow {
    bottom: 12px;
  }
  .custom-popover-placement-bottom .custom-popover-arrow,
  .custom-popover-placement-bottomLeft .custom-popover-arrow,
  .custom-popover-placement-bottomRight .custom-popover-arrow {
    top: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  .custom-popover-placement-bottom .custom-popover-arrow-content,
  .custom-popover-placement-bottomLeft .custom-popover-arrow-content,
  .custom-popover-placement-bottomRight .custom-popover-arrow-content {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
    -webkit-transform: translateY(11px) rotate(-135deg);
            transform: translateY(11px) rotate(-135deg);
  }
  .custom-popover-placement-bottom .custom-popover-arrow {
    left: 50%;
    -webkit-transform: translateY(-100%) translateX(-50%);
            transform: translateY(-100%) translateX(-50%);
  }
  .custom-popover-placement-bottomLeft .custom-popover-arrow {
    left: 16px;
  }
  .custom-popover-placement-bottomRight .custom-popover-arrow {
    right: 16px;
  }
  .custom-popover-placement-left .custom-popover-arrow,
  .custom-popover-placement-leftTop .custom-popover-arrow,
  .custom-popover-placement-leftBottom .custom-popover-arrow {
    right: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .custom-popover-placement-left .custom-popover-arrow-content,
  .custom-popover-placement-leftTop .custom-popover-arrow-content,
  .custom-popover-placement-leftBottom .custom-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(-11px) rotate(-45deg);
            transform: translateX(-11px) rotate(-45deg);
  }
  .custom-popover-placement-left .custom-popover-arrow {
    top: 50%;
    -webkit-transform: translateX(100%) translateY(-50%);
            transform: translateX(100%) translateY(-50%);
  }
  .custom-popover-placement-leftTop .custom-popover-arrow {
    top: 12px;
  }
  .custom-popover-placement-leftBottom .custom-popover-arrow {
    bottom: 12px;
  }
  .custom-popover-pink .custom-popover-inner {
    background-color: #eb2f96;
  }
  .custom-popover-pink .custom-popover-arrow-content {
    background-color: #eb2f96;
  }
  .custom-popover-magenta .custom-popover-inner {
    background-color: #eb2f96;
  }
  .custom-popover-magenta .custom-popover-arrow-content {
    background-color: #eb2f96;
  }
  .custom-popover-red .custom-popover-inner {
    background-color: #f5222d;
  }
  .custom-popover-red .custom-popover-arrow-content {
    background-color: #f5222d;
  }
  .custom-popover-volcano .custom-popover-inner {
    background-color: #fa541c;
  }
  .custom-popover-volcano .custom-popover-arrow-content {
    background-color: #fa541c;
  }
  .custom-popover-orange .custom-popover-inner {
    background-color: #fa8c16;
  }
  .custom-popover-orange .custom-popover-arrow-content {
    background-color: #fa8c16;
  }
  .custom-popover-yellow .custom-popover-inner {
    background-color: #fadb14;
  }
  .custom-popover-yellow .custom-popover-arrow-content {
    background-color: #fadb14;
  }
  .custom-popover-gold .custom-popover-inner {
    background-color: #faad14;
  }
  .custom-popover-gold .custom-popover-arrow-content {
    background-color: #faad14;
  }
  .custom-popover-cyan .custom-popover-inner {
    background-color: #13c2c2;
  }
  .custom-popover-cyan .custom-popover-arrow-content {
    background-color: #13c2c2;
  }
  .custom-popover-lime .custom-popover-inner {
    background-color: #a0d911;
  }
  .custom-popover-lime .custom-popover-arrow-content {
    background-color: #a0d911;
  }
  .custom-popover-green .custom-popover-inner {
    background-color: #52c41a;
  }
  .custom-popover-green .custom-popover-arrow-content {
    background-color: #52c41a;
  }
  .custom-popover-blue .custom-popover-inner {
    background-color: #1890ff;
  }
  .custom-popover-blue .custom-popover-arrow-content {
    background-color: #1890ff;
  }
  .custom-popover-geekblue .custom-popover-inner {
    background-color: #2f54eb;
  }
  .custom-popover-geekblue .custom-popover-arrow-content {
    background-color: #2f54eb;
  }
  .custom-popover-purple .custom-popover-inner {
    background-color: #722ed1;
  }
  .custom-popover-purple .custom-popover-arrow-content {
    background-color: #722ed1;
  }
  .custom-popover-rtl {
    direction: rtl;
    text-align: right;
  }
  .custom-popover-rtl .custom-popover-message-title {
    padding-right: 22px;
    padding-left: 16px;
  }
  .custom-popover-rtl .custom-popover-buttons {
    text-align: left;
  }
  .custom-popover-rtl .custom-popover-buttons button {
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-progress {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
  }
  .custom-progress-line {
    position: relative;
    width: 100%;
    font-size: 14px;
  }
  .custom-progress-steps {
    display: inline-block;
  }
  .custom-progress-steps-outer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .custom-progress-steps-item {
    flex-shrink: 0;
    min-width: 2px;
    margin-right: 2px;
    background: #f3f3f3;
    transition: all 0.3s;
  }
  .custom-progress-steps-item-active {
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-progress-small.custom-progress-line,
  .custom-progress-small.custom-progress-line .custom-progress-text .anticon {
    font-size: 12px;
  }
  .custom-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
  .custom-progress-show-info .custom-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em + 8px);
  }
  .custom-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 100px;
  }
  .custom-progress-circle-trail {
    stroke: rgba(0, 0, 0, 0.04);
  }
  .custom-progress-circle-path {
    -webkit-animation: custom-progress-appear 0.3s;
            animation: custom-progress-appear 0.3s;
  }
  .custom-progress-inner:not(.custom-progress-circle-gradient) .custom-progress-circle-path {
    stroke: #1890ff;
    stroke: var(--custom-primary-color);
  }
  .custom-progress-success-bg,
  .custom-progress-bg {
    position: relative;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  }
  .custom-progress-success-bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #52c41a;
    background-color: var(--custom-success-color);
  }
  .custom-progress-text {
    display: inline-block;
    width: 2em;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal;
  }
  .custom-progress-text .anticon {
    font-size: 14px;
  }
  .custom-progress-status-active .custom-progress-bg::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 10px;
    opacity: 0;
    -webkit-animation: custom-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
            animation: custom-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    content: '';
  }
  .custom-progress-status-exception .custom-progress-bg {
    background-color: #ff4d4f;
    background-color: var(--custom-error-color);
  }
  .custom-progress-status-exception .custom-progress-text {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-progress-status-exception .custom-progress-inner:not(.custom-progress-circle-gradient) .custom-progress-circle-path {
    stroke: #ff4d4f;
    stroke: var(--custom-error-color);
  }
  .custom-progress-status-success .custom-progress-bg {
    background-color: #52c41a;
    background-color: var(--custom-success-color);
  }
  .custom-progress-status-success .custom-progress-text {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-progress-status-success .custom-progress-inner:not(.custom-progress-circle-gradient) .custom-progress-circle-path {
    stroke: #52c41a;
    stroke: var(--custom-success-color);
  }
  .custom-progress-circle .custom-progress-inner {
    position: relative;
    line-height: 1;
    background-color: transparent;
  }
  .custom-progress-circle .custom-progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1em;
    line-height: 1;
    white-space: normal;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .custom-progress-circle .custom-progress-text .anticon {
    font-size: 1.16666667em;
  }
  .custom-progress-circle.custom-progress-status-exception .custom-progress-text {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-progress-circle.custom-progress-status-success .custom-progress-text {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  @-webkit-keyframes custom-progress-active {
    0% {
      -webkit-transform: translateX(-100%) scaleX(0);
              transform: translateX(-100%) scaleX(0);
      opacity: 0.1;
    }
    20% {
      -webkit-transform: translateX(-100%) scaleX(0);
              transform: translateX(-100%) scaleX(0);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translateX(0) scaleX(1);
              transform: translateX(0) scaleX(1);
      opacity: 0;
    }
  }
  @keyframes custom-progress-active {
    0% {
      -webkit-transform: translateX(-100%) scaleX(0);
              transform: translateX(-100%) scaleX(0);
      opacity: 0.1;
    }
    20% {
      -webkit-transform: translateX(-100%) scaleX(0);
              transform: translateX(-100%) scaleX(0);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translateX(0) scaleX(1);
              transform: translateX(0) scaleX(1);
      opacity: 0;
    }
  }
  .custom-progress-rtl {
    direction: rtl;
  }
  .custom-progress-rtl.custom-progress-show-info .custom-progress-outer {
    margin-right: 0;
    margin-left: calc(-2em - 8px);
    padding-right: 0;
    padding-left: calc(2em + 8px);
  }
  .custom-progress-rtl .custom-progress-success-bg {
    right: 0;
    left: auto;
  }
  .custom-progress-rtl.custom-progress-line .custom-progress-text,
  .custom-progress-rtl.custom-progress-steps .custom-progress-text {
    margin-right: 8px;
    margin-left: 0;
    text-align: right;
  }
  .custom-radio-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    font-size: 0;
  }
  .custom-radio-group .custom-badge-count {
    z-index: 1;
  }
  .custom-radio-group > .custom-badge:not(:first-child) > .custom-radio-button-wrapper {
    border-left: none;
  }
  .custom-radio-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-flex;
    align-items: baseline;
    margin-right: 8px;
    cursor: pointer;
  }
  .custom-radio-wrapper-disabled {
    cursor: not-allowed;
  }
  .custom-radio-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
  }
  .custom-radio-wrapper.custom-radio-wrapper-in-form-item input[type='radio'] {
    width: 14px;
    height: 14px;
  }
  .custom-radio {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0.2em;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }
  .custom-radio-wrapper:hover .custom-radio,
  .custom-radio:hover .custom-radio-inner,
  .custom-radio-input:focus + .custom-radio-inner {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-radio-input:focus + .custom-radio-inner {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
    box-shadow: 0 0 0 3px var(--custom-primary-color-deprecated-f-12);
  }
  .custom-radio-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    border-radius: 50%;
    visibility: hidden;
    -webkit-animation: antRadioEffect 0.36s ease-in-out;
            animation: antRadioEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    content: '';
  }
  .custom-radio:hover::after,
  .custom-radio-wrapper:hover .custom-radio::after {
    visibility: visible;
  }
  .custom-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: all 0.3s;
  }
  .custom-radio-inner::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-top: 0;
    border-left: 0;
    border-radius: 16px;
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
  }
  .custom-radio-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }
  .custom-radio.custom-radio-disabled .custom-radio-inner {
    border-color: #d9d9d9;
  }
  .custom-radio-checked .custom-radio-inner {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-radio-checked .custom-radio-inner::after {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-radio-disabled {
    cursor: not-allowed;
  }
  .custom-radio-disabled .custom-radio-inner {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  .custom-radio-disabled .custom-radio-inner::after {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .custom-radio-disabled .custom-radio-input {
    cursor: not-allowed;
  }
  .custom-radio-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  span.custom-radio + * {
    padding-right: 8px;
    padding-left: 8px;
  }
  .custom-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 30px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-left-width: 0;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  }
  .custom-radio-button-wrapper a {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-radio-button-wrapper > .custom-radio-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .custom-radio-group-large .custom-radio-button-wrapper {
    height: 40px;
    font-size: 16px;
    line-height: 38px;
  }
  .custom-radio-group-small .custom-radio-button-wrapper {
    height: 24px;
    padding: 0 7px;
    line-height: 22px;
  }
  .custom-radio-button-wrapper:not(:first-child)::before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    box-sizing: content-box;
    width: 1px;
    height: 100%;
    padding: 1px 0;
    background-color: #d9d9d9;
    transition: background-color 0.3s;
    content: '';
  }
  .custom-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
  }
  .custom-radio-button-wrapper:last-child {
    border-radius: 0 2px 2px 0;
  }
  .custom-radio-button-wrapper:first-child:last-child {
    border-radius: 2px;
  }
  .custom-radio-button-wrapper:hover {
    position: relative;
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-radio-button-wrapper:focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
    box-shadow: 0 0 0 3px var(--custom-primary-color-deprecated-f-12);
  }
  .custom-radio-button-wrapper .custom-radio-inner,
  .custom-radio-button-wrapper input[type='checkbox'],
  .custom-radio-button-wrapper input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    color: var(--custom-primary-color);
    background: #fff;
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled)::before {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):first-child {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):hover {
    color: #40a9ff;
    color: var(--custom-primary-5);
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):hover::before {
    background-color: #40a9ff;
    background-color: var(--custom-primary-5);
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):active {
    color: #096dd9;
    color: var(--custom-primary-7);
    border-color: #096dd9;
    border-color: var(--custom-primary-7);
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):active::before {
    background-color: #096dd9;
    background-color: var(--custom-primary-7);
  }
  .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
    box-shadow: 0 0 0 3px var(--custom-primary-color-deprecated-f-12);
  }
  .custom-radio-group-solid .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled) {
    color: #fff;
    background: #1890ff;
    background: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-radio-group-solid .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #40a9ff;
    background: var(--custom-primary-5);
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
  }
  .custom-radio-group-solid .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):active {
    color: #fff;
    background: #096dd9;
    background: var(--custom-primary-7);
    border-color: #096dd9;
    border-color: var(--custom-primary-7);
  }
  .custom-radio-group-solid .custom-radio-button-wrapper-checked:not(.custom-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
    box-shadow: 0 0 0 3px var(--custom-primary-color-deprecated-f-12);
  }
  .custom-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-radio-button-wrapper-disabled:first-child,
  .custom-radio-button-wrapper-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
  .custom-radio-button-wrapper-disabled:first-child {
    border-left-color: #d9d9d9;
  }
  .custom-radio-button-wrapper-disabled.custom-radio-button-wrapper-checked {
    color: rgba(0, 0, 0, 0.25);
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    box-shadow: none;
  }
  @-webkit-keyframes antRadioEffect {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1.6);
              transform: scale(1.6);
      opacity: 0;
    }
  }
  @keyframes antRadioEffect {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1.6);
              transform: scale(1.6);
      opacity: 0;
    }
  }
  .custom-radio-group.custom-radio-group-rtl {
    direction: rtl;
  }
  .custom-radio-wrapper.custom-radio-wrapper-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl;
  }
  .custom-radio-button-wrapper.custom-radio-button-wrapper-rtl {
    border-right-width: 0;
    border-left-width: 1px;
  }
  .custom-radio-button-wrapper.custom-radio-button-wrapper-rtl.custom-radio-button-wrapper:not(:first-child)::before {
    right: -1px;
    left: 0;
  }
  .custom-radio-button-wrapper.custom-radio-button-wrapper-rtl.custom-radio-button-wrapper:first-child {
    border-right: 1px solid #d9d9d9;
    border-radius: 0 2px 2px 0;
  }
  .custom-radio-button-wrapper-checked:not([class*=' custom-radio-button-wrapper-disabled']).custom-radio-button-wrapper:first-child {
    border-right-color: #40a9ff;
    border-right-color: var(--custom-primary-5);
  }
  .custom-radio-button-wrapper.custom-radio-button-wrapper-rtl.custom-radio-button-wrapper:last-child {
    border-radius: 2px 0 0 2px;
  }
  .custom-radio-button-wrapper.custom-radio-button-wrapper-rtl.custom-radio-button-wrapper-disabled:first-child {
    border-right-color: #d9d9d9;
  }
  .custom-rate {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    margin: 0;
    padding: 0;
    color: #fadb14;
    font-size: 20px;
    line-height: inherit;
    list-style: none;
    outline: none;
  }
  .custom-rate-disabled .custom-rate-star {
    cursor: default;
  }
  .custom-rate-disabled .custom-rate-star > div:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .custom-rate-star {
    position: relative;
    display: inline-block;
    color: inherit;
    cursor: pointer;
  }
  .custom-rate-star:not(:last-child) {
    margin-right: 8px;
  }
  .custom-rate-star > div {
    transition: all 0.3s, outline 0s;
  }
  .custom-rate-star > div:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .custom-rate-star > div:focus {
    outline: 0;
  }
  .custom-rate-star > div:focus-visible {
    outline: 1px dashed #fadb14;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .custom-rate-star-first,
  .custom-rate-star-second {
    color: rgba(0, 0, 0, 0.06);
    transition: all 0.3s;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-rate-star-first .anticon,
  .custom-rate-star-second .anticon {
    vertical-align: middle;
  }
  .custom-rate-star-first {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
  }
  .custom-rate-star-half .custom-rate-star-first,
  .custom-rate-star-half .custom-rate-star-second {
    opacity: 1;
  }
  .custom-rate-star-half .custom-rate-star-first,
  .custom-rate-star-full .custom-rate-star-second {
    color: inherit;
  }
  .custom-rate-text {
    display: inline-block;
    margin: 0 8px;
    font-size: 14px;
  }
  .custom-rate-rtl {
    direction: rtl;
  }
  .custom-rate-rtl .custom-rate-star:not(:last-child) {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-rate-rtl .custom-rate-star-first {
    right: 0;
    left: auto;
  }
  .custom-result {
    padding: 48px 32px;
  }
  .custom-result-success .custom-result-icon > .anticon {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-result-error .custom-result-icon > .anticon {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-result-info .custom-result-icon > .anticon {
    color: #1890ff;
    color: var(--custom-info-color);
  }
  .custom-result-warning .custom-result-icon > .anticon {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-result-image {
    width: 250px;
    height: 295px;
    margin: auto;
  }
  .custom-result-icon {
    margin-bottom: 24px;
    text-align: center;
  }
  .custom-result-icon > .anticon {
    font-size: 72px;
  }
  .custom-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }
  .custom-result-subtitle {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }
  .custom-result-extra {
    margin: 24px 0 0 0;
    text-align: center;
  }
  .custom-result-extra > * {
    margin-right: 8px;
  }
  .custom-result-extra > *:last-child {
    margin-right: 0;
  }
  .custom-result-content {
    margin-top: 24px;
    padding: 24px 40px;
    background-color: #fafafa;
  }
  .custom-result-rtl {
    direction: rtl;
  }
  .custom-result-rtl .custom-result-extra > * {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-result-rtl .custom-result-extra > *:last-child {
    margin-left: 0;
  }
  .segmented-disabled-item,
  .segmented-disabled-item:hover,
  .segmented-disabled-item:focus {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .segmented-item-selected {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  }
  .segmented-text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .custom-segmented {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    padding: 2px;
    color: rgba(0, 0, 0, 0.65);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-segmented-group {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-items: flex-start;
    width: 100%;
  }
  .custom-segmented.custom-segmented-block {
    display: flex;
  }
  .custom-segmented.custom-segmented-block .custom-segmented-item {
    flex: 1 1;
    min-width: 0;
  }
  .custom-segmented:not(.custom-segmented-disabled):hover,
  .custom-segmented:not(.custom-segmented-disabled):focus {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-segmented-item {
    position: relative;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-segmented-item-selected {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
    color: #262626;
  }
  .custom-segmented-item:hover,
  .custom-segmented-item:focus {
    color: #262626;
  }
  .custom-segmented-item-label {
    min-height: 28px;
    padding: 0 11px;
    line-height: 28px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .custom-segmented-item-icon + * {
    margin-left: 6px;
  }
  .custom-segmented-item-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
  .custom-segmented.custom-segmented-lg .custom-segmented-item-label {
    min-height: 36px;
    padding: 0 11px;
    font-size: 16px;
    line-height: 36px;
  }
  .custom-segmented.custom-segmented-sm .custom-segmented-item-label {
    min-height: 20px;
    padding: 0 7px;
    line-height: 20px;
  }
  .custom-segmented-item-disabled,
  .custom-segmented-item-disabled:hover,
  .custom-segmented-item-disabled:focus {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-segmented-thumb {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    padding: 4px 0;
  }
  .custom-segmented-thumb-motion-appear-active {
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform, width;
  }
  .custom-segmented.custom-segmented-rtl {
    direction: rtl;
  }
  .custom-segmented.custom-segmented-rtl .custom-segmented-item-icon {
    margin-right: 0;
    margin-left: 6px;
  }
  .custom-select-single .custom-select-selector {
    display: flex;
  }
  .custom-select-single .custom-select-selector .custom-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
  }
  .custom-select-single .custom-select-selector .custom-select-selection-search-input {
    width: 100%;
  }
  .custom-select-single .custom-select-selector .custom-select-selection-item,
  .custom-select-single .custom-select-selector .custom-select-selection-placeholder {
    padding: 0;
    line-height: 30px;
    transition: all 0.3s;
  }
  .custom-select-single .custom-select-selector .custom-select-selection-item {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-select-single .custom-select-selector .custom-select-selection-placeholder {
    transition: none;
    pointer-events: none;
  }
  .custom-select-single .custom-select-selector::after,
  .custom-select-single .custom-select-selector .custom-select-selection-item::after,
  .custom-select-single .custom-select-selector .custom-select-selection-placeholder::after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .custom-select-single.custom-select-show-arrow .custom-select-selection-search {
    right: 25px;
  }
  .custom-select-single.custom-select-show-arrow .custom-select-selection-item,
  .custom-select-single.custom-select-show-arrow .custom-select-selection-placeholder {
    padding-right: 18px;
  }
  .custom-select-single.custom-select-open .custom-select-selection-item {
    color: #bfbfbf;
  }
  .custom-select-single:not(.custom-select-customize-input) .custom-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 11px;
  }
  .custom-select-single:not(.custom-select-customize-input) .custom-select-selector .custom-select-selection-search-input {
    height: 30px;
  }
  .custom-select-single:not(.custom-select-customize-input) .custom-select-selector::after {
    line-height: 30px;
  }
  .custom-select-single.custom-select-customize-input .custom-select-selector::after {
    display: none;
  }
  .custom-select-single.custom-select-customize-input .custom-select-selector .custom-select-selection-search {
    position: static;
    width: 100%;
  }
  .custom-select-single.custom-select-customize-input .custom-select-selector .custom-select-selection-placeholder {
    position: absolute;
    right: 0;
    left: 0;
    padding: 0 11px;
  }
  .custom-select-single.custom-select-customize-input .custom-select-selector .custom-select-selection-placeholder::after {
    display: none;
  }
  .custom-select-single.custom-select-lg:not(.custom-select-customize-input) .custom-select-selector {
    height: 40px;
  }
  .custom-select-single.custom-select-lg:not(.custom-select-customize-input) .custom-select-selector::after,
  .custom-select-single.custom-select-lg:not(.custom-select-customize-input) .custom-select-selector .custom-select-selection-item,
  .custom-select-single.custom-select-lg:not(.custom-select-customize-input) .custom-select-selector .custom-select-selection-placeholder {
    line-height: 38px;
  }
  .custom-select-single.custom-select-lg:not(.custom-select-customize-input):not(.custom-select-customize-input) .custom-select-selection-search-input {
    height: 38px;
  }
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input) .custom-select-selector {
    height: 24px;
  }
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input) .custom-select-selector::after,
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input) .custom-select-selector .custom-select-selection-item,
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input) .custom-select-selector .custom-select-selection-placeholder {
    line-height: 22px;
  }
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input):not(.custom-select-customize-input) .custom-select-selection-search-input {
    height: 22px;
  }
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input) .custom-select-selection-search {
    right: 7px;
    left: 7px;
  }
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input) .custom-select-selector {
    padding: 0 7px;
  }
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input).custom-select-show-arrow .custom-select-selection-search {
    right: 28px;
  }
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input).custom-select-show-arrow .custom-select-selection-item,
  .custom-select-single.custom-select-sm:not(.custom-select-customize-input).custom-select-show-arrow .custom-select-selection-placeholder {
    padding-right: 21px;
  }
  .custom-select-single.custom-select-lg:not(.custom-select-customize-input) .custom-select-selector {
    padding: 0 11px;
  }
  /**
   * Do not merge `height` & `line-height` under style with `selection` & `search`,
   * since chrome may update to redesign with its align logic.
   */
  .custom-select-selection-overflow {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
  }
  .custom-select-selection-overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
  }
  .custom-select-multiple .custom-select-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1px 4px;
  }
  .custom-select-show-search.custom-select-multiple .custom-select-selector {
    cursor: text;
  }
  .custom-select-disabled.custom-select-multiple .custom-select-selector {
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .custom-select-multiple .custom-select-selector::after {
    display: inline-block;
    width: 0;
    margin: 2px 0;
    line-height: 24px;
    content: '\a0';
  }
  .custom-select-multiple.custom-select-show-arrow .custom-select-selector,
  .custom-select-multiple.custom-select-allow-clear .custom-select-selector {
    padding-right: 24px;
  }
  .custom-select-multiple .custom-select-selection-item {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
            user-select: none;
    -webkit-margin-end: 4px;
            margin-inline-end: 4px;
    -webkit-padding-start: 8px;
            padding-inline-start: 8px;
    -webkit-padding-end: 4px;
            padding-inline-end: 4px;
  }
  .custom-select-disabled.custom-select-multiple .custom-select-selection-item {
    color: #bfbfbf;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .custom-select-multiple .custom-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
  .custom-select-multiple .custom-select-selection-item-remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    font-size: 10px;
    line-height: inherit;
    cursor: pointer;
  }
  .custom-select-multiple .custom-select-selection-item-remove > * {
    line-height: 1;
  }
  .custom-select-multiple .custom-select-selection-item-remove svg {
    display: inline-block;
  }
  .custom-select-multiple .custom-select-selection-item-remove::before {
    display: none;
  }
  .custom-select-multiple .custom-select-selection-item-remove .custom-select-multiple .custom-select-selection-item-remove-icon {
    display: block;
  }
  .custom-select-multiple .custom-select-selection-item-remove > .anticon {
    vertical-align: middle;
  }
  .custom-select-multiple .custom-select-selection-item-remove:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .custom-select-multiple .custom-select-selection-overflow-item + .custom-select-selection-overflow-item .custom-select-selection-search {
    -webkit-margin-start: 0;
            margin-inline-start: 0;
  }
  .custom-select-multiple .custom-select-selection-search {
    position: relative;
    max-width: 100%;
    -webkit-margin-start: 7px;
            margin-inline-start: 7px;
  }
  .custom-select-multiple .custom-select-selection-search-input,
  .custom-select-multiple .custom-select-selection-search-mirror {
    height: 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 24px;
    transition: all 0.3s;
  }
  .custom-select-multiple .custom-select-selection-search-input {
    width: 100%;
    min-width: 4.1px;
  }
  .custom-select-multiple .custom-select-selection-search-mirror {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    white-space: pre;
    visibility: hidden;
  }
  .custom-select-multiple .custom-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 11px;
    left: 11px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: all 0.3s;
  }
  .custom-select-multiple.custom-select-lg .custom-select-selector::after {
    line-height: 32px;
  }
  .custom-select-multiple.custom-select-lg .custom-select-selection-item {
    height: 32px;
    line-height: 30px;
  }
  .custom-select-multiple.custom-select-lg .custom-select-selection-search {
    height: 32px;
    line-height: 32px;
  }
  .custom-select-multiple.custom-select-lg .custom-select-selection-search-input,
  .custom-select-multiple.custom-select-lg .custom-select-selection-search-mirror {
    height: 32px;
    line-height: 30px;
  }
  .custom-select-multiple.custom-select-sm .custom-select-selector::after {
    line-height: 16px;
  }
  .custom-select-multiple.custom-select-sm .custom-select-selection-item {
    height: 16px;
    line-height: 14px;
  }
  .custom-select-multiple.custom-select-sm .custom-select-selection-search {
    height: 16px;
    line-height: 16px;
  }
  .custom-select-multiple.custom-select-sm .custom-select-selection-search-input,
  .custom-select-multiple.custom-select-sm .custom-select-selection-search-mirror {
    height: 16px;
    line-height: 14px;
  }
  .custom-select-multiple.custom-select-sm .custom-select-selection-placeholder {
    left: 7px;
  }
  .custom-select-multiple.custom-select-sm .custom-select-selection-search {
    -webkit-margin-start: 3px;
            margin-inline-start: 3px;
  }
  .custom-select-multiple.custom-select-lg .custom-select-selection-item {
    height: 32px;
    line-height: 32px;
  }
  .custom-select-disabled .custom-select-selection-item-remove {
    display: none;
  }
  .custom-select-status-error.custom-select:not(.custom-select-disabled):not(.custom-select-customize-input):not(.custom-pagination-size-changer) .custom-select-selector {
    background-color: #fff;
    border-color: #ff4d4f !important;
    border-color: var(--custom-error-color) !important;
  }
  .custom-select-status-error.custom-select:not(.custom-select-disabled):not(.custom-select-customize-input):not(.custom-pagination-size-changer).custom-select-open .custom-select-selector,
  .custom-select-status-error.custom-select:not(.custom-select-disabled):not(.custom-select-customize-input):not(.custom-pagination-size-changer).custom-select-focused .custom-select-selector {
    border-color: #ff7875;
    border-color: var(--custom-error-color-hover);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px var(--custom-error-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-select-status-warning.custom-select:not(.custom-select-disabled):not(.custom-select-customize-input):not(.custom-pagination-size-changer) .custom-select-selector {
    background-color: #fff;
    border-color: #faad14 !important;
    border-color: var(--custom-warning-color) !important;
  }
  .custom-select-status-warning.custom-select:not(.custom-select-disabled):not(.custom-select-customize-input):not(.custom-pagination-size-changer).custom-select-open .custom-select-selector,
  .custom-select-status-warning.custom-select:not(.custom-select-disabled):not(.custom-select-customize-input):not(.custom-pagination-size-changer).custom-select-focused .custom-select-selector {
    border-color: #ffc53d;
    border-color: var(--custom-warning-color-hover);
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow: 0 0 0 2px var(--custom-warning-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-select-status-error.custom-select-has-feedback .custom-select-clear,
  .custom-select-status-warning.custom-select-has-feedback .custom-select-clear,
  .custom-select-status-success.custom-select-has-feedback .custom-select-clear,
  .custom-select-status-validating.custom-select-has-feedback .custom-select-clear {
    right: 32px;
  }
  .custom-select-status-error.custom-select-has-feedback .custom-select-selection-selected-value,
  .custom-select-status-warning.custom-select-has-feedback .custom-select-selection-selected-value,
  .custom-select-status-success.custom-select-has-feedback .custom-select-selection-selected-value,
  .custom-select-status-validating.custom-select-has-feedback .custom-select-selection-selected-value {
    padding-right: 42px;
  }
  /* Reset search input style */
  .custom-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .custom-select:not(.custom-select-customize-input) .custom-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-select:not(.custom-select-customize-input) .custom-select-selector input {
    cursor: pointer;
  }
  .custom-select-show-search.custom-select:not(.custom-select-customize-input) .custom-select-selector {
    cursor: text;
  }
  .custom-select-show-search.custom-select:not(.custom-select-customize-input) .custom-select-selector input {
    cursor: auto;
  }
  .custom-select-focused:not(.custom-select-disabled).custom-select:not(.custom-select-customize-input) .custom-select-selector {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-select-focused:not(.custom-select-disabled).custom-select:not(.custom-select-customize-input) .custom-select-selector {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-select-disabled.custom-select:not(.custom-select-customize-input) .custom-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .custom-select-multiple.custom-select-disabled.custom-select:not(.custom-select-customize-input) .custom-select-selector {
    background: #f5f5f5;
  }
  .custom-select-disabled.custom-select:not(.custom-select-customize-input) .custom-select-selector input {
    cursor: not-allowed;
  }
  .custom-select:not(.custom-select-customize-input) .custom-select-selector .custom-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
            appearance: none;
  }
  .custom-select:not(.custom-select-customize-input) .custom-select-selector .custom-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
  }
  .custom-select:not(.custom-select-disabled):hover .custom-select-selector {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-select:not(.custom-select-disabled):hover .custom-select-selector {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-select-selection-item {
    flex: 1 1;
    overflow: hidden;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media all and (-ms-high-contrast: none) {
    .custom-select-selection-item *::-ms-backdrop,
    .custom-select-selection-item {
      flex: auto;
    }
  }
  .custom-select-selection-placeholder {
    flex: 1 1;
    overflow: hidden;
    color: #bfbfbf;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }
  @media all and (-ms-high-contrast: none) {
    .custom-select-selection-placeholder *::-ms-backdrop,
    .custom-select-selection-placeholder {
      flex: auto;
    }
  }
  .custom-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    display: flex;
    align-items: center;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }
  .custom-select-arrow > * {
    line-height: 1;
  }
  .custom-select-arrow svg {
    display: inline-block;
  }
  .custom-select-arrow::before {
    display: none;
  }
  .custom-select-arrow .custom-select-arrow-icon {
    display: block;
  }
  .custom-select-arrow .anticon {
    vertical-align: top;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .custom-select-arrow .anticon > svg {
    vertical-align: top;
  }
  .custom-select-arrow .anticon:not(.custom-select-suffix) {
    pointer-events: auto;
  }
  .custom-select-disabled .custom-select-arrow {
    cursor: not-allowed;
  }
  .custom-select-arrow > *:not(:last-child) {
    -webkit-margin-end: 8px;
            margin-inline-end: 8px;
  }
  .custom-select-clear {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
  }
  .custom-select-clear::before {
    display: block;
  }
  .custom-select-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-select:hover .custom-select-clear {
    opacity: 1;
  }
  .custom-select-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum", ;
            font-feature-settings: 'tnum', "tnum", ;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .custom-select-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-select-dropdown-placement-bottomLeft,
  .custom-select-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .custom-select-dropdown.custom-slide-up-enter.custom-slide-up-enter-active.custom-select-dropdown-placement-topLeft,
  .custom-select-dropdown.custom-slide-up-appear.custom-slide-up-appear-active.custom-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .custom-select-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .custom-select-dropdown.custom-slide-up-leave.custom-slide-up-leave-active.custom-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .custom-select-dropdown-hidden {
    display: none;
  }
  .custom-select-dropdown-empty {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-select-item-empty {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-select-item {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .custom-select-item-group {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    cursor: default;
  }
  .custom-select-item-option {
    display: flex;
  }
  .custom-select-item-option-content {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-select-item-option-state {
    flex: none;
  }
  .custom-select-item-option-active:not(.custom-select-item-option-disabled) {
    background-color: #f5f5f5;
  }
  .custom-select-item-option-selected:not(.custom-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #e6f7ff;
    background-color: var(--custom-primary-1);
  }
  .custom-select-item-option-selected:not(.custom-select-item-option-disabled) .custom-select-item-option-state {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-select-item-option-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-select-item-option-disabled.custom-select-item-option-selected {
    background-color: #f5f5f5;
  }
  .custom-select-item-option-grouped {
    padding-left: 24px;
  }
  .custom-select-lg {
    font-size: 16px;
  }
  .custom-select-borderless .custom-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .custom-select.custom-select-in-form-item {
    width: 100%;
  }
  .custom-select-rtl {
    direction: rtl;
  }
  .custom-select-rtl .custom-select-arrow {
    right: auto;
    right: initial;
    left: 11px;
  }
  .custom-select-rtl .custom-select-clear {
    right: auto;
    right: initial;
    left: 11px;
  }
  .custom-select-dropdown-rtl {
    direction: rtl;
  }
  .custom-select-dropdown-rtl .custom-select-item-option-grouped {
    padding-right: 24px;
    padding-left: 12px;
  }
  .custom-select-rtl.custom-select-multiple.custom-select-show-arrow .custom-select-selector,
  .custom-select-rtl.custom-select-multiple.custom-select-allow-clear .custom-select-selector {
    padding-right: 4px;
    padding-left: 24px;
  }
  .custom-select-rtl.custom-select-multiple .custom-select-selection-item {
    text-align: right;
  }
  .custom-select-rtl.custom-select-multiple .custom-select-selection-item-content {
    margin-right: 0;
    margin-left: 4px;
    text-align: right;
  }
  .custom-select-rtl.custom-select-multiple .custom-select-selection-search-mirror {
    right: 0;
    left: auto;
  }
  .custom-select-rtl.custom-select-multiple .custom-select-selection-placeholder {
    right: 11px;
    left: auto;
  }
  .custom-select-rtl.custom-select-multiple.custom-select-sm .custom-select-selection-placeholder {
    right: 7px;
  }
  .custom-select-rtl.custom-select-single .custom-select-selector .custom-select-selection-item,
  .custom-select-rtl.custom-select-single .custom-select-selector .custom-select-selection-placeholder {
    right: 0;
    left: 9px;
    text-align: right;
  }
  .custom-select-rtl.custom-select-single.custom-select-show-arrow .custom-select-selection-search {
    right: 11px;
    left: 25px;
  }
  .custom-select-rtl.custom-select-single.custom-select-show-arrow .custom-select-selection-item,
  .custom-select-rtl.custom-select-single.custom-select-show-arrow .custom-select-selection-placeholder {
    padding-right: 0;
    padding-left: 18px;
  }
  .custom-select-rtl.custom-select-single.custom-select-sm:not(.custom-select-customize-input).custom-select-show-arrow .custom-select-selection-search {
    right: 6px;
  }
  .custom-select-rtl.custom-select-single.custom-select-sm:not(.custom-select-customize-input).custom-select-show-arrow .custom-select-selection-item,
  .custom-select-rtl.custom-select-single.custom-select-sm:not(.custom-select-customize-input).custom-select-show-arrow .custom-select-selection-placeholder {
    padding-right: 0;
    padding-left: 21px;
  }
  .custom-skeleton {
    display: table;
    width: 100%;
  }
  .custom-skeleton-header {
    display: table-cell;
    padding-right: 16px;
    vertical-align: top;
  }
  .custom-skeleton-header .custom-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .custom-skeleton-header .custom-skeleton-avatar.custom-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .custom-skeleton-header .custom-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .custom-skeleton-header .custom-skeleton-avatar-lg.custom-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .custom-skeleton-header .custom-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .custom-skeleton-header .custom-skeleton-avatar-sm.custom-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .custom-skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;
  }
  .custom-skeleton-content .custom-skeleton-title {
    width: 100%;
    height: 16px;
    background: rgba(190, 190, 190, 0.2);
    border-radius: 2px;
  }
  .custom-skeleton-content .custom-skeleton-title + .custom-skeleton-paragraph {
    margin-top: 24px;
  }
  .custom-skeleton-content .custom-skeleton-paragraph {
    padding: 0;
  }
  .custom-skeleton-content .custom-skeleton-paragraph > li {
    width: 100%;
    height: 16px;
    list-style: none;
    background: rgba(190, 190, 190, 0.2);
    border-radius: 2px;
  }
  .custom-skeleton-content .custom-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 61%;
  }
  .custom-skeleton-content .custom-skeleton-paragraph > li + li {
    margin-top: 16px;
  }
  .custom-skeleton-with-avatar .custom-skeleton-content .custom-skeleton-title {
    margin-top: 12px;
  }
  .custom-skeleton-with-avatar .custom-skeleton-content .custom-skeleton-title + .custom-skeleton-paragraph {
    margin-top: 28px;
  }
  .custom-skeleton-round .custom-skeleton-content .custom-skeleton-title,
  .custom-skeleton-round .custom-skeleton-content .custom-skeleton-paragraph > li {
    border-radius: 100px;
  }
  .custom-skeleton-active .custom-skeleton-title,
  .custom-skeleton-active .custom-skeleton-paragraph > li,
  .custom-skeleton-active .custom-skeleton-avatar,
  .custom-skeleton-active .custom-skeleton-button,
  .custom-skeleton-active .custom-skeleton-input,
  .custom-skeleton-active .custom-skeleton-image {
    position: relative;
    /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
    z-index: 0;
    overflow: hidden;
    background: transparent;
  }
  .custom-skeleton-active .custom-skeleton-title::after,
  .custom-skeleton-active .custom-skeleton-paragraph > li::after,
  .custom-skeleton-active .custom-skeleton-avatar::after,
  .custom-skeleton-active .custom-skeleton-button::after,
  .custom-skeleton-active .custom-skeleton-input::after,
  .custom-skeleton-active .custom-skeleton-image::after {
    position: absolute;
    top: 0;
    right: -150%;
    bottom: 0;
    left: -150%;
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
    -webkit-animation: custom-skeleton-loading 1.4s ease infinite;
            animation: custom-skeleton-loading 1.4s ease infinite;
    content: '';
  }
  .custom-skeleton.custom-skeleton-block {
    width: 100%;
  }
  .custom-skeleton.custom-skeleton-block .custom-skeleton-button {
    width: 100%;
  }
  .custom-skeleton.custom-skeleton-block .custom-skeleton-input {
    width: 100%;
  }
  .custom-skeleton-element {
    display: inline-block;
    width: auto;
  }
  .custom-skeleton-element .custom-skeleton-button {
    display: inline-block;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);
    border-radius: 2px;
    width: 64px;
    min-width: 64px;
    height: 32px;
    line-height: 32px;
  }
  .custom-skeleton-element .custom-skeleton-button.custom-skeleton-button-square {
    width: 32px;
    min-width: 32px;
  }
  .custom-skeleton-element .custom-skeleton-button.custom-skeleton-button-circle {
    width: 32px;
    min-width: 32px;
    border-radius: 50%;
  }
  .custom-skeleton-element .custom-skeleton-button.custom-skeleton-button-round {
    border-radius: 32px;
  }
  .custom-skeleton-element .custom-skeleton-button-lg {
    width: 80px;
    min-width: 80px;
    height: 40px;
    line-height: 40px;
  }
  .custom-skeleton-element .custom-skeleton-button-lg.custom-skeleton-button-square {
    width: 40px;
    min-width: 40px;
  }
  .custom-skeleton-element .custom-skeleton-button-lg.custom-skeleton-button-circle {
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
  }
  .custom-skeleton-element .custom-skeleton-button-lg.custom-skeleton-button-round {
    border-radius: 40px;
  }
  .custom-skeleton-element .custom-skeleton-button-sm {
    width: 48px;
    min-width: 48px;
    height: 24px;
    line-height: 24px;
  }
  .custom-skeleton-element .custom-skeleton-button-sm.custom-skeleton-button-square {
    width: 24px;
    min-width: 24px;
  }
  .custom-skeleton-element .custom-skeleton-button-sm.custom-skeleton-button-circle {
    width: 24px;
    min-width: 24px;
    border-radius: 50%;
  }
  .custom-skeleton-element .custom-skeleton-button-sm.custom-skeleton-button-round {
    border-radius: 24px;
  }
  .custom-skeleton-element .custom-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .custom-skeleton-element .custom-skeleton-avatar.custom-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .custom-skeleton-element .custom-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .custom-skeleton-element .custom-skeleton-avatar-lg.custom-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .custom-skeleton-element .custom-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .custom-skeleton-element .custom-skeleton-avatar-sm.custom-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .custom-skeleton-element .custom-skeleton-input {
    display: inline-block;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);
    width: 160px;
    min-width: 160px;
    height: 32px;
    line-height: 32px;
  }
  .custom-skeleton-element .custom-skeleton-input-lg {
    width: 200px;
    min-width: 200px;
    height: 40px;
    line-height: 40px;
  }
  .custom-skeleton-element .custom-skeleton-input-sm {
    width: 120px;
    min-width: 120px;
    height: 24px;
    line-height: 24px;
  }
  .custom-skeleton-element .custom-skeleton-image {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);
    width: 96px;
    height: 96px;
    line-height: 96px;
  }
  .custom-skeleton-element .custom-skeleton-image.custom-skeleton-image-circle {
    border-radius: 50%;
  }
  .custom-skeleton-element .custom-skeleton-image-path {
    fill: #bfbfbf;
  }
  .custom-skeleton-element .custom-skeleton-image-svg {
    width: 48px;
    height: 48px;
    line-height: 48px;
    max-width: 192px;
    max-height: 192px;
  }
  .custom-skeleton-element .custom-skeleton-image-svg.custom-skeleton-image-circle {
    border-radius: 50%;
  }
  @-webkit-keyframes custom-skeleton-loading {
    0% {
      -webkit-transform: translateX(-37.5%);
              transform: translateX(-37.5%);
    }
    100% {
      -webkit-transform: translateX(37.5%);
              transform: translateX(37.5%);
    }
  }
  @keyframes custom-skeleton-loading {
    0% {
      -webkit-transform: translateX(-37.5%);
              transform: translateX(-37.5%);
    }
    100% {
      -webkit-transform: translateX(37.5%);
              transform: translateX(37.5%);
    }
  }
  .custom-skeleton-rtl {
    direction: rtl;
  }
  .custom-skeleton-rtl .custom-skeleton-header {
    padding-right: 0;
    padding-left: 16px;
  }
  .custom-skeleton-rtl.custom-skeleton.custom-skeleton-active .custom-skeleton-content .custom-skeleton-title,
  .custom-skeleton-rtl.custom-skeleton.custom-skeleton-active .custom-skeleton-content .custom-skeleton-paragraph > li {
    -webkit-animation-name: custom-skeleton-loading-rtl;
            animation-name: custom-skeleton-loading-rtl;
  }
  .custom-skeleton-rtl.custom-skeleton.custom-skeleton-active .custom-skeleton-avatar {
    -webkit-animation-name: custom-skeleton-loading-rtl;
            animation-name: custom-skeleton-loading-rtl;
  }
  @-webkit-keyframes custom-skeleton-loading-rtl {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  @keyframes custom-skeleton-loading-rtl {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  .custom-slider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    height: 12px;
    margin: 10px 6px 10px;
    padding: 4px 0;
    cursor: pointer;
    touch-action: none;
  }
  .custom-slider-vertical {
    width: 12px;
    height: 100%;
    margin: 6px 10px;
    padding: 0 4px;
  }
  .custom-slider-vertical .custom-slider-rail {
    width: 4px;
    height: 100%;
  }
  .custom-slider-vertical .custom-slider-track {
    width: 4px;
  }
  .custom-slider-vertical .custom-slider-handle {
    margin-top: -6px;
    margin-left: -5px;
  }
  .custom-slider-vertical .custom-slider-mark {
    top: 0;
    left: 12px;
    width: 18px;
    height: 100%;
  }
  .custom-slider-vertical .custom-slider-mark-text {
    left: 4px;
    white-space: nowrap;
  }
  .custom-slider-vertical .custom-slider-step {
    width: 4px;
    height: 100%;
  }
  .custom-slider-vertical .custom-slider-dot {
    top: auto;
    margin-left: -2px;
  }
  .custom-slider-tooltip .custom-tooltip-inner {
    min-width: 0;
    min-width: initial;
  }
  .custom-slider-rtl.custom-slider-vertical .custom-slider-handle {
    margin-right: -5px;
    margin-left: 0;
  }
  .custom-slider-rtl.custom-slider-vertical .custom-slider-mark {
    right: 12px;
    left: auto;
  }
  .custom-slider-rtl.custom-slider-vertical .custom-slider-mark-text {
    right: 4px;
    left: auto;
  }
  .custom-slider-rtl.custom-slider-vertical .custom-slider-dot {
    right: 2px;
    left: auto;
  }
  .custom-slider-with-marks {
    margin-bottom: 28px;
  }
  .custom-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #f5f5f5;
    border-radius: 2px;
    transition: background-color 0.3s;
  }
  .custom-slider-track {
    position: absolute;
    height: 4px;
    background-color: #91d5ff;
    background-color: var(--custom-primary-3);
    border-radius: 2px;
    transition: background-color 0.3s;
  }
  .custom-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #91d5ff;
    border: solid 2px var(--custom-primary-3);
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
  .custom-slider-handle-dragging {
    z-index: 1;
  }
  .custom-slider-handle:focus {
    border-color: #46a6ff;
    border-color: var(--custom-primary-color-deprecated-t-20);
    outline: none;
    box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
    box-shadow: 0 0 0 5px var(--custom-primary-color-deprecated-f-12);
  }
  .custom-slider-handle.custom-tooltip-open {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-slider-handle::after {
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    content: '';
  }
  .custom-slider:hover .custom-slider-rail {
    background-color: #e1e1e1;
  }
  .custom-slider:hover .custom-slider-track {
    background-color: #69c0ff;
    background-color: var(--custom-primary-4);
  }
  .custom-slider:hover .custom-slider-handle:not(.custom-tooltip-open) {
    border-color: #69c0ff;
    border-color: var(--custom-primary-4);
  }
  .custom-slider-mark {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    font-size: 14px;
  }
  .custom-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    word-break: keep-all;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
    pointer-events: none;
  }
  .custom-slider-dot {
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    cursor: pointer;
  }
  .custom-slider-dot-active {
    border-color: #8cc8ff;
    border-color: var(--custom-primary-color-deprecated-t-50);
  }
  .custom-slider-disabled {
    cursor: not-allowed;
  }
  .custom-slider-disabled .custom-slider-rail {
    background-color: #f5f5f5 !important;
  }
  .custom-slider-disabled .custom-slider-track {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  .custom-slider-disabled .custom-slider-handle,
  .custom-slider-disabled .custom-slider-dot {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25) !important;
    box-shadow: none;
    cursor: not-allowed;
  }
  .custom-slider-disabled .custom-slider-mark-text,
  .custom-slider-disabled .custom-slider-dot {
    cursor: not-allowed !important;
  }
  .custom-slider-rtl {
    direction: rtl;
  }
  .custom-slider-rtl .custom-slider-mark {
    right: 0;
    left: auto;
  }
  .custom-space {
    display: inline-flex;
  }
  .custom-space-vertical {
    flex-direction: column;
  }
  .custom-space-align-center {
    align-items: center;
  }
  .custom-space-align-start {
    align-items: flex-start;
  }
  .custom-space-align-end {
    align-items: flex-end;
  }
  .custom-space-align-baseline {
    align-items: baseline;
  }
  .custom-space-item:empty {
    display: none;
  }
  .custom-space-rtl {
    direction: rtl;
  }
  .custom-spin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    display: none;
    color: #1890ff;
    color: var(--custom-primary-color);
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .custom-spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }
  .custom-spin-nested-loading {
    position: relative;
  }
  .custom-spin-nested-loading > div > .custom-spin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
  }
  .custom-spin-nested-loading > div > .custom-spin .custom-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
  }
  .custom-spin-nested-loading > div > .custom-spin .custom-spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding-top: 5px;
    text-shadow: 0 1px 2px #fff;
  }
  .custom-spin-nested-loading > div > .custom-spin.custom-spin-show-text .custom-spin-dot {
    margin-top: -20px;
  }
  .custom-spin-nested-loading > div > .custom-spin-sm .custom-spin-dot {
    margin: -7px;
  }
  .custom-spin-nested-loading > div > .custom-spin-sm .custom-spin-text {
    padding-top: 2px;
  }
  .custom-spin-nested-loading > div > .custom-spin-sm.custom-spin-show-text .custom-spin-dot {
    margin-top: -17px;
  }
  .custom-spin-nested-loading > div > .custom-spin-lg .custom-spin-dot {
    margin: -16px;
  }
  .custom-spin-nested-loading > div > .custom-spin-lg .custom-spin-text {
    padding-top: 11px;
  }
  .custom-spin-nested-loading > div > .custom-spin-lg.custom-spin-show-text .custom-spin-dot {
    margin-top: -26px;
  }
  .custom-spin-container {
    position: relative;
    transition: opacity 0.3s;
  }
  .custom-spin-container::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    transition: all 0.3s;
    content: '';
    pointer-events: none;
  }
  .custom-spin-blur {
    clear: both;
    opacity: 0.5;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
  }
  .custom-spin-blur::after {
    opacity: 0.4;
    pointer-events: auto;
  }
  .custom-spin-tip {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em;
  }
  .custom-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #096dd9;
    background-color: var(--custom-primary-7);
    border-radius: 100%;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    opacity: 0.3;
    -webkit-animation: antSpinMove 1s infinite linear alternate;
            animation: antSpinMove 1s infinite linear alternate;
  }
  .custom-spin-dot-item:nth-child(1) {
    top: 0;
    left: 0;
  }
  .custom-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .custom-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  .custom-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
  }
  .custom-spin-dot-spin {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation: antRotate 1.2s infinite linear;
            animation: antRotate 1.2s infinite linear;
  }
  .custom-spin-sm .custom-spin-dot {
    font-size: 14px;
  }
  .custom-spin-sm .custom-spin-dot i {
    width: 6px;
    height: 6px;
  }
  .custom-spin-lg .custom-spin-dot {
    font-size: 32px;
  }
  .custom-spin-lg .custom-spin-dot i {
    width: 14px;
    height: 14px;
  }
  .custom-spin.custom-spin-show-text .custom-spin-text {
    display: block;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ */
    .custom-spin-blur {
      background: #fff;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes antSpinMove {
    to {
      opacity: 1;
    }
  }
  @keyframes antSpinMove {
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes antRotate {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes antRotate {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .custom-spin-rtl {
    direction: rtl;
  }
  .custom-spin-rtl .custom-spin-dot-spin {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-animation-name: antRotateRtl;
            animation-name: antRotateRtl;
  }
  @-webkit-keyframes antRotateRtl {
    to {
      -webkit-transform: rotate(-405deg);
              transform: rotate(-405deg);
    }
  }
  @keyframes antRotateRtl {
    to {
      -webkit-transform: rotate(-405deg);
              transform: rotate(-405deg);
    }
  }
  .custom-statistic {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
  }
  .custom-statistic-title {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .custom-statistic-skeleton {
    padding-top: 16px;
  }
  .custom-statistic-content {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
  .custom-statistic-content-value {
    display: inline-block;
    direction: ltr;
  }
  .custom-statistic-content-prefix,
  .custom-statistic-content-suffix {
    display: inline-block;
  }
  .custom-statistic-content-prefix {
    margin-right: 4px;
  }
  .custom-statistic-content-suffix {
    margin-left: 4px;
  }
  .custom-statistic-rtl {
    direction: rtl;
  }
  .custom-statistic-rtl .custom-statistic-content-prefix {
    margin-right: 0;
    margin-left: 4px;
  }
  .custom-statistic-rtl .custom-statistic-content-suffix {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-steps {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: flex;
    width: 100%;
    font-size: 0;
    text-align: left;
    text-align: initial;
  }
  .custom-steps-item {
    position: relative;
    display: inline-block;
    flex: 1 1;
    overflow: hidden;
    vertical-align: top;
  }
  .custom-steps-item-container {
    outline: none;
  }
  .custom-steps-item:last-child {
    flex: none;
  }
  .custom-steps-item:last-child > .custom-steps-item-container > .custom-steps-item-tail,
  .custom-steps-item:last-child > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title::after {
    display: none;
  }
  .custom-steps-item-icon,
  .custom-steps-item-content {
    display: inline-block;
    vertical-align: top;
  }
  .custom-steps-item-icon {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 0;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 32px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .custom-steps-item-icon .custom-steps-icon {
    position: relative;
    top: -0.5px;
    color: #1890ff;
    color: var(--custom-primary-color);
    line-height: 1;
  }
  .custom-steps-item-tail {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    padding: 0 10px;
  }
  .custom-steps-item-tail::after {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 1px;
    transition: background 0.3s;
    content: '';
  }
  .custom-steps-item-title {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 32px;
  }
  .custom-steps-item-title::after {
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: rgba(0, 0, 0, 0.06);
    content: '';
  }
  .custom-steps-item-subtitle {
    display: inline;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: normal;
    font-size: 14px;
  }
  .custom-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .custom-steps-item-wait .custom-steps-item-icon {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
  }
  .custom-steps-item-wait .custom-steps-item-icon > .custom-steps-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-steps-item-wait .custom-steps-item-icon > .custom-steps-icon .custom-steps-icon-dot {
    background: rgba(0, 0, 0, 0.25);
  }
  .custom-steps-item-wait > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-steps-item-wait > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title::after {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-steps-item-wait > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-steps-item-wait > .custom-steps-item-container > .custom-steps-item-tail::after {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-steps-item-process .custom-steps-item-icon {
    background-color: #fff;
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-steps-item-process .custom-steps-item-icon > .custom-steps-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-steps-item-process .custom-steps-item-icon > .custom-steps-icon .custom-steps-icon-dot {
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-steps-item-process > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-steps-item-process > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title::after {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-steps-item-process > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-description {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-steps-item-process > .custom-steps-item-container > .custom-steps-item-tail::after {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-steps-item-process > .custom-steps-item-container > .custom-steps-item-icon {
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-steps-item-process > .custom-steps-item-container > .custom-steps-item-icon .custom-steps-icon {
    color: #fff;
  }
  .custom-steps-item-process > .custom-steps-item-container > .custom-steps-item-title {
    font-weight: 500;
  }
  .custom-steps-item-finish .custom-steps-item-icon {
    background-color: #fff;
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-steps-item-finish .custom-steps-item-icon > .custom-steps-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-steps-item-finish .custom-steps-item-icon > .custom-steps-icon .custom-steps-icon-dot {
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title::after {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
  }
  .custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-steps-item-finish > .custom-steps-item-container > .custom-steps-item-tail::after {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
  }
  .custom-steps-item-error .custom-steps-item-icon {
    background-color: #fff;
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-steps-item-error .custom-steps-item-icon > .custom-steps-icon {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-steps-item-error .custom-steps-item-icon > .custom-steps-icon .custom-steps-icon-dot {
    background: #ff4d4f;
    background: var(--custom-error-color);
  }
  .custom-steps-item-error > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-steps-item-error > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title::after {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-steps-item-error > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-description {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-steps-item-error > .custom-steps-item-container > .custom-steps-item-tail::after {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .custom-steps-item.custom-steps-next-error .custom-steps-item-title::after {
    background: #ff4d4f;
    background: var(--custom-error-color);
  }
  .custom-steps-item-disabled {
    cursor: not-allowed;
  }
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button'] {
    cursor: pointer;
  }
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button'] .custom-steps-item-title,
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button'] .custom-steps-item-subtitle,
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button'] .custom-steps-item-description,
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button'] .custom-steps-item-icon .custom-steps-icon {
    transition: color 0.3s;
  }
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button']:hover .custom-steps-item-title,
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button']:hover .custom-steps-item-subtitle,
  .custom-steps .custom-steps-item:not(.custom-steps-item-active) > .custom-steps-item-container[role='button']:hover .custom-steps-item-description {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-steps .custom-steps-item:not(.custom-steps-item-active):not(.custom-steps-item-process) > .custom-steps-item-container[role='button']:hover .custom-steps-item-icon {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-steps .custom-steps-item:not(.custom-steps-item-active):not(.custom-steps-item-process) > .custom-steps-item-container[role='button']:hover .custom-steps-item-icon .custom-steps-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item {
    padding-left: 16px;
    white-space: nowrap;
  }
  .custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item:first-child {
    padding-left: 0;
  }
  .custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item:last-child .custom-steps-item-title {
    padding-right: 0;
  }
  .custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item-tail {
    display: none;
  }
  .custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item-description {
    max-width: 140px;
    white-space: normal;
  }
  .custom-steps-item-custom > .custom-steps-item-container > .custom-steps-item-icon {
    height: auto;
    background: none;
    border: 0;
  }
  .custom-steps-item-custom > .custom-steps-item-container > .custom-steps-item-icon > .custom-steps-icon {
    top: 0px;
    left: 0.5px;
    width: 32px;
    height: 32px;
    font-size: 24px;
    line-height: 32px;
  }
  .custom-steps-item-custom.custom-steps-item-process .custom-steps-item-icon > .custom-steps-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-steps:not(.custom-steps-vertical) .custom-steps-item-custom .custom-steps-item-icon {
    width: auto;
    background: none;
  }
  .custom-steps-small.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item {
    padding-left: 12px;
  }
  .custom-steps-small.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item:first-child {
    padding-left: 0;
  }
  .custom-steps-small .custom-steps-item-icon {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border-radius: 24px;
  }
  .custom-steps-small .custom-steps-item-title {
    padding-right: 12px;
    font-size: 14px;
    line-height: 24px;
  }
  .custom-steps-small .custom-steps-item-title::after {
    top: 12px;
  }
  .custom-steps-small .custom-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .custom-steps-small .custom-steps-item-tail {
    top: 8px;
  }
  .custom-steps-small .custom-steps-item-custom .custom-steps-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: none;
    border: 0;
    border-radius: 0;
  }
  .custom-steps-small .custom-steps-item-custom .custom-steps-item-icon > .custom-steps-icon {
    font-size: 24px;
    line-height: 24px;
    -webkit-transform: none;
            transform: none;
  }
  .custom-steps-vertical {
    display: flex;
    flex-direction: column;
  }
  .custom-steps-vertical > .custom-steps-item {
    display: block;
    flex: 1 0 auto;
    padding-left: 0;
    overflow: visible;
  }
  .custom-steps-vertical > .custom-steps-item .custom-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .custom-steps-vertical > .custom-steps-item .custom-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .custom-steps-vertical > .custom-steps-item .custom-steps-item-title {
    line-height: 32px;
  }
  .custom-steps-vertical > .custom-steps-item .custom-steps-item-description {
    padding-bottom: 12px;
  }
  .custom-steps-vertical > .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .custom-steps-vertical > .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .custom-steps-vertical > .custom-steps-item:not(:last-child) > .custom-steps-item-container > .custom-steps-item-tail {
    display: block;
  }
  .custom-steps-vertical > .custom-steps-item > .custom-steps-item-container > .custom-steps-item-content > .custom-steps-item-title::after {
    display: none;
  }
  .custom-steps-vertical.custom-steps-small .custom-steps-item-container .custom-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .custom-steps-vertical.custom-steps-small .custom-steps-item-container .custom-steps-item-title {
    line-height: 24px;
  }
  .custom-steps-label-vertical .custom-steps-item {
    overflow: visible;
  }
  .custom-steps-label-vertical .custom-steps-item-tail {
    margin-left: 58px;
    padding: 3.5px 24px;
  }
  .custom-steps-label-vertical .custom-steps-item-content {
    display: block;
    width: 116px;
    margin-top: 8px;
    text-align: center;
  }
  .custom-steps-label-vertical .custom-steps-item-icon {
    display: inline-block;
    margin-left: 42px;
  }
  .custom-steps-label-vertical .custom-steps-item-title {
    padding-right: 0;
    padding-left: 0;
  }
  .custom-steps-label-vertical .custom-steps-item-title::after {
    display: none;
  }
  .custom-steps-label-vertical .custom-steps-item-subtitle {
    display: block;
    margin-bottom: 4px;
    margin-left: 0;
    line-height: 1.5715;
  }
  .custom-steps-label-vertical.custom-steps-small:not(.custom-steps-dot) .custom-steps-item-icon {
    margin-left: 46px;
  }
  .custom-steps-dot .custom-steps-item-title,
  .custom-steps-dot.custom-steps-small .custom-steps-item-title {
    line-height: 1.5715;
  }
  .custom-steps-dot .custom-steps-item-tail,
  .custom-steps-dot.custom-steps-small .custom-steps-item-tail {
    top: 2px;
    width: 100%;
    margin: 0 0 0 70px;
    padding: 0;
  }
  .custom-steps-dot .custom-steps-item-tail::after,
  .custom-steps-dot.custom-steps-small .custom-steps-item-tail::after {
    width: calc(100% - 20px);
    height: 3px;
    margin-left: 12px;
  }
  .custom-steps-dot .custom-steps-item:first-child .custom-steps-icon-dot,
  .custom-steps-dot.custom-steps-small .custom-steps-item:first-child .custom-steps-icon-dot {
    left: 2px;
  }
  .custom-steps-dot .custom-steps-item-icon,
  .custom-steps-dot.custom-steps-small .custom-steps-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 67px;
    padding-right: 0;
    line-height: 8px;
    background: transparent;
    border: 0;
  }
  .custom-steps-dot .custom-steps-item-icon .custom-steps-icon-dot,
  .custom-steps-dot.custom-steps-small .custom-steps-item-icon .custom-steps-icon-dot {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    transition: all 0.3s;
    /* expand hover area */
  }
  .custom-steps-dot .custom-steps-item-icon .custom-steps-icon-dot::after,
  .custom-steps-dot.custom-steps-small .custom-steps-item-icon .custom-steps-icon-dot::after {
    position: absolute;
    top: -12px;
    left: -26px;
    width: 60px;
    height: 32px;
    background: rgba(0, 0, 0, 0.001);
    content: '';
  }
  .custom-steps-dot .custom-steps-item-content,
  .custom-steps-dot.custom-steps-small .custom-steps-item-content {
    width: 140px;
  }
  .custom-steps-dot .custom-steps-item-process .custom-steps-item-icon,
  .custom-steps-dot.custom-steps-small .custom-steps-item-process .custom-steps-item-icon {
    position: relative;
    top: -1px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    background: none;
  }
  .custom-steps-dot .custom-steps-item-process .custom-steps-icon:first-child .custom-steps-icon-dot,
  .custom-steps-dot.custom-steps-small .custom-steps-item-process .custom-steps-icon:first-child .custom-steps-icon-dot {
    left: 0;
  }
  .custom-steps-vertical.custom-steps-dot .custom-steps-item-icon {
    margin-top: 13px;
    margin-left: 0;
    background: none;
  }
  .custom-steps-vertical.custom-steps-dot .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail {
    top: 6.5px;
    left: -9px;
    margin: 0;
    padding: 22px 0 4px;
  }
  .custom-steps-vertical.custom-steps-dot.custom-steps-small .custom-steps-item-icon {
    margin-top: 10px;
  }
  .custom-steps-vertical.custom-steps-dot.custom-steps-small .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail {
    top: 3.5px;
  }
  .custom-steps-vertical.custom-steps-dot .custom-steps-item:first-child .custom-steps-icon-dot {
    left: 0;
  }
  .custom-steps-vertical.custom-steps-dot .custom-steps-item-content {
    width: inherit;
  }
  .custom-steps-vertical.custom-steps-dot .custom-steps-item-process .custom-steps-item-container .custom-steps-item-icon .custom-steps-icon-dot {
    top: -1px;
    left: -1px;
  }
  .custom-steps-navigation {
    padding-top: 12px;
  }
  .custom-steps-navigation.custom-steps-small .custom-steps-item-container {
    margin-left: -12px;
  }
  .custom-steps-navigation .custom-steps-item {
    overflow: visible;
    text-align: center;
  }
  .custom-steps-navigation .custom-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    text-align: left;
    transition: opacity 0.3s;
  }
  .custom-steps-navigation .custom-steps-item-container .custom-steps-item-content {
    max-width: auto;
  }
  .custom-steps-navigation .custom-steps-item-container .custom-steps-item-title {
    max-width: 100%;
    padding-right: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-steps-navigation .custom-steps-item-container .custom-steps-item-title::after {
    display: none;
  }
  .custom-steps-navigation .custom-steps-item:not(.custom-steps-item-active) .custom-steps-item-container[role='button'] {
    cursor: pointer;
  }
  .custom-steps-navigation .custom-steps-item:not(.custom-steps-item-active) .custom-steps-item-container[role='button']:hover {
    opacity: 0.85;
  }
  .custom-steps-navigation .custom-steps-item:last-child {
    flex: 1 1;
  }
  .custom-steps-navigation .custom-steps-item:last-child::after {
    display: none;
  }
  .custom-steps-navigation .custom-steps-item::after {
    position: absolute;
    top: 50%;
    left: 100%;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -14px;
    margin-left: -2px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: none;
    border-left: none;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    content: '';
  }
  .custom-steps-navigation .custom-steps-item::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 2px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    transition: width 0.3s, left 0.3s;
    transition-timing-function: ease-out;
    content: '';
  }
  .custom-steps-navigation .custom-steps-item.custom-steps-item-active::before {
    left: 0;
    width: 100%;
  }
  .custom-steps-navigation.custom-steps-vertical > .custom-steps-item {
    margin-right: 0 !important;
  }
  .custom-steps-navigation.custom-steps-vertical > .custom-steps-item::before {
    display: none;
  }
  .custom-steps-navigation.custom-steps-vertical > .custom-steps-item.custom-steps-item-active::before {
    top: 0;
    right: 0;
    left: auto;
    left: initial;
    display: block;
    width: 3px;
    height: calc(100% - 24px);
  }
  .custom-steps-navigation.custom-steps-vertical > .custom-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .custom-steps-navigation.custom-steps-vertical > .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail {
    visibility: hidden;
  }
  .custom-steps-navigation.custom-steps-horizontal > .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail {
    visibility: hidden;
  }
  .custom-steps-rtl {
    direction: rtl;
  }
  .custom-steps.custom-steps-rtl .custom-steps-item-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-steps-rtl .custom-steps-item-tail {
    right: 0;
    left: auto;
  }
  .custom-steps-rtl .custom-steps-item-title {
    padding-right: 0;
    padding-left: 16px;
  }
  .custom-steps-rtl .custom-steps-item-title .custom-steps-item-subtitle {
    float: left;
    margin-right: 8px;
    margin-left: 0;
  }
  .custom-steps-rtl .custom-steps-item-title::after {
    right: 100%;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item {
    padding-right: 16px;
    padding-left: 0;
  }
  .custom-steps-rtl.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item:first-child {
    padding-right: 0;
  }
  .custom-steps-rtl.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item:last-child .custom-steps-item-title {
    padding-left: 0;
  }
  .custom-steps-rtl .custom-steps-item-custom .custom-steps-item-icon > .custom-steps-icon {
    right: 0.5px;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-navigation.custom-steps-small .custom-steps-item-container {
    margin-right: -12px;
    margin-left: 0;
  }
  .custom-steps-rtl.custom-steps-navigation .custom-steps-item-container {
    margin-right: -16px;
    margin-left: 0;
    text-align: right;
  }
  .custom-steps-rtl.custom-steps-navigation .custom-steps-item-container .custom-steps-item-title {
    padding-left: 0;
  }
  .custom-steps-rtl.custom-steps-navigation .custom-steps-item::after {
    right: 100%;
    left: auto;
    margin-right: -2px;
    margin-left: 0;
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
  .custom-steps-rtl.custom-steps-small.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item {
    padding-right: 12px;
    padding-left: 0;
  }
  .custom-steps-rtl.custom-steps-small.custom-steps-horizontal:not(.custom-steps-label-vertical) .custom-steps-item:first-child {
    padding-right: 0;
  }
  .custom-steps-rtl.custom-steps-small .custom-steps-item-title {
    padding-right: 0;
    padding-left: 12px;
  }
  .custom-steps-rtl.custom-steps-vertical > .custom-steps-item .custom-steps-item-icon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  .custom-steps-rtl.custom-steps-vertical > .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail {
    right: 16px;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-vertical.custom-steps-small .custom-steps-item-container .custom-steps-item-tail {
    right: 12px;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-label-vertical .custom-steps-item-title {
    padding-left: 0;
  }
  .custom-steps-rtl.custom-steps-dot .custom-steps-item-tail,
  .custom-steps-rtl.custom-steps-dot.custom-steps-small .custom-steps-item-tail {
    margin: 0 70px 0 0;
  }
  .custom-steps-rtl.custom-steps-dot .custom-steps-item-tail::after,
  .custom-steps-rtl.custom-steps-dot.custom-steps-small .custom-steps-item-tail::after {
    margin-right: 12px;
    margin-left: 0;
  }
  .custom-steps-rtl.custom-steps-dot .custom-steps-item:first-child .custom-steps-icon-dot,
  .custom-steps-rtl.custom-steps-dot.custom-steps-small .custom-steps-item:first-child .custom-steps-icon-dot {
    right: 2px;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-dot .custom-steps-item-icon,
  .custom-steps-rtl.custom-steps-dot.custom-steps-small .custom-steps-item-icon {
    margin-right: 67px;
    margin-left: 0;
  }
  .custom-steps-dot .custom-steps-item-icon .custom-steps-icon-dot,
  .custom-steps-dot.custom-steps-small .custom-steps-item-icon .custom-steps-icon-dot {
    /* expand hover area */
  }
  .custom-steps-rtl.custom-steps-dot .custom-steps-item-icon .custom-steps-icon-dot,
  .custom-steps-rtl.custom-steps-dot.custom-steps-small .custom-steps-item-icon .custom-steps-icon-dot {
    float: right;
  }
  .custom-steps-rtl.custom-steps-dot .custom-steps-item-icon .custom-steps-icon-dot::after,
  .custom-steps-rtl.custom-steps-dot.custom-steps-small .custom-steps-item-icon .custom-steps-icon-dot::after {
    right: -26px;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-vertical.custom-steps-dot .custom-steps-item-icon {
    margin-right: 0;
    margin-left: 16px;
  }
  .custom-steps-rtl.custom-steps-vertical.custom-steps-dot .custom-steps-item > .custom-steps-item-container > .custom-steps-item-tail {
    right: -9px;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-vertical.custom-steps-dot .custom-steps-item:first-child .custom-steps-icon-dot {
    right: 0;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-vertical.custom-steps-dot .custom-steps-item-process .custom-steps-icon-dot {
    right: -2px;
    left: auto;
  }
  .custom-steps-rtl.custom-steps-with-progress.custom-steps-horizontal.custom-steps-label-horizontal .custom-steps-item:first-child {
    padding-right: 4px;
    padding-left: 0;
  }
  .custom-steps-rtl.custom-steps-with-progress.custom-steps-horizontal.custom-steps-label-horizontal .custom-steps-item:first-child.custom-steps-item-active {
    padding-right: 4px;
  }
  .custom-steps-with-progress .custom-steps-item {
    padding-top: 4px;
  }
  .custom-steps-with-progress .custom-steps-item .custom-steps-item-tail {
    top: 4px !important;
  }
  .custom-steps-with-progress.custom-steps-horizontal .custom-steps-item:first-child {
    padding-bottom: 4px;
    padding-left: 4px;
  }
  .custom-steps-with-progress.custom-steps-label-vertical .custom-steps-item .custom-steps-item-tail {
    top: 14px !important;
  }
  .custom-steps-with-progress .custom-steps-item-icon {
    position: relative;
  }
  .custom-steps-with-progress .custom-steps-item-icon .custom-progress {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
  .custom-switch {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(to right, #fff, #fff);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
  .custom-switch-checked:focus {
    box-shadow: 0 0 0 2px #e6f7ff;
    box-shadow: 0 0 0 2px var(--custom-primary-1);
  }
  .custom-switch:focus:hover {
    box-shadow: none;
  }
  .custom-switch-checked {
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-switch-loading,
  .custom-switch-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
  .custom-switch-loading *,
  .custom-switch-disabled * {
    box-shadow: none;
    cursor: not-allowed;
  }
  .custom-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    transition: margin 0.2s;
  }
  .custom-switch-checked .custom-switch-inner {
    margin: 0 25px 0 7px;
  }
  .custom-switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-in-out;
  }
  .custom-switch-handle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    transition: all 0.2s ease-in-out;
    content: '';
  }
  .custom-switch-checked .custom-switch-handle {
    left: calc(100% - 18px - 2px);
  }
  .custom-switch:not(.custom-switch-disabled):active .custom-switch-handle::before {
    right: -30%;
    left: 0;
  }
  .custom-switch:not(.custom-switch-disabled):active.custom-switch-checked .custom-switch-handle::before {
    right: 0;
    left: -30%;
  }
  .custom-switch-loading-icon.anticon {
    position: relative;
    top: 2px;
    color: rgba(0, 0, 0, 0.65);
    vertical-align: top;
  }
  .custom-switch-checked .custom-switch-loading-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-switch-small {
    min-width: 28px;
    height: 16px;
    line-height: 16px;
  }
  .custom-switch-small .custom-switch-inner {
    margin: 0 5px 0 18px;
    font-size: 12px;
  }
  .custom-switch-small .custom-switch-handle {
    width: 12px;
    height: 12px;
  }
  .custom-switch-small .custom-switch-loading-icon {
    top: 1.5px;
    font-size: 9px;
  }
  .custom-switch-small.custom-switch-checked .custom-switch-inner {
    margin: 0 18px 0 5px;
  }
  .custom-switch-small.custom-switch-checked .custom-switch-handle {
    left: calc(100% - 12px - 2px);
  }
  .custom-switch-rtl {
    direction: rtl;
  }
  .custom-switch-rtl .custom-switch-inner {
    margin: 0 25px 0 7px;
  }
  .custom-switch-rtl .custom-switch-handle {
    right: 2px;
    left: auto;
  }
  .custom-switch-rtl:not(.custom-switch-rtl-disabled):active .custom-switch-handle::before {
    right: 0;
    left: -30%;
  }
  .custom-switch-rtl:not(.custom-switch-rtl-disabled):active.custom-switch-checked .custom-switch-handle::before {
    right: -30%;
    left: 0;
  }
  .custom-switch-rtl.custom-switch-checked .custom-switch-inner {
    margin: 0 7px 0 25px;
  }
  .custom-switch-rtl.custom-switch-checked .custom-switch-handle {
    right: calc(100% - 18px - 2px);
  }
  .custom-switch-rtl.custom-switch-small.custom-switch-checked .custom-switch-handle {
    right: calc(100% - 12px - 2px);
  }
  .custom-table.custom-table-middle {
    font-size: 14px;
  }
  .custom-table.custom-table-middle .custom-table-title,
  .custom-table.custom-table-middle .custom-table-footer,
  .custom-table.custom-table-middle .custom-table-thead > tr > th,
  .custom-table.custom-table-middle .custom-table-tbody > tr > td,
  .custom-table.custom-table-middle tfoot > tr > th,
  .custom-table.custom-table-middle tfoot > tr > td {
    padding: 12px 8px;
  }
  .custom-table.custom-table-middle .custom-table-filter-trigger {
    margin-right: -4px;
  }
  .custom-table.custom-table-middle .custom-table-expanded-row-fixed {
    margin: -12px -8px;
  }
  .custom-table.custom-table-middle .custom-table-tbody .custom-table-wrapper:only-child .custom-table {
    margin: -12px -8px -12px 40px;
  }
  .custom-table.custom-table-middle .custom-table-selection-column {
    -webkit-padding-start: 2px;
            padding-inline-start: 2px;
  }
  .custom-table.custom-table-small {
    font-size: 14px;
  }
  .custom-table.custom-table-small .custom-table-title,
  .custom-table.custom-table-small .custom-table-footer,
  .custom-table.custom-table-small .custom-table-thead > tr > th,
  .custom-table.custom-table-small .custom-table-tbody > tr > td,
  .custom-table.custom-table-small tfoot > tr > th,
  .custom-table.custom-table-small tfoot > tr > td {
    padding: 8px 8px;
  }
  .custom-table.custom-table-small .custom-table-filter-trigger {
    margin-right: -4px;
  }
  .custom-table.custom-table-small .custom-table-expanded-row-fixed {
    margin: -8px -8px;
  }
  .custom-table.custom-table-small .custom-table-tbody .custom-table-wrapper:only-child .custom-table {
    margin: -8px -8px -8px 40px;
  }
  .custom-table.custom-table-small .custom-table-selection-column {
    -webkit-padding-start: 2px;
            padding-inline-start: 2px;
  }
  .custom-table.custom-table-bordered > .custom-table-title {
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 0;
  }
  .custom-table.custom-table-bordered > .custom-table-container {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > thead > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > thead > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > thead > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > thead > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > tbody > tr > td,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > tbody > tr > td,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > tbody > tr > td,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > tbody > tr > td,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > tfoot > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > tfoot > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > tfoot > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > tfoot > tr > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > tfoot > tr > td,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > tfoot > tr > td,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > tfoot > tr > td,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > tfoot > tr > td {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > thead > tr:not(:last-child) > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > thead > tr:not(:last-child) > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > thead > tr:not(:last-child) > th,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > thead > tr:not(:last-child) > th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > thead > tr > th::before,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > thead > tr > th::before,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > thead > tr > th::before,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > thead > tr > th::before {
    background-color: transparent !important;
  }
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > thead > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > thead > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > thead > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > thead > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > tbody > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > tbody > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > tbody > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > tbody > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > tfoot > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > tfoot > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > tfoot > tr > .custom-table-cell-fix-right-first::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > tfoot > tr > .custom-table-cell-fix-right-first::after {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > tbody > tr > td > .custom-table-expanded-row-fixed,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > tbody > tr > td > .custom-table-expanded-row-fixed,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > tbody > tr > td > .custom-table-expanded-row-fixed,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > tbody > tr > td > .custom-table-expanded-row-fixed {
    margin: -16px -17px;
  }
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table > tbody > tr > td > .custom-table-expanded-row-fixed::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table > tbody > tr > td > .custom-table-expanded-row-fixed::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-body > table > tbody > tr > td > .custom-table-expanded-row-fixed::after,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-summary > table > tbody > tr > td > .custom-table-expanded-row-fixed::after {
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    content: '';
  }
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-content > table,
  .custom-table.custom-table-bordered > .custom-table-container > .custom-table-header > table {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-table.custom-table-bordered.custom-table-scroll-horizontal > .custom-table-container > .custom-table-body > table > tbody > tr.custom-table-expanded-row > td,
  .custom-table.custom-table-bordered.custom-table-scroll-horizontal > .custom-table-container > .custom-table-body > table > tbody > tr.custom-table-placeholder > td {
    border-right: 0;
  }
  .custom-table.custom-table-bordered.custom-table-middle > .custom-table-container > .custom-table-content > table > tbody > tr > td > .custom-table-expanded-row-fixed,
  .custom-table.custom-table-bordered.custom-table-middle > .custom-table-container > .custom-table-body > table > tbody > tr > td > .custom-table-expanded-row-fixed {
    margin: -12px -9px;
  }
  .custom-table.custom-table-bordered.custom-table-small > .custom-table-container > .custom-table-content > table > tbody > tr > td > .custom-table-expanded-row-fixed,
  .custom-table.custom-table-bordered.custom-table-small > .custom-table-container > .custom-table-body > table > tbody > tr > td > .custom-table-expanded-row-fixed {
    margin: -8px -9px;
  }
  .custom-table.custom-table-bordered > .custom-table-footer {
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-top: 0;
  }
  .custom-table-cell .custom-table-container:first-child {
    border-top: 0;
  }
  .custom-table-cell-scrollbar:not([rowspan]) {
    box-shadow: 0 1px 0 1px #fafafa;
  }
  .custom-table-wrapper {
    clear: both;
    max-width: 100%;
  }
  .custom-table-wrapper::before {
    display: table;
    content: '';
  }
  .custom-table-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-table-wrapper::before {
    display: table;
    content: '';
  }
  .custom-table-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    font-size: 14px;
    background: #fff;
    border-radius: 2px;
  }
  .custom-table table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
  }
  .custom-table-thead > tr > th,
  .custom-table-tbody > tr > td,
  .custom-table tfoot > tr > th,
  .custom-table tfoot > tr > td {
    position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
  }
  .custom-table-cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .custom-table-cell-ellipsis.custom-table-cell-fix-left-last,
  .custom-table-cell-ellipsis.custom-table-cell-fix-right-first {
    overflow: visible;
  }
  .custom-table-cell-ellipsis.custom-table-cell-fix-left-last .custom-table-cell-content,
  .custom-table-cell-ellipsis.custom-table-cell-fix-right-first .custom-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-table-cell-ellipsis .custom-table-column-title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .custom-table-title {
    padding: 16px 16px;
  }
  .custom-table-footer {
    padding: 16px 16px;
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
  }
  .custom-table-thead > tr > th {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    transition: background 0.3s ease;
  }
  .custom-table-thead > tr > th[colspan]:not([colspan='1']) {
    text-align: center;
  }
  .custom-table-thead > tr > th:not(:last-child):not(.custom-table-selection-column):not(.custom-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }
  .custom-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 0;
  }
  .custom-table-tbody > tr > td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    transition: background 0.3s;
  }
  .custom-table-tbody > tr > td > .custom-table-wrapper:only-child .custom-table,
  .custom-table-tbody > tr > td > .custom-table-expanded-row-fixed > .custom-table-wrapper:only-child .custom-table {
    margin: -16px -16px -16px 32px;
  }
  .custom-table-tbody > tr > td > .custom-table-wrapper:only-child .custom-table-tbody > tr:last-child > td,
  .custom-table-tbody > tr > td > .custom-table-expanded-row-fixed > .custom-table-wrapper:only-child .custom-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
  .custom-table-tbody > tr > td > .custom-table-wrapper:only-child .custom-table-tbody > tr:last-child > td:first-child,
  .custom-table-tbody > tr > td > .custom-table-expanded-row-fixed > .custom-table-wrapper:only-child .custom-table-tbody > tr:last-child > td:first-child,
  .custom-table-tbody > tr > td > .custom-table-wrapper:only-child .custom-table-tbody > tr:last-child > td:last-child,
  .custom-table-tbody > tr > td > .custom-table-expanded-row-fixed > .custom-table-wrapper:only-child .custom-table-tbody > tr:last-child > td:last-child {
    border-radius: 0;
  }
  .custom-table-tbody > tr.custom-table-row:hover > td,
  .custom-table-tbody > tr > td.custom-table-cell-row-hover {
    background: #fafafa;
  }
  .custom-table-tbody > tr.custom-table-row-selected > td {
    background: #e6f7ff;
    background: var(--custom-primary-1);
    border-color: rgba(0, 0, 0, 0.03);
  }
  .custom-table-tbody > tr.custom-table-row-selected:hover > td {
    background: #dcf4ff;
    background: var(--custom-primary-color-active-deprecated-d-02);
  }
  .custom-table-summary {
    position: relative;
    z-index: 2;
    background: #fff;
  }
  div.custom-table-summary {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
  }
  .custom-table-summary > tr > th,
  .custom-table-summary > tr > td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-table-pagination.custom-pagination {
    margin: 16px 0;
  }
  .custom-table-pagination {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
  }
  .custom-table-pagination > * {
    flex: none;
  }
  .custom-table-pagination-left {
    justify-content: flex-start;
  }
  .custom-table-pagination-center {
    justify-content: center;
  }
  .custom-table-pagination-right {
    justify-content: flex-end;
  }
  .custom-table-thead th.custom-table-column-has-sorters {
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-table-thead th.custom-table-column-has-sorters:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .custom-table-thead th.custom-table-column-has-sorters:hover::before {
    background-color: transparent !important;
  }
  .custom-table-thead th.custom-table-column-has-sorters:focus-visible {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-table-thead th.custom-table-column-has-sorters.custom-table-cell-fix-left:hover,
  .custom-table-thead th.custom-table-column-has-sorters.custom-table-cell-fix-right:hover {
    background: #f5f5f5;
  }
  .custom-table-thead th.custom-table-column-sort {
    background: #f5f5f5;
  }
  .custom-table-thead th.custom-table-column-sort::before {
    background-color: transparent !important;
  }
  td.custom-table-column-sort {
    background: #fafafa;
  }
  .custom-table-column-title {
    position: relative;
    z-index: 1;
    flex: 1 1;
  }
  .custom-table-column-sorters {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
  }
  .custom-table-column-sorters::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
  .custom-table-column-sorter {
    margin-left: 4px;
    color: #bfbfbf;
    font-size: 0;
    transition: color 0.3s;
  }
  .custom-table-column-sorter-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-table-column-sorter-up,
  .custom-table-column-sorter-down {
    font-size: 11px;
  }
  .custom-table-column-sorter-up.active,
  .custom-table-column-sorter-down.active {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-table-column-sorter-up + .custom-table-column-sorter-down {
    margin-top: -0.3em;
  }
  .custom-table-column-sorters:hover .custom-table-column-sorter {
    color: #a6a6a6;
  }
  .custom-table-filter-column {
    display: flex;
    justify-content: space-between;
  }
  .custom-table-filter-trigger {
    position: relative;
    display: flex;
    align-items: center;
    margin: -4px -8px -4px 4px;
    padding: 0 4px;
    color: #bfbfbf;
    font-size: 12px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-table-filter-trigger:hover {
    color: rgba(0, 0, 0, 0.45);
    background: rgba(0, 0, 0, 0.04);
  }
  .custom-table-filter-trigger.active {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-table-filter-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    min-width: 120px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .custom-table-filter-dropdown .custom-dropdown-menu {
    max-height: 264px;
    overflow-x: hidden;
    border: 0;
    box-shadow: none;
  }
  .custom-table-filter-dropdown .custom-dropdown-menu:empty::after {
    display: block;
    padding: 8px 0;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    text-align: center;
    content: 'Not Found';
  }
  .custom-table-filter-dropdown-tree {
    padding: 8px 8px 0;
  }
  .custom-table-filter-dropdown-tree .custom-tree-treenode .custom-tree-node-content-wrapper:hover {
    background-color: #f5f5f5;
  }
  .custom-table-filter-dropdown-tree .custom-tree-treenode-checkbox-checked .custom-tree-node-content-wrapper,
  .custom-table-filter-dropdown-tree .custom-tree-treenode-checkbox-checked .custom-tree-node-content-wrapper:hover {
    background-color: #bae7ff;
    background-color: var(--custom-primary-2);
  }
  .custom-table-filter-dropdown-search {
    padding: 8px;
    border-bottom: 1px rgba(0, 0, 0, 0.06) solid;
  }
  .custom-table-filter-dropdown-search-input input {
    min-width: 140px;
  }
  .custom-table-filter-dropdown-search-input .anticon {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-table-filter-dropdown-checkall {
    width: 100%;
    margin-bottom: 4px;
    margin-left: 4px;
  }
  .custom-table-filter-dropdown-submenu > ul {
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .custom-table-filter-dropdown .custom-checkbox-wrapper + span,
  .custom-table-filter-dropdown-submenu .custom-checkbox-wrapper + span {
    padding-left: 8px;
  }
  .custom-table-filter-dropdown-btns {
    display: flex;
    justify-content: space-between;
    padding: 7px 8px;
    overflow: hidden;
    background-color: inherit;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-table-selection-col {
    width: 32px;
  }
  .custom-table-bordered .custom-table-selection-col {
    width: 50px;
  }
  table tr th.custom-table-selection-column,
  table tr td.custom-table-selection-column {
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
  }
  table tr th.custom-table-selection-column .custom-radio-wrapper,
  table tr td.custom-table-selection-column .custom-radio-wrapper {
    margin-right: 0;
  }
  table tr th.custom-table-selection-column.custom-table-cell-fix-left {
    z-index: 3;
  }
  table tr th.custom-table-selection-column::after {
    background-color: transparent !important;
  }
  .custom-table-selection {
    position: relative;
    display: inline-flex;
    flex-direction: column;
  }
  .custom-table-selection-extra {
    position: absolute;
    top: 0;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-margin-start: 100%;
            margin-inline-start: 100%;
    -webkit-padding-start: 4px;
            padding-inline-start: 4px;
  }
  .custom-table-selection-extra .anticon {
    color: #bfbfbf;
    font-size: 10px;
  }
  .custom-table-selection-extra .anticon:hover {
    color: #a6a6a6;
  }
  .custom-table-expand-icon-col {
    width: 48px;
  }
  .custom-table-row-expand-icon-cell {
    text-align: center;
  }
  .custom-table-row-expand-icon-cell .custom-table-row-expand-icon {
    display: inline-flex;
    float: none;
    vertical-align: sub;
  }
  .custom-table-row-indent {
    float: left;
    height: 1px;
  }
  .custom-table-row-expand-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    float: left;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    padding: 0;
    color: inherit;
    line-height: 17px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    -webkit-transform: scale(0.94117647);
            transform: scale(0.94117647);
    transition: all 0.3s;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-table-row-expand-icon:focus-visible,
  .custom-table-row-expand-icon:hover {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
  }
  .custom-table-row-expand-icon:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
  }
  .custom-table-row-expand-icon:focus,
  .custom-table-row-expand-icon:hover,
  .custom-table-row-expand-icon:active {
    border-color: currentcolor;
  }
  .custom-table-row-expand-icon::before,
  .custom-table-row-expand-icon::after {
    position: absolute;
    background: currentcolor;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    content: '';
  }
  .custom-table-row-expand-icon::before {
    top: 7px;
    right: 3px;
    left: 3px;
    height: 1px;
  }
  .custom-table-row-expand-icon::after {
    top: 3px;
    bottom: 3px;
    left: 7px;
    width: 1px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .custom-table-row-expand-icon-collapsed::before {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .custom-table-row-expand-icon-collapsed::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .custom-table-row-expand-icon-spaced {
    background: transparent;
    border: 0;
    visibility: hidden;
  }
  .custom-table-row-expand-icon-spaced::before,
  .custom-table-row-expand-icon-spaced::after {
    display: none;
    content: none;
  }
  .custom-table-row-indent + .custom-table-row-expand-icon {
    margin-top: 2.5005px;
    margin-right: 8px;
  }
  tr.custom-table-expanded-row > td,
  tr.custom-table-expanded-row:hover > td {
    background: #fbfbfb;
  }
  tr.custom-table-expanded-row .custom-descriptions-view {
    display: flex;
  }
  tr.custom-table-expanded-row .custom-descriptions-view table {
    flex: auto;
    width: auto;
  }
  .custom-table .custom-table-expanded-row-fixed {
    position: relative;
    margin: -16px -16px;
    padding: 16px 16px;
  }
  .custom-table-tbody > tr.custom-table-placeholder {
    text-align: center;
  }
  .custom-table-empty .custom-table-tbody > tr.custom-table-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-table-tbody > tr.custom-table-placeholder:hover > td {
    background: #fff;
  }
  .custom-table-cell-fix-left,
  .custom-table-cell-fix-right {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 2;
    background: #fff;
  }
  .custom-table-cell-fix-left-first::after,
  .custom-table-cell-fix-left-last::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    width: 30px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .custom-table-cell-fix-left-all::after {
    display: none;
  }
  .custom-table-cell-fix-right-first::after,
  .custom-table-cell-fix-right-last::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .custom-table .custom-table-container::before,
  .custom-table .custom-table-container::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .custom-table .custom-table-container::before {
    left: 0;
  }
  .custom-table .custom-table-container::after {
    right: 0;
  }
  .custom-table-ping-left:not(.custom-table-has-fix-left) > .custom-table-container {
    position: relative;
  }
  .custom-table-ping-left:not(.custom-table-has-fix-left) > .custom-table-container::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .custom-table-ping-left .custom-table-cell-fix-left-first::after,
  .custom-table-ping-left .custom-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .custom-table-ping-left .custom-table-cell-fix-left-last::before {
    background-color: transparent !important;
  }
  .custom-table-ping-right:not(.custom-table-has-fix-right) > .custom-table-container {
    position: relative;
  }
  .custom-table-ping-right:not(.custom-table-has-fix-right) > .custom-table-container::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .custom-table-ping-right .custom-table-cell-fix-right-first::after,
  .custom-table-ping-right .custom-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .custom-table-sticky-holder {
    position: -webkit-sticky;
    position: sticky;
    z-index: calc(2 + 1);
    background: #fff;
  }
  .custom-table-sticky-scroll {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: calc(2 + 1);
    display: flex;
    align-items: center;
    background: rgba(204, 204, 204, 0.06);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    opacity: 0.6;
  }
  .custom-table-sticky-scroll:hover {
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
  }
  .custom-table-sticky-scroll-bar {
    height: 8px;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 4px;
  }
  .custom-table-sticky-scroll-bar:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .custom-table-sticky-scroll-bar-active {
    background-color: rgba(0, 0, 0, 0.8);
  }
  @media all and (-ms-high-contrast: none) {
    .custom-table-ping-left .custom-table-cell-fix-left-last::after {
      box-shadow: none !important;
    }
    .custom-table-ping-right .custom-table-cell-fix-right-first::after {
      box-shadow: none !important;
    }
  }
  .custom-table {
    /* title + table */
    /* table */
    /* table + footer */
  }
  .custom-table-title {
    border-radius: 2px 2px 0 0;
  }
  .custom-table-title + .custom-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .custom-table-title + .custom-table-container table {
    border-radius: 0;
  }
  .custom-table-title + .custom-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0;
  }
  .custom-table-title + .custom-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0;
  }
  .custom-table-container {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .custom-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 2px;
  }
  .custom-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 2px;
  }
  .custom-table-footer {
    border-radius: 0 0 2px 2px;
  }
  .custom-table-wrapper-rtl {
    direction: rtl;
  }
  .custom-table-rtl {
    direction: rtl;
  }
  .custom-table-wrapper-rtl .custom-table table {
    text-align: right;
  }
  .custom-table-wrapper-rtl .custom-table-thead > tr > th[colspan]:not([colspan='1']) {
    text-align: center;
  }
  .custom-table-wrapper-rtl .custom-table-thead > tr > th:not(:last-child):not(.custom-table-selection-column):not(.custom-table-row-expand-icon-cell):not([colspan])::before {
    right: auto;
    left: 0;
  }
  .custom-table-wrapper-rtl .custom-table-thead > tr > th {
    text-align: right;
  }
  .custom-table-tbody > tr .custom-table-wrapper:only-child .custom-table.custom-table-rtl {
    margin: -16px 33px -16px -16px;
  }
  .custom-table-wrapper.custom-table-wrapper-rtl .custom-table-pagination-left {
    justify-content: flex-end;
  }
  .custom-table-wrapper.custom-table-wrapper-rtl .custom-table-pagination-right {
    justify-content: flex-start;
  }
  .custom-table-wrapper-rtl .custom-table-column-sorter {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-table-wrapper-rtl .custom-table-filter-column-title {
    padding: 16px 16px 16px 2.3em;
  }
  .custom-table-rtl .custom-table-thead tr th.custom-table-column-has-sorters .custom-table-filter-column-title {
    padding: 0 0 0 2.3em;
  }
  .custom-table-wrapper-rtl .custom-table-filter-trigger {
    margin: -4px 4px -4px -8px;
  }
  .custom-dropdown-rtl .custom-table-filter-dropdown .custom-checkbox-wrapper + span,
  .custom-dropdown-rtl .custom-table-filter-dropdown-submenu .custom-checkbox-wrapper + span,
  .custom-dropdown-menu-submenu-rtl.custom-table-filter-dropdown .custom-checkbox-wrapper + span,
  .custom-dropdown-menu-submenu-rtl.custom-table-filter-dropdown-submenu .custom-checkbox-wrapper + span {
    padding-right: 8px;
    padding-left: 0;
  }
  .custom-table-wrapper-rtl .custom-table-selection {
    text-align: center;
  }
  .custom-table-wrapper-rtl .custom-table-row-indent {
    float: right;
  }
  .custom-table-wrapper-rtl .custom-table-row-expand-icon {
    float: right;
  }
  .custom-table-wrapper-rtl .custom-table-row-indent + .custom-table-row-expand-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-table-wrapper-rtl .custom-table-row-expand-icon::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .custom-table-wrapper-rtl .custom-table-row-expand-icon-collapsed::before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .custom-table-wrapper-rtl .custom-table-row-expand-icon-collapsed::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .custom-tabs-small > .custom-tabs-nav .custom-tabs-tab {
    padding: 8px 0;
    font-size: 14px;
  }
  .custom-tabs-large > .custom-tabs-nav .custom-tabs-tab {
    padding: 16px 0;
    font-size: 16px;
  }
  .custom-tabs-card.custom-tabs-small > .custom-tabs-nav .custom-tabs-tab {
    padding: 6px 16px;
  }
  .custom-tabs-card.custom-tabs-large > .custom-tabs-nav .custom-tabs-tab {
    padding: 7px 16px 6px;
  }
  .custom-tabs-rtl {
    direction: rtl;
  }
  .custom-tabs-rtl .custom-tabs-nav .custom-tabs-tab {
    margin: 0 0 0 32px;
  }
  .custom-tabs-rtl .custom-tabs-nav .custom-tabs-tab:last-of-type {
    margin-left: 0;
  }
  .custom-tabs-rtl .custom-tabs-nav .custom-tabs-tab .anticon {
    margin-right: 0;
    margin-left: 12px;
  }
  .custom-tabs-rtl .custom-tabs-nav .custom-tabs-tab .custom-tabs-tab-remove {
    margin-right: 8px;
    margin-left: -4px;
  }
  .custom-tabs-rtl .custom-tabs-nav .custom-tabs-tab .custom-tabs-tab-remove .anticon {
    margin: 0;
  }
  .custom-tabs-rtl.custom-tabs-left > .custom-tabs-nav {
    order: 1;
  }
  .custom-tabs-rtl.custom-tabs-left > .custom-tabs-content-holder {
    order: 0;
  }
  .custom-tabs-rtl.custom-tabs-right > .custom-tabs-nav {
    order: 0;
  }
  .custom-tabs-rtl.custom-tabs-right > .custom-tabs-content-holder {
    order: 1;
  }
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-top > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-bottom > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-top > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab {
    margin-right: 2px;
    margin-left: 0;
  }
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-top > .custom-tabs-nav .custom-tabs-nav-add,
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-bottom > .custom-tabs-nav .custom-tabs-nav-add,
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-top > div > .custom-tabs-nav .custom-tabs-nav-add,
  .custom-tabs-rtl.custom-tabs-card.custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-nav-add {
    margin-right: 2px;
    margin-left: 0;
  }
  .custom-tabs-dropdown-rtl {
    direction: rtl;
  }
  .custom-tabs-dropdown-rtl .custom-tabs-dropdown-menu-item {
    text-align: right;
  }
  .custom-tabs-top,
  .custom-tabs-bottom {
    flex-direction: column;
  }
  .custom-tabs-top > .custom-tabs-nav,
  .custom-tabs-bottom > .custom-tabs-nav,
  .custom-tabs-top > div > .custom-tabs-nav,
  .custom-tabs-bottom > div > .custom-tabs-nav {
    margin: 0 0 16px 0;
  }
  .custom-tabs-top > .custom-tabs-nav::before,
  .custom-tabs-bottom > .custom-tabs-nav::before,
  .custom-tabs-top > div > .custom-tabs-nav::before,
  .custom-tabs-bottom > div > .custom-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    content: '';
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-ink-bar {
    height: 2px;
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-ink-bar-animated,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-ink-bar-animated,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-ink-bar-animated,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-ink-bar-animated {
    transition: width 0.3s, left 0.3s, right 0.3s;
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-nav-wrap::after {
    top: 0;
    bottom: 0;
    width: 30px;
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-nav-wrap::before {
    left: 0;
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-nav-wrap::after {
    right: 0;
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-left::before,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-left::before,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-left::before,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-left::before {
    opacity: 1;
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-right::after,
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-right::after,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-right::after,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-right::after {
    opacity: 1;
  }
  .custom-tabs-top > .custom-tabs-nav::before,
  .custom-tabs-top > div > .custom-tabs-nav::before {
    bottom: 0;
  }
  .custom-tabs-top > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-top > div > .custom-tabs-nav .custom-tabs-ink-bar {
    bottom: 0;
  }
  .custom-tabs-bottom > .custom-tabs-nav,
  .custom-tabs-bottom > div > .custom-tabs-nav {
    order: 1;
    margin-top: 16px;
    margin-bottom: 0;
  }
  .custom-tabs-bottom > .custom-tabs-nav::before,
  .custom-tabs-bottom > div > .custom-tabs-nav::before {
    top: 0;
  }
  .custom-tabs-bottom > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-ink-bar {
    top: 0;
  }
  .custom-tabs-bottom > .custom-tabs-content-holder,
  .custom-tabs-bottom > div > .custom-tabs-content-holder {
    order: 0;
  }
  .custom-tabs-left > .custom-tabs-nav,
  .custom-tabs-right > .custom-tabs-nav,
  .custom-tabs-left > div > .custom-tabs-nav,
  .custom-tabs-right > div > .custom-tabs-nav {
    flex-direction: column;
    min-width: 50px;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-tab {
    padding: 8px 24px;
    text-align: center;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab {
    margin: 16px 0 0 0;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-wrap,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-wrap,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-wrap,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-wrap {
    flex-direction: column;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-wrap::after {
    right: 0;
    left: 0;
    height: 30px;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-wrap::before {
    top: 0;
    box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-wrap::after {
    bottom: 0;
    box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-top::before,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-top::before,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-top::before,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-top::before {
    opacity: 1;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-bottom::after,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-bottom::after,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-bottom::after,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-wrap.custom-tabs-nav-wrap-ping-bottom::after {
    opacity: 1;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-ink-bar {
    width: 2px;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-ink-bar-animated,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-ink-bar-animated,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-ink-bar-animated,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-ink-bar-animated {
    transition: height 0.3s, top 0.3s;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-list,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-list,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-list,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-list,
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-nav-operations,
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-nav-operations,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-nav-operations,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-nav-operations {
    flex: 1 0 auto;
    flex-direction: column;
  }
  .custom-tabs-left > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-left > div > .custom-tabs-nav .custom-tabs-ink-bar {
    right: 0;
  }
  .custom-tabs-left > .custom-tabs-content-holder,
  .custom-tabs-left > div > .custom-tabs-content-holder {
    margin-left: -1px;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-tabs-left > .custom-tabs-content-holder > .custom-tabs-content > .custom-tabs-tabpane,
  .custom-tabs-left > div > .custom-tabs-content-holder > .custom-tabs-content > .custom-tabs-tabpane {
    padding-left: 24px;
  }
  .custom-tabs-right > .custom-tabs-nav,
  .custom-tabs-right > div > .custom-tabs-nav {
    order: 1;
  }
  .custom-tabs-right > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-right > div > .custom-tabs-nav .custom-tabs-ink-bar {
    left: 0;
  }
  .custom-tabs-right > .custom-tabs-content-holder,
  .custom-tabs-right > div > .custom-tabs-content-holder {
    order: 0;
    margin-right: -1px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-tabs-right > .custom-tabs-content-holder > .custom-tabs-content > .custom-tabs-tabpane,
  .custom-tabs-right > div > .custom-tabs-content-holder > .custom-tabs-content > .custom-tabs-tabpane {
    padding-right: 24px;
  }
  .custom-tabs-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .custom-tabs-dropdown-hidden {
    display: none;
  }
  .custom-tabs-dropdown-menu {
    max-height: 200px;
    margin: 0;
    padding: 4px 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .custom-tabs-dropdown-menu-item {
    display: flex;
    align-items: center;
    min-width: 120px;
    margin: 0;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-tabs-dropdown-menu-item > span {
    flex: 1 1;
    white-space: nowrap;
  }
  .custom-tabs-dropdown-menu-item-remove {
    flex: none;
    margin-left: 12px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  .custom-tabs-dropdown-menu-item-remove:hover {
    color: #40a9ff;
    color: var(--custom-primary-5);
  }
  .custom-tabs-dropdown-menu-item:hover {
    background: #f5f5f5;
  }
  .custom-tabs-dropdown-menu-item-disabled,
  .custom-tabs-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    cursor: not-allowed;
  }
  .custom-tabs-card > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-card > div > .custom-tabs-nav .custom-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.06);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-tabs-card > .custom-tabs-nav .custom-tabs-tab-active,
  .custom-tabs-card > div > .custom-tabs-nav .custom-tabs-tab-active {
    color: #1890ff;
    color: var(--custom-primary-color);
    background: #fff;
  }
  .custom-tabs-card > .custom-tabs-nav .custom-tabs-ink-bar,
  .custom-tabs-card > div > .custom-tabs-nav .custom-tabs-ink-bar {
    visibility: hidden;
  }
  .custom-tabs-card.custom-tabs-top > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-bottom > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-top > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab {
    margin-left: 2px;
  }
  .custom-tabs-card.custom-tabs-top > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-top > div > .custom-tabs-nav .custom-tabs-tab {
    border-radius: 2px 2px 0 0;
  }
  .custom-tabs-card.custom-tabs-top > .custom-tabs-nav .custom-tabs-tab-active,
  .custom-tabs-card.custom-tabs-top > div > .custom-tabs-nav .custom-tabs-tab-active {
    border-bottom-color: #fff;
  }
  .custom-tabs-card.custom-tabs-bottom > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-tab {
    border-radius: 0 0 2px 2px;
  }
  .custom-tabs-card.custom-tabs-bottom > .custom-tabs-nav .custom-tabs-tab-active,
  .custom-tabs-card.custom-tabs-bottom > div > .custom-tabs-nav .custom-tabs-tab-active {
    border-top-color: #fff;
  }
  .custom-tabs-card.custom-tabs-left > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-right > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-left > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-right > div > .custom-tabs-nav .custom-tabs-tab + .custom-tabs-tab {
    margin-top: 2px;
  }
  .custom-tabs-card.custom-tabs-left > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-left > div > .custom-tabs-nav .custom-tabs-tab {
    border-radius: 2px 0 0 2px;
  }
  .custom-tabs-card.custom-tabs-left > .custom-tabs-nav .custom-tabs-tab-active,
  .custom-tabs-card.custom-tabs-left > div > .custom-tabs-nav .custom-tabs-tab-active {
    border-right-color: #fff;
  }
  .custom-tabs-card.custom-tabs-right > .custom-tabs-nav .custom-tabs-tab,
  .custom-tabs-card.custom-tabs-right > div > .custom-tabs-nav .custom-tabs-tab {
    border-radius: 0 2px 2px 0;
  }
  .custom-tabs-card.custom-tabs-right > .custom-tabs-nav .custom-tabs-tab-active,
  .custom-tabs-card.custom-tabs-right > div > .custom-tabs-nav .custom-tabs-tab-active {
    border-left-color: #fff;
  }
  .custom-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: flex;
  }
  .custom-tabs > .custom-tabs-nav,
  .custom-tabs > div > .custom-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-wrap,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-wrap {
    position: relative;
    display: inline-block;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-wrap::before,
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-wrap::after,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-wrap::after {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    content: '';
    pointer-events: none;
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-list,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-list {
    position: relative;
    display: flex;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-operations,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-operations {
    display: flex;
    align-self: stretch;
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-operations-hidden,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-more,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-more {
    position: relative;
    padding: 8px 16px;
    background: transparent;
    border: 0;
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-more::after,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-more::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    content: '';
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-add,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-add {
    min-width: 40px;
    margin-left: 2px;
    padding: 0 8px;
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px 2px 0 0;
    outline: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-add:hover,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-add:hover {
    color: #40a9ff;
    color: var(--custom-primary-5);
  }
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-add:active,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-add:active,
  .custom-tabs > .custom-tabs-nav .custom-tabs-nav-add:focus,
  .custom-tabs > div > .custom-tabs-nav .custom-tabs-nav-add:focus {
    color: #096dd9;
    color: var(--custom-primary-7);
  }
  .custom-tabs-extra-content {
    flex: none;
  }
  .custom-tabs-centered > .custom-tabs-nav .custom-tabs-nav-wrap:not([class*='custom-tabs-nav-wrap-ping']),
  .custom-tabs-centered > div > .custom-tabs-nav .custom-tabs-nav-wrap:not([class*='custom-tabs-nav-wrap-ping']) {
    justify-content: center;
  }
  .custom-tabs-ink-bar {
    position: absolute;
    background: #1890ff;
    background: var(--custom-primary-color);
    pointer-events: none;
  }
  .custom-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }
  .custom-tabs-tab-btn:focus,
  .custom-tabs-tab-remove:focus,
  .custom-tabs-tab-btn:active,
  .custom-tabs-tab-remove:active {
    color: #096dd9;
    color: var(--custom-primary-7);
  }
  .custom-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
  }
  .custom-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-tabs-tab-remove:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-tabs-tab:hover {
    color: #40a9ff;
    color: var(--custom-primary-5);
  }
  .custom-tabs-tab.custom-tabs-tab-active .custom-tabs-tab-btn {
    color: #1890ff;
    color: var(--custom-primary-color);
    text-shadow: 0 0 0.25px currentcolor;
  }
  .custom-tabs-tab.custom-tabs-tab-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-tabs-tab.custom-tabs-tab-disabled .custom-tabs-tab-btn:focus,
  .custom-tabs-tab.custom-tabs-tab-disabled .custom-tabs-tab-remove:focus,
  .custom-tabs-tab.custom-tabs-tab-disabled .custom-tabs-tab-btn:active,
  .custom-tabs-tab.custom-tabs-tab-disabled .custom-tabs-tab-remove:active {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-tabs-tab .custom-tabs-tab-remove .anticon {
    margin: 0;
  }
  .custom-tabs-tab .anticon {
    margin-right: 12px;
  }
  .custom-tabs-tab + .custom-tabs-tab {
    margin: 0 0 0 32px;
  }
  .custom-tabs-content {
    display: flex;
    width: 100%;
  }
  .custom-tabs-content-holder {
    flex: auto;
    min-width: 0;
    min-height: 0;
  }
  .custom-tabs-content-animated {
    transition: margin 0.3s;
  }
  .custom-tabs-tabpane {
    flex: none;
    width: 100%;
    outline: none;
  }
  .custom-tag {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    height: auto;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    opacity: 1;
    transition: all 0.3s;
  }
  .custom-tag,
  .custom-tag a,
  .custom-tag a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-tag > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }
  .custom-tag-close-icon {
    margin-left: 3px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-tag-close-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-tag-has-color {
    border-color: transparent;
  }
  .custom-tag-has-color,
  .custom-tag-has-color a,
  .custom-tag-has-color a:hover,
  .custom-tag-has-color .anticon-close,
  .custom-tag-has-color .anticon-close:hover {
    color: #fff;
  }
  .custom-tag-checkable {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
  }
  .custom-tag-checkable:not(.custom-tag-checkable-checked):hover {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-tag-checkable:active,
  .custom-tag-checkable-checked {
    color: #fff;
  }
  .custom-tag-checkable-checked {
    background-color: #1890ff;
    background-color: var(--custom-primary-6);
  }
  .custom-tag-checkable:active {
    background-color: #096dd9;
    background-color: var(--custom-primary-7);
  }
  .custom-tag-hidden {
    display: none;
  }
  .custom-tag-pink {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2;
  }
  .custom-tag-pink-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96;
  }
  .custom-tag-magenta {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2;
  }
  .custom-tag-magenta-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96;
  }
  .custom-tag-red {
    color: #cf1322;
    background: #fff1f0;
    border-color: #ffa39e;
  }
  .custom-tag-red-inverse {
    color: #fff;
    background: #f5222d;
    border-color: #f5222d;
  }
  .custom-tag-volcano {
    color: #d4380d;
    background: #fff2e8;
    border-color: #ffbb96;
  }
  .custom-tag-volcano-inverse {
    color: #fff;
    background: #fa541c;
    border-color: #fa541c;
  }
  .custom-tag-orange {
    color: #d46b08;
    background: #fff7e6;
    border-color: #ffd591;
  }
  .custom-tag-orange-inverse {
    color: #fff;
    background: #fa8c16;
    border-color: #fa8c16;
  }
  .custom-tag-yellow {
    color: #d4b106;
    background: #feffe6;
    border-color: #fffb8f;
  }
  .custom-tag-yellow-inverse {
    color: #fff;
    background: #fadb14;
    border-color: #fadb14;
  }
  .custom-tag-gold {
    color: #d48806;
    background: #fffbe6;
    border-color: #ffe58f;
  }
  .custom-tag-gold-inverse {
    color: #fff;
    background: #faad14;
    border-color: #faad14;
  }
  .custom-tag-cyan {
    color: #08979c;
    background: #e6fffb;
    border-color: #87e8de;
  }
  .custom-tag-cyan-inverse {
    color: #fff;
    background: #13c2c2;
    border-color: #13c2c2;
  }
  .custom-tag-lime {
    color: #7cb305;
    background: #fcffe6;
    border-color: #eaff8f;
  }
  .custom-tag-lime-inverse {
    color: #fff;
    background: #a0d911;
    border-color: #a0d911;
  }
  .custom-tag-green {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
  .custom-tag-green-inverse {
    color: #fff;
    background: #52c41a;
    border-color: #52c41a;
  }
  .custom-tag-blue {
    color: #096dd9;
    background: #e6f7ff;
    border-color: #91d5ff;
  }
  .custom-tag-blue-inverse {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
  }
  .custom-tag-geekblue {
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff;
  }
  .custom-tag-geekblue-inverse {
    color: #fff;
    background: #2f54eb;
    border-color: #2f54eb;
  }
  .custom-tag-purple {
    color: #531dab;
    background: #f9f0ff;
    border-color: #d3adf7;
  }
  .custom-tag-purple-inverse {
    color: #fff;
    background: #722ed1;
    border-color: #722ed1;
  }
  .custom-tag-success {
    color: #52c41a;
    color: var(--custom-success-color);
    background: #f6ffed;
    background: var(--custom-success-color-deprecated-bg);
    border-color: #b7eb8f;
    border-color: var(--custom-success-color-deprecated-border);
  }
  .custom-tag-processing {
    color: #1890ff;
    color: var(--custom-info-color);
    background: #e6f7ff;
    background: var(--custom-info-color-deprecated-bg);
    border-color: #91d5ff;
    border-color: var(--custom-info-color-deprecated-border);
  }
  .custom-tag-error {
    color: #ff4d4f;
    color: var(--custom-error-color);
    background: #fff2f0;
    background: var(--custom-error-color-deprecated-bg);
    border-color: #ffccc7;
    border-color: var(--custom-error-color-deprecated-border);
  }
  .custom-tag-warning {
    color: #faad14;
    color: var(--custom-warning-color);
    background: #fffbe6;
    background: var(--custom-warning-color-deprecated-bg);
    border-color: #ffe58f;
    border-color: var(--custom-warning-color-deprecated-border);
  }
  .custom-tag > .anticon + span,
  .custom-tag > span + .anticon {
    margin-left: 7px;
  }
  .custom-tag.custom-tag-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl;
    text-align: right;
  }
  .custom-tag-rtl .custom-tag-close-icon {
    margin-right: 3px;
    margin-left: 0;
  }
  .custom-tag-rtl.custom-tag > .anticon + span,
  .custom-tag-rtl.custom-tag > span + .anticon {
    margin-right: 7px;
    margin-left: 0;
  }
  .custom-timeline {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .custom-timeline-item {
    position: relative;
    margin: 0;
    padding-bottom: 20px;
    font-size: 14px;
    list-style: none;
  }
  .custom-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px solid rgba(0, 0, 0, 0.06);
  }
  .custom-timeline-item-pending .custom-timeline-item-head {
    font-size: 12px;
    background-color: transparent;
  }
  .custom-timeline-item-pending .custom-timeline-item-tail {
    display: none;
  }
  .custom-timeline-item-head {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 100px;
  }
  .custom-timeline-item-head-blue {
    color: #1890ff;
    color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-timeline-item-head-red {
    color: #ff4d4f;
    color: var(--custom-error-color);
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-timeline-item-head-green {
    color: #52c41a;
    color: var(--custom-success-color);
    border-color: #52c41a;
    border-color: var(--custom-success-color);
  }
  .custom-timeline-item-head-gray {
    color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .custom-timeline-item-head-custom {
    position: absolute;
    top: 5.5px;
    left: 5px;
    width: auto;
    height: auto;
    margin-top: 0;
    padding: 3px 1px;
    line-height: 1;
    text-align: center;
    border: 0;
    border-radius: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .custom-timeline-item-content {
    position: relative;
    top: -7.001px;
    margin: 0 0 0 26px;
    word-break: break-word;
  }
  .custom-timeline-item-last > .custom-timeline-item-tail {
    display: none;
  }
  .custom-timeline-item-last > .custom-timeline-item-content {
    min-height: 48px;
  }
  .custom-timeline.custom-timeline-alternate .custom-timeline-item-tail,
  .custom-timeline.custom-timeline-right .custom-timeline-item-tail,
  .custom-timeline.custom-timeline-label .custom-timeline-item-tail,
  .custom-timeline.custom-timeline-alternate .custom-timeline-item-head,
  .custom-timeline.custom-timeline-right .custom-timeline-item-head,
  .custom-timeline.custom-timeline-label .custom-timeline-item-head,
  .custom-timeline.custom-timeline-alternate .custom-timeline-item-head-custom,
  .custom-timeline.custom-timeline-right .custom-timeline-item-head-custom,
  .custom-timeline.custom-timeline-label .custom-timeline-item-head-custom {
    left: 50%;
  }
  .custom-timeline.custom-timeline-alternate .custom-timeline-item-head,
  .custom-timeline.custom-timeline-right .custom-timeline-item-head,
  .custom-timeline.custom-timeline-label .custom-timeline-item-head {
    margin-left: -4px;
  }
  .custom-timeline.custom-timeline-alternate .custom-timeline-item-head-custom,
  .custom-timeline.custom-timeline-right .custom-timeline-item-head-custom,
  .custom-timeline.custom-timeline-label .custom-timeline-item-head-custom {
    margin-left: 1px;
  }
  .custom-timeline.custom-timeline-alternate .custom-timeline-item-left .custom-timeline-item-content,
  .custom-timeline.custom-timeline-right .custom-timeline-item-left .custom-timeline-item-content,
  .custom-timeline.custom-timeline-label .custom-timeline-item-left .custom-timeline-item-content {
    left: calc(50% - 4px);
    width: calc(50% - 14px);
    text-align: left;
  }
  .custom-timeline.custom-timeline-alternate .custom-timeline-item-right .custom-timeline-item-content,
  .custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-content,
  .custom-timeline.custom-timeline-label .custom-timeline-item-right .custom-timeline-item-content {
    width: calc(50% - 12px);
    margin: 0;
    text-align: right;
  }
  .custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-tail,
  .custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-head,
  .custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-head-custom {
    left: calc(100% - 4px - 2px);
  }
  .custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-content {
    width: calc(100% - 18px);
  }
  .custom-timeline.custom-timeline-pending .custom-timeline-item-last .custom-timeline-item-tail {
    display: block;
    height: calc(100% - 14px);
    border-left: 2px dotted rgba(0, 0, 0, 0.06);
  }
  .custom-timeline.custom-timeline-reverse .custom-timeline-item-last .custom-timeline-item-tail {
    display: none;
  }
  .custom-timeline.custom-timeline-reverse .custom-timeline-item-pending .custom-timeline-item-tail {
    top: 15px;
    display: block;
    height: calc(100% - 15px);
    border-left: 2px dotted rgba(0, 0, 0, 0.06);
  }
  .custom-timeline.custom-timeline-reverse .custom-timeline-item-pending .custom-timeline-item-content {
    min-height: 48px;
  }
  .custom-timeline.custom-timeline-label .custom-timeline-item-label {
    position: absolute;
    top: -7.001px;
    width: calc(50% - 12px);
    text-align: right;
  }
  .custom-timeline.custom-timeline-label .custom-timeline-item-right .custom-timeline-item-label {
    left: calc(50% + 14px);
    width: calc(50% - 14px);
    text-align: left;
  }
  .custom-timeline-rtl {
    direction: rtl;
  }
  .custom-timeline-rtl .custom-timeline-item-tail {
    right: 4px;
    left: auto;
    border-right: 2px solid rgba(0, 0, 0, 0.06);
    border-left: none;
  }
  .custom-timeline-rtl .custom-timeline-item-head-custom {
    right: 5px;
    left: auto;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
  }
  .custom-timeline-rtl .custom-timeline-item-content {
    margin: 0 18px 0 0;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-alternate .custom-timeline-item-tail,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-tail,
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-tail,
  .custom-timeline-rtl.custom-timeline.custom-timeline-alternate .custom-timeline-item-head,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-head,
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-head,
  .custom-timeline-rtl.custom-timeline.custom-timeline-alternate .custom-timeline-item-head-custom,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-head-custom,
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-head-custom {
    right: 50%;
    left: auto;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-alternate .custom-timeline-item-head,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-head,
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-head {
    margin-right: -4px;
    margin-left: 0;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-alternate .custom-timeline-item-head-custom,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-head-custom,
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-head-custom {
    margin-right: 1px;
    margin-left: 0;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-alternate .custom-timeline-item-left .custom-timeline-item-content,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-left .custom-timeline-item-content,
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-left .custom-timeline-item-content {
    right: calc(50% - 4px);
    left: auto;
    text-align: right;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-alternate .custom-timeline-item-right .custom-timeline-item-content,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-content,
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-right .custom-timeline-item-content {
    text-align: left;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-tail,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-head,
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-head-custom {
    right: 0;
    left: auto;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-right .custom-timeline-item-right .custom-timeline-item-content {
    width: 100%;
    margin-right: 18px;
    text-align: right;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-pending .custom-timeline-item-last .custom-timeline-item-tail {
    border-right: 2px dotted rgba(0, 0, 0, 0.06);
    border-left: none;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-reverse .custom-timeline-item-pending .custom-timeline-item-tail {
    border-right: 2px dotted rgba(0, 0, 0, 0.06);
    border-left: none;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-label {
    text-align: left;
  }
  .custom-timeline-rtl.custom-timeline.custom-timeline-label .custom-timeline-item-right .custom-timeline-item-label {
    right: calc(50% + 14px);
    text-align: right;
  }
  .custom-tooltip {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: absolute;
    z-index: 1070;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    width: intrinsic;
    max-width: 250px;
    visibility: visible;
  }
  .custom-tooltip-content {
    position: relative;
  }
  .custom-tooltip-hidden {
    display: none;
  }
  .custom-tooltip-placement-top,
  .custom-tooltip-placement-topLeft,
  .custom-tooltip-placement-topRight {
    padding-bottom: 14.3137085px;
  }
  .custom-tooltip-placement-right,
  .custom-tooltip-placement-rightTop,
  .custom-tooltip-placement-rightBottom {
    padding-left: 14.3137085px;
  }
  .custom-tooltip-placement-bottom,
  .custom-tooltip-placement-bottomLeft,
  .custom-tooltip-placement-bottomRight {
    padding-top: 14.3137085px;
  }
  .custom-tooltip-placement-left,
  .custom-tooltip-placement-leftTop,
  .custom-tooltip-placement-leftBottom {
    padding-right: 14.3137085px;
  }
  .custom-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .custom-tooltip-arrow {
    position: absolute;
    z-index: 2;
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
  }
  .custom-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 11.3137085px;
    height: 11.3137085px;
    margin: auto;
    content: '';
    pointer-events: auto;
    border-radius: 0 0 2px;
    pointer-events: none;
  }
  .custom-tooltip-arrow-content::before {
    position: absolute;
    top: -11.3137085px;
    left: -11.3137085px;
    width: 33.9411255px;
    height: 33.9411255px;
    background: var(--antd-arrow-background-color);
    background-repeat: no-repeat;
    background-position: -10px -10px;
    content: '';
    -webkit-clip-path: inset(33% 33%);
            clip-path: inset(33% 33%);
    -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
            clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
  }
  .custom-tooltip-placement-top .custom-tooltip-arrow,
  .custom-tooltip-placement-topLeft .custom-tooltip-arrow,
  .custom-tooltip-placement-topRight .custom-tooltip-arrow {
    bottom: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .custom-tooltip-placement-top .custom-tooltip-arrow-content,
  .custom-tooltip-placement-topLeft .custom-tooltip-arrow-content,
  .custom-tooltip-placement-topRight .custom-tooltip-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(-11px) rotate(45deg);
            transform: translateY(-11px) rotate(45deg);
  }
  .custom-tooltip-placement-top .custom-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateY(100%) translateX(-50%);
            transform: translateY(100%) translateX(-50%);
  }
  .custom-tooltip-placement-topLeft .custom-tooltip-arrow {
    left: 13px;
  }
  .custom-tooltip-placement-topRight .custom-tooltip-arrow {
    right: 13px;
  }
  .custom-tooltip-placement-right .custom-tooltip-arrow,
  .custom-tooltip-placement-rightTop .custom-tooltip-arrow,
  .custom-tooltip-placement-rightBottom .custom-tooltip-arrow {
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .custom-tooltip-placement-right .custom-tooltip-arrow-content,
  .custom-tooltip-placement-rightTop .custom-tooltip-arrow-content,
  .custom-tooltip-placement-rightBottom .custom-tooltip-arrow-content {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(11px) rotate(135deg);
            transform: translateX(11px) rotate(135deg);
  }
  .custom-tooltip-placement-right .custom-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateX(-100%) translateY(-50%);
            transform: translateX(-100%) translateY(-50%);
  }
  .custom-tooltip-placement-rightTop .custom-tooltip-arrow {
    top: 5px;
  }
  .custom-tooltip-placement-rightBottom .custom-tooltip-arrow {
    bottom: 5px;
  }
  .custom-tooltip-placement-left .custom-tooltip-arrow,
  .custom-tooltip-placement-leftTop .custom-tooltip-arrow,
  .custom-tooltip-placement-leftBottom .custom-tooltip-arrow {
    right: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .custom-tooltip-placement-left .custom-tooltip-arrow-content,
  .custom-tooltip-placement-leftTop .custom-tooltip-arrow-content,
  .custom-tooltip-placement-leftBottom .custom-tooltip-arrow-content {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(-11px) rotate(315deg);
            transform: translateX(-11px) rotate(315deg);
  }
  .custom-tooltip-placement-left .custom-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateX(100%) translateY(-50%);
            transform: translateX(100%) translateY(-50%);
  }
  .custom-tooltip-placement-leftTop .custom-tooltip-arrow {
    top: 5px;
  }
  .custom-tooltip-placement-leftBottom .custom-tooltip-arrow {
    bottom: 5px;
  }
  .custom-tooltip-placement-bottom .custom-tooltip-arrow,
  .custom-tooltip-placement-bottomLeft .custom-tooltip-arrow,
  .custom-tooltip-placement-bottomRight .custom-tooltip-arrow {
    top: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  .custom-tooltip-placement-bottom .custom-tooltip-arrow-content,
  .custom-tooltip-placement-bottomLeft .custom-tooltip-arrow-content,
  .custom-tooltip-placement-bottomRight .custom-tooltip-arrow-content {
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(11px) rotate(225deg);
            transform: translateY(11px) rotate(225deg);
  }
  .custom-tooltip-placement-bottom .custom-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateY(-100%) translateX(-50%);
            transform: translateY(-100%) translateX(-50%);
  }
  .custom-tooltip-placement-bottomLeft .custom-tooltip-arrow {
    left: 13px;
  }
  .custom-tooltip-placement-bottomRight .custom-tooltip-arrow {
    right: 13px;
  }
  .custom-tooltip-pink .custom-tooltip-inner {
    background-color: #eb2f96;
  }
  .custom-tooltip-pink .custom-tooltip-arrow-content::before {
    background: #eb2f96;
  }
  .custom-tooltip-magenta .custom-tooltip-inner {
    background-color: #eb2f96;
  }
  .custom-tooltip-magenta .custom-tooltip-arrow-content::before {
    background: #eb2f96;
  }
  .custom-tooltip-red .custom-tooltip-inner {
    background-color: #f5222d;
  }
  .custom-tooltip-red .custom-tooltip-arrow-content::before {
    background: #f5222d;
  }
  .custom-tooltip-volcano .custom-tooltip-inner {
    background-color: #fa541c;
  }
  .custom-tooltip-volcano .custom-tooltip-arrow-content::before {
    background: #fa541c;
  }
  .custom-tooltip-orange .custom-tooltip-inner {
    background-color: #fa8c16;
  }
  .custom-tooltip-orange .custom-tooltip-arrow-content::before {
    background: #fa8c16;
  }
  .custom-tooltip-yellow .custom-tooltip-inner {
    background-color: #fadb14;
  }
  .custom-tooltip-yellow .custom-tooltip-arrow-content::before {
    background: #fadb14;
  }
  .custom-tooltip-gold .custom-tooltip-inner {
    background-color: #faad14;
  }
  .custom-tooltip-gold .custom-tooltip-arrow-content::before {
    background: #faad14;
  }
  .custom-tooltip-cyan .custom-tooltip-inner {
    background-color: #13c2c2;
  }
  .custom-tooltip-cyan .custom-tooltip-arrow-content::before {
    background: #13c2c2;
  }
  .custom-tooltip-lime .custom-tooltip-inner {
    background-color: #a0d911;
  }
  .custom-tooltip-lime .custom-tooltip-arrow-content::before {
    background: #a0d911;
  }
  .custom-tooltip-green .custom-tooltip-inner {
    background-color: #52c41a;
  }
  .custom-tooltip-green .custom-tooltip-arrow-content::before {
    background: #52c41a;
  }
  .custom-tooltip-blue .custom-tooltip-inner {
    background-color: #1890ff;
  }
  .custom-tooltip-blue .custom-tooltip-arrow-content::before {
    background: #1890ff;
  }
  .custom-tooltip-geekblue .custom-tooltip-inner {
    background-color: #2f54eb;
  }
  .custom-tooltip-geekblue .custom-tooltip-arrow-content::before {
    background: #2f54eb;
  }
  .custom-tooltip-purple .custom-tooltip-inner {
    background-color: #722ed1;
  }
  .custom-tooltip-purple .custom-tooltip-arrow-content::before {
    background: #722ed1;
  }
  .custom-tooltip-rtl {
    direction: rtl;
  }
  .custom-tooltip-rtl .custom-tooltip-inner {
    text-align: right;
  }
  .custom-transfer-customize-list .custom-transfer-list {
    flex: 1 1 50%;
    width: auto;
    height: auto;
    min-height: 200px;
  }
  .custom-transfer-customize-list .custom-table-wrapper .custom-table-small {
    border: 0;
    border-radius: 0;
  }
  .custom-transfer-customize-list .custom-table-wrapper .custom-table-small .custom-table-selection-column {
    width: 40px;
    min-width: 40px;
  }
  .custom-transfer-customize-list .custom-table-wrapper .custom-table-small > .custom-table-content > .custom-table-body > table > .custom-table-thead > tr > th {
    background: #fafafa;
  }
  .custom-transfer-customize-list .custom-table-wrapper .custom-table-small > .custom-table-content .custom-table-row:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-transfer-customize-list .custom-table-wrapper .custom-table-small .custom-table-body {
    margin: 0;
  }
  .custom-transfer-customize-list .custom-table-wrapper .custom-table-pagination.custom-pagination {
    margin: 16px 0 4px;
  }
  .custom-transfer-customize-list .custom-input[disabled] {
    background-color: transparent;
  }
  .custom-transfer-status-error .custom-transfer-list {
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-transfer-status-error .custom-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;
  }
  .custom-transfer-status-error .custom-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-transfer-status-error .custom-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-transfer-status-error .custom-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-transfer-status-error .custom-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-transfer-status-warning .custom-transfer-list {
    border-color: #faad14;
    border-color: var(--custom-warning-color);
  }
  .custom-transfer-status-warning .custom-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;
  }
  .custom-transfer-status-warning .custom-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
    border-right-width: 1px;
  }
  .custom-input-rtl .custom-transfer-status-warning .custom-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-transfer-status-warning .custom-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    border-color: var(--custom-primary-color-hover);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px var(--custom-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
  }
  .custom-input-rtl .custom-transfer-status-warning .custom-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .custom-transfer {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: flex;
    align-items: stretch;
  }
  .custom-transfer-disabled .custom-transfer-list {
    background: #f5f5f5;
  }
  .custom-transfer-list {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 200px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .custom-transfer-list-with-pagination {
    width: 250px;
    height: auto;
  }
  .custom-transfer-list-search .anticon-search {
    color: rgba(0, 0, 0, 0.25);
  }
  .custom-transfer-list-header {
    display: flex;
    flex: none;
    align-items: center;
    height: 40px;
    padding: 8px 12px 9px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px 2px 0 0;
  }
  .custom-transfer-list-header > *:not(:last-child) {
    margin-right: 4px;
  }
  .custom-transfer-list-header > * {
    flex: none;
  }
  .custom-transfer-list-header-title {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    text-overflow: ellipsis;
  }
  .custom-transfer-list-header-dropdown {
    font-size: 10px;
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
    cursor: pointer;
  }
  .custom-transfer-list-header-dropdown[disabled] {
    cursor: not-allowed;
  }
  .custom-transfer-list-body {
    display: flex;
    flex: auto;
    flex-direction: column;
    overflow: hidden;
    font-size: 14px;
  }
  .custom-transfer-list-body-search-wrapper {
    position: relative;
    flex: none;
    padding: 12px;
  }
  .custom-transfer-list-content {
    flex: auto;
    margin: 0;
    padding: 0;
    overflow: auto;
    list-style: none;
  }
  .custom-transfer-list-content-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 6px 12px;
    line-height: 20px;
    transition: all 0.3s;
  }
  .custom-transfer-list-content-item > *:not(:last-child) {
    margin-right: 8px;
  }
  .custom-transfer-list-content-item > * {
    flex: none;
  }
  .custom-transfer-list-content-item-text {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-transfer-list-content-item-remove {
    position: relative;
    color: #d9d9d9;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-transfer-list-content-item-remove:hover {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
  }
  .custom-transfer-list-content-item-remove::after {
    position: absolute;
    top: -6px;
    right: -50%;
    bottom: -6px;
    left: -50%;
    content: '';
  }
  .custom-transfer-list-content-item:not(.custom-transfer-list-content-item-disabled):hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  .custom-transfer-list-content-item:not(.custom-transfer-list-content-item-disabled).custom-transfer-list-content-item-checked:hover {
    background-color: #dcf4ff;
    background-color: var(--custom-primary-color-active-deprecated-d-02);
  }
  .custom-transfer-list-content-show-remove .custom-transfer-list-content-item:not(.custom-transfer-list-content-item-disabled):hover {
    background: transparent;
    cursor: default;
  }
  .custom-transfer-list-content-item-checked {
    background-color: #e6f7ff;
    background-color: var(--custom-primary-1);
  }
  .custom-transfer-list-content-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-transfer-list-pagination {
    padding: 8px 0;
    text-align: right;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-transfer-list-body-not-found {
    flex: none;
    width: 100%;
    margin: auto 0;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
  }
  .custom-transfer-list-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .custom-transfer-operation {
    display: flex;
    flex: none;
    flex-direction: column;
    align-self: center;
    margin: 0 8px;
    vertical-align: middle;
  }
  .custom-transfer-operation .custom-btn {
    display: block;
  }
  .custom-transfer-operation .custom-btn:first-child {
    margin-bottom: 4px;
  }
  .custom-transfer-operation .custom-btn .anticon {
    font-size: 12px;
  }
  .custom-transfer .custom-empty-image {
    max-height: -2px;
  }
  .custom-transfer-rtl {
    direction: rtl;
  }
  .custom-transfer-rtl .custom-transfer-list-search {
    padding-right: 8px;
    padding-left: 24px;
  }
  .custom-transfer-rtl .custom-transfer-list-search-action {
    right: auto;
    left: 12px;
  }
  .custom-transfer-rtl .custom-transfer-list-header > *:not(:last-child) {
    margin-right: 0;
    margin-left: 4px;
  }
  .custom-transfer-rtl .custom-transfer-list-header {
    right: 0;
    left: auto;
  }
  .custom-transfer-rtl .custom-transfer-list-header-title {
    text-align: left;
  }
  .custom-transfer-rtl .custom-transfer-list-content-item > *:not(:last-child) {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-transfer-rtl .custom-transfer-list-pagination {
    text-align: left;
  }
  .custom-transfer-rtl .custom-transfer-list-footer {
    right: 0;
    left: auto;
  }
  @-webkit-keyframes ant-tree-node-fx-do-not-use {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes ant-tree-node-fx-do-not-use {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode {
    position: relative;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 4px;
    left: 0;
    transition: background-color 0.3s;
    content: '';
    pointer-events: none;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode:hover::before {
    background: #f5f5f5;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode > * {
    z-index: 1;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode .custom-tree-switcher {
    transition: color 0.3s;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode .custom-tree-node-content-wrapper {
    border-radius: 0;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode .custom-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode .custom-tree-node-content-wrapper.custom-tree-node-selected {
    color: #fff;
    background: transparent;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode-selected:hover::before,
  .custom-tree.custom-tree-directory .custom-tree-treenode-selected::before {
    background: #1890ff;
    background: var(--custom-primary-color);
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode-selected .custom-tree-switcher {
    color: #fff;
  }
  .custom-tree.custom-tree-directory .custom-tree-treenode-selected .custom-tree-node-content-wrapper {
    color: #fff;
    background: transparent;
  }
  .custom-tree-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0.2em;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
  }
  .custom-tree-checkbox-wrapper:hover .custom-tree-checkbox-inner,
  .custom-tree-checkbox:hover .custom-tree-checkbox-inner,
  .custom-tree-checkbox-input:focus + .custom-tree-checkbox-inner {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-tree-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
            animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    content: '';
  }
  .custom-tree-checkbox:hover::after,
  .custom-tree-checkbox-wrapper:hover .custom-tree-checkbox::after {
    visibility: visible;
  }
  .custom-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .custom-tree-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
            transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }
  .custom-tree-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .custom-tree-checkbox-checked .custom-tree-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
  .custom-tree-checkbox-checked .custom-tree-checkbox-inner {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-tree-checkbox-disabled {
    cursor: not-allowed;
  }
  .custom-tree-checkbox-disabled.custom-tree-checkbox-checked .custom-tree-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-tree-checkbox-disabled .custom-tree-checkbox-input {
    cursor: not-allowed;
    pointer-events: none;
  }
  .custom-tree-checkbox-disabled .custom-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
  }
  .custom-tree-checkbox-disabled .custom-tree-checkbox-inner::after {
    border-color: #f5f5f5;
    border-collapse: separate;
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-tree-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-tree-checkbox-disabled:hover::after,
  .custom-tree-checkbox-wrapper:hover .custom-tree-checkbox-disabled::after {
    visibility: hidden;
  }
  .custom-tree-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-flex;
    align-items: baseline;
    line-height: inherit;
    cursor: pointer;
  }
  .custom-tree-checkbox-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
  }
  .custom-tree-checkbox-wrapper.custom-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .custom-tree-checkbox-wrapper + .custom-tree-checkbox-wrapper {
    margin-left: 8px;
  }
  .custom-tree-checkbox-wrapper.custom-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
  .custom-tree-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .custom-tree-checkbox-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
  }
  .custom-tree-checkbox-group-item {
    margin-right: 8px;
  }
  .custom-tree-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .custom-tree-checkbox-group-item + .custom-tree-checkbox-group-item {
    margin-left: 0;
  }
  .custom-tree-checkbox-indeterminate .custom-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .custom-tree-checkbox-indeterminate .custom-tree-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
  }
  .custom-tree-checkbox-indeterminate.custom-tree-checkbox-disabled .custom-tree-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .custom-tree-checkbox-rtl {
    direction: rtl;
  }
  .custom-tree-checkbox-group-rtl .custom-tree-checkbox-group-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-tree-checkbox-group-rtl .custom-tree-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .custom-tree-checkbox-group-rtl .custom-tree-checkbox-group-item + .custom-tree-checkbox-group-item {
    margin-left: 8px;
  }
  .custom-tree {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    background: #fff;
    border-radius: 2px;
    transition: background-color 0.3s;
  }
  .custom-tree-focused:not(:hover):not(.custom-tree-active-focused) {
    background: #e6f7ff;
    background: var(--custom-primary-1);
  }
  .custom-tree-list-holder-inner {
    align-items: flex-start;
  }
  .custom-tree.custom-tree-block-node .custom-tree-list-holder-inner {
    align-items: stretch;
  }
  .custom-tree.custom-tree-block-node .custom-tree-list-holder-inner .custom-tree-node-content-wrapper {
    flex: auto;
  }
  .custom-tree.custom-tree-block-node .custom-tree-list-holder-inner .custom-tree-treenode.dragging {
    position: relative;
  }
  .custom-tree.custom-tree-block-node .custom-tree-list-holder-inner .custom-tree-treenode.dragging::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 4px;
    left: 0;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    opacity: 0;
    -webkit-animation: ant-tree-node-fx-do-not-use 0.3s;
            animation: ant-tree-node-fx-do-not-use 0.3s;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
  }
  .custom-tree .custom-tree-treenode {
    display: flex;
    align-items: flex-start;
    padding: 0 0 4px 0;
    outline: none;
  }
  .custom-tree .custom-tree-treenode-disabled .custom-tree-node-content-wrapper {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-tree .custom-tree-treenode-disabled .custom-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .custom-tree .custom-tree-treenode-active .custom-tree-node-content-wrapper {
    background: #f5f5f5;
  }
  .custom-tree .custom-tree-treenode:not(.custom-tree .custom-tree-treenode-disabled).filter-node .custom-tree-title {
    color: inherit;
    font-weight: 500;
  }
  .custom-tree .custom-tree-treenode-draggable .custom-tree-draggable-icon {
    width: 24px;
    line-height: 24px;
    text-align: center;
    visibility: visible;
    opacity: 0.2;
    transition: opacity 0.3s;
  }
  .custom-tree-treenode:hover .custom-tree .custom-tree-treenode-draggable .custom-tree-draggable-icon {
    opacity: 0.45;
  }
  .custom-tree-indent {
    align-self: stretch;
    white-space: nowrap;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-tree-indent-unit {
    display: inline-block;
    width: 24px;
  }
  .custom-tree-draggable-icon {
    visibility: hidden;
  }
  .custom-tree-switcher {
    position: relative;
    flex: none;
    align-self: stretch;
    width: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-tree-switcher .custom-tree-switcher-icon,
  .custom-tree-switcher .custom-select-tree-switcher-icon {
    display: inline-block;
    font-size: 10px;
    vertical-align: baseline;
  }
  .custom-tree-switcher .custom-tree-switcher-icon svg,
  .custom-tree-switcher .custom-select-tree-switcher-icon svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .custom-tree-switcher-noop {
    cursor: default;
  }
  .custom-tree-switcher_close .custom-tree-switcher-icon svg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .custom-tree-switcher-loading-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-tree-switcher-leaf-line {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .custom-tree-switcher-leaf-line::before {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: -4px;
    margin-left: -1px;
    border-right: 1px solid #d9d9d9;
    content: ' ';
  }
  .custom-tree-switcher-leaf-line::after {
    position: absolute;
    width: 10px;
    height: 14px;
    border-bottom: 1px solid #d9d9d9;
    content: ' ';
  }
  .custom-tree-checkbox {
    top: auto;
    top: initial;
    margin: 4px 8px 0 0;
  }
  .custom-tree .custom-tree-node-content-wrapper {
    position: relative;
    z-index: auto;
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  }
  .custom-tree .custom-tree-node-content-wrapper:hover {
    background-color: #f5f5f5;
  }
  .custom-tree .custom-tree-node-content-wrapper.custom-tree-node-selected {
    background-color: #bae7ff;
    background-color: var(--custom-primary-2);
  }
  .custom-tree .custom-tree-node-content-wrapper .custom-tree-iconEle {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
  }
  .custom-tree .custom-tree-node-content-wrapper .custom-tree-iconEle:empty {
    display: none;
  }
  .custom-tree-unselectable .custom-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
  .custom-tree-node-content-wrapper {
    line-height: 24px;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-tree-node-content-wrapper .custom-tree-drop-indicator {
    position: absolute;
    z-index: 1;
    height: 2px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-radius: 1px;
    pointer-events: none;
  }
  .custom-tree-node-content-wrapper .custom-tree-drop-indicator::after {
    position: absolute;
    top: -3px;
    left: -6px;
    width: 8px;
    height: 8px;
    background-color: transparent;
    border: 2px solid #1890ff;
    border: 2px solid var(--custom-primary-color);
    border-radius: 50%;
    content: '';
  }
  .custom-tree .custom-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px #1890ff;
    box-shadow: 0 0 0 2px var(--custom-primary-color);
  }
  .custom-tree-show-line .custom-tree-indent-unit {
    position: relative;
    height: 100%;
  }
  .custom-tree-show-line .custom-tree-indent-unit::before {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: -4px;
    border-right: 1px solid #d9d9d9;
    content: '';
  }
  .custom-tree-show-line .custom-tree-indent-unit-end::before {
    display: none;
  }
  .custom-tree-show-line .custom-tree-switcher {
    background: #fff;
  }
  .custom-tree-show-line .custom-tree-switcher-line-icon {
    vertical-align: -0.15em;
  }
  .custom-tree .custom-tree-treenode-leaf-last .custom-tree-switcher-leaf-line::before {
    top: auto !important;
    bottom: auto !important;
    height: 14px !important;
  }
  .custom-tree-rtl {
    direction: rtl;
  }
  .custom-tree-rtl .custom-tree-node-content-wrapper[draggable='true'] .custom-tree-drop-indicator::after {
    right: -6px;
    left: auto;
    left: initial;
  }
  .custom-tree .custom-tree-treenode-rtl {
    direction: rtl;
  }
  .custom-tree-rtl .custom-tree-switcher_close .custom-tree-switcher-icon svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .custom-tree-rtl.custom-tree-show-line .custom-tree-indent-unit::before {
    right: auto;
    left: -13px;
    border-right: none;
    border-left: 1px solid #d9d9d9;
  }
  .custom-tree-rtl .custom-tree-checkbox {
    margin: 4px 0 0 8px;
  }
  .custom-tree-select-dropdown-rtl .custom-select-tree-checkbox {
    margin: 4px 0 0 8px;
  }
  .custom-select-tree-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0.2em;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
  }
  .custom-select-tree-checkbox-wrapper:hover .custom-select-tree-checkbox-inner,
  .custom-select-tree-checkbox:hover .custom-select-tree-checkbox-inner,
  .custom-select-tree-checkbox-input:focus + .custom-select-tree-checkbox-inner {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-select-tree-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
            animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    content: '';
  }
  .custom-select-tree-checkbox:hover::after,
  .custom-select-tree-checkbox-wrapper:hover .custom-select-tree-checkbox::after {
    visibility: visible;
  }
  .custom-select-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .custom-select-tree-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
            transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }
  .custom-select-tree-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .custom-select-tree-checkbox-checked .custom-select-tree-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
  .custom-select-tree-checkbox-checked .custom-select-tree-checkbox-inner {
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-select-tree-checkbox-disabled {
    cursor: not-allowed;
  }
  .custom-select-tree-checkbox-disabled.custom-select-tree-checkbox-checked .custom-select-tree-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-select-tree-checkbox-disabled .custom-select-tree-checkbox-input {
    cursor: not-allowed;
    pointer-events: none;
  }
  .custom-select-tree-checkbox-disabled .custom-select-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
  }
  .custom-select-tree-checkbox-disabled .custom-select-tree-checkbox-inner::after {
    border-color: #f5f5f5;
    border-collapse: separate;
    -webkit-animation-name: none;
            animation-name: none;
  }
  .custom-select-tree-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-select-tree-checkbox-disabled:hover::after,
  .custom-select-tree-checkbox-wrapper:hover .custom-select-tree-checkbox-disabled::after {
    visibility: hidden;
  }
  .custom-select-tree-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-flex;
    align-items: baseline;
    line-height: inherit;
    cursor: pointer;
  }
  .custom-select-tree-checkbox-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
  }
  .custom-select-tree-checkbox-wrapper.custom-select-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }
  .custom-select-tree-checkbox-wrapper + .custom-select-tree-checkbox-wrapper {
    margin-left: 8px;
  }
  .custom-select-tree-checkbox-wrapper.custom-select-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
  .custom-select-tree-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .custom-select-tree-checkbox-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    display: inline-block;
  }
  .custom-select-tree-checkbox-group-item {
    margin-right: 8px;
  }
  .custom-select-tree-checkbox-group-item:last-child {
    margin-right: 0;
  }
  .custom-select-tree-checkbox-group-item + .custom-select-tree-checkbox-group-item {
    margin-left: 0;
  }
  .custom-select-tree-checkbox-indeterminate .custom-select-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .custom-select-tree-checkbox-indeterminate .custom-select-tree-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
  }
  .custom-select-tree-checkbox-indeterminate.custom-select-tree-checkbox-disabled .custom-select-tree-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
  .custom-select-tree-checkbox-rtl {
    direction: rtl;
  }
  .custom-select-tree-checkbox-group-rtl .custom-select-tree-checkbox-group-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .custom-select-tree-checkbox-group-rtl .custom-select-tree-checkbox-group-item:last-child {
    margin-left: 0 !important;
  }
  .custom-select-tree-checkbox-group-rtl .custom-select-tree-checkbox-group-item + .custom-select-tree-checkbox-group-item {
    margin-left: 8px;
  }
  .custom-tree-select-dropdown {
    padding: 8px 4px;
  }
  .custom-tree-select-dropdown-rtl {
    direction: rtl;
  }
  .custom-tree-select-dropdown .custom-select-tree {
    border-radius: 0;
  }
  .custom-tree-select-dropdown .custom-select-tree-list-holder-inner {
    align-items: stretch;
  }
  .custom-tree-select-dropdown .custom-select-tree-list-holder-inner .custom-select-tree-treenode .custom-select-tree-node-content-wrapper {
    flex: auto;
  }
  .custom-select-tree {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    background: #fff;
    border-radius: 2px;
    transition: background-color 0.3s;
  }
  .custom-select-tree-focused:not(:hover):not(.custom-select-tree-active-focused) {
    background: #e6f7ff;
    background: var(--custom-primary-1);
  }
  .custom-select-tree-list-holder-inner {
    align-items: flex-start;
  }
  .custom-select-tree.custom-select-tree-block-node .custom-select-tree-list-holder-inner {
    align-items: stretch;
  }
  .custom-select-tree.custom-select-tree-block-node .custom-select-tree-list-holder-inner .custom-select-tree-node-content-wrapper {
    flex: auto;
  }
  .custom-select-tree.custom-select-tree-block-node .custom-select-tree-list-holder-inner .custom-select-tree-treenode.dragging {
    position: relative;
  }
  .custom-select-tree.custom-select-tree-block-node .custom-select-tree-list-holder-inner .custom-select-tree-treenode.dragging::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 4px;
    left: 0;
    border: 1px solid #1890ff;
    border: 1px solid var(--custom-primary-color);
    opacity: 0;
    -webkit-animation: ant-tree-node-fx-do-not-use 0.3s;
            animation: ant-tree-node-fx-do-not-use 0.3s;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
  }
  .custom-select-tree .custom-select-tree-treenode {
    display: flex;
    align-items: flex-start;
    padding: 0 0 4px 0;
    outline: none;
  }
  .custom-select-tree .custom-select-tree-treenode-disabled .custom-select-tree-node-content-wrapper {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-select-tree .custom-select-tree-treenode-disabled .custom-select-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .custom-select-tree .custom-select-tree-treenode-active .custom-select-tree-node-content-wrapper {
    background: #f5f5f5;
  }
  .custom-select-tree .custom-select-tree-treenode:not(.custom-select-tree .custom-select-tree-treenode-disabled).filter-node .custom-select-tree-title {
    color: inherit;
    font-weight: 500;
  }
  .custom-select-tree .custom-select-tree-treenode-draggable .custom-select-tree-draggable-icon {
    width: 24px;
    line-height: 24px;
    text-align: center;
    visibility: visible;
    opacity: 0.2;
    transition: opacity 0.3s;
  }
  .custom-select-tree-treenode:hover .custom-select-tree .custom-select-tree-treenode-draggable .custom-select-tree-draggable-icon {
    opacity: 0.45;
  }
  .custom-select-tree-indent {
    align-self: stretch;
    white-space: nowrap;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-select-tree-indent-unit {
    display: inline-block;
    width: 24px;
  }
  .custom-select-tree-draggable-icon {
    visibility: hidden;
  }
  .custom-select-tree-switcher {
    position: relative;
    flex: none;
    align-self: stretch;
    width: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-select-tree-switcher .custom-tree-switcher-icon,
  .custom-select-tree-switcher .custom-select-tree-switcher-icon {
    display: inline-block;
    font-size: 10px;
    vertical-align: baseline;
  }
  .custom-select-tree-switcher .custom-tree-switcher-icon svg,
  .custom-select-tree-switcher .custom-select-tree-switcher-icon svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .custom-select-tree-switcher-noop {
    cursor: default;
  }
  .custom-select-tree-switcher_close .custom-select-tree-switcher-icon svg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .custom-select-tree-switcher-loading-icon {
    color: #1890ff;
    color: var(--custom-primary-color);
  }
  .custom-select-tree-switcher-leaf-line {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .custom-select-tree-switcher-leaf-line::before {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: -4px;
    margin-left: -1px;
    border-right: 1px solid #d9d9d9;
    content: ' ';
  }
  .custom-select-tree-switcher-leaf-line::after {
    position: absolute;
    width: 10px;
    height: 14px;
    border-bottom: 1px solid #d9d9d9;
    content: ' ';
  }
  .custom-select-tree-checkbox {
    top: auto;
    top: initial;
    margin: 4px 8px 0 0;
  }
  .custom-select-tree .custom-select-tree-node-content-wrapper {
    position: relative;
    z-index: auto;
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  }
  .custom-select-tree .custom-select-tree-node-content-wrapper:hover {
    background-color: #f5f5f5;
  }
  .custom-select-tree .custom-select-tree-node-content-wrapper.custom-select-tree-node-selected {
    background-color: #bae7ff;
    background-color: var(--custom-primary-2);
  }
  .custom-select-tree .custom-select-tree-node-content-wrapper .custom-select-tree-iconEle {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
  }
  .custom-select-tree .custom-select-tree-node-content-wrapper .custom-select-tree-iconEle:empty {
    display: none;
  }
  .custom-select-tree-unselectable .custom-select-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
  .custom-select-tree-node-content-wrapper {
    line-height: 24px;
    -webkit-user-select: none;
            user-select: none;
  }
  .custom-select-tree-node-content-wrapper .custom-tree-drop-indicator {
    position: absolute;
    z-index: 1;
    height: 2px;
    background-color: #1890ff;
    background-color: var(--custom-primary-color);
    border-radius: 1px;
    pointer-events: none;
  }
  .custom-select-tree-node-content-wrapper .custom-tree-drop-indicator::after {
    position: absolute;
    top: -3px;
    left: -6px;
    width: 8px;
    height: 8px;
    background-color: transparent;
    border: 2px solid #1890ff;
    border: 2px solid var(--custom-primary-color);
    border-radius: 50%;
    content: '';
  }
  .custom-select-tree .custom-select-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px #1890ff;
    box-shadow: 0 0 0 2px var(--custom-primary-color);
  }
  .custom-select-tree-show-line .custom-select-tree-indent-unit {
    position: relative;
    height: 100%;
  }
  .custom-select-tree-show-line .custom-select-tree-indent-unit::before {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: -4px;
    border-right: 1px solid #d9d9d9;
    content: '';
  }
  .custom-select-tree-show-line .custom-select-tree-indent-unit-end::before {
    display: none;
  }
  .custom-select-tree-show-line .custom-select-tree-switcher {
    background: #fff;
  }
  .custom-select-tree-show-line .custom-select-tree-switcher-line-icon {
    vertical-align: -0.15em;
  }
  .custom-select-tree .custom-select-tree-treenode-leaf-last .custom-select-tree-switcher-leaf-line::before {
    top: auto !important;
    bottom: auto !important;
    height: 14px !important;
  }
  .custom-tree-select-dropdown-rtl .custom-select-tree .custom-select-tree-switcher_close .custom-select-tree-switcher-icon svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .custom-tree-select-dropdown-rtl .custom-select-tree .custom-select-tree-switcher-loading-icon {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
  }
  .custom-typography {
    color: rgba(0, 0, 0, 0.85);
    overflow-wrap: break-word;
  }
  .custom-typography.custom-typography-secondary {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-typography.custom-typography-success {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-typography.custom-typography-warning {
    color: #faad14;
    color: var(--custom-warning-color);
  }
  .custom-typography.custom-typography-danger {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  a.custom-typography.custom-typography-danger:active,
  a.custom-typography.custom-typography-danger:focus {
    color: #d9363e;
    color: var(--custom-error-color-active);
  }
  a.custom-typography.custom-typography-danger:hover {
    color: #ff7875;
    color: var(--custom-error-color-hover);
  }
  .custom-typography.custom-typography-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    -webkit-user-select: none;
            user-select: none;
  }
  div.custom-typography,
  .custom-typography p {
    margin-bottom: 1em;
  }
  h1.custom-typography,
  div.custom-typography-h1,
  div.custom-typography-h1 > textarea,
  .custom-typography h1 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 38px;
    line-height: 1.23;
  }
  h2.custom-typography,
  div.custom-typography-h2,
  div.custom-typography-h2 > textarea,
  .custom-typography h2 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.35;
  }
  h3.custom-typography,
  div.custom-typography-h3,
  div.custom-typography-h3 > textarea,
  .custom-typography h3 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
  }
  h4.custom-typography,
  div.custom-typography-h4,
  div.custom-typography-h4 > textarea,
  .custom-typography h4 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
  }
  h5.custom-typography,
  div.custom-typography-h5,
  div.custom-typography-h5 > textarea,
  .custom-typography h5 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  }
  .custom-typography + h1.custom-typography,
  .custom-typography + h2.custom-typography,
  .custom-typography + h3.custom-typography,
  .custom-typography + h4.custom-typography,
  .custom-typography + h5.custom-typography {
    margin-top: 1.2em;
  }
  .custom-typography div + h1,
  .custom-typography ul + h1,
  .custom-typography li + h1,
  .custom-typography p + h1,
  .custom-typography h1 + h1,
  .custom-typography h2 + h1,
  .custom-typography h3 + h1,
  .custom-typography h4 + h1,
  .custom-typography h5 + h1,
  .custom-typography div + h2,
  .custom-typography ul + h2,
  .custom-typography li + h2,
  .custom-typography p + h2,
  .custom-typography h1 + h2,
  .custom-typography h2 + h2,
  .custom-typography h3 + h2,
  .custom-typography h4 + h2,
  .custom-typography h5 + h2,
  .custom-typography div + h3,
  .custom-typography ul + h3,
  .custom-typography li + h3,
  .custom-typography p + h3,
  .custom-typography h1 + h3,
  .custom-typography h2 + h3,
  .custom-typography h3 + h3,
  .custom-typography h4 + h3,
  .custom-typography h5 + h3,
  .custom-typography div + h4,
  .custom-typography ul + h4,
  .custom-typography li + h4,
  .custom-typography p + h4,
  .custom-typography h1 + h4,
  .custom-typography h2 + h4,
  .custom-typography h3 + h4,
  .custom-typography h4 + h4,
  .custom-typography h5 + h4,
  .custom-typography div + h5,
  .custom-typography ul + h5,
  .custom-typography li + h5,
  .custom-typography p + h5,
  .custom-typography h1 + h5,
  .custom-typography h2 + h5,
  .custom-typography h3 + h5,
  .custom-typography h4 + h5,
  .custom-typography h5 + h5 {
    margin-top: 1.2em;
  }
  a.custom-typography-ellipsis,
  span.custom-typography-ellipsis {
    display: inline-block;
    max-width: 100%;
  }
  a.custom-typography,
  .custom-typography a {
    color: #1890ff;
    color: var(--custom-primary-color);
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    text-decoration: none;
  }
  a.custom-typography:focus-visible,
  .custom-typography a:focus-visible,
  a.custom-typography:hover,
  .custom-typography a:hover {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
  }
  a.custom-typography:active,
  .custom-typography a:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
  }
  a.custom-typography:active,
  .custom-typography a:active,
  a.custom-typography:hover,
  .custom-typography a:hover {
    text-decoration: none;
  }
  a.custom-typography[disabled],
  .custom-typography a[disabled],
  a.custom-typography.custom-typography-disabled,
  .custom-typography a.custom-typography-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  a.custom-typography[disabled]:active,
  .custom-typography a[disabled]:active,
  a.custom-typography.custom-typography-disabled:active,
  .custom-typography a.custom-typography-disabled:active,
  a.custom-typography[disabled]:hover,
  .custom-typography a[disabled]:hover,
  a.custom-typography.custom-typography-disabled:hover,
  .custom-typography a.custom-typography-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }
  a.custom-typography[disabled]:active,
  .custom-typography a[disabled]:active,
  a.custom-typography.custom-typography-disabled:active,
  .custom-typography a.custom-typography-disabled:active {
    pointer-events: none;
  }
  .custom-typography code {
    margin: 0 0.2em;
    padding: 0.2em 0.4em 0.1em;
    font-size: 85%;
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
  }
  .custom-typography kbd {
    margin: 0 0.2em;
    padding: 0.15em 0.4em 0.1em;
    font-size: 90%;
    background: rgba(150, 150, 150, 0.06);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-bottom-width: 2px;
    border-radius: 3px;
  }
  .custom-typography mark {
    padding: 0;
    background-color: #ffe58f;
  }
  .custom-typography u,
  .custom-typography ins {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
  }
  .custom-typography s,
  .custom-typography del {
    text-decoration: line-through;
  }
  .custom-typography strong {
    font-weight: 600;
  }
  .custom-typography-expand,
  .custom-typography-edit,
  .custom-typography-copy {
    color: #1890ff;
    color: var(--custom-primary-color);
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    margin-left: 4px;
  }
  .custom-typography-expand:focus-visible,
  .custom-typography-edit:focus-visible,
  .custom-typography-copy:focus-visible,
  .custom-typography-expand:hover,
  .custom-typography-edit:hover,
  .custom-typography-copy:hover {
    color: #40a9ff;
    color: var(--custom-primary-color-hover);
  }
  .custom-typography-expand:active,
  .custom-typography-edit:active,
  .custom-typography-copy:active {
    color: #096dd9;
    color: var(--custom-primary-color-active);
  }
  .custom-typography-copy-success,
  .custom-typography-copy-success:hover,
  .custom-typography-copy-success:focus {
    color: #52c41a;
    color: var(--custom-success-color);
  }
  .custom-typography-edit-content {
    position: relative;
  }
  div.custom-typography-edit-content {
    left: -12px;
    margin-top: -5px;
    margin-bottom: calc(1em - 4px - 1px);
  }
  .custom-typography-edit-content-confirm {
    position: absolute;
    right: 10px;
    bottom: 8px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    pointer-events: none;
  }
  .custom-typography-edit-content textarea {
    height: 1em;
    margin: 0 !important;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-transition: none;
  }
  .custom-typography ul,
  .custom-typography ol {
    margin: 0 0 1em;
    padding: 0;
  }
  .custom-typography ul li,
  .custom-typography ol li {
    margin: 0 0 0 20px;
    padding: 0 0 0 4px;
  }
  .custom-typography ul {
    list-style-type: circle;
  }
  .custom-typography ul ul {
    list-style-type: disc;
  }
  .custom-typography ol {
    list-style-type: decimal;
  }
  .custom-typography pre,
  .custom-typography blockquote {
    margin: 1em 0;
  }
  .custom-typography pre {
    padding: 0.4em 0.6em;
    white-space: pre-wrap;
    word-wrap: break-word;
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
  }
  .custom-typography pre code {
    display: inline;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    background: transparent;
    border: 0;
  }
  .custom-typography blockquote {
    padding: 0 0 0 0.6em;
    border-left: 4px solid rgba(100, 100, 100, 0.2);
    opacity: 0.85;
  }
  .custom-typography-single-line {
    white-space: nowrap;
  }
  .custom-typography-ellipsis-single-line {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a.custom-typography-ellipsis-single-line,
  span.custom-typography-ellipsis-single-line {
    vertical-align: bottom;
  }
  .custom-typography-ellipsis-multiple-line {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
  .custom-typography-rtl {
    direction: rtl;
  }
  .custom-typography-rtl .custom-typography-expand,
  .custom-typography-rtl .custom-typography-edit,
  .custom-typography-rtl .custom-typography-copy {
    margin-right: 4px;
    margin-left: 0;
  }
  .custom-typography-rtl .custom-typography-expand {
    float: left;
  }
  div.custom-typography-edit-content.custom-typography-rtl {
    right: -12px;
    left: auto;
  }
  .custom-typography-rtl .custom-typography-edit-content-confirm {
    right: auto;
    left: 10px;
  }
  .custom-typography-rtl.custom-typography ul li,
  .custom-typography-rtl.custom-typography ol li {
    margin: 0 20px 0 0;
    padding: 0 4px 0 0;
  }
  .custom-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    outline: 0;
  }
  .custom-upload p {
    margin: 0;
  }
  .custom-upload-btn {
    display: block;
    width: 100%;
    outline: none;
  }
  .custom-upload input[type='file'] {
    cursor: pointer;
  }
  .custom-upload.custom-upload-select {
    display: inline-block;
  }
  .custom-upload.custom-upload-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .custom-upload.custom-upload-select-picture-card {
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .custom-upload.custom-upload-select-picture-card > .custom-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  .custom-upload.custom-upload-select-picture-card:hover {
    border-color: #1890ff;
    border-color: var(--custom-primary-color);
  }
  .custom-upload-disabled.custom-upload.custom-upload-select-picture-card:hover {
    border-color: #d9d9d9;
  }
  .custom-upload.custom-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .custom-upload.custom-upload-drag .custom-upload {
    padding: 16px 0;
  }
  .custom-upload.custom-upload-drag.custom-upload-drag-hover:not(.custom-upload-disabled) {
    border-color: #096dd9;
    border-color: var(--custom-primary-7);
  }
  .custom-upload.custom-upload-drag.custom-upload-disabled {
    cursor: not-allowed;
  }
  .custom-upload.custom-upload-drag .custom-upload-btn {
    display: table;
    height: 100%;
  }
  .custom-upload.custom-upload-drag .custom-upload-drag-container {
    display: table-cell;
    vertical-align: middle;
  }
  .custom-upload.custom-upload-drag:not(.custom-upload-disabled):hover {
    border-color: #40a9ff;
    border-color: var(--custom-primary-5);
  }
  .custom-upload.custom-upload-drag p.custom-upload-drag-icon {
    margin-bottom: 20px;
  }
  .custom-upload.custom-upload-drag p.custom-upload-drag-icon .anticon {
    color: #40a9ff;
    color: var(--custom-primary-5);
    font-size: 48px;
  }
  .custom-upload.custom-upload-drag p.custom-upload-text {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
  .custom-upload.custom-upload-drag p.custom-upload-hint {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .custom-upload.custom-upload-drag .anticon-plus {
    color: rgba(0, 0, 0, 0.25);
    font-size: 30px;
    transition: all 0.3s;
  }
  .custom-upload.custom-upload-drag .anticon-plus:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-upload.custom-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, 0.45);
  }
  .custom-upload-picture-card-wrapper {
    display: inline-block;
    width: 100%;
  }
  .custom-upload-picture-card-wrapper::before {
    display: table;
    content: '';
  }
  .custom-upload-picture-card-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-upload-picture-card-wrapper::before {
    display: table;
    content: '';
  }
  .custom-upload-picture-card-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-upload-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
            font-feature-settings: 'tnum', "tnum";
    line-height: 1.5715;
  }
  .custom-upload-list::before {
    display: table;
    content: '';
  }
  .custom-upload-list::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-upload-list::before {
    display: table;
    content: '';
  }
  .custom-upload-list::after {
    display: table;
    clear: both;
    content: '';
  }
  .custom-upload-list-item {
    position: relative;
    height: 22.001px;
    margin-top: 8px;
    font-size: 14px;
  }
  .custom-upload-list-item-name {
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    overflow: hidden;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-upload-list-item-card-actions {
    position: absolute;
    right: 0;
  }
  .custom-upload-list-item-card-actions-btn {
    opacity: 0;
  }
  .custom-upload-list-item-card-actions-btn.custom-btn-sm {
    height: 22.001px;
    line-height: 1;
    vertical-align: top;
  }
  .custom-upload-list-item-card-actions.picture {
    top: 22px;
    line-height: 0;
  }
  .custom-upload-list-item-card-actions-btn:focus,
  .custom-upload-list-item-card-actions.picture .custom-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .custom-upload-list-item-card-actions .anticon {
    color: rgba(0, 0, 0, 0.45);
    transition: all 0.3s;
  }
  .custom-upload-list-item-card-actions:hover .anticon {
    color: rgba(0, 0, 0, 0.85);
  }
  .custom-upload-list-item-info {
    height: 100%;
    transition: background-color 0.3s;
  }
  .custom-upload-list-item-info > span {
    display: block;
    width: 100%;
    height: 100%;
  }
  .custom-upload-list-item-info .anticon-loading .anticon,
  .custom-upload-list-item-info .custom-upload-text-icon .anticon {
    position: absolute;
    top: 5px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .custom-upload-list-item:hover .custom-upload-list-item-info {
    background-color: #f5f5f5;
  }
  .custom-upload-list-item:hover .custom-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .custom-upload-list-item-error,
  .custom-upload-list-item-error .custom-upload-text-icon > .anticon,
  .custom-upload-list-item-error .custom-upload-list-item-name {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-upload-list-item-error .custom-upload-list-item-card-actions .anticon {
    color: #ff4d4f;
    color: var(--custom-error-color);
  }
  .custom-upload-list-item-error .custom-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .custom-upload-list-item-progress {
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding-left: 26px;
    font-size: 14px;
    line-height: 0;
  }
  .custom-upload-list-picture .custom-upload-list-item,
  .custom-upload-list-picture-card .custom-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .custom-upload-list-picture .custom-upload-list-item:hover,
  .custom-upload-list-picture-card .custom-upload-list-item:hover {
    background: transparent;
  }
  .custom-upload-list-picture .custom-upload-list-item-error,
  .custom-upload-list-picture-card .custom-upload-list-item-error {
    border-color: #ff4d4f;
    border-color: var(--custom-error-color);
  }
  .custom-upload-list-picture .custom-upload-list-item-info,
  .custom-upload-list-picture-card .custom-upload-list-item-info {
    padding: 0;
  }
  .custom-upload-list-picture .custom-upload-list-item:hover .custom-upload-list-item-info,
  .custom-upload-list-picture-card .custom-upload-list-item:hover .custom-upload-list-item-info {
    background: transparent;
  }
  .custom-upload-list-picture .custom-upload-list-item-uploading,
  .custom-upload-list-picture-card .custom-upload-list-item-uploading {
    border-style: dashed;
  }
  .custom-upload-list-picture .custom-upload-list-item-thumbnail,
  .custom-upload-list-picture-card .custom-upload-list-item-thumbnail {
    width: 48px;
    height: 48px;
    line-height: 60px;
    text-align: center;
    opacity: 0.8;
  }
  .custom-upload-list-picture .custom-upload-list-item-thumbnail .anticon,
  .custom-upload-list-picture-card .custom-upload-list-item-thumbnail .anticon {
    font-size: 26px;
  }
  .custom-upload-list-picture .custom-upload-list-item-error .custom-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
  .custom-upload-list-picture-card .custom-upload-list-item-error .custom-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
    fill: #fff2f0;
    fill: var(--custom-error-color-deprecated-bg);
  }
  .custom-upload-list-picture .custom-upload-list-item-error .custom-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
  .custom-upload-list-picture-card .custom-upload-list-item-error .custom-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
    fill: #ff4d4f;
    fill: var(--custom-error-color);
  }
  .custom-upload-list-picture .custom-upload-list-item-icon,
  .custom-upload-list-picture-card .custom-upload-list-item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .custom-upload-list-picture .custom-upload-list-item-icon .anticon,
  .custom-upload-list-picture-card .custom-upload-list-item-icon .anticon {
    font-size: 26px;
  }
  .custom-upload-list-picture .custom-upload-list-item-image,
  .custom-upload-list-picture-card .custom-upload-list-item-image {
    max-width: 100%;
  }
  .custom-upload-list-picture .custom-upload-list-item-thumbnail img,
  .custom-upload-list-picture-card .custom-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden;
  }
  .custom-upload-list-picture .custom-upload-list-item-name,
  .custom-upload-list-picture-card .custom-upload-list-item-name {
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    overflow: hidden;
    line-height: 44px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
  }
  .custom-upload-list-picture .custom-upload-list-item-uploading .custom-upload-list-item-name,
  .custom-upload-list-picture-card .custom-upload-list-item-uploading .custom-upload-list-item-name {
    margin-bottom: 12px;
  }
  .custom-upload-list-picture .custom-upload-list-item-progress,
  .custom-upload-list-picture-card .custom-upload-list-item-progress {
    bottom: 14px;
    width: calc(100% - 24px);
    margin-top: 0;
    padding-left: 56px;
  }
  .custom-upload-list-picture-card-container {
    display: inline-block;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    vertical-align: top;
  }
  .custom-upload-list-picture-card .custom-upload-list-item {
    height: 100%;
    margin: 0;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-info::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s;
    content: ' ';
  }
  .custom-upload-list-picture-card .custom-upload-list-item:hover .custom-upload-list-item-info::before {
    opacity: 1;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-actions .anticon-eye,
  .custom-upload-list-picture-card .custom-upload-list-item-actions .anticon-download,
  .custom-upload-list-picture-card .custom-upload-list-item-actions .anticon-delete {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-actions .anticon-eye:hover,
  .custom-upload-list-picture-card .custom-upload-list-item-actions .anticon-download:hover,
  .custom-upload-list-picture-card .custom-upload-list-item-actions .anticon-delete:hover {
    color: #fff;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-info:hover + .custom-upload-list-item-actions,
  .custom-upload-list-picture-card .custom-upload-list-item-actions:hover {
    opacity: 1;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-thumbnail,
  .custom-upload-list-picture-card .custom-upload-list-item-thumbnail img {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-name {
    display: none;
    margin: 8px 0 0;
    padding: 0;
    line-height: 1.5715;
    text-align: center;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-file + .custom-upload-list-item-name {
    position: absolute;
    bottom: 10px;
    display: block;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-uploading.custom-upload-list-item {
    background-color: #fafafa;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-uploading .custom-upload-list-item-info {
    height: auto;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-uploading .custom-upload-list-item-info::before,
  .custom-upload-list-picture-card .custom-upload-list-item-uploading .custom-upload-list-item-info .anticon-eye,
  .custom-upload-list-picture-card .custom-upload-list-item-uploading .custom-upload-list-item-info .anticon-delete {
    display: none;
  }
  .custom-upload-list-picture-card .custom-upload-list-item-progress {
    bottom: 32px;
    width: calc(100% - 14px);
    padding-left: 0;
  }
  .custom-upload-list-text-container,
  .custom-upload-list-picture-container {
    transition: opacity 0.3s, height 0.3s;
  }
  .custom-upload-list-text-container::before,
  .custom-upload-list-picture-container::before {
    display: table;
    width: 0;
    height: 0;
    content: '';
  }
  .custom-upload-list-text-container .custom-upload-span,
  .custom-upload-list-picture-container .custom-upload-span {
    display: block;
    flex: auto;
  }
  .custom-upload-list-text .custom-upload-span,
  .custom-upload-list-picture .custom-upload-span {
    display: flex;
    align-items: center;
  }
  .custom-upload-list-text .custom-upload-span > *,
  .custom-upload-list-picture .custom-upload-span > * {
    flex: none;
  }
  .custom-upload-list-text .custom-upload-list-item-name,
  .custom-upload-list-picture .custom-upload-list-item-name {
    flex: auto;
    margin: 0;
    padding: 0 8px;
  }
  .custom-upload-list-text .custom-upload-list-item-card-actions,
  .custom-upload-list-picture .custom-upload-list-item-card-actions {
    position: static;
  }
  .custom-upload-list-text .custom-upload-text-icon .anticon {
    position: static;
  }
  .custom-upload-list .custom-upload-animate-inline-appear,
  .custom-upload-list .custom-upload-animate-inline-enter,
  .custom-upload-list .custom-upload-animate-inline-leave {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .custom-upload-list .custom-upload-animate-inline-appear,
  .custom-upload-list .custom-upload-animate-inline-enter {
    -webkit-animation-name: uploadAnimateInlineIn;
            animation-name: uploadAnimateInlineIn;
  }
  .custom-upload-list .custom-upload-animate-inline-leave {
    -webkit-animation-name: uploadAnimateInlineOut;
            animation-name: uploadAnimateInlineOut;
  }
  @-webkit-keyframes uploadAnimateInlineIn {
    from {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes uploadAnimateInlineIn {
    from {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes uploadAnimateInlineOut {
    to {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes uploadAnimateInlineOut {
    to {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  .custom-upload-rtl {
    direction: rtl;
  }
  .custom-upload-rtl.custom-upload.custom-upload-select-picture-card {
    margin-right: auto;
    margin-left: 8px;
  }
  .custom-upload-list-rtl {
    direction: rtl;
  }
  .custom-upload-list-rtl .custom-upload-list-item-list-type-text:hover .custom-upload-list-item-name-icon-count-1 {
    padding-right: 22px;
    padding-left: 14px;
  }
  .custom-upload-list-rtl .custom-upload-list-item-list-type-text:hover .custom-upload-list-item-name-icon-count-2 {
    padding-right: 22px;
    padding-left: 28px;
  }
  .custom-upload-list-rtl .custom-upload-list-item-name {
    padding-right: 22px;
    padding-left: 0;
  }
  .custom-upload-list-rtl .custom-upload-list-item-name-icon-count-1 {
    padding-left: 14px;
  }
  .custom-upload-list-rtl .custom-upload-list-item-card-actions {
    right: auto;
    left: 0;
  }
  .custom-upload-list-rtl .custom-upload-list-item-card-actions .anticon {
    padding-right: 0;
    padding-left: 5px;
  }
  .custom-upload-list-rtl .custom-upload-list-item-info {
    padding: 0 4px 0 12px;
  }
  .custom-upload-list-rtl .custom-upload-list-item-error .custom-upload-list-item-card-actions .anticon {
    padding-right: 0;
    padding-left: 5px;
  }
  .custom-upload-list-rtl .custom-upload-list-item-progress {
    padding-right: 26px;
    padding-left: 0;
  }
  .custom-upload-list-picture .custom-upload-list-item-info,
  .custom-upload-list-picture-card .custom-upload-list-item-info {
    padding: 0;
  }
  .custom-upload-list-rtl.custom-upload-list-picture .custom-upload-list-item-thumbnail,
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-thumbnail {
    right: 8px;
    left: auto;
  }
  .custom-upload-list-rtl.custom-upload-list-picture .custom-upload-list-item-icon,
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-icon {
    right: 50%;
    left: auto;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
  }
  .custom-upload-list-rtl.custom-upload-list-picture .custom-upload-list-item-name,
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-name {
    margin: 0 8px 0 0;
    padding-right: 48px;
    padding-left: 8px;
  }
  .custom-upload-list-rtl.custom-upload-list-picture .custom-upload-list-item-name-icon-count-1,
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-name-icon-count-1 {
    padding-right: 48px;
    padding-left: 18px;
  }
  .custom-upload-list-rtl.custom-upload-list-picture .custom-upload-list-item-name-icon-count-2,
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-name-icon-count-2 {
    padding-right: 48px;
    padding-left: 36px;
  }
  .custom-upload-list-rtl.custom-upload-list-picture .custom-upload-list-item-progress,
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-progress {
    padding-right: 0;
    padding-left: 0;
  }
  .custom-upload-list-rtl .custom-upload-list-picture-card-container {
    margin: 0 0 8px 8px;
  }
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-actions {
    right: 50%;
    left: auto;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
  }
  .custom-upload-list-rtl.custom-upload-list-picture-card .custom-upload-list-item-file + .custom-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0;
  }
  