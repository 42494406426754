.order-items-container[data-v-cb91db2e] {
    margin: 25px auto;
    width: 1200px;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
}
.order-main-title[data-v-cb91db2e] {
    color: grey;
    font-weight: bold;
    font-size: small;
}
.order-item-title[data-v-cb91db2e] {
    color: grey;
    font-weight: bold;
    font-size: small;
    width: 35%;
    text-align: end;
}
.order-item-subtitle[data-v-cb91db2e] {
    font-size: small;
    width: 55%;
}
.customize[data-v-cb91db2e] {
    font-size: smaller;
    font-weight: bold;
    cursor: pointer;
    color: var(--custom-primary-7);
}
.external-box-container[data-v-cb91db2e] {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.inner-box-container[data-v-cb91db2e] {
    display: flex;
}
.inner-box-item[data-v-cb91db2e] {
    display: flex;
    flex-direction: column;
}
.switch-increase[data-v-cb91db2e] {
    display: flex;
    width: 120px;
    margin-left: 50px;
    /* margin-right: 50px; */
}
.switch-increase-inner[data-v-cb91db2e] {
    width: 100%;
    text-align: center;
    line-height: 25px;
}
@media screen and (max-width: 1200px) {
.order-items-container[data-v-cb91db2e] {
        margin: auto;
        width: auto;
        border: none;
}
.order-item-subtitle[data-v-cb91db2e] {
        width: 30%;
}
.order-item-title[data-v-cb91db2e] {
        width: 20%;
}
}