.justify-space[data-v-9081bdd8] {
    display: flex;
    justify-content: space-between;
    padding: 15px 5px 15px 5px;
}
.justify-center[data-v-9081bdd8] {
    display: flex;
    justify-content: center;
    padding: 15px 5px 15px 5px;
}
.external-container[data-v-9081bdd8] {
    margin: auto;
    padding: 0px 20px 0px 20px;
    width: 1200px;
    background-color: white;
}
.confirm-btn[data-v-9081bdd8] {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 20px auto;
    padding: 1% 3% 1% 4%;
    box-shadow: 0 3px 15px rgb(0 0 0 / 0.1);
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--custom-primary-color);

    -webkit-animation: idle-animation-data-v-9081bdd8 1s infinite;

            animation: idle-animation-data-v-9081bdd8 1s infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}
.confirm-btn-title[data-v-9081bdd8] {
    color: white;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: lighter;
}
.icon[data-v-9081bdd8] {
    display: block;
    margin: auto;
}
.bank-images[data-v-9081bdd8] {
    display: block;
    margin: 20px auto 20px auto;
    width: 80%;
}
.input-holder[data-v-9081bdd8] {
    padding: 7px 9px 7px 9px;
}
.text-highlight[data-v-9081bdd8] {
    color: red;
    text-decoration: underline;
}
.text-tips[data-v-9081bdd8] {
    font-size: 10px;
}
@-webkit-keyframes idle-animation-data-v-9081bdd8 {
0% {
        -webkit-transform: translate(-2px);
                transform: translate(-2px);
}
100% {
        -webkit-transform: translate(2px);
                transform: translate(2px);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
}
}
@keyframes idle-animation-data-v-9081bdd8 {
0% {
        -webkit-transform: translate(-2px);
                transform: translate(-2px);
}
100% {
        -webkit-transform: translate(2px);
                transform: translate(2px);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
}
}
@media screen and (max-width: 1200px) {
.external-container[data-v-9081bdd8] {
        width: auto;
}
.confirm-btn[data-v-9081bdd8] {
        width: 100%;
}
}