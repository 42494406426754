.item-list-container[data-v-e659e8d4] {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px 15px 150px 15px;
}
.item-container[data-v-e659e8d4] {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 7px;
    background-color: white;
    text-align: center;
    margin: 5px !important;
    border-radius: 3px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
}
.item-image[data-v-e659e8d4] {
    overflow: hidden;
}
.item-description[data-v-e659e8d4] {
    padding-top: 10px;
    padding-left: 20px;
    text-align: left;
    word-wrap: break-word;
}
.item-description-group[data-v-e659e8d4] {
    display: flex;
    flex-wrap: wrap;
}
.item-description-group-icon[data-v-e659e8d4] {
    margin-left: auto;
    margin-right: 25px;
}
.item-description-title[data-v-e659e8d4] {
    font-weight: 1000;
    font-size: 20px;
    display: block;
}
.item-description-long[data-v-e659e8d4] {
    font-size: 13px;
    display: block;
}
.item-description-subtitle[data-v-e659e8d4] {
    font-weight: 600;
    font-size: 15px;
    color: rgb(255, 0, 106);
}
.item-description-info[data-v-e659e8d4] {
    color: rgb(255, 0, 106);
    font-size: 20px;
}
.item-icon[data-v-e659e8d4] {
    font-size: 20px;
    margin-bottom: -4.5px;
    margin-left: 15px
}
.item-btn[data-v-e659e8d4] {
    margin: auto;
    margin-bottom: 5px;
    width: 95%;
}
.grid-container[data-v-e659e8d4] {
    overflow-y: auto;
}
.gridbar[data-v-e659e8d4] {
    padding-left: 25px;
    padding-right: 25px;
}
.gridtitle[data-v-e659e8d4] {
    padding-top: 10px;
    display: block;
    font-size: 6vw;
    font-weight: bold;
}
.gridsubtitle[data-v-e659e8d4] {
    display: block;
    margin-bottom: 15px;
    font-size: 4vw;
}
.divider[data-v-e659e8d4] {
    margin-top: 10x;
    margin-bottom: 15px;
}
@media screen and (min-width: 750px) {
.gridtitle[data-v-e659e8d4] {
        font-size: 3vw;
}
.gridsubtitle[data-v-e659e8d4] {
        font-size: 2vw;
}
@media screen and (min-width: 1450px) {
.gridtitle[data-v-e659e8d4] {
            font-size: 2vw;
}
.gridsubtitle[data-v-e659e8d4] {
            font-size: 1vw;
}
}
}