.flex-top-bar[data-v-c55b9695] {
    display: flex;
    justify-content: center;
    box-shadow: 0 5px 10px 5px rgb(0 0 0 / 5%);
    padding: 20px 0px 10px 0px;
}
.flex-group[data-v-c55b9695] {
    display: flex;
    align-items: center;
    line-height: 30px;
}
.flex-title[data-v-c55b9695] {
    font-size: 30px;
    font-weight: 600;
}
.flex-subtitle[data-v-c55b9695] {
    display: block;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
}
.flex-title-icon[data-v-c55b9695] {
    font-size: 25px;
    margin-right: 15px;
    color: var(--custom-primary-color);
}